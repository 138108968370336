import React, { Component } from 'react';
import { connect } from 'react-redux';

// Redux
import { fetchTable, fetchNews } from 'redux/actions/informerNews';

// Utils
import { withTranslation } from 'react-i18next';
import { getDaysRange, getDaysAgoRange, datesArrayToUnix } from 'utils/date';
import { Descriptions, Icon } from 'antd';

// Components
import { informerNewsHeader } from '../../components/Table/config/tables';
import PageTitle from '../../components/PageTitle';
import LinearChart from '../../components/LinearChart';
import Table from '../../components/Table';

class InformerNews extends Component {
  constructor(props) {
    super(props);

    this.state = {
      calendarDates: getDaysAgoRange(),
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { newsId },
      },
    } = this.props;

    this.handleUpdateAll(datesArrayToUnix(getDaysAgoRange()));

    this.props.fetchNews(newsId);
  }

  handleUpdateAll = dates => {
    const { props } = this;
    const { newsId, publisherId } = props.match.params;

    props.fetchTable({
      start: +dates[0],
      end: +dates[1],
      newsId,
      publisherId,
      isWithChart: true,
    });

    this.setState({
      calendarDates: getDaysRange(dates),
    });
  };

  handleFetch = (page, sortBy, sortDirection) => {
    const { props } = this;
    const { table } = props;
    const {
      match: {
        params: { publisherId, newsId },
      },
    } = props;
    const { start, end } = table;

    props.fetchTable({
      start,
      end,
      newsId,
      publisherId,
      sortBy,
      sortDirection,
    });
  };

  render() {
    const { props, state } = this;
    const { news, table, t } = props;
    const { isLoading } = table;

    return (
      <div className="page-body main-page">
        <PageTitle
          title={`${t('content.title.news')}: "${news.title.slice(0, 25)}${
            news.title.length > 25 ? '...' : ''
          }"`}
          hint={t('content.titleHint.news')}
        />
        <Descriptions bordered column={1} size="small">
          <Descriptions.Item label={t('basic.title')}>
            {news.title}
          </Descriptions.Item>
          <Descriptions.Item label={t('basic.link')}>
            {news.url}
            <a
              target="_blank"
              href={news.url}
              rel="noopener noreferrer"
              style={{ float: 'right' }}
            >
              <Icon type="link" />
            </a>
          </Descriptions.Item>
        </Descriptions>
        <br />
        <br />
        <LinearChart
          isLoading={isLoading}
          categoriesForX={state.calendarDates}
          onChange={this.handleUpdateAll}
          data={props.chart}
          rightTitle="CTR"
          showKeys={[
            'views',
            'clickOn',
            'clickFrom',
            'viewsInformer',
            'viewsRotator',
            'viewsPreview',
            'clicksInformer',
            'clicksRotator',
            'clicksPreview',
            'ctrInformer',
            'ctrRotator',
            'ctrPreview',
          ]}
        />
        <Table
          isLoading={isLoading}
          isClientPagination
          headers={informerNewsHeader}
          data={props.table.data}
          total={props.table.total}
          limit={props.table.limit}
          fetch={this.handleFetch}
          rowKey="date"
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    chart: state.informerNews.chart,
    table: state.informerNews.table,
    news: state.informerNews.info,
  };
}

const mapDispatchToProps = {
  fetchTable,
  fetchNews,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(InformerNews));
