import React from 'react';
import ReactDOM from 'react-dom';
import { UserLeaveConfirmationModal } from './components/UserLeaveConfirmationModal';

const parseMessage = message => {
  let result = {
    title: 'Attention',
    content: message,
  };

  try {
    result = {
      ...result,
      ...JSON.parse(message),
    };
  } catch (e) {} // eslint-disable-line no-empty

  return result;
};

export const UserLeaveConfirmation = (
  message,
  callback,
  confirmOpen,
  setConfirmOpen,
) => {
  const container = document.createElement('div');
  const preparedMessage = parseMessage(message);

  container.setAttribute('custom-confirm-view', '');

  const handleConfirm = callbackState => {
    ReactDOM.unmountComponentAtNode(container);
    callback(callbackState);
    setConfirmOpen(false);
  };

  const handleCancel = () => {
    ReactDOM.unmountComponentAtNode(container);
    callback();
    setConfirmOpen(false);
  };

  document.body.appendChild(container);

  ReactDOM.render(
    <UserLeaveConfirmationModal
      title={preparedMessage.title}
      content={preparedMessage.content || message}
      isVisible={confirmOpen}
      onSubmit={handleConfirm}
      onCancel={handleCancel}
    />,
    container,
  );
};
