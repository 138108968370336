import React from 'react';
import { string, oneOfType, element } from 'prop-types';
import Tooltip from '../../Tooltip';

const TableColumnTitle = ({ title, tooltip }) => (
  <>
    {tooltip && <Tooltip tooltip={tooltip} />}
    <span className="ant-table-column-title-text">{title}</span>
  </>
);

TableColumnTitle.propTypes = {
  title: oneOfType([string, element]),
};

export default TableColumnTitle;
