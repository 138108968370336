import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getStartStatus } from '../../../redux/helpers/statuses';
import * as T from '../../../redux/types';

// Components
import { AbTestLogicForm } from '../../Form/forms/AddAbTestLogicForm';

export const AddAbTestLogicModal = ({
  fields,
  onClose,
  actions,
  success,
  isEditMode,
}) => {
  const { t } = useTranslation();
  const net = useSelector(({ network }) => network);
  const isLoading =
    net[getStartStatus(T.EDIT_AB_TEST_LOGIC)] ||
    net[getStartStatus(T.ADD_AB_TEST_LOGIC)];

  const handleSubmit = form =>
    actions
      .onSubmit(form)
      .then(response => response?.payload?.status && onClose())
      .then(success.onSubmit);

  return (
    <>
      <h3>
        {t(
          `form.title.${
            Object.keys(fields).length ? 'edit' : 'add'
          }AbTestLogic`,
        )}
      </h3>
      <AbTestLogicForm
        formData={fields}
        onSubmit={handleSubmit}
        onCancel={onClose}
        isLoading={isLoading}
        isEditMode={isEditMode}
      />
    </>
  );
};

AddAbTestLogicModal.propTypes = {
  onClose: PropTypes.func,
  success: PropTypes.shape({
    onSubmit: PropTypes.func,
  }),
};

AddAbTestLogicModal.defaultProps = {
  onClose: () => {},
  success: {
    onSubmit: () => {},
  },
};
