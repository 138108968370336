import { Icon } from 'antd';
import React from 'react';
import { string, oneOfType, element } from 'prop-types';

// Utils
import routesPath from '../../../config/routePaths';

// Components
import LinkWithTag from '../../LinkWithTag';

export const MenuItemContent = ({ keyType, icon, title }) => (
  <LinkWithTag href={routesPath[keyType]}>
    {icon && <Icon type={icon} theme="outlined" />}
    <span>{title}</span>
  </LinkWithTag>
);

MenuItemContent.propTypes = {
  keyType: string,
  title: oneOfType([string, element]),
  icon: string,
};
