import { Button, Icon } from 'antd';
import React, { useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { setChartVisibility } from '../../../services/localStorage';

const ChartToggle = ({ isVisibleChart, setIsVisibleChart, t }) => {
  const handleSetChartVisibility = useCallback(() => {
    setChartVisibility({ [window.location.pathname]: !isVisibleChart });
    setIsVisibleChart(!isVisibleChart);
  }, [isVisibleChart, setIsVisibleChart]);

  return (
    <Button
      type="link"
      className={`highcharts-toggle highcharts-toggle--${!isVisibleChart &&
        'hidden'}`}
      onClick={handleSetChartVisibility}
    >
      <Icon type="line-chart" />
      {t(`basic.${isVisibleChart ? 'hide' : 'show'}`)}&nbsp;
      {t('basic.chart').toLowerCase()}
    </Button>
  );
};

export default withTranslation()(ChartToggle);
