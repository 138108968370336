import { string } from 'prop-types';
import React from 'react';
import Head from './components/Head';

const CreateHead = ({
  title = 'Super Admin',
  description = 'Page displays superadmin',
  image = `${process.env.REACT_APP_API_APP_URL}/static/media/logo.3276ab9d.svg`,
  url = process.env.REACT_APP_API_APP_URL,
}) => (
  <Head>
    <meta property="og:title" content={title} />
    <meta property="og:url" content={url} />
    <meta property="og:image" content={image} />
    <meta property="og:description" content={description} />
    <meta property="og:type" content="article" />
    <meta name="twitter:card" content={image} />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image" content={image} />
    <title>{title}</title>
    <meta name="description" content={description} />
  </Head>
);

CreateHead.propTypes = {
  title: string,
  description: string,
  image: string,
  url: string,
};

export default CreateHead;
