/* eslint-disable indent */

import { monthEarlierDate, todayDate } from 'utils/date';
import { convertAllKeysToChartsData } from '../../services/formatter';
import {
  SUCCESS_FETCH_PUBLISHERS_TABLE,
  SUCCESS_FETCH_PUBLISHERS_CHART,
  SUCCESS_EDIT_PUBLISHER,
  SUCCESS_FETCH_PUBLISHER,
  SUCCESS_CHOICE_PUBLISHER,
} from '../types';

const initialState = {
  chart: {},
  table: {
    total: 0,
    limit: 20,
    offset: 0,
    start: +monthEarlierDate,
    end: +todayDate,
    data: [],
    isLoading: false,
  },
  info: {},
};

export default function publishers(state = initialState, action = {}) {
  switch (action.type) {
    // Fetch publisher
    // ________________________________________________________
    case SUCCESS_FETCH_PUBLISHER:
      return {
        ...state,
        info: action.payload.data,
      };

    case SUCCESS_CHOICE_PUBLISHER:
      return {
        ...state,
        info: {
          ...state.info,
          ...(action.payload.data && action.payload.data[0]),
        },
      };

    // Fetch table publishers
    // ________________________________________________________
    case SUCCESS_FETCH_PUBLISHERS_TABLE: {
      // CHANGE TO DATA AFTER
      const { data, ...rest } = action.payload;
      return {
        ...state,
        table: {
          ...state.table,
          ...rest,
          data,
        },
      };
    }

    // Edit  publisher in table
    // ________________________________________________________

    case SUCCESS_EDIT_PUBLISHER:
      return {
        ...state,
        table: {
          ...state.table,
          data: action.payload,
        },
      };

    // Fetch chart data
    // ________________________________________________________

    case SUCCESS_FETCH_PUBLISHERS_CHART:
      return {
        ...state,
        chart: convertAllKeysToChartsData(action.payload.data),
      };

    default:
      return state;
  }
}
