import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Layout, Menu, Icon, Badge } from 'antd';
import { withTranslation } from 'react-i18next';

// Redux
import {
  setSidebarVisibilityLoc,
  getSidebarVisibilityLoc,
} from '../../redux/actions/userSettings';

// Utils
import { types } from '../../config/routePaths';
import ThemeSwitcher from './components/ThemeSwitcher';

// Helpers
import useCurrentPage from './helpers/useCurrentPage';
import useModerationQty from './helpers/useModerationQty';
import useOpenedMenu from './helpers/useOpenedMenu';

// Components
import { MenuItemContent } from './components/MenuItemComponent';
import useSidebarTheme from './helpers/useSidebarTheme';
import { SubMenuTitle } from './components/SubmenuTitle';

const { Sider } = Layout;
const { SubMenu } = Menu;

const Sidebar = props => {
  const {
    location: { pathname },
    getSidebarVisibility,
    setSidebarVisibility,
    isHiddenSidebar,
    sitesStatuses,
    isMobile,
    t,
  } = props;
  const [theme, switchTheme] = useSidebarTheme();
  const currentPage = useCurrentPage({ pathname });
  const openedSubmenus = useOpenedMenu({ currentPage });
  const moderationQty = useModerationQty({ statusesList: sitesStatuses });

  const sidebarToggle = () => setSidebarVisibility(!isHiddenSidebar);

  const hideSidebarOnMobile = useCallback(() => {
    if (isMobile) setSidebarVisibility(true);
  }, [setSidebarVisibility, isMobile]);

  useEffect(hideSidebarOnMobile, [isMobile]);

  useEffect(() => {
    getSidebarVisibility();
  }, [getSidebarVisibility]);

  return (
    <Sider
      className="sidebar"
      collapsible
      collapsed={isHiddenSidebar}
      onCollapse={sidebarToggle}
      theme={theme}
    >
      <ThemeSwitcher theme={theme} onSwitch={switchTheme} />
      {/* TODO: defaultSelectedKeys should be filled only if menu item is present in sidebar  */}
      <Menu
        defaultSelectedKeys={[currentPage]}
        defaultOpenKeys={isMobile || isHiddenSidebar ? [] : [...openedSubmenus]}
        forceSubMenuRender={!isHiddenSidebar}
        theme={theme}
        className="sidebar__menu"
        mode="inline"
      >
        <Menu.Item key={types.HOME}>
          <MenuItemContent
            keyType={types.HOME}
            title={t('menu.homepage')}
            icon="home"
          />
        </Menu.Item>

        <SubMenu
          key={`sub-${types.SITES}`}
          title={
            <div>
              <Icon type="global" />
              <Badge dot={moderationQty > 0}>
                <span>{t('menu.sites')}</span>
              </Badge>
            </div>
          }
        >
          <Menu.Item key={types.SITES_LIST}>
            <MenuItemContent
              keyType={types.SITES_LIST}
              title={t('menu.sitesList')}
            />
          </Menu.Item>
          <Menu.Item key={types.SITES_MODERATION}>
            <MenuItemContent
              keyType={types.SITES_MODERATION}
              title={
                <Badge count={moderationQty}>
                  <div className="dots-overflow">{t('menu.moderation')}</div>
                </Badge>
              }
            />
          </Menu.Item>
        </SubMenu>

        <SubMenu
          key={`sub-${types.ARTICLES}`}
          title={
            <div>
              <Icon type="file-done" />
              <span>{t('menu.articles')}</span>
            </div>
          }
        >
          <Menu.Item key={types.ARTICLES}>
            <MenuItemContent
              keyType={types.ARTICLES}
              title={t('menu.articlesAll')}
            />
          </Menu.Item>
          <Menu.Item key={types.ARTICLES_STATISTICS}>
            <MenuItemContent
              keyType={types.ARTICLES_STATISTICS}
              title={t('menu.statistics')}
            />
          </Menu.Item>
        </SubMenu>

        <SubMenu
          key={`sub-${types.ARTICLE_PROMOTION}`}
          title={
            <div>
              <Icon type="line-chart" />
              <span>{t('menu.promotion')}</span>
            </div>
          }
        >
          <Menu.Item key={types.ARTICLE_PROMOTION}>
            <MenuItemContent
              keyType={types.ARTICLE_PROMOTION}
              title={t('menu.articlesPromotion')}
            />
          </Menu.Item>
        </SubMenu>

        <SubMenu
          key={`sub-${types.AB_TESTS}`}
          title={<SubMenuTitle title={t('menu.abTests')} icon="monitor" />}
          style={{ borderColor: 'transparent' }}
        >
          <Menu.Item key={types.AB_TESTS}>
            <MenuItemContent
              keyType={types.AB_TESTS}
              title={t('menu.abTestsConfig')}
            />
          </Menu.Item>
        </SubMenu>

        <SubMenu
          key={`sub-${types.GLOBAL_STATISTICS}`}
          title={
            <div>
              <Icon type="area-chart" />
              <span>{t('menu.statistics')}</span>
            </div>
          }
        >
          <Menu.Item key={types.GLOBAL_STATISTICS}>
            <MenuItemContent
              keyType={types.GLOBAL_STATISTICS}
              title={t('menu.globalStatistics')}
            />
            <span>{t('menu.globalStatistics')}</span>
          </Menu.Item>
          <Menu.Item key={types.STATISTICS_BY_TYPE}>
            <MenuItemContent
              keyType={types.STATISTICS_BY_TYPE}
              title={t('menu.analytics')}
            />
          </Menu.Item>
          <Menu.Item key={types.AB_TESTS_STATISTICS}>
            <MenuItemContent
              keyType={types.AB_TESTS_STATISTICS}
              title={t('menu.abTests')}
            />
            <span>{t('menu.abTests')}</span>
          </Menu.Item>
        </SubMenu>

        {/* label BOTTOM MENU */}
        <Menu.Item
          key={types.GLOBAL_SETTINGS}
          style={{
            position: 'absolute',
            bottom: '0',
            left: '0',
          }}
        >
          <MenuItemContent
            keyType={types.GLOBAL_SETTINGS}
            title={t('menu.settings')}
            icon="setting"
          />
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

const mapStateToProps = ({ userSettings, moderation }) => ({
  isHiddenSidebar: userSettings.isHiddenSidebar,
  sitesStatuses: moderation.statuses,
  isMobile: userSettings.isMobile,
});

const mapDispatchToProps = {
  getSidebarVisibility: getSidebarVisibilityLoc,
  setSidebarVisibility: setSidebarVisibilityLoc,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('common')(Sidebar));
