import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Card, Table } from 'antd';

// Components
import EmptyData from '../../../components/EmptyData';
import Loader from '../../../components/Loader';

const ArticleDetails = props => {
  const {
    selectedArticle,
    tableSortHandler,
    tableProps,
    isLoading,
    isMobile,
    mode,
  } = props;
  const isRotatorMode = mode === 'rotator';

  return (
    <Card
      className="default"
      bodyStyle={{
        padding: 0,
        height: '100%',
      }}
      style={{
        borderColor: '#d9d9d9',
        marginLeft: isMobile ? 0 : 40,
        height: 450,
      }}
    >
      {!selectedArticle ? (
        <div className="flex-center">
          <EmptyData
            title={
              <span>
                Выберите статью, чтобы увидеть информеры,
                <br /> на которых она размещена
              </span>
            }
          />
        </div>
      ) : (
        <Table
          {...tableProps}
          pagination={false}
          rowKey="custom_id"
          size="small"
          scroll={{ y: 412 }}
          locale={{
            emptyText: (
              <div className="flex-center" style={{ height: 380 }}>
                {isLoading ? (
                  <Loader isLoading />
                ) : (
                  <EmptyData
                    title={
                      <span>
                        Эта статья еще не размещена
                        {isRotatorMode
                          ? ' на ротаторе'
                          : ' ни на одном информере'}
                      </span>
                    }
                  />
                )}
              </div>
            ),
          }}
          columns={[
            {
              title: 'Издатель',
              dataIndex: 'publisher',
              key: 'publisher',
              sorter: tableSortHandler('publisher'),
            },
            {
              title: 'Сайт',
              dataIndex: 'site',
              key: 'site',
              sorter: tableSortHandler('site'),
            },
            {
              title: 'Информер',
              dataIndex: 'informer_name',
              key: 'informer_name',
              sorter: tableSortHandler('informer'),
            },
            {
              title: 'Позиция',
              dataIndex: 'position',
              key: 'position',
              sorter: tableSortHandler('position'),
              width: '80px',
              ellipsis: true,
              align: 'center',
            },
            ...(isRotatorMode
              ? [
                  {
                    title: 'Блок',
                    dataIndex: 'block',
                    key: 'block',
                    sorter: tableSortHandler('block'),
                    width: '80px',
                    ellipsis: true,
                    align: 'center',
                  },
                ]
              : []),
          ]}
        />
      )}
    </Card>
  );
};

ArticleDetails.defaultProps = {
  selectedArticle: null,
  isLoading: false,
  mode: 'informer',
};

ArticleDetails.propTypes = {
  tableSortHandler: PropTypes.func.isRequired,
  selectedArticle: PropTypes.shape({ id: PropTypes.string }),
  tableProps: PropTypes.shape({
    dataSource: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  isLoading: PropTypes.bool,
  mode: PropTypes.oneOf(['informer', 'rotator']),
};

const mapStateToProps = ({ userSettings }) => ({
  isMobile: userSettings.isMobile,
});

export default connect(mapStateToProps)(
  withTranslation('common')(ArticleDetails),
);
