/* eslint-disable indent */
import {
  SUCCESS_FETCH_GLOBAL_SETTINGS,
  SUCCESS_EDIT_GLOBAL_SETTINGS,
  SET_INIT_GLOBAL_SETTINGS,
  SUCCESS_SAVE_GLOBAL_SETTINGS,
} from '../types';

const initialState = {
  tabs: [],
};

export default function globalSettings(state = initialState, action = {}) {
  switch (action.type) {
    case SUCCESS_FETCH_GLOBAL_SETTINGS:
      return {
        ...state,
        tabs: action.payload,
      };

    case SET_INIT_GLOBAL_SETTINGS:
      return {
        ...state,
        initTabs: state.tabs,
      };

    case SUCCESS_SAVE_GLOBAL_SETTINGS:
      return {
        ...state,
        initTabs: state.tabs,
      };

    case SUCCESS_EDIT_GLOBAL_SETTINGS:
      return {
        ...state,
        tabs: state.tabs.map((tab, index) =>
          index === action.payload.tabIndex
            ? {
                ...tab,
                blocks: tab.blocks.map((block, index2) =>
                  index2 === action.payload.blockIndex
                    ? block.map((element, index3) =>
                        index3 === action.payload.elementIndex
                          ? action.payload.element
                          : element,
                      )
                    : block,
                ),
              }
            : tab,
        ),
      };

    default:
      return state;
  }
}
