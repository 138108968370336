import React, { useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ValidatorContext } from 'context/ValidatorContext';
import { Divider } from 'antd';

import InputsBlock from '../utils/InputsBlocks';
import {
  getPeriodValues,
  getSelfOptionValues,
  ratioTypesKeys,
} from '../utils/lists';
import SelectsBlock from '../utils/SelectsBlocks';
import {
  mustBeFilled,
  mustBeMore0AndLess1,
} from '../../../config/validationRules';

const InformerSettingsBlock = ({ informerSettings, handleChange, t }) => {
  const {
    rubric_ratio,
    rubric_period,
    mix_period,
    rubric_self,
    mix_self,
  } = informerSettings.settings;
  const { actions } = React.useContext(ValidatorContext);

  const selfOptions = useMemo(() => getSelfOptionValues(t), [t]);
  const periodValues = useMemo(() => getPeriodValues(t), [t]);

  return (
    <>
      <Divider style={{ marginBottom: 0 }}>Self</Divider>
      <SelectsBlock
        params={{ rubric_self, mix_self }}
        list={selfOptions}
        labels={['Rubric', 'Mix']}
        onChange={handleChange}
        columnsQty={2}
      />

      <Divider style={{ marginBottom: 0 }}>Ratio</Divider>
      <InputsBlock
        params={{ rubric_ratio }}
        labels={ratioTypesKeys}
        onChange={handleChange}
        actions={actions}
        inputProps={{
          value: '0',
          min: '0',
          max: '1',
        }}
        rules={[mustBeFilled, mustBeMore0AndLess1]}
      />

      <Divider>Period</Divider>
      <SelectsBlock
        params={{ rubric_period, mix_period }}
        list={periodValues}
        labels={['Rubric', 'Mix']}
        onChange={handleChange}
        columnsQty={2}
      />
    </>
  );
};

function mapStateToProps(state) {
  return {
    informerSettings: state.informerSettings,
  };
}

export default connect(mapStateToProps)(
  withTranslation()(InformerSettingsBlock),
);
