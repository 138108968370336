import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Tabs, Icon } from 'antd';
import { connect } from 'react-redux';
import Grid from '../../components/Grid';
import CodeInsertBlock from '../../components/SettingPanel/blocks/CodeInsertBlock';

// Redux
import { START_EDIT_INFORMER } from '../../redux/types';
import {
  editInformer,
  addInformer,
  editInformerLocal,
  resetInformerStylesLocal,
  choiceInformer,
  resetInformerStyles,
} from '../../redux/actions/informerSettings';
import {
  fetchInformerCustomArticles,
  deleteCustomNews,
} from '../../redux/actions/publisherSettings';
import { fetchInformer } from '../../redux/actions/informer';

// Utils
import { ValidatorCOP } from '../../context/ValidatorContext';

// Components
import SettingPanel from '../../components/SettingPanel';
import SettingGallery from '../../components/SettingGallery';
import PageTitle from '../../components/PageTitle';
import GeneralBlock from '../../components/SettingPanel/blocks/GeneralBlock';
import TabsWithQuery from '../../components/TabsWithQuery';
import FormActionsSticky from '../../components/Form/components/FormActionsSticky';
import ArticlesList from './components/ArticlesList';
import routesPath, { getFilledRoutePath } from '../../config/routePaths';

const MODE_TYPE_EDIT = 'edit';
const MODE_TYPE_ADD = 'add';
const DEVICE_TYPE_MOBILE = 'mobile';
const DEVICE_TYPE_DESKTOP = 'desktop';

const { TabPane } = Tabs;

class AddInformer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileView: false,
      mode: this.getPageMode(),
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { informerId },
      },
    } = this.props;
    const { mode } = this.state;

    if (mode === MODE_TYPE_EDIT) {
      this.props.fetchInformer(informerId).then(() => {
        const { styles } = this.props.info;
        const emptyJson = '{}';
        const parsedStyles = styles || emptyJson;
        // const parsedStyles = JSON.parse(jsonPrettifier(styles || emptyJson));
        const infoFromProp = styles
          ? { ...this.props.info, ...parsedStyles }
          : emptyJson;

        this.props.choiceInformer(infoFromProp);
      });
      this.getArticles();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const mode = this.getPageMode();

    if (mode !== prevState.mode) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ mode });
    }
  }

  getArticles = () => {
    const {
      match: {
        params: { informerId },
      },
    } = this.props;
    this.props.fetchInformerCustomArticles({
      informerId,
    });
  };

  toggleDeviceType = event =>
    this.setState({
      mobileView: event.target.value === 'mobile',
    });

  getPageMode = () => {
    const { props } = this;

    return props.match.params.informerId ? MODE_TYPE_EDIT : MODE_TYPE_ADD;
  };

  handleAction = () => {
    const { mode } = this.state;

    if (mode === MODE_TYPE_ADD) this.handleAddInformer();
    if (mode === MODE_TYPE_EDIT) this.handleEditInformer();
  };

  goToInformersList = () => {
    const linkToInformersList = getFilledRoutePath({
      path: routesPath.INFORMERS_LIST,
      params: this.props.match.params,
    });
    this.props.history.push(linkToInformersList);
  };

  handleAddInformer = () => {
    const { props } = this;
    const { informerSettings } = props;
    const { siteId } = props.match.params;
    const { name } = informerSettings;

    props
      .addInformer({ siteId, name, data: informerSettings })
      .then(this.goToInformersList);
  };

  handleEditInformer = () => {
    const { props } = this;
    const { informerSettings } = props;
    const { siteId, informerId } = props.match.params;
    const { name } = informerSettings;

    props.editInformer({
      id: informerId,
      siteId,
      name,
      data: informerSettings,
    });
  };

  renderTabTitle = (title, icon) => (
    <span>
      <Icon type={icon} />
      {title}
    </span>
  );

  handleResetInformerStyles = () =>
    this.props.resetInformerStyles({
      id: this.props.match.params.informerId,
    });

  render() {
    const { mobileView, mode } = this.state;
    const {
      editInformerInState,
      informerSettings,
      deleteArticle,
      articlesList,
      match,
      t,
    } = this.props;
    const typeDevice = mobileView ? DEVICE_TYPE_MOBILE : DEVICE_TYPE_DESKTOP;
    const { name } = informerSettings;
    const { general } = informerSettings[typeDevice];
    const isEditMode = mode === 'edit';

    return (
      <div className="page-body main-page page-informer">
        <PageTitle title={t('content.title.informerSettings')} />
        <ValidatorCOP>
          <TabsWithQuery
            defaultActiveKey="1"
            className="page-informer__content"
            type="card"
          >
            <TabPane
              tab={this.renderTabTitle(t('basic.basic'), 'setting')}
              key="1"
            >
              <Grid columns={2}>
                <div>
                  <GeneralBlock
                    mainField={name}
                    onChange={editInformerInState}
                    element={general}
                  />
                </div>
                <div>
                  <CodeInsertBlock
                    isEditMode={isEditMode}
                    informerId={match.params.informerId}
                    siteId={match.params.siteId}
                  />
                </div>
              </Grid>
            </TabPane>
            <TabPane
              tab={this.renderTabTitle(t('basic.view'), 'layout')}
              key="2"
            >
              <Grid columns={2}>
                <div className="page-informer__settings">
                  <SettingPanel
                    toggleDeviceType={this.toggleDeviceType}
                    typeDevice={typeDevice}
                    mobileView={mobileView}
                    onChange={editInformerInState}
                    onCancel={this.goToInformersList}
                    onClick={this.handleAction}
                    onResetStyles={this.handleResetInformerStyles}
                    onResetStylesLocal={this.props.resetInformerStylesLocal}
                    mode={mode}
                  />
                </div>
                <div className="page-informer__gallery">
                  <SettingGallery typeDevice={typeDevice} />
                </div>
              </Grid>
            </TabPane>
            <TabPane
              tab={this.renderTabTitle(t('basic.promotion'), 'line-chart')}
              key="3"
            >
              <Grid columns={2}>
                <div>
                  <ArticlesList
                    deleteArticle={deleteArticle}
                    getArticles={this.getArticles}
                    list={articlesList}
                  />
                </div>
              </Grid>
            </TabPane>
          </TabsWithQuery>
          <Grid columns={2}>
            <div>
              <br />
              <FormActionsSticky
                isLoading={this.props.isLoadingSavingInformer}
                onCancel={this.goToInformersList}
                onSubmit={this.handleAction}
                titleSubmit={t(`form.button${isEditMode ? 'Save' : 'Create'}`)}
                titleCancel={t('form.buttonCancel')}
                style={{ justifyContent: 'flex-end', display: 'flex' }}
              />
            </div>
          </Grid>
        </ValidatorCOP>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  informerSettings: state.informerSettings,
  articlesList: state.publisherSettings.informerCustomArticles,
  info: state.informer.info,
  site: state.site,
  isLoadingSavingInformer: state.network[START_EDIT_INFORMER],
});

const mapDispatchToProps = {
  editInformer,
  editInformerInState: editInformerLocal,
  addInformer,
  resetInformerStylesLocal,
  resetInformerStyles,
  fetchInformer,
  choiceInformer,
  fetchInformerCustomArticles,
  deleteArticle: deleteCustomNews,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(AddInformer));
