// LISTS
// -------------------------------------------------------------
export const START_FETCH_SITES_LIST = 'START_FETCH_SITES_LIST';
export const SUCCESS_FETCH_SITES_LIST = 'SUCCESS_FETCH_SITES_LIST';
export const FAILURE_FETCH_SITES_LIST = 'FAILURE_FETCH_SITES_LIST';

export const START_FETCH_NEWS_CATEGORIES_LIST =
  'START_FETCH_NEWS_CATEGORIES_LIST';
export const SUCCESS_FETCH_NEWS_CATEGORIES_LIST =
  'SUCCESS_FETCH_NEWS_CATEGORIES_LIST';
export const FAILURE_FETCH_NEWS_CATEGORIES_LIST =
  'FAILURE_FETCH_NEWS_CATEGORIES_LIST';

export const START_FETCH_SITES_LIST_WITH_INFORMERS =
  'START_FETCH_SITES_LIST_WITH_INFORMERS';
export const SUCCESS_FETCH_SITES_LIST_WITH_INFORMERS =
  'SUCCESS_FETCH_SITES_LIST_WITH_INFORMERS';
export const FAILURE_FETCH_SITES_LIST_WITH_INFORMERS =
  'FAILURE_FETCH_SITES_WITH_INFORMERS';

export const START_FETCH_SITES_WITH_INFORMERS =
  'START_FETCH_SITES_WITH_INFORMERS';
export const SUCCESS_FETCH_SITES_WITH_INFORMERS =
  'SUCCESS_FETCH_SITES_WITH_INFORMERS';
export const FAILURE_FETCH_SITES_WITH_INFORMERS =
  'FAILURE_FETCH_SITES_WITH_INFORMERS';

export const START_FETCH_SITES_WITH_INFORMERS_FOR_ROTATOR =
  'START_FETCH_SITES_WITH_INFORMERS_FOR_ROTATOR';
export const SUCCESS_FETCH_SITES_WITH_INFORMERS_FOR_ROTATOR =
  'SUCCESS_FETCH_SITES_WITH_INFORMERS_FOR_ROTATOR';
export const FAILURE_FETCH_SITES_WITH_INFORMERS_FOR_ROTATOR =
  'FAILURE_FETCH_SITES_WITH_INFORMERS_FOR_ROTATOR';

export const START_FETCH_LANGUAGES_LIST = 'START_FETCH_LANGUAGES_LIST';
export const SUCCESS_FETCH_LANGUAGES_LIST = 'SUCCESS_FETCH_LANGUAGES_LIST';
export const FAILURE_FETCH_LANGUAGES_LIST = 'FAILURE_FETCH_LANGUAGES_LIST';
