import React from 'react';
import { string, func } from 'prop-types';
import { withTranslation } from 'react-i18next';
import { FaMoon, FaSun } from 'react-icons/all';

const ThemeSwitcher = ({ theme, onSwitch, t }) => (
  <div className="theme-switcher" onClick={onSwitch}>
    <div className="theme-switcher__title">
      &nbsp;<span>{t('basic.switchTheme')}</span>
    </div>
    <div>{theme === 'light' ? <FaMoon /> : <FaSun />}</div>
  </div>
);

ThemeSwitcher.propTypes = {
  theme: string,
  onSwitch: func,
};

export default withTranslation()(ThemeSwitcher);
