import { exact, number } from 'prop-types';

export const iStatusesList = exact({
  active: number,
  analytics: number,
  analytics_speedup: number,
  blocked: number,
  deleted: number,
  pending: number,
  suspended: number,
  verification: number,
});
