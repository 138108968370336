import { Icon, Button } from 'antd';
import React, { useEffect, useState, useCallback, memo } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

// Redux
import { addSite, deleteSite, editSite, fetchChart } from 'redux/actions/sites';
import { fetchTable as fetchPublishers } from 'redux/actions/publishers';
import { showModal } from 'redux/actions/modals';
import routesPath, { getFilledRoutePath } from '../../config/routePaths';
import { fetchSitesList } from '../../redux/actions/dataLists';
import { fetchAllSitesTableAction } from '../../redux/actions/sites';

// Utils
import {
  getDaysAgoRange,
  datesArrayToUnix,
  getDaysRange,
} from '../../utils/date';

// Components
import LinearChart from '../../components/LinearChart';
import Table from '../../components/Table';
import PageTitle from '../../components/PageTitle';
import { prepareFilters } from './helpers/prepareFilters';
import { useTableHeaders } from './helpers/useTableHeaders';

const SitesList = props => {
  const {
    isLoadingPublishers,
    isLoadingAllSites,
    getPublishers,
    getAllSites,
    publishers = [],
    sitesList = [],
    getTable,
    getChart,
    table,
    chart,
    t,
  } = props;
  const { limit, offset } = table;
  const isChartTableLoaded = !chart.isLoading && !table.isLoading;
  const isTableDataLoaded = !isLoadingPublishers && !isLoadingAllSites;
  const [datesStr, setDatesStr] = useState(getDaysAgoRange());
  const [datesChart, setDatesChart] = useState(datesStr);
  const [params, setParams] = useState({
    limit,
    offset,
    start: +datesArrayToUnix(datesStr)[0],
    end: +datesArrayToUnix(datesStr)[1],
  });
  const tableHeaders = useTableHeaders({ publishers, sitesList, params });

  const updateParams = (page, sortBy, sortDirection, filters) => {
    return setParams({
      ...params,
      ...(sortBy && { sortBy }),
      ...(sortDirection && { sortDirection }),
      ...prepareFilters(filters),
      offset: (page - 1) * limit,
    });
  };

  const loadTable = useCallback(() => {
    getTable(params);
  }, [getTable, params]);

  const loadChart = useCallback(() => {
    getChart({
      start: params.start,
      end: params.end,
    });
  }, [getChart, params]);

  const loadAll = useCallback(
    datesArr => {
      const newDates = getDaysRange(datesArr);
      const newDatesUnix = datesArrayToUnix(datesArr);

      setDatesStr(newDates);
      setParams({ ...params, start: newDatesUnix[0], end: newDatesUnix[1] });
    },
    [params],
  );

  const handleShowTooltip = ({ id, ...otherProps }) =>
    props.showModal({
      type: 'MODAL_CODE_BLOCK_SITE',
      params: {
        history: props.history,
        siteId: id,
        ...otherProps,
      },
    });

  const handleEditSite = ({ publisher_id: publisherId, id: siteId }) =>
    getFilledRoutePath({
      path: routesPath.SITE,
      params: { publisherId, siteId },
    });

  const handleSiteStatistics = ({ publisher_id: publisherId, id: siteId }) =>
    `/${publisherId}/${siteId}/statistics`;

  const handleSiteInformers = ({ publisher_id: publisherId, id: siteId }) =>
    `/${publisherId}/${siteId}`;

  const handleDeleteSite = ({ id }) => props.deleteSite(id).then(loadTable);

  const getPublishersList = useCallback(() => {
    if (!publishers.length) {
      getPublishers(params);
    }
  }, [getPublishers, params, publishers]);

  const getAllSitesList = useCallback(() => {
    if (!sitesList.length) {
      getAllSites();
    }
  }, [getAllSites, sitesList.length]);

  const convertDatesForChart = useCallback(() => {
    setDatesChart(getDaysRange(datesStr));
  }, [datesStr]);

  useEffect(loadChart, [params.start, params.end]);
  useEffect(loadTable, [params]);
  useEffect(getPublishersList, []);
  useEffect(getAllSitesList, []);
  useEffect(convertDatesForChart, [datesStr]);

  return (
    <div className="page-body main-page">
      <PageTitle
        title={t('content.title.sitesList')}
        hint={t('content.titleHint.sitesList')}
        button={
          <Button type="success" onClick={handleEditSite}>
            <Icon type="plus" />
            {`${t('basic.add')} ${t('basic.informersList').toLowerCase()}`}
          </Button>
        }
      />

      <LinearChart
        isChartToggle
        isShowChart={!!table.data.length}
        categoriesForX={datesChart}
        onChange={loadAll}
        data={chart}
        isAutoKeys
        excludeKeys={['efficiencies']}
      />

      {isTableDataLoaded && (
        <Table
          isLoading={!isChartTableLoaded}
          headers={tableHeaders}
          data={table.data}
          total={table.total}
          limit={table.limit}
          fetch={updateParams}
          actions={{
            page: handleSiteStatistics,
            informersList: handleSiteInformers,
            code: handleShowTooltip,
            editSite: handleEditSite,
            delete: handleDeleteSite,
          }}
        />
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    info: state.sites.info,
    chart: state.sites.chart,
    table: state.sites.table,
    publishers: state.publishers.table.data,
    sitesList: state.dataLists.sitesList,
    isLoadingPublishers: state.network.START_FETCH_PUBLISHERS_TABLE,
    isLoadingAllSites: state.network.START_FETCH_SITES_LIST,
  };
}

const mapDispatchToProps = {
  showModal,
  getTable: fetchAllSitesTableAction,
  getChart: fetchChart,
  getPublishers: fetchPublishers,
  addSite,
  editSite,
  deleteSite,
  getAllSites: fetchSitesList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(memo(SitesList)));
