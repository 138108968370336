import React, { useCallback, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

// Utils
import { statusesSites } from '../../constants/statuses';
import { getModerationSpeedupInfo } from '../../redux/actions/sites';
import ImageView from '../ImageView';

// Styles
import {
  SpeedupInfoWrapper,
  SpeedupInfoComment,
  SpeedupInfoDescription,
  SpeedupInfoLabel,
} from './styles';

const SpeedupInfo = ({ getSpeedupInfo, speedupInfo, status, siteId, t }) => {
  const handleGetSpeedupInfo = useCallback(() => {
    if (status === statusesSites.STATUS_ANALYTICS_SPEEDUP) {
      getSpeedupInfo({ siteId });
    }
  }, [getSpeedupInfo, siteId, status]);

  useEffect(handleGetSpeedupInfo, []);

  return speedupInfo === null ? null : (
    <SpeedupInfoWrapper className="box">
      {speedupInfo.description && (
        <SpeedupInfoDescription>
          <div>
            <SpeedupInfoLabel>{t('basic.message')}:</SpeedupInfoLabel>
            <SpeedupInfoComment>"{speedupInfo.description}"</SpeedupInfoComment>
          </div>
        </SpeedupInfoDescription>
      )}

      {speedupInfo.screens.length > 0 && (
        <SpeedupInfoDescription>
          <div>
            <SpeedupInfoLabel>{t('basic.screenshots')}:</SpeedupInfoLabel>
            <ImageView images={speedupInfo.screens} />
          </div>
        </SpeedupInfoDescription>
      )}
    </SpeedupInfoWrapper>
  );
};

SpeedupInfo.propTypes = {};
const mapStateToProps = ({ sites }) => ({
  speedupInfo: sites.speedupInfo,
});

const mapDispatchToProps = {
  getSpeedupInfo: getModerationSpeedupInfo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(SpeedupInfo));
