import React from 'react';
import { string, func, shape } from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Button, Icon, Popconfirm, Radio, Tooltip, Tabs } from 'antd';
import { connect } from 'react-redux';

// Components
import ImageBlock from 'components/SettingPanel/blocks/ImageBlock';
import GridBlock from 'components/SettingPanel/blocks/GridBlock';
import FontBlock from 'components/SettingPanel/blocks/FontBlock';
import TitleBlock from 'components/SettingPanel/blocks/TitleBlock';
import ContainerBlock from 'components/SettingPanel/blocks/ContainerBlock';
import TabsWithQuery from '../TabsWithQuery';

const SettingPanel = props => {
  const {
    informerSettings,
    typeDevice,
    toggleDeviceType,
    mobileView,
    t,
  } = props;
  const { general, image, font, block, title } = informerSettings[typeDevice];

  const onChange = ({ item, response, isSetting }) => {
    props.onChange({ item, response, isSetting, typeDevice: props.typeDevice });
  };

  return (
    <>
      <Popconfirm
        title={t('confirm.resetAndSave')}
        onConfirm={props.onResetStyles}
      >
        <Tooltip title={t('tooltip.resetInformerSettings')} placement="bottom">
          <Button type="link" style={{ float: 'right' }}>
            <Icon type="redo" /> {t('basic.reset')}
          </Button>
        </Tooltip>
      </Popconfirm>

      <Radio.Group
        defaultValue={mobileView ? 'mobile' : 'desktop'}
        buttonStyle="solid"
        onChange={toggleDeviceType}
      >
        <Radio.Button value="desktop">{t('basic.desktopVersion')}</Radio.Button>
        <Radio.Button value="mobile">{t('basic.mobileVersion')}</Radio.Button>
      </Radio.Group>

      <TabsWithQuery
        className="tabs-settings"
        style={{ marginTop: 25 }}
        type="card"
      >
        <Tabs.TabPane
          key="title"
          tab={
            <span>
              <Icon type="font-size" /> {t('basic.title')}
            </span>
          }
        >
          <TitleBlock element={title} onChange={onChange} />
        </Tabs.TabPane>
        <Tabs.TabPane
          key="grid"
          tab={
            <span>
              <Icon type="build" /> {t('basic.grid')}
            </span>
          }
        >
          <GridBlock
            element={general}
            onChange={onChange}
            mobileView={mobileView}
          />
        </Tabs.TabPane>

        <Tabs.TabPane
          key="elements"
          tab={
            <span>
              <Icon type="picture" /> {t('basic.elements')}
            </span>
          }
        >
          <TabsWithQuery tabBarStyle={{ textAlign: 'center' }}>
            <Tabs.TabPane key="el-container" tab={t('basic.container')}>
              <ContainerBlock
                typeDevice={typeDevice}
                onChange={onChange}
                element={block}
              />
            </Tabs.TabPane>

            <Tabs.TabPane key="el-font" tab={t('basic.title')}>
              <FontBlock element={font} onChange={onChange} />
            </Tabs.TabPane>

            <Tabs.TabPane key="el-image" tab={t('basic.image')}>
              <ImageBlock
                element={image}
                onChange={onChange}
                typeDevice={typeDevice}
              />
            </Tabs.TabPane>
          </TabsWithQuery>
        </Tabs.TabPane>
      </TabsWithQuery>
    </>
  );
};

SettingPanel.propTypes = {
  informer: shape({}),
  typeDevice: string,
  editInformer: func,
};

SettingPanel.defaultProps = {
  informer: {},
  typeDevice: '',
  editInformer: () => {},
};

function mapStateToProps(state) {
  return {
    informerSettings: state.informerSettings,
    informer: state.informer,
  };
}

export default connect(mapStateToProps)(withTranslation()(SettingPanel));
