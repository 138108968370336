import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import PageTitle from '../../components/PageTitle';
import Table from '../../components/Table';
import { fetchArticlesStatistics } from '../../redux/actions/articlesStatistics';

class ArticlesStatistics extends React.PureComponent {
  componentDidMount() {
    this.props.fetchArticlesStatistics();
  }

  handleFetch = (page, sortBy, sortDirection) => {
    const { table } = this.props;
    const { limit, start, end } = table;
    const offset = (page - 1) * limit;

    this.props.fetchArticlesStatistics({
      limit,
      offset,
      start,
      end,
      sortBy,
      sortDirection,
    });
  };

  render() {
    const { table, t } = this.props;
    const { isLoading } = table;

    const customHeaders = {
      views_informer: {
        title: t('table.auto.informer'),
        key: 'views_informer',
        children: [
          { title: t('table.auto.views'), dataIndex: 'views_informer' },
          { title: t('table.auto.clicks'), dataIndex: 'clicks_informer' },
          { title: t('table.auto.ctr'), dataIndex: 'ctr_informer' },
        ],
      },
      views_rotator: {
        title: t('table.auto.rotator'),
        key: 'views_rotator',
        children: [
          { title: t('table.auto.views'), dataIndex: 'views_rotator' },
          { title: t('table.auto.clicks'), dataIndex: 'clicks_rotator' },
          { title: t('table.auto.ctr'), dataIndex: 'ctr_rotator' },
        ],
      },
      ctr_informer: null,
      ctr_rotator: null,
      clicks_informer: null,
      clicks_rotator: null,
    };

    return (
      <>
        <PageTitle
          centered
          title={t('content.title.articlesStatistics')}
          hint={t('content.titleHint.articlesStatistics')}
        />

        <Table
          isAutoHeaders
          isLoading={isLoading}
          data={table.data}
          total={table.total}
          limit={table.limit}
          fetch={this.handleFetch}
          customHeaders={customHeaders}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    // chart: state.articlesStatistics.chart,
    table: state.articlesStatistics.table,
    news: state.articlesStatistics.info,
  };
}

const mapDispatchToProps = {
  fetchArticlesStatistics,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('common')(ArticlesStatistics));
