export const START_GET_SIDEBAR_VISIBILITY = 'START_SIDEBAR_VISIBILITY';
export const FAILURE_GET_SIDEBAR_VISIBILITY = 'FAILURE_GET_SIDEBAR_VISIBILITY';
export const SUCCESS_GET_SIDEBAR_VISIBILITY = 'SUCCESS_GET_SIDEBAR_VISIBILITY';

export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export const SET_IS_MOBILE = 'SET_IS_MOBILE';

export const CLEAR_USER = 'CLEAR_USER';

export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';

export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export const START_SIGN_IN_AUTH = 'START_SIGN_IN_AUTH';
export const SUCCESS_SIGN_IN_AUTH = 'SUCCESS_SIGN_IN_AUTH';
export const FAILURE_SIGN_IN_AUTH = 'FAILURE_SIGN_IN_AUTH';

export const START_REFRESH_TOKEN = 'START_REFRESH_TOKEN';
export const SUCCESS_REFRESH_TOKEN = 'SUCCESS_REFRESH_TOKEN';
export const FAILURE_REFRESH_TOKEN = 'FAILURE_REFRESH_TOKEN';

// Informers
// ________________________________________________________
// _________________________________________________________
// END Publishers

// Publishers
// ________________________________________________________
export const START_FETCH_PUBLISHER = 'START_FETCH_PUBLISHER';
export const SUCCESS_FETCH_PUBLISHER = 'SUCCESS_FETCH_PUBLISHER';
export const FAILURE_FETCH_PUBLISHER = 'FAILURE_FETCH_PUBLISHER';

export const SUCCESS_CHOICE_PUBLISHER = 'SUCCESS_CHOICE_PUBLISHER';

export const START_FETCH_PUBLISHERS_TABLE = 'START_FETCH_PUBLISHERS_TABLE';
export const SUCCESS_FETCH_PUBLISHERS_TABLE = 'SUCCESS_FETCH_PUBLISHERS_TABLE';
export const FAILURE_FETCH_PUBLISHERS_TABLE = 'FAILURE_FETCH_PUBLISHERS_TABLE';

export const START_ADD_PUBLISHER = 'START_ADD_PUBLISHER';
export const SUCCESS_ADD_PUBLISHER = 'SUCCESS_ADD_PUBLISHER';
export const FAILURE_ADD_PUBLISHER = 'FAILURE_ADD_PUBLISHER';

export const START_EDIT_PUBLISHER = 'START_EDIT_PUBLISHER';
export const SUCCESS_EDIT_PUBLISHER = 'SUCCESS_EDIT_PUBLISHER';
export const FAILURE_EDIT_PUBLISHER = 'FAILURE_EDIT_PUBLISHER';

export const START_DELETE_PUBLISHER = 'START_DELETE_PUBLISHER';
export const SUCCESS_DELETE_PUBLISHER = 'SUCCESS_DELETE_PUBLISHER';
export const FAILURE_DELETE_PUBLISHER = 'FAILURE_DELETE_PUBLISHER';

export const START_FETCH_PUBLISHERS_CHART = 'START_FETCH_PUBLISHERS_CHART';
export const SUCCESS_FETCH_PUBLISHERS_CHART = 'SUCCESS_FETCH_PUBLISHERS_CHART';
export const FAILURE_FETCH_PUBLISHERS_CHART = 'FAILURE_FETCH_PUBLISHERS_CHART';
// _________________________________________________________
// END Publishers

// Sites
// ________________________________________________________
export const START_FETCH_SITES = 'START_FETCH_SITES';
export const SUCCESS_FETCH_SITES = 'SUCCESS_FETCH_SITES';
export const FAILURE_FETCH_SITES = 'FAILURE_FETCH_SITES';

export const FETCH_SITES_LIST = 'FETCH_SITES_LIST';

export const START_ADD_SITE = 'START_ADD_SITE';
export const SUCCESS_ADD_SITE = 'SUCCESS_ADD_SITE';
export const FAILURE_ADD_SITE = 'FAILURE_ADD_SITE';

export const EDIT_SITE_FORM = 'EDIT_SITE_FORM';
export const START_EDIT_SITE = 'START_EDIT_SITE';
export const SUCCESS_EDIT_SITE = 'SUCCESS_EDIT_SITE';
export const FAILURE_EDIT_SITE = 'FAILURE_EDIT_SITE';

export const START_DELETE_SITE = 'START_DELETE_SITE';
export const SUCCESS_DELETE_SITE = 'SUCCESS_DELETE_SITE';
export const FAILURE_DELETE_SITE = 'FAILURE_DELETE_SITE';

export const START_FETCH_CHART = 'START_FETCH_CHART';
export const SUCCESS_FETCH_CHART = 'SUCCESS_FETCH_CHART';
export const FAILURE_FETCH_CHART = 'FAILURE_FETCH_CHART';

export const START_FETCH_SITE = 'START_FETCH_SITE';
export const SUCCESS_FETCH_SITE = 'SUCCESS_FETCH_SITE';
export const FAILURE_FETCH_SITE = 'FAILURE_FETCH_SITE';

export const START_SITE_MODERATION = 'START_SITE_MODERATION';
export const SUCCESS_SITE_MODERATION = 'SUCCESS_SITE_MODERATION';
export const FAILURE_SITE_MODERATION = 'FAILURE_SITE_MODERATION';

export const START_SITE_VERIFICATION = 'START_SITE_VERIFICATION';
export const SUCCESS_SITE_VERIFICATION = 'SUCCESS_SITE_VERIFICATION';
export const FAILURE_SITE_VERIFICATION = 'FAILURE_SITE_VERIFICATION';

export const START_FETCH_SPEEDUP_INFO = 'START_FETCH_SPEEDUP_INFO';
export const SUCCESS_FETCH_SPEEDUP_INFO = 'SUCCESS_FETCH_SPEEDUP_INFO';
export const FAILURE_FETCH_SPEEDUP_INFO = 'FAILURE_FETCH_SPEEDUP_INFO';

export const START_FETCH_SITES_STATUSES = 'START_FETCH_SITES_STATUSES';
export const SUCCESS_FETCH_SITES_STATUSES = 'SUCCESS_FETCH_SITES_STATUSES';
export const FAILURE_FETCH_SITES_STATUSES = 'FAILURE_FETCH_SITES_STATUSES';

// ________________________________________________________
// END Sites

// INFORMER
// ________________________________________________________
export const START_FETCH_INFORMER = 'START_FETCH_INFORMER';
export const SUCCESS_FETCH_INFORMER = 'SUCCESS_FETCH_INFORMER';
export const FAILURE_FETCH_INFORMER = 'FAILURE_FETCH_INFORMER';

export const START_FETCH_CHART_INFORMER = 'START_FETCH_CHART_INFORMER';
export const SUCCESS_FETCH_CHART_INFORMER = 'SUCCESS_FETCH_CHART_INFORMER';
export const FAILURE_FETCH_CHART_INFORMER = 'FAILURE_FETCH_CHART_INFORMER';

export const START_FETCH_TABLE_INFORMER = 'START_FETCH_TABLE_INFORMER';
export const SUCCESS_FETCH_TABLE_INFORMER = 'SUCCESS_FETCH_TABLE_INFORMER';
export const FAILURE_FETCH_TABLE_INFORMER = 'FAILURE_FETCH_TABLE_INFORMER';

export const START_FETCH_TABLE_INFORMER_BY_DATE =
  'START_FETCH_TABLE_INFORMER_BY_DATE';
export const SUCCESS_FETCH_TABLE_INFORMER_BY_DATE =
  'SUCCESS_FETCH_TABLE_INFORMER_BY_DATE';
export const FAILURE_FETCH_TABLE_INFORMER_BY_DATE =
  'FAILURE_FETCH_TABLE_INFORMER_BY_DATE';

export const START_ADD_INFORMER = 'START_ADD_INFORMER';
export const SUCCESS_ADD_INFORMER = 'SUCCESS_ADD_INFORMER';
export const FAILURE_ADD_INFORMER = 'FAILURE_ADD_INFORMER';

export const START_EDIT_INFORMER = 'START_EDIT_INFORMER';
export const SUCCESS_EDIT_INFORMER = 'SUCCESS_EDIT_INFORMER';
export const FAILURE_EDIT_INFORMER = 'FAILURE_EDIT_INFORMER';

export const EDIT_INFORMER_LOCAL = 'EDIT_INFORMER_LOCAL';

export const CHOICE_INFORMER = 'CHOICE_INFORMER';

export const START_DELETE_INFORMER = 'START_DELETE_INFORMER';
export const SUCCESS_DELETE_INFORMER = 'SUCCESS_DELETE_INFORMER';
export const FAILURE_DELETE_INFORMER = 'FAILURE_DELETE_INFORMER';

// ________________________________________________________
// END INFORMER

// Site
// ________________________________________________________
export const START_FETCH_CHART_INFORMERS = 'START_FETCH_CHART_INFORMERS';
export const SUCCESS_FETCH_CHART_INFORMERS = 'SUCCESS_FETCH_CHART_INFORMERS';
export const FAILURE_FETCH_CHART_INFORMERS = 'FAILURE_FETCH_CHART_INFORMERS';

export const START_FETCH_TABLE_INFORMERS = 'START_FETCH_TABLE_INFORMERS';
export const SUCCESS_FETCH_TABLE_INFORMERS = 'SUCCESS_FETCH_TABLE_INFORMERS';
export const FAILURE_FETCH_TABLE_INFORMERS = 'FAILURE_FETCH_TABLE_INFORMERS';

export const START_FETCH_SITE_NEWS = 'START_FETCH_SITE_NEWS';
export const FAILURE_FETCH_SITE_NEWS = 'FAILURE_FETCH_SITE_NEWS';
export const SUCCESS_FETCH_SITE_NEWS = 'SUCCESS_FETCH_SITE_NEWS';
// ________________________________________________________
// END Site

// Site Statistics
// ________________________________________________________
export const START_FETCH_CHART_SITE_STATISTICS =
  'START_FETCH_CHART_SITE_STATISTICS';
export const SUCCESS_FETCH_CHART_SITE_STATISTICS =
  'SUCCESS_FETCH_CHART_SITE_STATISTICS';
export const FAILURE_FETCH_CHART_SITE_STATISTICS =
  'FAILURE_FETCH_CHART_SITE_STATISTICS';

export const START_FETCH_TABLE_SITE_STATISTICS =
  'START_FETCH_TABLE_SITE_STATISTICS';
export const SUCCESS_FETCH_TABLE_SITE_STATISTICS =
  'SUCCESS_FETCH_TABLE_SITE_STATISTICS';
export const FAILURE_FETCH_TABLE_SITE_STATISTICS =
  'FAILURE_FETCH_TABLE_SITE_STATISTICS';
// ________________________________________________________
// END Site Statistics

// News
// ________________________________________________________
export const START_FETCH_TABLE_INFORMER_NEWS =
  'START_FETCH_TABLE_INFORMER_NEWS';
export const SUCCESS_FETCH_TABLE_INFORMER_NEWS =
  'SUCCESS_FETCH_TABLE_INFORMER_NEWS';
export const FAILURE_FETCH_TABLE_INFORMER_NEWS =
  'FAILURE_FETCH_TABLE_INFORMER_NEWS';

export const START_FETCH_CHART_INFORMER_NEWS =
  'START_FETCH_CHART_INFORMER_NEWS';
export const SUCCESS_FETCH_CHART_INFORMER_NEWS =
  'SUCCESS_FETCH_CHART_INFORMER_NEWS';
export const FAILURE_FETCH_CHART_INFORMER_NEWS =
  'FAILURE_FETCH_CHART_INFORMER_NEWS';

export const START_FETCH_INFORMERS_DATA_BY_NEWS_ID =
  'START_FETCH_INFORMERS_DATA_BY_NEWS_ID';
export const SUCCESS_FETCH_INFORMERS_DATA_BY_NEWS_ID =
  'SUCCESS_FETCH_INFORMERS_DATA_BY_NEWS_ID';
export const FAILURE_FETCH_INFORMERS_DATA_BY_NEWS_ID =
  'FAILURE_INFORMERS_DATA_BY_NEWS_ID';

export const START_FETCH_INFORMERS_DATA_FOR_ROTATOR_BY_NEWS_ID =
  'START_FETCH_INFORMERS_DATA_FOR_ROTATOR_BY_NEWS_ID';
export const SUCCESS_FETCH_INFORMERS_DATA_FOR_ROTATOR_BY_NEWS_ID =
  'SUCCESS_FETCH_INFORMERS_DATA_FOR_ROTATOR_BY_NEWS_ID';
export const FAILURE_FETCH_INFORMERS_DATA_FOR_ROTATOR_BY_NEWS_ID =
  'FAILURE_INFORMERS_DATA_FOR_ROTATOR_BY_NEWS_ID';

export const START_FETCH_NEWS = 'START_FETCH_NEWS';
export const SUCCESS_FETCH_NEWS = 'SUCCESS_FETCH_NEWS';
export const FAILURE_FETCH_NEWS = 'FAILURE_FETCH_NEWS';
// ________________________________________________________
// END News

export const RESET_INFORMER_STYLE = 'RESET_INFORMER_STYLE';
export const START_RESET_INFORMER_STYLES = 'START_RESET_INFORMER_STYLES';
export const FAILURE_RESET_INFORMER_STYLES = 'FAILURE_RESET_INFORMER_STYLES';
export const SUCCESS_RESET_INFORMER_STYLES = 'SUCCESS_RESET_INFORMER_STYLES';

export const START_FETCH_GLOBAL_STATISTICS = 'START_FETCH_GLOBAL_STATISTICS';
export const SUCCESS_FETCH_GLOBAL_STATISTICS =
  'SUCCESS_FETCH_GLOBAL_STATISTICS';
export const FAILURE_FETCH_GLOBAL_STATISTICS =
  'FAILURE_FETCH_GLOBAL_STATISTICS';

export const START_FETCH_STATISTICS_BY_TYPE = 'START_FETCH_STATISTICS_BY_TYPE';
export const SUCCESS_FETCH_STATISTICS_BY_TYPE =
  'SUCCESS_FETCH_STATISTICS_BY_TYPE';
export const FAILURE_FETCH_STATISTICS_BY_TYPE =
  'FAILURE_FETCH_STATISTICS_BY_TYPE';

export const START_FETCH_AB_TESTING_STATISTICS =
  'START_FETCH_AB_TESTING_STATISTICS';
export const SUCCESS_FETCH_AB_TESTING_STATISTICS =
  'SUCCESS_FETCH_AB_TESTING_STATISTICS';
export const FAILURE_FETCH_AB_TESTING_STATISTICS =
  'FAILURE_FETCH_AB_TESTING_STATISTICS';

export const START_FETCH_GLOBAL_SETTINGS = 'START_FETCH_GLOBAL_SETTINGS';
export const SUCCESS_FETCH_GLOBAL_SETTINGS = 'SUCCESS_FETCH_GLOBAL_SETTINGS';
export const FAILURE_FETCH_GLOBAL_SETTINGS = 'FAILURE_FETCH_GLOBAL_SETTINGS';

export const START_EDIT_GLOBAL_SETTINGS = 'START_EDIT_GLOBAL_SETTINGS';
export const SUCCESS_EDIT_GLOBAL_SETTINGS = 'SUCCESS_EDIT_GLOBAL_SETTINGS';
export const FAILURE_EDIT_GLOBAL_SETTINGS = 'FAILURE_EDIT_GLOBAL_SETTINGS';
export const START_SAVE_GLOBAL_SETTINGS = 'START_SAVE_GLOBAL_SETTINGS';
export const SUCCESS_SAVE_GLOBAL_SETTINGS = 'SUCCESS_SAVE_GLOBAL_SETTINGS';
export const FAILURE_SAVE_GLOBAL_SETTINGS = 'FAILURE_SAVE_GLOBAL_SETTINGS';
export const SET_INIT_GLOBAL_SETTINGS = 'SET_INIT_GLOBAL_SETTINGS';

// ________________________________________________________
// END Site Statistics

export const START_FETCH_DELETE_CUSTOM_NEWS = 'START_FETCH_DELETE_CUSTOM_NEWS';
export const SUCCESS_FETCH_DELETE_CUSTOM_NEWS =
  'SUCCESS_FETCH_DELETE_CUSTOM_NEWS';
export const FAILURE_FETCH_DELETE_CUSTOM_NEWS =
  'FAILURE_FETCH_DELETE_CUSTOM_NEWS';

// ________________________________________________________
// END Site Statistics

export const FETCH_AB_TESTS_LOGIC = 'FETCH_AB_TESTS_LOGIC';
export const ADD_AB_TEST_LOGIC = 'ADD_AB_TEST_LOGIC';
export const EDIT_AB_TEST_LOGIC = 'EDIT_AB_TEST_LOGIC';

export const FETCH_AB_TESTS = 'FETCH_AB_TESTS';
export const FETCH_AB_TEST_RESULTS = 'FETCH_AB_TEST_RESULTS';
export const ADD_AB_TEST = 'ADD_AB_TEST';
export const EDIT_AB_TEST = 'EDIT_AB_TEST';

export const FETCH_SCRIPT_CHECKS = 'FETCH_SCRIPT_CHECKS';
export const FETCH_SCRIPT_CHECKS_LOG = 'FETCH_SCRIPT_CHECKS_LOG';
export const ADD_SCRIPT_CHECK = 'ADD_SCRIPT_CHECK';
export const EDIT_SCRIPT_CHECK = 'EDIT_SCRIPT_CHECK';
export const FETCH_SITE_SCRIPT_VERSIONS = 'FETCH_SITE_SCRIPT_VERSIONS';
export const CHECK_SITE_SCRIPT_VERSION = 'CHECK_SITE_SCRIPT_VERSION';
export const FETCH_SITE_STATUSES_HISTORY = 'FETCH_SITE_STATUSES_HISTORY';

export const FETCH_PARSING_ERRORS = 'FETCH_PARSING_ERRORS';

export const FETCH_USER_NOTIFICATIONS = 'FETCH_USER_NOTIFICATIONS';

export const FETCH_USER_NOTIFICATIONS_CONFIG =
  'FETCH_USER_NOTIFICATIONS_CONFIG';
export const EDIT_USER_NOTIFICATION_CONFIG = 'EDIT_USER_NOTIFICATION_CONFIG';
export const DELETE_USER_NOTIFICATION_CONFIG =
  'DELETE_USER_NOTIFICATION_CONFIG';
export const ADD_USER_NOTIFICATION_CONFIG = 'ADD_USER_NOTIFICATION_CONFIG';

export const MARK_AS_READ_USER_NOTIFICATIONS =
  'MARK_AS_READ_USER_NOTIFICATIONS';
