import React from 'react';
import { renderToString } from 'react-dom/server';
import { Icon } from 'antd';
import { colorGreen } from '../../../styles/js/variables/colors';

export function labelRender() {
  const { name } = this;
  const hint = this.options.hint || '';
  const stringIcon = renderToString(
    <Icon
      type="info-circle"
      theme="filled"
      style={{
        top: '-0.5em',
        position: 'relative',
        opacity: '0.6',
        fontSize: '0.75em',
        color: colorGreen,
      }}
    />,
  );

  return `<b title='${hint}'>${name} ${hint && stringIcon}</b>`;
}
