import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Input, Row, Select } from 'antd';
import { withTranslation } from 'react-i18next';
import { FaExclamationTriangle } from 'react-icons/fa';
import { connect } from 'react-redux';

// Utils
import { statusesSites } from '../../../constants/statuses';
import {
  mustBeFilled,
  mustBeUrl,
  lengthMustBeMoreAndLess,
} from '../../../config/validationRules';
import { colorYellow } from '../../../styles/js/variables/colors';
import { addLineBreaks } from '../../../utils/string';
import FormSwitch from '../components/FormSwitch';
import useValidation from '../utils/useValidation';

// Components
import Status from '../../Status';
import FormActions from '../components/FormActions';

const initialForm = {
  domain: { value: '', rules: [mustBeFilled, mustBeUrl] },
  name: { value: '', rules: [mustBeFilled] },
  status: { value: '', rules: [] },
  title_xpath: { value: '', rules: [mustBeFilled] },
  content_xpath: { value: '', rules: [mustBeFilled] },
  description_xpath: { value: '', rules: [mustBeFilled] },
  image_xpath: { value: '', rules: [mustBeFilled] },
  date_xpath: { value: '', rules: [mustBeFilled] },
  date_format: { value: '', rules: [mustBeFilled] },
  date_format_enable: { value: false, rules: [] },
  views_required: { value: '', rules: [mustBeFilled] },
  coef_min: { value: '', rules: [mustBeFilled] },
  coef_max: { value: '', rules: [mustBeFilled] },
  is_parsing_enabled: { value: false, rules: [] },
};

const AddSiteForm = ({
  formData: formDataProp,
  onSubmit,
  onCancel,
  isSaving,
  t,
}) => {
  const isEditForm = !!formDataProp;
  const [formData, setFormData] = useState(formDataProp);
  const [initForm, setInitForm] = useState(initialForm);

  const {
    errors,
    bindedSubmit,
    bindedInputFunctions,
    currentForm,
    isFormTouched,
  } = useValidation(onSubmit, initForm, formData);

  const editInitForm = useCallback(() => {
    const isSuspended = currentForm.status === statusesSites.STATUS_SUSPENDED;

    const removeDescription = ({ description, notice, ...other }) => other;

    setFormData(
      isSuspended
        ? {
            ...currentForm,
            description: '',
            notice: '',
          }
        : removeDescription(currentForm),
    );

    setInitForm(
      isSuspended
        ? {
            ...initForm,
            description: {
              value: '',
              rules: [lengthMustBeMoreAndLess(1, 512), mustBeFilled],
            },
            notice: {
              value: '',
              rules: [lengthMustBeMoreAndLess(1, 512), mustBeFilled],
            },
          }
        : removeDescription(initForm),
    );
  }, [currentForm, initForm]);

  useEffect(editInitForm, [currentForm.status]);

  return (
    <Form>
      <h4>{t('basic.general')}:</h4>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12}>
          <Form.Item {...errors.domain} label={t('basic.domain')}>
            <Input
              name="domain"
              size="large"
              placeholder="..."
              defaultValue={currentForm.domain}
              {...bindedInputFunctions}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item {...errors.name} label={t('basic.title')}>
            <Input
              name="name"
              size="large"
              placeholder="..."
              defaultValue={currentForm.name}
              {...bindedInputFunctions}
            />
          </Form.Item>
        </Col>
      </Row>

      {isEditForm && (
        <>
          <hr className="hr--divider" />

          <h4>{t('basic.status')}:</h4>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={8}>
              <Form.Item {...errors.status} label="">
                <Select
                  name="status"
                  defaultValue={currentForm.status}
                  placeholder={`-- ${t('basic.status')} --`}
                  style={{ minWidth: '100%' }}
                  {...bindedInputFunctions}
                >
                  {Object.values(statusesSites).map(status => (
                    <Select.Option key={status} name="status" value={status}>
                      <Status status={status} noBackground />
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={16}>
              <FaExclamationTriangle color={colorYellow} />{' '}
              {addLineBreaks(t('tooltip.siteStatusChangeWarning'))}
            </Col>
          </Row>

          <Row gutter={16}>
            {initForm.notice && (
              <Col xs={24} md={12}>
                <Form.Item {...errors.notice} label={t('form.noticeLabel')}>
                  <Input.TextArea
                    name="notice"
                    placeholder="..."
                    defaultValue={currentForm.notice}
                    {...bindedInputFunctions}
                  />
                </Form.Item>
              </Col>
            )}
            {initForm.description && (
              <Col xs={24} md={12}>
                <Form.Item
                  {...errors.description}
                  label={t('form.descriptionLabel')}
                >
                  <Input.TextArea
                    name="description"
                    placeholder="..."
                    defaultValue={currentForm.description}
                    {...bindedInputFunctions}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
        </>
      )}

      <hr className="hr--divider" />

      <h4>{t('basic.configuration')}:</h4>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={8}>
          <Form.Item {...errors.coef_min} label="coef_min">
            <Input
              name="coef_min"
              placeholder="..."
              size="default"
              defaultValue={currentForm.coef_min}
              {...bindedInputFunctions}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Form.Item {...errors.coef_max} label="coef_max">
            <Input
              name="coef_max"
              placeholder="..."
              size="default"
              defaultValue={currentForm.coef_max}
              {...bindedInputFunctions}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Form.Item {...errors.views_required} label="views_required">
            <Input
              name="views_required"
              placeholder="..."
              size="default"
              defaultValue={currentForm.views_required}
              {...bindedInputFunctions}
            />
          </Form.Item>
        </Col>
      </Row>

      <hr className="hr--divider" />

      <h4>{t('basic.settingsParsing')}:</h4>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12}>
          <Form.Item {...errors.title_xpath} label="title_xpath">
            <Input
              name="title_xpath"
              placeholder="..."
              size="default"
              defaultValue={currentForm.title_xpath}
              {...bindedInputFunctions}
            />
          </Form.Item>
          <Form.Item {...errors.description_xpath} label="description_xpath">
            <Input
              name="description_xpath"
              placeholder="..."
              size="default"
              defaultValue={currentForm.description_xpath}
              {...bindedInputFunctions}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item {...errors.image_xpath} label="image_xpath">
            <Input
              name="image_xpath"
              placeholder="..."
              size="default"
              defaultValue={currentForm.image_xpath}
              {...bindedInputFunctions}
            />
          </Form.Item>
          <Form.Item {...errors.content_xpath} label="content_xpath">
            <Input
              name="content_xpath"
              placeholder="..."
              size="default"
              defaultValue={currentForm.content_xpath}
              {...bindedInputFunctions}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12}>
          <Form.Item {...errors.date_xpath} label="date_xpath">
            <Input
              name="date_xpath"
              placeholder="..."
              size="default"
              defaultValue={currentForm.date_xpath}
              {...bindedInputFunctions}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Form.Item {...errors.date_format} label="date_format">
            <Input
              disabled={!currentForm.date_format_enable}
              name="date_format"
              placeholder="..."
              size="default"
              defaultValue={currentForm.date_format}
              {...bindedInputFunctions}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={4}>
          <Form.Item {...errors.date_format_enable} label=" ">
            <FormSwitch
              name="date_format_enable"
              size="large"
              checkedChildren={t('basic.on')}
              unCheckedChildren={t('basic.off')}
              label={<div>&nbsp;</div>}
              checked={currentForm.date_format_enable}
              {...bindedInputFunctions}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={12}>
          <Form.Item {...errors.is_parsing_enabled} label=" ">
            <FormSwitch
              name="is_parsing_enabled"
              size="large"
              checkedChildren={t('basic.on')}
              unCheckedChildren={t('basic.off')}
              label={t('basic.parsing')}
              checked={currentForm.is_parsing_enabled}
              {...bindedInputFunctions}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item {...errors.amp_attribute} label="amp_attribute">
            <Input
              name="amp_attribute"
              placeholder="..."
              size="default"
              defaultValue={currentForm.amp_attribute}
              {...bindedInputFunctions}
            />
          </Form.Item>
        </Col>
      </Row>

      <br />
      <FormActions
        isLoading={isSaving}
        style={{ textAlign: 'right' }}
        titleSubmit={t('form.buttonSave')}
        onSubmit={bindedSubmit}
        disable={!isFormTouched}
        {...(onCancel && {
          onCancel,
          titleCancel: t('form.buttonCancel'),
        })}
      />
    </Form>
  );
};

AddSiteForm.propTypes = {
  onChange: PropTypes.func,
  form: PropTypes.shape({}),
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(AddSiteForm));
