import { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { notification as notificationAntd } from 'antd';
import { string, oneOf, shape, arrayOf } from 'prop-types';

import { hideNotification } from '../../redux/actions/notifications';

export const notification = ({
  id,
  text,
  type = 'info',
  timeout,
  placement = 'topRight',
  actions,
}) =>
  notificationAntd[type]({
    key: id || 0,
    message: type.toUpperCase(),
    description: text,
    duration: timeout / 1000 || (text && text.length * 0.125),
    placement,
    onClose: () => actions?.hideNotification(id),
  });

const Notifications = ({ actions, notifications, placement }) => {
  useEffect(() => {
    notifications.forEach(notification);
  }, [notifications, placement, actions]);

  return null;
};

const mapStateToProps = ({ notifications }) => ({
  notifications: notifications.list,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators({ hideNotification }, dispatch),
  },
});

Notifications.defaultProps = {
  placement: 'topRight',
  actions: {},
};

Notifications.propTypes = {
  actions: shape({ id: string, text: string, type: string }),
  notifications: arrayOf(shape({})).isRequired,
  placement: oneOf(['topLeft', 'topRight', 'bottomRight', 'bottomLeft']),
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
