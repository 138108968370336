import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

// Redux
import { addSite, deleteSite, editSite } from 'redux/actions/sites';
import { showModal } from 'redux/actions/modals';
import Status from '../../../components/Status';
import TableBoolean from '../../../components/Table/components/TableBoolean';
import TableRowTitle from '../../../components/Table/components/TableRowTitle';

// Utils
import { statusesSites as S } from '../../../constants/statuses';
import {
  fetchModerationList,
  moderateSite,
  getSitesStatuses as getSitesStatusesAction,
} from '../../../redux/actions/moderation';
import { START_FETCH_MODERATION_LIST } from '../../../redux/types/articles';

// Styles
import { ModerationTableWrapper } from '../styles';

const changeStatusTo = {
  [S.STATUS_ANALYTICS_SPEEDUP]: {
    approve: S.STATUS_PENDING,
    decline: '',
  },
  [S.STATUS_PENDING]: {
    approve: S.STATUS_ACTIVE,
    decline: S.STATUS_SUSPENDED,
  },
  [S.STATUS_VERIFICATION]: {
    approve: S.STATUS_ANALYTICS,
    decline: '',
  },
  [S.STATUS_SUSPENDED]: {
    approve: S.STATUS_ANALYTICS,
    decline: '',
  },
};

const ModerationTable = props => {
  const {
    t,
    list,
    status,
    isLoading,
    fetchTable,
    currentTab,
    moderateSiteStatus,
    getSitesStatuses,
  } = props;

  const handleModalEditSite = item => {
    props.showModal({
      type: 'MODAL_ADD_SITE',
      params: {
        fields: item,
        actions: {
          onSubmit: props.editSite,
        },
      },
    });
  };

  const proceedModeration = (params, isApprove) => {
    const { id: siteId } = params;

    const nextStatus = isApprove
      ? changeStatusTo[status].approve
      : changeStatusTo[status].decline;

    props.showModal({
      type: 'MODAL_SITE_MODERATION',
      params: {
        ...params,
        status,
        nextStatus,
        actions: {
          onSubmit: () => {
            return moderateSiteStatus({ siteId, status: nextStatus })
              .then(() => fetchTable({ status }))
              .then(getSitesStatuses);
          },
        },
      },
    });
  };

  const handleFetch = useCallback(() => {
    fetchTable({ status });
  }, [fetchTable, status]);

  useEffect(() => {
    if (currentTab === status) handleFetch();
  }, [currentTab, handleFetch, status]);

  return (
    <div className="page-body main-page">
      <ModerationTableWrapper
        isLoading={isLoading}
        // isAutoHeaders
        className="ant-table--auto-size"
        headers={[
          { dataIndex: 'id', title: 'id' },
          {
            dataIndex: 'name',
            title: t('table.site'),
            render: (text, { domain }) => (
              <TableRowTitle title={text} domain={domain} />
            ),
          },
          {
            dataIndex: 'status',
            title: t('basic.status'),
            render: cellStatus => <Status status={cellStatus} />,
          },
          {
            dataIndex: 'title_xpath',
            title: 'title_xpath',
            // className: 'ant-table-column--vertical',
            render: text => (
              <TableBoolean boolResult={text} title="title_xpath" />
            ),
          },
          {
            dataIndex: 'content_xpath',
            title: 'content_xpath',
            // className: 'ant-table-column--vertical',
            render: text => (
              <TableBoolean boolResult={text} title="content_xpath" />
            ),
          },
          {
            dataIndex: 'description_xpath',
            title: 'description_xpath',
            // className: 'ant-table-column--vertical',
            render: text => (
              <TableBoolean boolResult={text} title="description_xpath" />
            ),
          },
          {
            dataIndex: 'date_xpath',
            title: 'date_xpath',
            // className: 'ant-table-column--vertical',
            render: text => (
              <TableBoolean boolResult={text} title="date_xpath" />
            ),
          },
          {
            dataIndex: 'image_xpath',
            title: 'image_xpath',
            // className: 'ant-table-column--vertical',
            render: text => (
              <TableBoolean boolResult={text} title="image_xpath" />
            ),
          },
          {
            dataIndex: 'date_format',
            title: 'date_format',
            // className: 'ant-table-column--vertical',
            render: text => (
              <TableBoolean boolResult={text} title="date_xpath" />
            ),
          },
        ]}
        data={list}
        total={list.total}
        limit={list.limit}
        fetch={handleFetch}
        actions={{
          ...(status &&
            changeStatusTo[status].approve && {
              approve: params => proceedModeration(params, true),
            }),
          ...(status &&
            changeStatusTo[status].decline && {
              decline: params => proceedModeration(params, false),
            }),
          edit: handleModalEditSite,
        }}
        type="moderationActions"
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    list: state.moderation.list,
    isLoading: state.network[START_FETCH_MODERATION_LIST],
  };
}

const mapDispatchToProps = {
  showModal,
  fetchTable: fetchModerationList,
  addSite,
  editSite,
  deleteSite,
  moderateSiteStatus: moderateSite,
  getSitesStatuses: getSitesStatusesAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(ModerationTable));
