import {
  START_FETCH_PUBLISHERS_TABLE,
  SUCCESS_FETCH_PUBLISHERS_TABLE,
  FAILURE_FETCH_PUBLISHERS_TABLE,
  START_FETCH_PUBLISHERS_CHART,
  SUCCESS_FETCH_PUBLISHERS_CHART,
  FAILURE_FETCH_PUBLISHERS_CHART,
  START_ADD_PUBLISHER,
  SUCCESS_ADD_PUBLISHER,
  FAILURE_ADD_PUBLISHER,
  START_EDIT_PUBLISHER,
  SUCCESS_EDIT_PUBLISHER,
  FAILURE_EDIT_PUBLISHER,
  START_DELETE_PUBLISHER,
  SUCCESS_DELETE_PUBLISHER,
  FAILURE_DELETE_PUBLISHER,
  START_FETCH_PUBLISHER,
  SUCCESS_FETCH_PUBLISHER,
  FAILURE_FETCH_PUBLISHER,
} from 'redux/types';

import {
  getPublishersInfo,
  addNewPublisher,
  editPublisherById,
  removePublisherById,
  getPublishersStatistics,
  getPublisherById,
} from 'api';
import { showErrorSavingMessage } from '../../components/Notifications/helper';

export const fetchTable = params => dispatch => {
  dispatch({
    type: START_FETCH_PUBLISHERS_TABLE,
  });

  return getPublishersInfo(params)
    .then(payload => {
      dispatch({
        type: SUCCESS_FETCH_PUBLISHERS_TABLE,
        payload: {
          ...payload.data,
          ...params,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: FAILURE_FETCH_PUBLISHERS_TABLE,
        payload: error,
        notification: {
          type: 'error',
          text: `Fetch table of publishers has failure. Status ${error.status}`,
        },
      });
    });
};

export const fetchPublisher = params => dispatch => {
  dispatch({
    type: START_FETCH_PUBLISHER,
  });

  return getPublisherById(params)
    .then(payload => {
      dispatch({
        type: SUCCESS_FETCH_PUBLISHER,
        payload: payload.data,
      });
    })
    .catch(error => {
      dispatch({
        type: FAILURE_FETCH_PUBLISHER,
        payload: error,
        notification: {
          type: 'error',
          text: `get publisher has failure. Status ${error.status}`,
        },
      });
    });
};

export const savePublisherInfo = data => dispatch => {
  dispatch({
    type: SUCCESS_FETCH_PUBLISHER,
    payload: data,
  });
};

export const addPublisher = params => dispatch => {
  dispatch({
    type: START_ADD_PUBLISHER,
  });

  return addNewPublisher(params)
    .then(payload => {
      dispatch({
        type: SUCCESS_ADD_PUBLISHER,
        payload: payload.data,
      });
    })
    .catch(error => {
      dispatch({
        type: FAILURE_ADD_PUBLISHER,
        payload: error,
        notification: {
          type: 'error',
          text: showErrorSavingMessage(error),
        },
      });
    });
};

export const editPublisher = data => (dispatch, getState) => {
  dispatch({
    type: START_EDIT_PUBLISHER,
  });

  return editPublisherById(data)
    .then(response => {
      // optimistic ui
      const table = getState().publishers.table.data;

      table[table.findIndex(item => data.id === item.id)] = {
        ...table[table.findIndex(item => data.id === item.id)],
        ...data,
      };

      if (response) {
        dispatch({
          type: SUCCESS_EDIT_PUBLISHER,
          payload: table,
        });
      }
    })
    .catch(error => {
      dispatch({
        type: FAILURE_EDIT_PUBLISHER,
        payload: error,
        notification: {
          type: 'error',
          text: showErrorSavingMessage(error),
        },
      });
    });
};

export const deletePublisher = id => (dispatch, getState) => {
  dispatch({
    type: START_DELETE_PUBLISHER,
  });

  return removePublisherById(id)
    .then(() => {
      const table = getState().publishers.table.data;

      table.splice(
        table.findIndex(item => item.id === id),
        1,
      );

      dispatch({
        type: SUCCESS_DELETE_PUBLISHER,
        payload: table,
      });
    })
    .catch(error => {
      dispatch({
        type: FAILURE_DELETE_PUBLISHER,
        payload: error,
        notification: {
          type: 'error',
          text: `Delete publisher has failure. Status ${error.status}`,
        },
      });
    });
};

export const fetchChart = params => dispatch => {
  dispatch({
    type: START_FETCH_PUBLISHERS_CHART,
  });

  return getPublishersStatistics(params)
    .then(payload => {
      dispatch({
        type: SUCCESS_FETCH_PUBLISHERS_CHART,
        payload: payload.data,
      });
    })
    .catch(error => {
      dispatch({
        type: FAILURE_FETCH_PUBLISHERS_CHART,
        payload: error,
        notification: {
          type: 'error',
          text: `Fetch chart of publishers has failure. Status ${error.status}`,
        },
      });
    });
};
