import {
  START_FETCH_TABLE_ARTICLES_STATISTICS,
  SUCCESS_FETCH_TABLE_ARTICLES_STATISTICS,
  FAILURE_FETCH_TABLE_ARTICLES_STATISTICS,
} from 'redux/types/articles';

const initialState = {
  info: {},
  table: {
    total: 0,
    limit: 10,
    offset: 0,
    data: [],
    isLoading: false,
  },
  chart: {},
};

export default function articlesStatistics(state = initialState, action = {}) {
  switch (action.type) {
    // Fetch table informer by id
    // ________________________________________________________
    case START_FETCH_TABLE_ARTICLES_STATISTICS:
      return { ...state, table: { ...state.table, isLoading: true } };

    case SUCCESS_FETCH_TABLE_ARTICLES_STATISTICS:
      return {
        ...state,
        table: action.payload,
        isLoading: false,
      };

    case FAILURE_FETCH_TABLE_ARTICLES_STATISTICS:
      return { ...state, table: { ...state.table, isLoading: false } };
    // ________________________________________________________
    // END Fetch table informer by id

    default:
      return state;
  }
}
