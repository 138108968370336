import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import LinkWithTag from '../../../components/LinkWithTag';
import TableRowTitle from '../../../components/Table/components/TableRowTitle';
import TableStatus from '../../../components/Table/components/TableStatus';
import {
  getSingleHeader,
  getGroupHeaders,
} from '../../../components/Table/config/moldingTable';
import XpathsProgress from '../components/XpathsProgress';

export const useTableHeaders = ({ publishers, sitesList, params }) => {
  const { t } = useTranslation();
  const [tableHeaders, setTableHeaders] = useState([]);

  const getHeaders = headerParams => {
    setTableHeaders([
      {
        dataIndex: 'id',
        title: 'ID',
      },
      {
        dataIndex: 'name',
        title: t('table.sites'),
        render: (text, { domain }) => (
          <TableRowTitle title={text} isLink subtitle={domain} />
        ),
        filters: sitesList.map(({ name, id }) => ({
          text: name,
          value: id,
        })),
        // filteredValue: filtered.name || null,
        // onFilter: (value, record) => record.id.indexOf(value) === 0,
      },
      {
        dataIndex: 'publisher_name',
        title: t('table.publisher'),
        filters: publishers.map(({ name, id }) => ({
          text: name,
          value: id,
        })),
        render: (text, { publisher_id }) => (
          <LinkWithTag href={`/${publisher_id}`} style={{ fontSize: 13 }}>
            {text}
          </LinkWithTag>
        ),
      },
      getSingleHeader('status', {
        ...headerParams,
        render: (text, record) => <TableStatus {...record} />,
      }),
      {
        dataIndex: 'title_xpath',
        title: 'xpaths',
        render: (title, row) => <XpathsProgress title={title} row={row} />,
      },
      ...getGroupHeaders(
        [
          'views_our',
          'views_another',
          'views',
          'views_our_real',
          'views_another_real',
          'views_real',
          'display',
          'ctr',
          'click_from',
          'click_on',
          'ko',
        ],
        params,
      ),
    ]);
  };

  useEffect(getHeaders, []);

  return tableHeaders;
};
