import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { List, Popconfirm, Typography, Avatar } from 'antd';
import EmptyData from 'components/EmptyData';
import Button from 'components/Button';

const { Title } = Typography;

const ArticlesList = props => {
  const { list, getArticles, deleteArticle } = props;
  const deleteArticleHandler = useCallback(
    id => deleteArticle(id).then(getArticles),
    [getArticles, deleteArticle],
  );

  return (
    <>
      <Title level={4}>Размещенные статьи</Title>
      <List
        dataSource={list}
        bordered
        locale={{
          emptyText: (
            <EmptyData
              title={
                <span>
                  На этом информере еще не размещены статьи.
                  <br />
                  <Link to="/article-promotion">Перейдите по ссылке</Link> для
                  размещения
                </span>
              }
            />
          ),
        }}
        renderItem={({ title, img, position, custom_id }) => (
          <List.Item
            actions={[
              <Popconfirm
                title="Статья будет удалена. Вы уверены?"
                okText="Да"
                cancelText="Нет"
                onConfirm={() => deleteArticleHandler(custom_id)}
              >
                <Button icon="close" type="danger" title="Удалить статью" />
              </Popconfirm>,
            ]}
          >
            <List.Item.Meta
              description={`Позиция: ${position}`}
              avatar={<Avatar src={img} size={50} />}
              title={title}
            />
          </List.Item>
        )}
      />
    </>
  );
};

ArticlesList.propTypes = {
  deleteArticle: PropTypes.func.isRequired,
  getArticles: PropTypes.func,
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

ArticlesList.defaultProps = {
  getArticles: () => {},
};

export default ArticlesList;
