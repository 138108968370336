import uid from 'uid';
import { SHOW_NOTIFICATION, HIDE_NOTIFICATION } from '../../types';

export const initialState = {
  list: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_NOTIFICATION: {
      const notification = { ...action.payload, id: uid() };
      return {
        ...state,
        list: [...state.list, notification],
      };
    }
    case HIDE_NOTIFICATION: {
      return {
        ...state,
        list: state.list.filter(
          notification => notification.id !== action.payload,
        ),
      };
    }
    default:
      return state;
  }
}
