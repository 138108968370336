import { Icon } from 'antd';
import React from 'react';

// Utils
import { TYPE_DESKTOP, TYPE_MOBILE } from '../../../constants/devices';
import { allStatuses } from '../../../constants/statuses';
import { convertDate } from '../../../services/formatter';
import {
  colorGreenSemantic,
  colorRed,
} from '../../../styles/js/variables/colors';
import { timestampToFormat } from '../../../utils/date';
import { cutStringWithDots } from '../../../utils/string';

// Components
import TableDevice from '../components/TableDevice';
import TableRowTitle from '../components/TableRowTitle';
import TableStatus from '../components/TableStatus';
import { Translation } from '../../Translation';

const getTranslatedCell = ({ type, isWithTooltip, isVertical, isBoolean }) => ({
  name: <Translation type={`table.${type}`} />,
  key: type,
  className: isVertical ? 'ant-table-column--vertical ' : '',
  tooltip: isWithTooltip ? (
    <Translation type={`table.tooltips.${type}`} />
  ) : null,
  ...(isBoolean && {
    render: text => (
      <Icon
        type={text ? 'check' : 'close'}
        twoToneColor={text ? colorGreenSemantic : colorRed}
        theme="outlined"
        style={{ color: text ? colorGreenSemantic : colorRed }}
      />
    ),
  }),
});

export default {
  sites: {
    name: <Translation type="table.sites" />,
    key: 'name',
    render: (text, { domain }) => (
      <TableRowTitle title={text} isLink subtitle={domain} />
    ),
  },
  publisher: {
    name: <Translation type="table.publisher" />,
    key: 'name',
    render: text => <div className="table-cell--left">{text}</div>,
    // render: (text, { login }) => (
    //   <TableRowTitle title={text} subtitle={login} icon={FaRegUser} />
    // ),
  },
  id: getTranslatedCell({ type: 'id' }),
  informer: {
    name: <Translation type="table.informer" />,
    key: 'name',
  },
  status: {
    ...getTranslatedCell({ type: 'status' }),
    render: (text, record) => <TableStatus {...record} />,
    align: 'center',
    filters: Object.keys(allStatuses).map(key => ({
      text: <Translation type={`status.${allStatuses[key]}`} />,
      value: allStatuses[key],
    })),
    onFilter: (value, record) => record.status.indexOf(value) === 0,
  },
  ab_title: getTranslatedCell({ type: 'ab_title' }),
  date: {
    ...getTranslatedCell({ type: 'date' }),
    render: text => convertDate(text),
  },
  dateTime: {
    ...getTranslatedCell({ type: 'date' }),
    render: text => timestampToFormat(text, 'DD.MM.YY hh:mm'),
  },
  device_type: {
    ...getTranslatedCell({ type: 'device_type' }),
    render: (text, record) => <TableDevice {...record} deviceType={text} />,
    align: 'center',
    filters: [TYPE_MOBILE, TYPE_DESKTOP].map(type => ({
      text: <Translation type={`device.${type}`} />,
      value: type,
    })),
  },
  domain: {
    ...getTranslatedCell({ type: 'domain' }),
    render: domain => (
      <a href={domain} target="_blank" rel="noopener noreferrer">
        {domain}
      </a>
    ),
  },
  login: getTranslatedCell({ type: 'login' }),
  url: {
    ...getTranslatedCell({ type: 'url' }),
    render: text => <div className="table-cell--left">{text}</div>,
  },
  title: {
    ...getTranslatedCell({ type: 'title' }),
    render: text => <div className="table-cell--left">{text}</div>,
  },

  analytics: getTranslatedCell({ type: 'analytics' }),
  click_from: getTranslatedCell({ type: 'click_from', isWithTooltip: true }),
  click_on: getTranslatedCell({ type: 'click_on', isWithTooltip: true }),
  clicks_informer: getTranslatedCell({ type: 'clicks_informer' }),
  clicks_preview: getTranslatedCell({ type: 'clicks_preview' }),
  clicks_rotator: getTranslatedCell({ type: 'clicks_rotator' }),
  ctr: getTranslatedCell({ type: 'ctr', isWithTooltip: true }),
  ctr_informer: getTranslatedCell({ type: 'ctr_informer' }),
  ctr_preview: getTranslatedCell({ type: 'ctr_preview' }),
  ctr_rotator: getTranslatedCell({ type: 'ctr_rotator' }),
  description: {
    ...getTranslatedCell({ type: 'description' }),
    align: 'left',
    render: text => (
      <div style={{ width: '20em' }}>
        {text ? cutStringWithDots(text, 80) : ''}
      </div>
    ),
  },
  notice: {
    ...getTranslatedCell({ type: 'notice' }),
    align: 'left',
    render: text => <i style={{ width: '20em' }}>&quot;{text}&quot;</i>,
  },
  display: getTranslatedCell({ type: 'display', isWithTooltip: true }),
  ko: {
    ...getTranslatedCell({ type: 'ko', isWithTooltip: true }),
    render: text => (
      <div className="table-cell--right">{Number(text).toFixed(2)}</div>
    ),
  },
  published: {
    ...getTranslatedCell({ type: 'published' }),
    render: text => (text ? timestampToFormat(text, 'DD.MM.YY') : ''),
  },
  result: {
    ...getTranslatedCell({ type: 'result' }),
    align: 'left',
    render: text => (
      <div style={{ width: '15em' }}>
        {text ? cutStringWithDots(text, 60) : ''}
      </div>
    ),
  },
  unpublished: {
    ...getTranslatedCell({ type: 'unpublished' }),
    render: text => (text ? timestampToFormat(text, 'DD.MM.YY') : ''),
  },
  version: getTranslatedCell({ type: 'version' }),
  views: getTranslatedCell({ type: 'views', isWithTooltip: true }),
  views_another: getTranslatedCell({
    type: 'views_another',
    isWithTooltip: true,
  }),
  views_another_real: getTranslatedCell({
    type: 'views_another_real',
    isWithTooltip: true,
  }),
  views_informer: getTranslatedCell({ type: 'views_informer' }),
  views_our: getTranslatedCell({ type: 'views_our', isWithTooltip: true }),
  views_our_real: getTranslatedCell({
    type: 'views_our_real',
    isWithTooltip: true,
  }),
  views_preview: getTranslatedCell({ type: 'views_preview' }),
  views_real: getTranslatedCell({ type: 'views_real', isWithTooltip: true }),
  views_rotator: getTranslatedCell({ type: 'views_rotator' }),
};
