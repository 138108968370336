import {
  START_FETCH_TABLE_INFORMER_BY_DATE,
  SUCCESS_FETCH_TABLE_INFORMER_BY_DATE,
  FAILURE_FETCH_TABLE_INFORMER_BY_DATE,
} from 'redux/types';

import { getInformerByDateInfo } from 'api';

export const fetchTable = params => dispatch => {
  dispatch({
    type: START_FETCH_TABLE_INFORMER_BY_DATE,
  });

  return getInformerByDateInfo(params)
    .then(payload => {
      dispatch({
        type: SUCCESS_FETCH_TABLE_INFORMER_BY_DATE,
        payload: {
          ...payload.data,
          ...params,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: FAILURE_FETCH_TABLE_INFORMER_BY_DATE,
        payload: error,
        notification: {
          type: 'error',
          text: `Fetch table of informer by date has failure. Status ${error.status}`,
        },
      });
    });
};
