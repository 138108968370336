import * as routes from '../config/routePaths';
import { setLoginRedirect, getAccessToken } from './localStorage';

export const isNotAuth = (nextState, replace) => {
  setLoginRedirect(nextState.location); // saving location for redirect after login

  if (!getAccessToken()) {
    replace({ pathname: routes.LOGIN });
  }
};

export const isAuth = (nextState, replace) => {
  if (getAccessToken()) {
    replace({ pathname: routes.HOME });
  }
};

export const areInvalidRoutes = (nextState, replace) => {
  [
    {
      url: '/:publisherId/:siteId/:informerId/:date',
      regex: new RegExp('/[0-9]+/[0-9]+/(([0-9]+.){2}([0-9]+))$', 'g'),
      redirect: routes.INFORMER,
      redirectParams: ['siteId', 'informerId', 'publisherId', 'newsId'],
    },
  ].find(invalid => {
    if (
      nextState.match.path === invalid.url &&
      !invalid.regex.test(nextState.match.url)
    ) {
      const redirectPath = invalid.redirectParams.reduce((template, param) => {
        return template.replace(`:${param}`, nextState.match.params[param]);
      }, invalid.redirect);

      replace({ pathname: redirectPath });
      return true;
    }

    return false;
  });
};
