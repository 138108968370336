const routesPath = {
  HOME: '/',
  LOGIN: '/login',
  GLOBAL_SETTINGS: '/settings',
  GLOBAL_STATISTICS: '/statistics',
  STATISTICS_BY_TYPE: '/statistics/types',
  AB_TESTS_STATISTICS: '/statistics/ab-tests-statistics',
  AB_TESTS: '/ab-tests',
  ARTICLES: '/articles',
  ARTICLES_STATISTICS: '/articles-statistics',
  ARTICLE_PROMOTION: '/article-promotion',
  SITES_MODERATION: '/sites-moderation',
  SITES: '/sites',
  SITES_LIST: '/sites-list',

  // Dynamic
  PUBLISHER: '/:publisherId',
  SITE: '/:publisherId/:siteId',
  SITE_STATISTICS: '/:publisherId/:siteId/statistics',
  INFORMER_ADD: '/:publisherId/:siteId/informers-list/informer_add',
  INFORMERS_LIST: '/:publisherId/:siteId/informers-list',
  INFORMER: '/:publisherId/:siteId/informers-list/:informerId',
  INFORMER_EDIT: '/:publisherId/:siteId/informers-list/:informerId/edit',
  INFORMER_BY_DATE: '/:publisherId/:siteId/informers-list/:informerId/:date',
  INFORMER_NEWS:
    '/:publisherId/:siteId/informers-list/:informerId/news/:newsId',
};

export const types = Object.keys(routesPath).reduce((acc, cur) => {
  acc[cur] = cur;
  return acc;
}, {});

export const getFilledRoutePath = ({ path, params }) =>
  Object.entries(params).reduce(
    (acc, [key, value]) => acc.replace(`:${key}`, value),
    path,
  );

export const {
  AB_TESTS,
  AB_TESTS_STATISTICS,
  ARTICLE_PROMOTION,
  ARTICLES,
  ARTICLES_STATISTICS,
  GLOBAL_SETTINGS,
  GLOBAL_STATISTICS,
  HOME,
  INFORMER,
  INFORMERS_LIST,
  INFORMER_ADD,
  INFORMER_BY_DATE,
  INFORMER_EDIT,
  INFORMER_NEWS,
  LOGIN,
  PUBLISHER,
  SITE,
  SITES_LIST,
  SITE_STATISTICS,
  SITES_MODERATION,
  STATISTICS_BY_TYPE,
} = routesPath;

export default routesPath;
