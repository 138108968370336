import {
  START_FETCH_GLOBAL_STATISTICS,
  SUCCESS_FETCH_GLOBAL_STATISTICS,
  FAILURE_FETCH_GLOBAL_STATISTICS,
  START_FETCH_AB_TESTING_STATISTICS,
  SUCCESS_FETCH_AB_TESTING_STATISTICS,
  FAILURE_FETCH_AB_TESTING_STATISTICS,
} from 'redux/types';

import { getAbTestingStatisticsApi, getGlobalStatisticsApi } from 'api';
import {
  FAILURE_FETCH_STATISTICS_BY_TYPE,
  START_FETCH_STATISTICS_BY_TYPE,
  SUCCESS_FETCH_STATISTICS_BY_TYPE,
} from '../types';
import { getStatisticsByType } from '../../api';
import { ERROR_NOT_RESPONDED } from '../../constants/messages';

export const fetchAbTestingStatistics = params => dispatch => {
  dispatch({
    type: START_FETCH_AB_TESTING_STATISTICS,
  });

  return getAbTestingStatisticsApi(params)
    .then(payload => {
      dispatch({
        type: SUCCESS_FETCH_AB_TESTING_STATISTICS,
        payload: payload.data,
      });
    })
    .catch(error => {
      dispatch({
        type: FAILURE_FETCH_AB_TESTING_STATISTICS,
        payload: error,
        notification: {
          type: 'error',
          text: ERROR_NOT_RESPONDED,
        },
      });
    });
};

export const fetchGlobalStatistics = () => dispatch => {
  dispatch({
    type: START_FETCH_GLOBAL_STATISTICS,
  });

  return getGlobalStatisticsApi()
    .then(payload => {
      dispatch({
        type: SUCCESS_FETCH_GLOBAL_STATISTICS,
        payload: payload.data,
      });
    })
    .catch(error => {
      dispatch({
        type: FAILURE_FETCH_GLOBAL_STATISTICS,
        payload: error,
        notification: {
          type: 'error',
          text: ERROR_NOT_RESPONDED,
        },
      });
    });
};

export const fetchStatisticsByType = params => dispatch => {
  dispatch({
    type: START_FETCH_STATISTICS_BY_TYPE,
  });

  return getStatisticsByType(params)
    .then(payload => {
      dispatch({
        type: SUCCESS_FETCH_STATISTICS_BY_TYPE,
        payload: {
          data: payload.data,
          blockTitle: params.block,
          isInformer: params.isInformer,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: FAILURE_FETCH_STATISTICS_BY_TYPE,
        payload: error,
        notification: {
          type: 'error',
          text: ERROR_NOT_RESPONDED,
        },
      });
    });
};
