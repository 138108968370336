import React from 'react';
import { oneOfType, shape, string } from 'prop-types';

const LinkItem = ({ children }) => {
  let label;
  let title = children;
  const isCurrent = !children.props;

  try {
    const renderParams = isCurrent ? children : children.props.children;
    const parsedProps = JSON.parse(renderParams);
    title = parsedProps.title;
    label = parsedProps.label;
  } catch (e) {} // eslint-disable-line no-empty

  const render = label ? (
    <>
      <span>
        {label}
        {title && ':'}&nbsp;
      </span>
      {title && <i>{label ? `"${title}"` : title}</i>}
    </>
  ) : (
    <span>{title}</span>
  );

  const newChildren = {
    ...children,
    props: {
      ...children.props,
      children: render,
    },
  };

  return (
    <li
      className={`breadcrumbs__item ${isCurrent &&
        'breadcrumbs__item--active'}`}
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      {isCurrent ? <a>{render}</a> : newChildren}
    </li>
  );
};

LinkItem.propTypes = {
  children: oneOfType([shape({}), string]),
};

export default LinkItem;
