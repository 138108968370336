// eslint-disable-next-line import/no-cycle
/* eslint-disable no-param-reassign */
import axios from 'axios';
import qs from 'querystringify';
import { secureAxios } from 'services/axios/enhanceAxios';
import { convertToUTC } from '../../utils/date';
import { api } from '../../api/config';
import { LOGIN } from '../../config/routePaths';
import {
  getAccessToken,
  getRefreshToken,
  removeAccessToken,
  removeRefreshToken,
  saveDataToLocalStorage,
  logOutUser,
} from '../localStorage';
import store from '../../redux/store';

const apiRefresh = `${api}/refresh`;
const getRefreshTokenApi = params => secureAxios(params).get(apiRefresh, {});
const instance = axios.create({ baseURL: api });

let isPendingToken = false;

const convertRequestToUTC = config => {
  const [url, urlParams = ''] = config.url.split('?');
  const parsedUrlParams = qs.parse(urlParams);
  const params = config.params || parsedUrlParams;
  const startDate = +params?.start;
  const endDate = +params?.end;

  if (startDate && endDate) {
    // if dates is present in url, all url params moves to params object
    config.params = {
      ...config.params,
      ...parsedUrlParams,
      start: +convertToUTC(startDate),
      end: +convertToUTC(endDate),
    };

    if (urlParams) config.url = url;
  }

  return config;
};

// label REQUEST
instance.interceptors.request.use(
  config => convertRequestToUTC(config),
  error => Promise.reject(error),
);

// label RESPONSE
instance.interceptors.response.use(
  response => response,
  error => {
    const originalRequest = error.config;
    const isError401 = error.response.status === 401;
    const isRefreshTokenApi = apiRefresh === originalRequest.url;
    const isExpiredToken =
      isError401 && !isRefreshTokenApi && !originalRequest._retry;
    const moveToLoginPage = () => {
      removeAccessToken();
      removeRefreshToken();
      logOutUser();
      document.location.replace(LOGIN);
    };
    const getTokenWithDelay = ms =>
      new Promise(resolve =>
        setTimeout(async () => {
          const newToken = await getAccessToken();
          resolve(newToken);
        }, ms),
      );

    if (isPendingToken) {
      return getTokenWithDelay(1000).then(token => {
        originalRequest.headers.Authorization = token;
        return axios(originalRequest);
      });
    }

    if (!isPendingToken && isExpiredToken) {
      isPendingToken = true;

      return (
        getRefreshTokenApi({ token: getRefreshToken() })
          // label: Checking errors
          .then(({ status, data }) => {
            if (status !== 200) {
              if (status === 401) moveToLoginPage();
              throw new Error();
            }

            isPendingToken = false;

            return data;
          })

          // label: Saving tokens
          .then(data => {
            saveDataToLocalStorage(data);

            return data.access_token;
          })

          // label: Updating request
          .then(accessToken => {
            originalRequest.headers.Authorization = accessToken;
            originalRequest._retry = true; // eslint-disable-line no-underscore-dangle

            return originalRequest;
          })

          // label: Resending request
          .then(request => {
            return axios(request);
          })

          // label: Error redirecting
          .catch(e => {
            const { modals } = store.getState();
            const isModalShowing = !!modals.type;

            if (!isModalShowing || (e.response && e.response.status === 401)) {
              moveToLoginPage();
            }
          })
      );
    }

    return Promise.reject(error);
  },
);

export default instance;
