import {
  SUCCESS_FETCH_SITES_LIST,
  SUCCESS_FETCH_NEWS_CATEGORIES_LIST,
  SUCCESS_FETCH_SITES_LIST_WITH_INFORMERS,
  SUCCESS_FETCH_LANGUAGES_LIST,
} from '../types/dataLists';

const initialState = {
  sitesList: [],
  sitesListWithInformers: [],
  newsCategoriesList: [],
};

export default function dataLists(state = initialState, action = {}) {
  switch (action.type) {
    case SUCCESS_FETCH_NEWS_CATEGORIES_LIST:
      return {
        ...state,
        newsCategoriesList: action.payload.data,
      };

    case SUCCESS_FETCH_SITES_LIST:
      return {
        ...state,
        sitesList: action.payload.data,
      };

    case SUCCESS_FETCH_LANGUAGES_LIST:
      return {
        ...state,
        languagesList: action.payload.data,
      };

    case SUCCESS_FETCH_SITES_LIST_WITH_INFORMERS:
      return {
        ...state,
        sitesListWithInformers: action.payload.data,
      };

    default:
      return state;
  }
}
