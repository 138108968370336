import { statusesStyles, allStatuses } from '../../../../constants/statuses';

export default {
  approve: {
    index: 0,
    icon: 'check',
    color: 'green',
    type: 'success',
  },
  activate: {
    index: 0,
    type: 'success',
    ...statusesStyles[allStatuses.STATUS_ACTIVE],
    icon: 'check',
    confirm: {},
  },
  restartCheck: {
    index: 0,
    icon: 'reload',
  },
  notification: {
    index: 0,
    icon: 'notification',
    color: 'blue',
  },
  decline: {
    index: 10,
    icon: 'close',
    color: 'red',
    type: 'error',
  },
  deactivate: {
    index: 10,
    type: 'error',
    ...statusesStyles[allStatuses.STATUS_NOT_ACTIVE],
    icon: 'close',
    confirm: {},
  },
  page: {
    index: 20,
    icon: 'line-chart',
    color: 'blue',
  },
  informersList: {
    index: 30,
    icon: 'unordered-list',
  },
  results: {
    index: 40,
    icon: 'file-done',
  },
  link: {
    index: 40,
    icon: 'link',
  },
  settings: {
    index: 50,
    icon: 'setting',
    color: 'yellow',
  },
  code: {
    index: 60,
    icon: 'code',
  },
  siteIntegration: {
    index: 61,
    icon: 'file-done',
    color: 'green',
  },
  edit: {
    index: 70,
    icon: 'setting',
    color: 'yellow',
  },
  editInformer: {
    index: 71,
    icon: 'setting',
    color: 'yellow',
  },
  editSite: {
    index: 72,
    icon: 'setting',
    color: 'yellow',
  },
  delete: {
    index: 100,
    icon: 'delete',
    color: 'red',
    confirm: {
      okButtonProps: {
        type: 'error',
      },
    },
  },
};
