import styled from 'styled-components';

export const ImageViewWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const ImageViewImgWrapper = styled.div`
  width: 40px;
  height: 40px;
  overflow: hidden;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  margin: 5px 5px 0 0;
  //box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;

  &:hover {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.25);
      transition: background 0.25s;
    }
  }

  img {
    width: auto;
    height: 200%;
    margin: -25% -10%;
  }
`;

// export const ImageViewImg = styled.img``;
