import { Icon } from 'antd';
import React from 'react';
import { string, func, exact } from 'prop-types';
import { withTranslation } from 'react-i18next';

// Utils
import { iStatusesList } from '../../../prop-types';
import {
  ModerationBadge,
  ModerationTabTitle,
  ModerationTabPane,
} from '../styles';

const ModerationTab = ({ statusesList, status, style, isMarked, t }) => (
  <ModerationTabPane style={{ paddingRight: '25px' }}>
    <Icon
      type={style.icon}
      theme={style.theme}
      style={{ color: style.color }}
    />
    <ModerationBadge
      isMarked={isMarked}
      count={statusesList[status]}
      offset={[18, 7]}
    >
      <ModerationTabTitle>{t(`status.${status}`)}</ModerationTabTitle>
    </ModerationBadge>
  </ModerationTabPane>
);

ModerationTab.propTypes = {
  statusesList: iStatusesList,
  status: string,
  t: func,
  style: exact({
    color: string,
    icon: string,
    theme: string,
  }),
};

export default withTranslation()(ModerationTab);
