import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import ActionButtonsItem from '../../../components/Table/components/Actions/components/ActionButtonsItem';
import { statusesAbTests } from '../../../constants/statuses';
import actionsConfig from '../../../components/Table/components/Actions/actionsConfig';

const TestsActionButton = props => {
  const {
    propsByType: { isWithConfirm, actionType, ...restPropsByType },
    rowData,
  } = props;
  const { t } = useTranslation();

  return (
    <ActionButtonsItem
      row={rowData}
      tooltip={`table.rowActions.${actionType}`}
      {...actionsConfig[actionType]}
      {...(isWithConfirm && {
        confirm: {
          title: t('confirm.areYouSure'),
          okType: 'warning',
        },
      })}
      {...restPropsByType}
    />
  );
};

export const TestsActions = ({ status, onActivate, onDeactivate, onEdit }) => {
  const propsByType = React.useMemo(
    () => ({
      activate: {
        actionType: 'activate',
        action: onActivate,
        disabled:
          status === statusesAbTests.STATUS_FINISHED ||
          status === statusesAbTests.STATUS_ACTIVE,
        isWithConfirm: true,
      },
      deactivate: {
        actionType: 'deactivate',
        action: onDeactivate,
        disabled: status !== statusesAbTests.STATUS_ACTIVE,
        isWithConfirm: true,
      },
      settings: {
        actionType: 'settings',
        action: onEdit,
        tooltip:
          status === statusesAbTests.STATUS_ACTIVE
            ? 'message.testStopToProceed'
            : 'table.rowActions.settings',
      },
    }),
    [status, onActivate, onDeactivate, onEdit],
  );

  return (
    <Button.Group style={{ whiteSpace: 'nowrap' }}>
      <TestsActionButton propsByType={propsByType.activate} />
      <TestsActionButton propsByType={propsByType.deactivate} />
      <TestsActionButton propsByType={propsByType.settings} />
    </Button.Group>
  );
};
