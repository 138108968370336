import React from 'react';

const Footer = props => (
  <>
    <div className="footer">
      {/* <div className="footer__copy"></div> */}
      {/* <div className="footer__info"></div> */}
    </div>
  </>
);

export default Footer;
