import { Row, Col, Statistic, Icon, Card } from 'antd';
import React from 'react';
import Button from '../../../components/Button';

export const PromotionStats = ({
  isMobile,
  customArticlesListSource,
  openModalHandler,
}) => {
  return (
    <Card
      className="default"
      style={{
        textAlign: 'center',
        marginLeft: 40,
        ...(isMobile && { marginTop: 10, marginLeft: 0 }),
      }}
    >
      <b className="ant-statistic-title">Всего размещено статей:</b>
      <Row>
        {Object.entries(customArticlesListSource).map(([key, list]) => (
          <Col span={12} key={key}>
            <Statistic
              groupSeparator=""
              title={key === 'informer' ? 'На информере' : 'На ротаторе'}
              prefix={<Icon type="file-text" />}
              value={list ? list.length : 0}
            />
            {list && list.length ? (
              <Button
                onClick={() => openModalHandler(key)}
                style={{ marginTop: 16 }}
                type="default"
              >
                Просмотреть
              </Button>
            ) : null}
          </Col>
        ))}
      </Row>
    </Card>
  );
};
