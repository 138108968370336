import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
// import logger from 'redux-logger';
import { routerMiddleware } from 'connected-react-router';

import notificationMiddleware from './middlewares/notifications';
import authorizationMiddleware from './middlewares/authorization';

import reducer from './reducers';
import history from './history';

const initialState = {};

// const developmentMiddlewares = [logger];
const developmentMiddlewares = [];

const routeMiddlewareWithHistory = routerMiddleware(history);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ name: 'MemberAdmin' })
  : compose;

const store = createStore(
  reducer,
  initialState,
  composeEnhancers(
    applyMiddleware(
      thunk,
      notificationMiddleware,
      authorizationMiddleware,
      routeMiddlewareWithHistory,
      ...(process.env.NODE_ENV === 'production' ? [] : developmentMiddlewares),
    ),
  ),
);

export { store as default };
