import { basicAction, repeatBasicActionUntilSuccess } from '../helpers/actions';
import * as T from '../types';
import * as api from '../../api';
import { showErrorSavingMessage } from '../../components/Notifications/helper';
import { ERROR_NOT_RESPONDED } from '../../constants/messages';

export const fetchAllSitesTableAction = params => dispatch => {
  dispatch({
    type: T.START_FETCH_SITES,
  });

  return api
    .getSitesListApi(params)
    .then(payload =>
      dispatch({
        type: T.SUCCESS_FETCH_SITES,
        payload: payload.data,
      }),
    )
    .catch(error =>
      dispatch({
        type: T.FAILURE_FETCH_SITES,
        payload: error,
      }),
    );
};

export const fetchTable = params => dispatch => {
  dispatch({
    type: T.START_FETCH_SITES,
  });

  return api
    .getSitesInfo(params)
    .then(payload => {
      dispatch({
        type: T.SUCCESS_FETCH_SITES,
        payload: {
          ...payload.data,
          ...params,
        },
      });

      dispatch({
        type: T.SUCCESS_CHOICE_PUBLISHER,
        payload: payload.data,
      });
    })
    .catch(error => {
      dispatch({
        type: T.FAILURE_FETCH_SITES,
        payload: error,
        notification: {
          type: 'error',
          text: `Fetch table of sites has failure. Status ${error.status}`,
        },
      });
    });
};

export const addSite = params => dispatch => {
  dispatch({
    type: T.START_ADD_SITE,
  });

  return api
    .addNewSite(params)
    .then(payload =>
      dispatch({
        type: T.SUCCESS_ADD_SITE,
        payload: payload.data,
        notification: {
          type: 'success',
          text: 'Сайт сохранен',
        },
      }),
    )
    .catch(error =>
      dispatch({
        type: T.FAILURE_ADD_SITE,
        payload: error,
        notification: {
          type: 'error',
          text: showErrorSavingMessage(error),
        },
      }),
    );
};

export const editSite = data => (dispatch, getState) => {
  dispatch({
    type: T.START_EDIT_SITE,
  });

  return api
    .editSiteById(data)
    .then(() => {
      const table = getState().sites.table.data;

      table[table.findIndex(item => data.id === item.id)] = {
        ...table[table.findIndex(item => data.id === item.id)],
        ...data,
      };

      return dispatch({
        type: T.SUCCESS_EDIT_SITE,
        payload: table,
      });
    })
    .catch(error =>
      dispatch({
        type: T.FAILURE_EDIT_SITE,
        payload: error,
        notification: {
          type: 'error',
          text: showErrorSavingMessage(error),
        },
      }),
    );
};

export const deleteSite = id => (dispatch, getState) => {
  dispatch({
    type: T.START_DELETE_SITE,
  });

  return api
    .removeSiteById(id)
    .then(() => {
      const table = getState().sites.table.data;

      table.splice(
        table.findIndex(item => item.id === id),
        1,
      );

      dispatch({
        type: T.SUCCESS_DELETE_SITE,
        payload: table,
        notification: {
          type: 'success',
          text: 'Сайт удален',
        },
      });
    })
    .catch(error => {
      dispatch({
        type: T.FAILURE_DELETE_SITE,
        payload: error,
        notification: {
          type: 'error',
          text: 'Не удалось удалить сайт',
        },
      });
    });
};

export const fetchChart = params => dispatch => {
  dispatch({
    type: T.START_FETCH_CHART,
  });

  return api
    .getSitesStatistics(params)
    .then(payload => {
      dispatch({
        type: T.SUCCESS_FETCH_CHART,
        payload: payload.data,
      });
    })
    .catch(error => {
      dispatch({
        type: T.FAILURE_FETCH_CHART,
        payload: error,
        notification: {
          type: 'error',
          text: `Fetch chart of sites has failure. Status ${error.status}`,
        },
      });
    });
};

export const analyticsCheckAction = params => dispatch => {
  dispatch({
    type: T.START_SITE_VERIFICATION,
  });

  return api
    .analyticsCheckApi(params)
    .then(payload => {
      const { status, message = '' } = payload.data;

      return dispatch({
        type: T.SUCCESS_SITE_VERIFICATION,
        notification: {
          type: status ? 'success' : 'error',
          text: message,
        },
        payload,
      });
    })
    .catch(error => {
      let message = ERROR_NOT_RESPONDED;

      try {
        // eslint-disable-next-line prefer-destructuring
        message = error.response.data.message;
        // eslint-disable-next-line no-empty
      } catch (e) {}

      return dispatch({
        type: T.FAILURE_SITE_VERIFICATION,
        payload: error,
        notification: {
          type: 'error',
          text: message,
        },
      });
    });
};

export const getModerationSpeedupInfo = params => dispatch => {
  dispatch({
    type: T.START_FETCH_SPEEDUP_INFO,
  });

  return api
    .getModerationSpeedupInfoApi(params)
    .then(payload =>
      dispatch({
        type: T.SUCCESS_FETCH_SPEEDUP_INFO,
        payload,
      }),
    )
    .catch(error =>
      dispatch({
        type: T.FAILURE_FETCH_SPEEDUP_INFO,
        payload: error,
      }),
    );
};

export const getSiteScriptVersions = (params = {}) => dispatch =>
  basicAction({
    apiCall: () => api.getSiteScriptVersions(params),
    type: T.FETCH_SITE_SCRIPT_VERSIONS,
  })(dispatch);

export const checkSiteScriptVersion = (params = {}) => dispatch =>
  repeatBasicActionUntilSuccess(
    {
      apiCall: () => api.restartScriptCheck(params),
      type: T.CHECK_SITE_SCRIPT_VERSION,
    },
    10000,
    response => response?.payload?.data?.isChecking === false,
  )(dispatch);
