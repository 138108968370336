import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

// Redux
import { getStartStatus } from '../../../redux/helpers/statuses';
import { getAbTestsLogic } from '../../../redux/actions/abTests';
import * as T from '../../../redux/types';

// Components
import { AbTestForm } from '../../Form/forms/AddAbTestForm';

export const AddAbTestModal = ({ fields, onClose, actions, success }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const logicList = useSelector(({ abTests }) => abTests.logicList);
  const net = useSelector(({ network }) => network);
  const isLoading =
    net[getStartStatus(T.EDIT_AB_TEST)] ||
    net[getStartStatus(T.ADD_AB_TEST)] ||
    net[getStartStatus(T.FETCH_AB_TESTS_LOGIC)];

  useEffect(() => {
    if (logicList.length === 0) {
      dispatch(getAbTestsLogic());
    }
  }, [dispatch, logicList]);

  const handleSubmit = form =>
    actions
      .onSubmit(form)
      .then(response => response?.payload?.status && onClose())
      .then(success.onSubmit);

  return (
    <>
      <h3>
        {t(
          `form.title.${
            Object.keys(fields).length ? 'editAbTest' : 'addAbTest'
          }`,
        )}
      </h3>
      <AbTestForm
        formData={fields}
        onSubmit={handleSubmit}
        onCancel={onClose}
        isLoading={isLoading}
        logicList={logicList}
      />
    </>
  );
};

AddAbTestModal.propTypes = {
  onClose: PropTypes.func,
  success: PropTypes.shape({
    onSubmit: PropTypes.func,
  }),
};

AddAbTestModal.defaultProps = {
  onClose: () => {},
  success: {
    onSubmit: () => {},
  },
};
