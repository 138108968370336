import React, { Fragment } from 'react';
import { func, string } from 'prop-types';
import { withTranslation } from 'react-i18next';

// Utils
import Verification from '../../ScriptsInfo/Integration';

// Components

const SiteIntegrationModal = props => {
  const { domain, name, t } = props;

  return (
    <Fragment>
      <h3>
        <b>{t('form.title.siteIntegration')}</b>
        {': '}
        <a href={domain} target="_blank" rel="noopener noreferrer">
          {name}
        </a>
      </h3>
      <Verification {...props} />
    </Fragment>
  );
};

SiteIntegrationModal.propTypes = {
  domain: string,
  t: func,
};

export default withTranslation()(SiteIntegrationModal);
