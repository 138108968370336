import { statusesAbTests } from '../../constants/statuses';
import { replaceItem, hideItem } from '../helpers/reducers';
import { getSuccessStatus } from '../helpers/statuses';
import * as T from '../types';

const initialState = {
  logicList: [],
  testsList: [],
  totalWeight: 0,
};

export default function abTests(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    // Label: LOGIC

    case getSuccessStatus(T.FETCH_AB_TESTS_LOGIC):
      return {
        ...state,
        logicList: payload.data,
      };

    case getSuccessStatus(T.EDIT_AB_TEST_LOGIC):
      return {
        ...state,
        logicList:
          action.payload.status === statusesAbTests.STATUS_DELETED
            ? hideItem(state.logicList, action.payload, 'id')
            : replaceItem(state.logicList, action.payload, 'id'),
      };

    case getSuccessStatus(T.ADD_AB_TEST_LOGIC):
      return {
        ...state,
        logicList: [action.payload, ...state.logicList],
      };

    // Label: TESTS

    case getSuccessStatus(T.FETCH_AB_TESTS):
      return {
        ...state,
        testsList: payload.data,
        totalWeight: payload.total_weight,
      };

    case getSuccessStatus(T.EDIT_AB_TEST):
      return {
        ...state,
        testsList: replaceItem(state.testsList, payload?.data?.a_b_test, 'id'), // eslint-disable-line
        totalWeight: payload.data.total_weight,
      };

    case getSuccessStatus(T.ADD_AB_TEST):
      return {
        ...state,
        testsList: [payload?.data?.a_b_test, ...state.testsList], // eslint-disable-line
        totalWeight: payload.data.total_weight,
      };

    default:
      return state;
  }
}
