import { Tabs } from 'antd';
import React, { Fragment } from 'react';
import { func, number } from 'prop-types';

// Utils
import { withTranslation } from 'react-i18next';
import CodeBlock from '../../CodeBlock';

const CodeBlockModal = props => {
  const { informerId, siteId, t } = props;

  return (
    <Fragment>
      <h3>{t('blocks.scriptAdd.placingScript')}</h3>
      <Tabs type="card" defaultActiveKey="code">
        <Tabs.TabPane tab={t('basic.code')} key="code">
          <p>{t('blocks.scriptAdd.description')}</p>
          <CodeBlock informerId={informerId} siteId={siteId} />
        </Tabs.TabPane>
        {/* <Tabs.TabPane tab={t('basic.history')} key="history">
          <ScriptsVersionsTable siteId={siteId} />
        </Tabs.TabPane> */}
      </Tabs>
    </Fragment>
  );
};

CodeBlockModal.propTypes = {
  informerId: number,
  t: func,
};

CodeBlockModal.defaultProps = {};

export default withTranslation()(CodeBlockModal);
