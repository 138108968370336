import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { colorBlue, colorGreen } from '../../../styles/js/variables/colors';
import { cutStringWithDots } from '../../../utils/string';

const ButtonWrapper = styled.a`
  display: block;
  margin: 0.25em 0;
  color: ${({ isFrontend }) => (isFrontend ? colorBlue : colorGreen)};
`;

export const LogicButton = ({ logicId, onClickAction }) => {
  const logicList = useSelector(({ abTests }) => abTests.logicList);
  const logicItem = logicList.find(logic => logic.id === Number(logicId));

  const handleClick = () => onClickAction(logicItem);

  return (
    <ButtonWrapper
      type="link"
      onClick={handleClick}
      isFrontend={logicItem?.implemented_on === 'front'}
      style={{
        display: 'block',
        margin: '0.25em 0',
      }}
    >
      <b>{cutStringWithDots(logicItem?.title, 20)}</b>
    </ButtonWrapper>
  );
};
