/* eslint-disable indent */
import {
  SUCCESS_GET_SIDEBAR_VISIBILITY,
  SET_CURRENT_PAGE,
  SET_IS_MOBILE,
} from '../types';

const initialState = {
  isHiddenSidebar: false,
  currentPage: '',
  isMobile: null,
};

export default function userSettings(state = initialState, action = {}) {
  switch (action.type) {
    case SUCCESS_GET_SIDEBAR_VISIBILITY:
      return { ...state, isHiddenSidebar: action.payload };

    case SET_CURRENT_PAGE:
      return { ...state, currentPage: action.payload };

    case SET_IS_MOBILE:
      return {
        ...state,
        isMobile: action.payload.data,
      };

    default:
      return state;
  }
}
