import { Popconfirm } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaCheck } from 'react-icons/all';
import styled from 'styled-components';

import TooltipConditional from '../../../components/Tooltip/TooltipConditional';

// Utils
import { colorGreen } from '../../../styles/js/variables/colors';

// Components
import Button from '../../../components/Button';
import { ConditionalWrapper } from '../../../components/ConditionalWrapper';

const ButtonStyled = styled(Button)`
  margin-left: -0.25em;
  z-index: 10;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
`;

export const SearchArticlesButton = ({
  article,
  selectedArticle,
  submitHandler,
}) => {
  const { t } = useTranslation();
  const isNewArticle =
    article && selectedArticle && article.id !== selectedArticle?.id;
  const isFreshButton = !(article && selectedArticle && !isNewArticle);

  return (
    <ConditionalWrapper
      condition={!article}
      wrapper={children => (
        <TooltipConditional
          withTooltip={!article}
          isHiddenByDefault={false}
          placement="topRight"
          title={t('tooltip.onlyForSelectedArticle')}
        >
          {children}
        </TooltipConditional>
      )}
    >
      <ConditionalWrapper
        condition={isNewArticle}
        wrapper={children => (
          <Popconfirm
            placement="topLeft"
            title={t('confirm.selectAnother')}
            onConfirm={submitHandler}
            okText={t('form.buttonProceed')}
            cancelText={t('form.buttonCancel')}
          >
            {children}
          </Popconfirm>
        )}
      >
        <ButtonStyled
          type={isFreshButton ? 'success' : 'default'}
          disabled={!article}
          {...(!isNewArticle && { onClick: submitHandler })}
        >
          {isFreshButton ? (
            t('basic.select')
          ) : (
            <>
              <FaCheck color={colorGreen} /> {t('basic.selected')}
            </>
          )}
        </ButtonStyled>
      </ConditionalWrapper>
    </ConditionalWrapper>
  );
};
