import React, { Component } from 'react';
import { connect } from 'react-redux';
import { func, bool, shape } from 'prop-types';
import { withTranslation } from 'react-i18next';

// Redux
import { fetchGlobalStatistics } from 'redux/actions/statistics';

// Components
import PageTitle from '../../components/PageTitle';
import StatisticsData from './components/StatisticsData';

class GlobalStatistics extends Component {
  componentDidMount() {
    this.props.fetchGlobalStatistics();
  }

  render() {
    const { statistics, isLoadingStats, t } = this.props;
    const { global } = statistics;

    return (
      <div className="page-body page-main-settings">
        <PageTitle
          centered
          title={t('content.title.globalStatistics')}
          hint={t('content.titleHint.globalStatistics')}
        />

        <StatisticsData isLoading={isLoadingStats} {...global} />
      </div>
    );
  }
}

GlobalStatistics.defaultProps = {
  statistics: {},
  isLoadingStats: false,
};

GlobalStatistics.propTypes = {
  statistics: shape({}),
  isLoadingStats: bool,
  t: func,
  fetchGlobalStatistics: func,
};

const mapStateToProps = ({ statistics, network }) => ({
  statistics,
  isLoadingStats: network.START_FETCH_GLOBAL_STATISTICS,
});

const mapDispatchToProps = {
  fetchGlobalStatistics,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('')(GlobalStatistics));
