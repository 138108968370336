import React from 'react';
import { Series, YAxis } from 'react-jsx-highcharts';
import { arrayOf, bool, number, string, shape } from 'prop-types';

const YAxises = ({ data, isLoading, rightTitle }) => {
  const leftCharts = [];
  const rightCharts = [];

  data.forEach(({ isPositionRight, ...element }) => {
    const block = isPositionRight ? rightCharts : leftCharts;
    block.push(element);
  });

  return !isLoading ? (
    <>
      {leftCharts && (
        <YAxis id="yaxis-left">
          {leftCharts.map((dataChunk, i) => (
            <Series
              key={i}
              type="spline"
              id={dataChunk.name}
              yAxis="yaxis-left"
              zIndex={1}
              {...dataChunk}
            />
          ))}
        </YAxis>
      )}
      {rightCharts && (
        <YAxis id="yaxis-right" opposite={!!leftCharts.length}>
          {rightCharts.map((dataChunk, i) => (
            <Series
              key={i}
              type="spline"
              id={dataChunk.name}
              yAxis="yaxis-right"
              zIndex={1}
              {...dataChunk}
            />
          ))}
          <YAxis.Title>
            {rightCharts.length === 1 ? rightCharts[0].name : rightTitle}
          </YAxis.Title>
        </YAxis>
      )}
    </>
  ) : null;
};

YAxises.defaultProps = {
  data: [],
  isLoading: false,
  rightTitle: '',
};

YAxises.propTypes = {
  data: arrayOf(
    shape({
      name: string,
      hint: string,
      data: arrayOf(number),
    }),
  ),
  isLoading: bool,
  rightTitle: string,
};

export default YAxises;
