import React from 'react';
import { convertNumberToFormattedString } from '../../../services/formatter';

export const formatCell = cell => {
  return Number.isNaN(+cell) ? (
    cell
  ) : (
    <div className="table-cell--number">
      {convertNumberToFormattedString(cell)}
    </div>
  );
};
