import styled from 'styled-components';
import { Switch } from 'antd';
import { colorBlue } from '../../../../styles/js/variables/colors';

export const FormSwitchWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .ant-switch-handle {
    &:before {
      border-radius: 50%;
    }
  }
`;

export const FormSwitchStyled = styled(Switch)`
  background: ${({ checked }) =>
    checked ? colorBlue : 'rgba(0, 0, 0, 0.125)'};
  margin-right: 10px !important;
  ${({ checkedChildren }) => checkedChildren && 'min-width: 5em;'};
`;
