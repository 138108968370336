import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Icon, Tag } from 'antd';

import { colorBlue } from '../../styles/js/variables/colors';
import CreateHead from '../Head';

const PageTitle = ({
  title,
  titleTag: TitleTag = 'h1',
  subtitle,
  tag,
  button,
  hint,
  bordered,
  centered,
  className,
}) => {
  const textAlign = centered ? 'center' : 'inherit';
  const description = typeof hint === 'string' ? hint : '';
  const hintBlock = (
    <div className="content-title__hint">
      <Icon
        type="info-circle"
        theme="twoTone"
        twoToneColor={colorBlue}
        style={{
          color: colorBlue,
          marginRight: '0.5em',
        }}
      />
      {hint}
    </div>
  );

  const classNamesString = useMemo(
    () =>
      classNames({
        'content-title': true,
        'content-title--bordered': bordered,
        'content-title--centered': centered,
        'content-title--buttoned': button,
      }),
    [bordered, centered, button],
  );

  return (
    <>
      <CreateHead title={title} description={description} />
      <div
        className={`content-title__wrapper ${className}`}
        style={{ textAlign }}
      >
        <div className={classNamesString}>
          <div className="content-title__block">
            {tag && <Tag className="content-title__tag">{tag}</Tag>}
            <TitleTag>
              {subtitle && <span>{subtitle}</span>}
              {title}
            </TitleTag>
            {button && hint && hintBlock}
          </div>
          {button}
        </div>
        {hint && !button && hintBlock}
      </div>
    </>
  );
};

export default PageTitle;
