import React from 'react';
import { connect } from 'react-redux';
import uid from 'uid';
import { Dropdown } from 'semantic-ui-react';
import { Checkbox, Input, Tabs, Row, Col } from 'antd';

// Components
import TabsWithQuery from '../../../components/TabsWithQuery';

const typeToTag = {
  select: Dropdown,
  text: Input,
  number: Input,
  integer: Input,
  checkbox: Checkbox,
  default: Input,
};

const onChangeInput = (valueKey, props) => ({ target }) =>
  props.onChange({
    ...props,
    element: {
      ...props.element,
      value: target[valueKey].toString(),
    },
  });

const mappingKeys = props => {
  const { element } = props;
  const object = { ...element };

  Object.entries(element).map(([key, entry]) => {
    switch (key) {
      case 'type':
        if (entry === 'integer') {
          object.type = 'number';
          object.onChange = onChangeInput('value', props);
          object.style = { width: '100%' };
        } else if (entry === 'checkbox') {
          object.checked = element.value === 'true';
          object.onChange = onChangeInput('checked', props);
        } else {
          // Default input type from typeToTag
          object.type = 'text';
          object.onChange = onChangeInput('value', props);
        }
        return object;

      case 'value':
        object.defaultValue = entry;
        delete object.value;
        return object;

      case 'id':
        object.name = entry;
        return object;

      case 'label':
        delete object[key];
        return object;

      default:
        return object;
    }
  });

  return object;
};

const ParseElement = props => {
  const elementProps = mappingKeys(props);
  let CustomElement = typeToTag.default;
  try {
    CustomElement = typeToTag[elementProps.type];
  } catch (e) {}

  return <CustomElement {...elementProps} />;
};

const ParseElements = props => {
  const { elements, isMobile, ...otherProps } = props;

  return elements.map((element, index) => (
    <Row
      key={index}
      className="tabs__block"
      gutter={[16, isMobile ? 32 : 16]}
      type="flex"
      align="middle"
      justify="center"
      style={{
        marginTop: isMobile ? '18px' : '8px',
      }}
    >
      {element.label && (
        <Col
          className="tabs__element-label"
          xs={24}
          md={12}
          lg={16}
          style={
            isMobile && {
              paddingBottom: '0',
            }
          }
        >
          <label>{element.label}</label>
        </Col>
      )}
      <Col key={uid()} className="tabs__element" xs={24} md={12} lg={8}>
        <ParseElement {...otherProps} element={element} elementIndex={index} />
      </Col>
    </Row>
  ));
};

const ParseBlocks = ({ tab: blocks = [], isLoading, ...otherProps }) =>
  blocks.map((elements, index) => (
    <div key={index} className="nth-list">
      <ParseElements {...otherProps} elements={elements} blockIndex={index} />
    </div>
  ));

const TabParser = props => {
  const { tabs } = props;

  return (
    <TabsWithQuery type="card">
      {tabs.map((tab, index) => (
        <Tabs.TabPane key={tab.title} tab={tab.title}>
          <ParseBlocks tab={tab.blocks} tabIndex={index} {...props} />
        </Tabs.TabPane>
      ))}
    </TabsWithQuery>
  );
};

const mapStateToProps = ({ userSettings }) => ({
  isMobile: userSettings.isMobile,
});

export default connect(mapStateToProps)(TabParser);
