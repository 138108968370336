import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Input, Row } from 'antd';
import { withTranslation } from 'react-i18next';

// Utils
import { mustBeFilled } from '../../../config/validationRules';
import { statusesSites } from '../../../constants/statuses';
import useValidation from '../utils/useValidation';

// Components
import FormActions from '../components/FormActions';

const SiteModerationForm = ({
  nextStatus,
  formData,
  onSubmit,
  onCancel,
  t,
}) => {
  const isDecline = nextStatus === statusesSites.STATUS_SUSPENDED;

  const initialForm = {
    ...(isDecline && { notice: { value: '', rules: [mustBeFilled] } }),
    description: { value: '', rules: [mustBeFilled] },
  };

  const {
    errors,
    currentForm,
    bindedSubmit,
    bindedInputFunctions,
  } = useValidation(onSubmit, initialForm, formData);

  return (
    <Form>
      <Row gutter={16}>
        {isDecline && (
          <Col xs={24} sm={24} md={12}>
            <Form.Item {...errors.notice} label={t('form.noticeLabel')}>
              <Input.TextArea
                name="notice"
                placeholder={t('form.noticePlaceholder')}
                defaultValue={currentForm.notice}
                {...bindedInputFunctions}
              />
            </Form.Item>
          </Col>
        )}
        <Col xs={24} sm={24} md={isDecline ? 12 : 24}>
          <Form.Item {...errors.description} label={t('form.descriptionLabel')}>
            <Input.TextArea
              name="description"
              placeholder={t('form.descriptionPlaceholder')}
              defaultValue={currentForm.description}
              {...bindedInputFunctions}
            />
          </Form.Item>
        </Col>
      </Row>

      <br />
      <FormActions
        style={{ textAlign: 'right' }}
        titleSubmit={t('form.buttonSave')}
        onSubmit={bindedSubmit}
        onCancel={onCancel}
      />
    </Form>
  );
};

SiteModerationForm.ProptTypes = {
  onChange: PropTypes.func,
  form: PropTypes.shape({}),
};

export default withTranslation('common')(SiteModerationForm);
