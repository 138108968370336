// Sorting by field (string comparison)

export const sortByField = (a, b, field = 'title') => {
  const fieldA = a[field].toLowerCase();
  const fieldB = b[field].toLowerCase();

  if (fieldA > fieldB) {
    return 1;
  }
  if (fieldA < fieldB) {
    return -1;
  }
  return 0;
};

// Sorting by existing 'children' array and then by 'name' field

export const sortSites = (a, b) => {
  if (a.children && a.children.length && b.children && b.children.length) {
    return sortByField(a, b);
  }
  if (a.children && !a.children.length && b.children && !b.children.length) {
    return sortByField(a, b);
  }
  if (a.children && a.children.length && b.children && !b.children.length) {
    return -1;
  }

  if (a.children && !a.children.length && b.children && b.children.length) {
    return 1;
  }
  return 0;
};
