import { Icon } from 'antd';
import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import LinkWithTag from '../../../components/LinkWithTag';
import PageTitle from '../../../components/PageTitle';
import routesPath, { getFilledRoutePath } from '../../../config/routePaths';
import { getSites } from '../../../redux/actions/site';
import { colorBlue } from '../../../styles/js/variables/colors';
import { msFromStartTillNow } from '../../../utils/date';
import { SiteStatsInfo } from './SiteStatisticsInfo';

export const SiteStatistics = () => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { isMobile } = useSelector(s => s.userSettings);
  const linkToSiteStats = getFilledRoutePath({
    path: routesPath.SITE_STATISTICS,
    params: match.params,
  });
  const { publisherId } = match.params;

  const getSitesList = useCallback(() => {
    getSites({
      publisherIds: publisherId,
      ...msFromStartTillNow,
    })(dispatch);
  }, [dispatch, publisherId]);

  useEffect(() => {
    if (publisherId) getSitesList();
  }, [getSitesList, publisherId]);

  return (
    <>
      <PageTitle
        title={t('basic.siteStatsAllTime')}
        titleTag="h4"
        button={
          <LinkWithTag
            isButton
            href={linkToSiteStats}
            linkProps={{
              type: 'default',
            }}
          >
            <Icon type="line-chart" style={{ color: colorBlue }} />
            {!isMobile && t('content.title.siteStatistics')}
          </LinkWithTag>
        }
      />
      <SiteStatsInfo />
    </>
  );
};
