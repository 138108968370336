import {
  START_FETCH_GLOBAL_SETTINGS,
  SUCCESS_FETCH_GLOBAL_SETTINGS,
  FAILURE_FETCH_GLOBAL_SETTINGS,
  SUCCESS_EDIT_GLOBAL_SETTINGS,
} from 'redux/types';

import { getGlobalSettingsApi, setGlobalSettingApi } from 'api';
import { ERROR_NOT_RESPONDED } from '../../constants/messages';
import {
  FAILURE_SAVE_GLOBAL_SETTINGS,
  SET_INIT_GLOBAL_SETTINGS,
  START_SAVE_GLOBAL_SETTINGS,
  SUCCESS_SAVE_GLOBAL_SETTINGS,
} from '../types';

export const fetchGlobalSettings = () => dispatch => {
  dispatch({
    type: START_FETCH_GLOBAL_SETTINGS,
  });

  return getGlobalSettingsApi()
    .then(payload => {
      dispatch({
        type: SUCCESS_FETCH_GLOBAL_SETTINGS,
        payload: payload.data,
      });
    })
    .catch(error => {
      dispatch({
        type: FAILURE_FETCH_GLOBAL_SETTINGS,
        payload: error,
        notification: {
          type: 'error',
          text: ERROR_NOT_RESPONDED,
        },
      });
    });
};

export const editGlobalSetting = params => dispatch => {
  dispatch({
    type: SUCCESS_EDIT_GLOBAL_SETTINGS,
    payload: params,
  });
};

export const saveGlobalSetting = params => dispatch => {
  dispatch({
    type: START_SAVE_GLOBAL_SETTINGS,
  });

  return setGlobalSettingApi(params)
    .then(() => {
      dispatch({
        type: SUCCESS_SAVE_GLOBAL_SETTINGS,
        payload: params,
      });
    })
    .catch(fail => {
      dispatch({
        status: 401,
        type: FAILURE_SAVE_GLOBAL_SETTINGS,
        fail,
        notification: {
          type: 'error',
          text: ERROR_NOT_RESPONDED,
        },
      });
    });
};

export const setInitGlobalSettings = () => dispatch => {
  dispatch({
    type: SET_INIT_GLOBAL_SETTINGS,
  });
};
