import React from 'react';

const Label = ({ labelText, asterisk, mark }) => (
  <div className="input-label-wrapper">
    {asterisk && <span className="input-asterisk">*</span>}
    <span>{labelText}</span>
    {mark && <span className="input-mark">{mark}</span>}
  </div>
);

export default Label;
