import {
  START_FETCH_INFORMER,
  SUCCESS_FETCH_INFORMER,
  FAILURE_FETCH_INFORMER,
  START_FETCH_CHART_INFORMER,
  SUCCESS_FETCH_CHART_INFORMER,
  FAILURE_FETCH_CHART_INFORMER,
  START_FETCH_TABLE_INFORMER,
  SUCCESS_FETCH_TABLE_INFORMER,
  FAILURE_FETCH_TABLE_INFORMER,
  START_DELETE_INFORMER,
  SUCCESS_DELETE_INFORMER,
  FAILURE_DELETE_INFORMER,
} from 'redux/types';

import {
  getInformerById,
  removeInformerById,
  getInformerInfo,
  getInformerStatistics,
} from 'api';

export const fetchInformer = id => dispatch => {
  dispatch({
    type: START_FETCH_INFORMER,
  });

  return getInformerById(id)
    .then(payload => {
      dispatch({
        type: SUCCESS_FETCH_INFORMER,
        payload: payload.data,
      });
    })
    .catch(error => {
      dispatch({
        type: FAILURE_FETCH_INFORMER,
        payload: error,
      });
    });
};

export const fetchTable = params => dispatch => {
  dispatch({
    type: START_FETCH_TABLE_INFORMER,
  });

  const { id, ...tableSettings } = params;

  return getInformerInfo(params)
    .then(payload => {
      dispatch({
        type: SUCCESS_FETCH_TABLE_INFORMER,
        payload: {
          ...payload.data,
          ...tableSettings,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: FAILURE_FETCH_TABLE_INFORMER,
        payload: error,
        notification: {
          type: 'error',
          text: `Fetch table of informer has failure. Status ${error.status}`,
        },
      });
    });
};

export const fetchChart = params => dispatch => {
  dispatch({
    type: START_FETCH_CHART_INFORMER,
  });

  return getInformerStatistics(params)
    .then(payload => {
      dispatch({
        type: SUCCESS_FETCH_CHART_INFORMER,
        payload: payload.data,
      });
    })
    .catch(error => {
      dispatch({
        type: FAILURE_FETCH_CHART_INFORMER,
        payload: error,
        notification: {
          type: 'error',
          text: `Fetch chart of informer has failure. Status ${error.status}`,
        },
      });
    });
};

export const deleteInformer = id => dispatch => {
  dispatch({
    type: START_DELETE_INFORMER,
  });

  return removeInformerById(id)
    .then(() => {
      dispatch({
        type: SUCCESS_DELETE_INFORMER,
      });
    })
    .catch(error => {
      dispatch({
        type: FAILURE_DELETE_INFORMER,
        payload: error,
        notification: {
          type: 'error',
          text: `Delete informer has failure. Status ${error.status}`,
        },
      });
    });
};
