import {
  getSitesWithInformers,
  getCustomArticlesByInformerId,
  setCustomArticlePosition,
  postCustomArticle,
  searchArticlesApi,
  deleteCustomArticleApi,
  getInformersDataByNewsId,
  postPromoteArticle,
  fetchCustomArticlesApi,
  fetchCustomArticlesRotatorApi,
  getInformersDataForRotatorByNewsId,
  getSitesWithInformersForRotator,
  postPromoteArticleForRotator,
} from '../../api';
import * as actionTypes from '../types';
import * as actionTypesArticles from '../types/articles';
import * as actionTypesDataLists from '../types/dataLists';

export const fetchInformerCustomArticles = params => dispatch => {
  dispatch({
    type: actionTypesArticles.START_FETCH_INFORMER_CUSTOM_ARTICLES,
  });

  return getCustomArticlesByInformerId(params)
    .then(payload => {
      dispatch({
        type: actionTypesArticles.SUCCESS_FETCH_INFORMER_CUSTOM_ARTICLES,
        payload: payload.data,
      });
    })
    .catch(error => {
      dispatch({
        type: actionTypesArticles.FAILURE_FETCH_INFORMER_CUSTOM_ARTICLES,
        payload: error,
        notification: {
          type: 'error',
          text: `Не удалось получить статьи, размещенные на информере. Статус: ${error.status}`,
        },
      });
    });
};

export const fetchSitesWithInformers = id => dispatch => {
  dispatch({
    type: actionTypesDataLists.START_FETCH_SITES_WITH_INFORMERS,
  });

  return getSitesWithInformers(id)
    .then(payload => {
      dispatch({
        type: actionTypesDataLists.SUCCESS_FETCH_SITES_WITH_INFORMERS,
        payload: payload.data,
      });
    })
    .catch(error => {
      dispatch({
        type: actionTypesDataLists.FAILURE_FETCH_SITES_WITH_INFORMERS,
        payload: error,
        notification: {
          type: 'error',
          text: `не удалось получить список сайтов и информеров. Статус ${error.status}`,
        },
      });
    });
};

export const fetchSitesWithInformersForRotator = id => dispatch => {
  dispatch({
    type: actionTypesDataLists.START_FETCH_SITES_WITH_INFORMERS_FOR_ROTATOR,
  });

  return getSitesWithInformersForRotator(id)
    .then(payload => {
      dispatch({
        type:
          actionTypesDataLists.SUCCESS_FETCH_SITES_WITH_INFORMERS_FOR_ROTATOR,
        payload: payload.data,
      });
    })
    .catch(error => {
      dispatch({
        type:
          actionTypesDataLists.FAILURE_FETCH_SITES_WITH_INFORMERS_FOR_ROTATOR,
        payload: error,
        notification: {
          type: 'error',
          text: `не удалось получить список сайтов и информеров. Статус ${error.status}`,
        },
      });
    });
};

export const changeArticlePosition = params => dispatch => {
  dispatch({
    type: actionTypesArticles.START_FETCH_ARTICLE_POSITION,
  });

  return setCustomArticlePosition(params)
    .then(() => {
      dispatch({
        type: actionTypesArticles.SUCCESS_FETCH_ARTICLE_POSITION,
        payload: params,
      });
    })
    .catch(error => {
      dispatch({
        type: actionTypesArticles.FAILURE_FETCH_ARTICLE_POSITION,
        payload: error,
        notification: {
          type: 'error',
          text: `get publisher has failure. Status ${error.status}`,
        },
      });
    });
};

export const addCustomArticle = params => dispatch => {
  dispatch({
    type: actionTypesArticles.START_FETCH_ADD_CUSTOM_ARTICLE,
  });

  return postCustomArticle(params)
    .then(payload => {
      dispatch({
        type: actionTypesArticles.SUCCESS_FETCH_ADD_CUSTOM_ARTICLE,
        payload: payload.data,
      });
    })
    .catch(error => {
      dispatch({
        type: actionTypesArticles.FAILURE_FETCH_ADD_CUSTOM_ARTICLE,
        payload: error,
        notification: {
          type: 'error',
          text: `get publisher has failure. Status ${error.status}`,
        },
      });
    });
};

export const fetchCustomArticles = () => dispatch => {
  dispatch({
    type: actionTypesArticles.START_FETCH_CUSTOM_ARTICLES,
  });

  return fetchCustomArticlesApi()
    .then(payload => {
      dispatch({
        type: actionTypesArticles.SUCCESS_FETCH_CUSTOM_ARTICLES,
        payload: payload.data,
      });
    })
    .catch(error => {
      dispatch({
        type: actionTypesArticles.FAILURE_FETCH_CUSTOM_ARTICLES,
        payload: error,
        notification: {
          type: 'error',
          text: `Не удалось получить список продвигаемых новостей. Статус ${error.status}`,
        },
      });
    });
};

export const fetchCustomArticlesRotator = () => dispatch => {
  dispatch({
    type: actionTypesArticles.START_FETCH_CUSTOM_ARTICLES_ROTATOR,
  });

  return fetchCustomArticlesRotatorApi()
    .then(payload => {
      dispatch({
        type: actionTypesArticles.SUCCESS_FETCH_CUSTOM_ARTICLES_ROTATOR,
        payload: payload.data,
      });
    })
    .catch(error => {
      dispatch({
        type: actionTypesArticles.FAILURE_FETCH_CUSTOM_ARTICLES_ROTATOR,
        payload: error,
        notification: {
          type: 'error',
          text: `Не удалось получить список продвигаемых новостей. Статус ${error.status}`,
        },
      });
    });
};

export const searchArticles = params => dispatch => {
  dispatch({
    type: actionTypesArticles.START_FETCH_SEARCH_ARTICLES,
  });

  return searchArticlesApi(params)
    .then(payload => {
      dispatch({
        type: actionTypesArticles.SUCCESS_FETCH_SEARCH_ARTICLES,
        payload: payload.data,
      });
    })
    .catch(error => {
      dispatch({
        type: actionTypesArticles.FAILURE_FETCH_SEARCH_ARTICLES,
        payload: error,
        notification: {
          type: 'error',
          text: `get publisher has failure. Status ${error.status}`,
        },
      });
    });
};

export const deleteCustomNews = params => dispatch => {
  dispatch({
    type: actionTypes.START_FETCH_DELETE_CUSTOM_NEWS,
  });

  return deleteCustomArticleApi(params)
    .then(payload => {
      dispatch({
        type: actionTypes.SUCCESS_FETCH_DELETE_CUSTOM_NEWS,
        payload: payload.data,
      });
    })
    .catch(error => {
      dispatch({
        type: actionTypes.FAILURE_FETCH_DELETE_CUSTOM_NEWS,
        payload: error,
        notification: {
          type: 'error',
          text: `Не удалось удалить новость. Статус: ${error.status}`,
        },
      });
    });
};

export const fetchInformersDataByNewsId = params => dispatch => {
  dispatch({
    type: actionTypes.START_FETCH_INFORMERS_DATA_BY_NEWS_ID,
  });

  return getInformersDataByNewsId(params)
    .then(payload => {
      dispatch({
        type: actionTypes.SUCCESS_FETCH_INFORMERS_DATA_BY_NEWS_ID,
        payload: payload.data,
      });
    })
    .catch(error => {
      dispatch({
        type: actionTypes.FAILURE_FETCH_INFORMERS_DATA_BY_NEWS_ID,
        payload: error,
        notification: {
          type: 'error',
          text: `Get informers data has failure. Status ${error.status}`,
        },
      });
    });
};

export const fetchInformersDataForRotatorByNewsId = params => dispatch => {
  dispatch({
    type: actionTypes.START_FETCH_INFORMERS_DATA_FOR_ROTATOR_BY_NEWS_ID,
  });

  return getInformersDataForRotatorByNewsId(params)
    .then(payload => {
      dispatch({
        type: actionTypes.SUCCESS_FETCH_INFORMERS_DATA_FOR_ROTATOR_BY_NEWS_ID,
        payload: payload.data,
      });
    })
    .catch(error => {
      dispatch({
        type: actionTypes.FAILURE_FETCH_INFORMERS_DATA_FOR_ROTATOR_BY_NEWS_ID,
        payload: error,
        notification: {
          type: 'error',
          text: `Get informers data has failure. Status ${error.status}`,
        },
      });
    });
};

export const promoteArticle = params => dispatch => {
  dispatch({
    type: actionTypesArticles.START_PROMOTE_ARTICLE,
  });

  return postPromoteArticle(params)
    .then(payload => {
      dispatch({
        type: actionTypesArticles.SUCCESS_PROMOTE_ARTICLE,
        payload: payload.data,
        notification: {
          type: 'success',
          text: 'Статья размещена',
        },
      });
      dispatch(fetchSitesWithInformers());
      dispatch(fetchInformersDataByNewsId(params.news_id));
      dispatch(fetchCustomArticles());
    })
    .catch(error => {
      dispatch({
        type: actionTypesArticles.FAILURE_PROMOTE_ARTICLE,
        payload: error,
        notification: {
          type: 'error',
          text: `Не удалось разместить статью. 
          Статус: ${error.status}`,
        },
      });
    });
};

export const promoteArticleForRotator = params => dispatch => {
  dispatch({
    type: actionTypesArticles.START_PROMOTE_ARTICLE_FOR_ROTATOR,
  });

  return postPromoteArticleForRotator(params)
    .then(payload => {
      dispatch({
        type: actionTypesArticles.SUCCESS_PROMOTE_ARTICLE_FOR_ROTATOR,
        payload: payload.data,
        notification: {
          type: 'success',
          text: 'Статья размещена',
        },
      });
      dispatch(fetchSitesWithInformersForRotator());
      dispatch(fetchInformersDataForRotatorByNewsId(params.news_id));
      dispatch(fetchCustomArticlesRotator());
    })
    .catch(error => {
      dispatch({
        type: actionTypesArticles.FAILURE_PROMOTE_ARTICLE_FOR_ROTATOR,
        payload: error,
        notification: {
          type: 'error',
          text: `Не удалось разместить статью. 
          Статус: ${error.status}`,
        },
      });
    });
};
