import React from 'react';
import { withTranslation } from 'react-i18next';

import { deviceIcons } from '../../../constants/devices';

const TableDevice = ({ deviceType = 'default', t }) => {
  const style = {
    display: 'inline-block',
    verticalAlign: 'middle',
  };

  const { icon: Icon, color } = deviceIcons[deviceType];

  return (
    <>
      <Icon color={color} style={style} />
      &nbsp;
      {t(`device.${deviceType}`)}
    </>
  );
};

export default withTranslation()(TableDevice);
