import React, { useEffect, useRef, useState, useMemo } from 'react';
import Highcharts from 'highcharts';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  HighchartsChart,
  Chart,
  withHighcharts,
  XAxis,
  Legend,
  Tooltip,
} from 'react-jsx-highcharts';

// Styles
import './styles.scss';
import { chartColors } from './helpers/chartColors';

// Utils
import { getChartVisibility } from '../../services/localStorage';
import { propsDataToChart } from './helpers';
import { labelRender } from './helpers/labelRender';

// Components
import Loader from '../Loader';
import Calendar from './components/Calendar';
import ChartToggler from './components/ChartToggler';
import YAxises from './components/YAxises';

const LinearChart = ({
  data,
  categoriesForX,
  isLoading,
  onChange,
  isAutoKeys,
  showKeys,
  excludeKeys,
  rightTitle,
  isHiddenSidebar,
  isChartToggle,
  isShowChart,
  t,
}) => {
  const parentRef = useRef(null);
  const [width, setWidth] = useState(0);
  const [isLoadingChart, setIsloadingChart] = useState(true);
  const categoriesWithoutYear = useMemo(
    () => categoriesForX.map(el => el.slice(0, el.length - 5)),
    [categoriesForX],
  );

  // Chart visibility
  const hiddenCharts = getChartVisibility();
  const defaultChartVisibility =
    hiddenCharts && window.location.pathname in hiddenCharts
      ? hiddenCharts[window.location.pathname]
      : true;
  const [isVisibleChart, setIsVisibleChart] = useState(defaultChartVisibility);

  useEffect(() => {
    const handleResize = () =>
      // why timeout? We need to wait for sidebar action finishes (open/close)
      setTimeout(() => {
        if (parentRef.current && parentRef.current.offsetWidth) {
          setWidth(parentRef.current.offsetWidth);
        }
      }, 500);

    if (!isLoading) {
      handleResize();
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isLoading, parentRef, isHiddenSidebar]);

  // increasing load time for hiding jerking action on width change
  useEffect(() => {
    if (!isLoading && width > 0) {
      setTimeout(() => setIsloadingChart(false), 1000);
    }
  }, [width, isLoading]);

  return (
    <div
      ref={parentRef}
      className={`highcharts-wrapper ${!isVisibleChart &&
        'highcharts-wrapper--hidden'}`}
      style={{ margin: '0 auto' }}
    >
      <Loader isLoading={isLoadingChart} />
      <div
        style={{
          opacity: Number(!isLoadingChart),
          transition: 'opacity 4s',
        }}
      >
        <div
          className="ant-calendar-picker__wrapper"
          style={{
            marginBottom: showKeys.length > 4 ? '3em' : '1em',
            opacity: Number(isShowChart),
          }}
        >
          <Calendar onChange={onChange} categoriesForX={categoriesForX} />

          {isChartToggle && (
            <ChartToggler
              isVisibleChart={isVisibleChart}
              setIsVisibleChart={setIsVisibleChart}
            />
          )}
        </div>

        {isVisibleChart && (
          <div
            className="highcharts__wrapper"
            style={{
              ...(showKeys.length > 4 && { marginTop: '-2.75em' }),
              ...(!isLoadingChart && !isShowChart ? { height: 0 } : {}),
            }}
          >
            <HighchartsChart colors={chartColors}>
              <Chart height={350} animation width={width} />
              {showKeys.length > 1 && (
                <Legend
                  labelFormatter={labelRender}
                  useHTML
                  verticalAlign="top"
                  align="right"
                />
              )}
              <Tooltip />

              <XAxis
                id="myXaxis"
                type="datetime"
                categories={categoriesWithoutYear}
              />
              <YAxises
                data={propsDataToChart({
                  isAutoKeys,
                  showKeys,
                  excludeKeys,
                  data,
                  t,
                })}
                rightTitle={rightTitle}
                isLoading={isLoading}
              />
            </HighchartsChart>
          </div>
        )}
      </div>
    </div>
  );
};

LinearChart.defaultProps = {
  isLoading: false,
  isAutoKeys: false,
  isHiddenSidebar: false,
  showKeys: ['views', 'clickOn', 'clickFrom'],
  excludeKeys: [],
  isShowChart: true,
};

LinearChart.propTypes = {
  data: PropTypes.shape({}).isRequired,
  categoriesForX: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isAutoKeys: PropTypes.bool,
  showKeys: PropTypes.arrayOf(PropTypes.string),
  excludeKeys: PropTypes.arrayOf(PropTypes.string),
  isHiddenSidebar: PropTypes.bool,
  isChartToggle: PropTypes.bool,
  isShowChart: PropTypes.bool,
};

const mapStateToProps = ({ userSettings }) => ({
  isHiddenSidebar: userSettings.isHiddenSidebar,
});

export default connect(
  mapStateToProps,
  {},
)(withTranslation()(withHighcharts(LinearChart, Highcharts)));
