import {
  bool,
  number,
  string,
  exact,
  arrayOf,
  shape,
  oneOfType,
} from 'prop-types';
import { iInformerSiteNews } from './informer';

export const iSite = {
  chart: exact({
    isLoading: bool,
  }),
  info: exact({
    id: number,
    name: string,
    domain: string,
    status: string,
    title_xpath: string,
    image_xpath: string,
    description_xpath: string,
    content_xpath: string,
    date_xpath: string,
    date_format: string,
    date_format_enable: bool,
  }),
  statisticsChart: exact({
    isLoading: bool,
  }),
  statisticsTable: exact({
    isLoading: bool,
    total: number,
    limit: number,
    offset: number,
    start: number,
    end: number,
    data: arrayOf(shape({})),
  }),
  table: exact({
    isLoading: bool,
    total: number,
    limit: number,
    offset: number,
    start: number,
    end: number,
    data: arrayOf(shape({})),
    siteId: oneOfType([number, string]),
  }),
  news: arrayOf(exact(iInformerSiteNews)),
};
