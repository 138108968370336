import {
  SUCCESS_FETCH_INFORMERS_DATA_BY_NEWS_ID,
  START_FETCH_INFORMERS_DATA_BY_NEWS_ID,
  SUCCESS_FETCH_INFORMERS_DATA_FOR_ROTATOR_BY_NEWS_ID,
} from '../types';
import {
  SUCCESS_FETCH_SITES_WITH_INFORMERS,
  SUCCESS_FETCH_SITES_WITH_INFORMERS_FOR_ROTATOR,
} from '../types/dataLists';
import {
  SUCCESS_FETCH_INFORMER_CUSTOM_ARTICLES,
  SUCCESS_FETCH_SEARCH_ARTICLES,
  SUCCESS_FETCH_ARTICLE_POSITION,
  SUCCESS_FETCH_CUSTOM_ARTICLES,
  SUCCESS_FETCH_CUSTOM_ARTICLES_ROTATOR,
} from '../types/articles';
import { sortByField, sortSites } from '../helpers/sorting';

// Format sites data to the required structure for rendering in <Transfer /> component

const formatSitesData = (
  data,
  informersKey = 'informers',
  sitesKey = 'sites',
) => {
  const informers = [];
  const sites = data
    .map(item => {
      const { id, site, informers: children, ...restProps } = item;

      return {
        ...restProps,
        key: `site_${id}`,
        title: site,
        children: children
          .map(child => {
            const informer = {
              key: String(child.id),
              title: child.informer_name,
              site,
              siteDomain: restProps.domain,
              news: child.news,
            };
            informers.push(informer);

            return informer;
          })
          .sort(sortByField),
      };
    })
    .sort(sortSites);

  return { [informersKey]: informers, [sitesKey]: sites };
};

const sortArticles = articles =>
  articles && articles.length
    ? articles.sort((a, b) => {
        if (a.position > b.position) return 1;
        if (a.position < b.position) return -1;
        return 0;
      })
    : [];

const initialState = {
  sites: [],
  informers: [],
  sitesForRotator: [],
  informersForRotator: [],
  informersData: [], // List of informers related to selected article
  informersDataForRotator: [], // List of informers for rotator related to selected article
  search: {
    results: [], // Results of articles searching
  },
  informerCustomArticles: [], // Articles published on selected informer
  customArticles: [], // All published articles on informers
  customArticlesRotator: [], // All published articles on rotator
};

export default function publisherSettings(state = initialState, action = {}) {
  switch (action.type) {
    case SUCCESS_FETCH_SITES_WITH_INFORMERS:
      return { ...state, ...formatSitesData(action.payload.data) };

    case SUCCESS_FETCH_SITES_WITH_INFORMERS_FOR_ROTATOR:
      return {
        ...state,
        ...formatSitesData(
          action.payload.data,
          'informersForRotator',
          'sitesForRotator',
        ),
      };

    case START_FETCH_INFORMERS_DATA_BY_NEWS_ID:
      return {
        ...state,
        informersData: [],
      };

    case SUCCESS_FETCH_INFORMERS_DATA_FOR_ROTATOR_BY_NEWS_ID:
      return {
        ...state,
        informersDataForRotator: action.payload.data,
        ...(action.payload.data && action.payload.data.length
          ? {
              informersForRotator: state.informersForRotator.map(
                ({ position, block, ...informerProps }) => {
                  const findedInformer = action.payload.data.find(
                    item => String(item.informer_id) === informerProps.key,
                  );

                  return findedInformer
                    ? {
                        ...informerProps,
                        position: findedInformer.position,
                        block: findedInformer.block,
                      }
                    : informerProps;
                },
              ),
            }
          : {
              informersForRotator: state.informersForRotator.map(
                ({ position, block, ...informerProps }) => informerProps,
              ),
            }),
      };

    case SUCCESS_FETCH_INFORMERS_DATA_BY_NEWS_ID:
      return {
        ...state,
        informersData: action.payload.data,
        ...(action.payload.data && action.payload.data.length
          ? {
              informers: state.informers.map(
                ({ position, ...informerProps }) => {
                  const findedInformer = action.payload.data.find(
                    item => String(item.informer_id) === informerProps.key,
                  );

                  return findedInformer
                    ? { ...informerProps, position: findedInformer.position }
                    : informerProps;
                },
              ),
            }
          : {
              informers: state.informers.map(
                ({ position, ...informerProps }) => informerProps,
              ),
            }),
      };

    case SUCCESS_FETCH_INFORMER_CUSTOM_ARTICLES:
      return {
        ...state,
        informerCustomArticles: sortArticles(action.payload.data),
      };

    case SUCCESS_FETCH_SEARCH_ARTICLES:
      return {
        ...state,
        search: {
          ...state.search,
          results: action.payload.news,
        },
      };

    case SUCCESS_FETCH_CUSTOM_ARTICLES:
      return {
        ...state,
        customArticles: action.payload.data,
      };

    case SUCCESS_FETCH_CUSTOM_ARTICLES_ROTATOR:
      return {
        ...state,
        customArticlesRotator: action.payload.data,
      };

    case SUCCESS_FETCH_ARTICLE_POSITION:
      // eslint-disable-next-line
      const index = state.articles.findIndex(
        el => el.custom_id === action.payload.custom_id,
      );

      return {
        ...state,
        articles: state.articles.map((el, i) => {
          if (index === i) {
            return {
              ...el,
              serial_number: action.payload.serial_number,
            };
          }
          return el;
        }),
      };

    default:
      return state;
  }
}
