import React from 'react';
import { Card, Col, Icon, Row, Statistic } from 'antd';
import { withTranslation } from 'react-i18next';

// Utils
import {
  colorBlue,
  colorGreen,
  colorYellow,
} from '../../../styles/js/variables/colors';
import { snakeToCamelCase } from '../../../utils';

const StatisticsData = props => {
  const { publishersCount, sitesCount, informersCount, t } = snakeToCamelCase(
    props,
  );

  return (
    <Card
      className="fullscreen--disabled"
      style={{ maxWidth: '600px', textAlign: 'center' }}
    >
      <Row>
        <Col span={8}>
          <Statistic
            title={t('basic.publishers')}
            value={publishersCount || '...'}
            prefix={<Icon type="team" style={{ color: colorYellow }} />}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title={t('basic.sites')}
            value={sitesCount || '...'}
            prefix={<Icon type="global" style={{ color: colorBlue }} />}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title={t('basic.informers')}
            value={informersCount || '...'}
            prefix={<Icon type="appstore" style={{ color: colorGreen }} />}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default withTranslation()(StatisticsData);
