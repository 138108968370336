import React from 'react';
import { Button, Icon, Popover } from 'antd';
import { withTranslation } from 'react-i18next';
import FilterForm from './FilterForm';
import { colorYellow } from '../../../styles/js/variables/colors';

const PopupFilter = props => {
  const {
    dataLists,
    onFilterChange,
    pagination,
    isBasicPagination,
    toggleFilter,
    fixFilter,
    isFilterFixed,
    t,
  } = props;
  const { sitesList, newsCategoriesList } = dataLists;

  return (
    <Popover
      trigger="click"
      placement="bottom"
      title={
        <>
          {t('basic.filterArticle')}
          <Icon
            className="ant-popover-title__icon"
            type="pushpin"
            theme={isFilterFixed ? 'filled' : ''}
            style={{ float: 'right' }}
            onClick={fixFilter}
          />
        </>
      }
      content={
        <FilterForm
          pagination={pagination}
          sitesList={sitesList}
          newsCategoriesList={newsCategoriesList}
          onChange={onFilterChange}
          isSmall
        />
      }
    >
      <Button className="articles-list__filter-button" onClick={toggleFilter}>
        <Icon type="filter" />
        {t('basic.filter')}&nbsp;&nbsp;
        {!isBasicPagination && (
          <Icon
            className="button__status"
            type="check-circle"
            theme="filled"
            style={{ color: colorYellow }}
          />
        )}
      </Button>
    </Popover>
  );
};

export default withTranslation()(PopupFilter);
