import React from 'react';
import { hot } from 'react-hot-loader/root';
import { BrowserRouter as Router } from 'react-router-dom';

import LayoutDefault from 'HOC/Layouts/LayoutDefault';
import ModalContainer from 'components/Modals';
import Notifications from 'components/Notifications';
import ReduxContainer from 'redux/ReduxContainer';
import InitRequests from './components/InitRequests';
import { UserLeaveConfirmation } from './components/UserLeaveConfirmation';

const App = () => {
  const [confirmOpen, setConfirmOpen] = React.useState(true);

  const getUserConfirmation = (message, callback) => {
    return UserLeaveConfirmation(
      message,
      callback,
      confirmOpen,
      setConfirmOpen,
    );
  };

  return (
    <ReduxContainer>
      <Router getUserConfirmation={getUserConfirmation}>
        <InitRequests />

        <LayoutDefault />

        <Notifications />

        <ModalContainer />
      </Router>
    </ReduxContainer>
  );
};

export default hot(App);
