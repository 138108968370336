import React from 'react';
import { Popconfirm } from 'antd';
import { ConditionalWrapper } from '../../../../ConditionalWrapper';
import LinkWithTag from '../../../../LinkWithTag';
import { Translation } from '../../../../Translation';

const linkedActions = [
  'page',
  'informersList',
  'link',
  'editInformer',
  'editSite',
];

const ActionButtonsItem = props => {
  const { clickKey, color, confirm, tooltip, row, action, ...button } = props;
  const onClick = data => action(row, data);
  const isLinkWrap = linkedActions.includes(clickKey);
  const isTargetBlank = clickKey === 'link';
  // const isIntegration = tooltip === 'form.title.siteIntegration';

  return (
    <ConditionalWrapper
      condition={!!confirm}
      wrapper={children => (
        <Popconfirm onConfirm={onClick} {...confirm}>
          {children}
        </Popconfirm>
      )}
    >
      <LinkWithTag
        isButton
        linkProps={{
          icon: 'question',
          ...button,
          tooltip: <Translation type={tooltip} />,
        }}
        isTargetBlank={isTargetBlank}
        href={isLinkWrap ? onClick({}) : null}
        className={`button--inverted button--${color}`}
        onClick={!confirm && !isLinkWrap && onClick}
      />
    </ConditionalWrapper>
  );
};

ActionButtonsItem.propTypes = {};

export default ActionButtonsItem;
