import React, { Component } from 'react';
import { moment } from 'utils/date';
import { connect } from 'react-redux';
import StatisticsFilter from './StatisticsFilter';
import StatisticsBlock from './StatisticsBlock';
import { fetchStatisticsByType } from '../../../redux/actions/statistics';

class RotatorStatistics extends Component {
  state = {
    from: moment().set({ hour: 0, minute: 0 }),
    to: moment(),
    siteId: null,
    informerId: null,
    abTest: null,
    deviceType: null,
  };

  componentDidMount() {
    this.handleFetch('base');
    this.handleFetch('main');
    this.handleFetch('rubric');
    this.handleFetch('last');
  }

  handleFetch = (blockTitle, params = ['', '', '']) => {
    const [page = 1, sortBy = '', sortDirection = ''] = params;

    return this.props.fetchStatisticsByType({
      ...this.state,
      from: this.state.from.format('x'),
      to: this.state.to.format('x'),
      block: blockTitle,
      page,
      sortBy,
      sortDirection,
    });
  };

  onChangeFilter = changes => {
    this.setState(
      state => ({ ...state, ...changes }),
      () => {
        this.handleFetch('base');
        this.handleFetch('main');
        this.handleFetch('rubric');
        this.handleFetch('last');
      },
    );
  };

  render() {
    const { isLoading } = this.props;

    return (
      <>
        <StatisticsFilter
          isRotator
          {...this.state}
          onChange={this.onChangeFilter}
        />
        <div className="statistics-block__rotator">
          <StatisticsBlock
            isRotator
            blockTitle="base"
            isLoading={isLoading}
            handleFetch={(...args) => this.handleFetch('base', args)}
          />
          <StatisticsBlock
            isRotator
            blockTitle="rubric"
            isLoading={isLoading}
            handleFetch={(...args) => this.handleFetch('rubric', args)}
          />
          <StatisticsBlock
            isRotator
            blockTitle="last"
            isLoading={isLoading}
            handleFetch={(...args) => this.handleFetch('last', args)}
          />
          <StatisticsBlock
            isRotator
            blockTitle="main"
            isLoading={isLoading}
            handleFetch={(...args) => this.handleFetch('main', args)}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ network }) => ({
  isLoading: network.START_FETCH_STATISTICS_BY_TYPE,
});

const mapDispatchToProps = {
  fetchStatisticsByType,
};

export default connect(mapStateToProps, mapDispatchToProps)(RotatorStatistics);
