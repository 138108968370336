import React, { useCallback, useMemo } from 'react';
import { snakeCase } from 'lodash';

import { getSingleHeader } from '../../Table/config/moldingTable';
import { Descriptions } from '../index';
import TableColumnTitle from '../../Table/components/TableColumnTitle';
import { formatCell } from '../../Table/helpers/formatCell';

export const ColumnDescriptions = ({ excludedFields = [], data }) => {
  const getColumn = useCallback(({ key, value }) => {
    try {
      return {
        ...getSingleHeader(snakeCase(key), { [key]: value }, false),
      };
    } catch (e) {
      return {
        title: key,
        render: () => value,
      };
    }
  }, []);

  const list = useMemo(
    () =>
      Object.entries(data).reduce((acc, [key, value]) => {
        if (!excludedFields.includes(key)) {
          const column = getColumn({ key, value });
          const render = column.render
            ? column.render(value, column)
            : formatCell(value);

          acc.push({
            key: (
              <TableColumnTitle title={column.title} tooltip={column.tooltip} />
            ),
            value: render,
          });
        }

        return acc;
      }, []),
    [excludedFields, getColumn, data],
  );

  return <Descriptions list={list} size="small" />;
};
