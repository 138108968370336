import React, { useEffect, useState } from 'react';
import { number, string, shape, oneOfType } from 'prop-types';
import { withTranslation } from 'react-i18next';

// Components
import Status from '../../Status';
import Loader from '../../Loader';

const InformerDetails = ({ informer, t }) => {
  const { status, views } = informer;
  const [isLoading, setIsLoading] = useState(false);

  // We need timeout to ensure user that informer status changes
  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => setIsLoading(false), 500);
  }, [informer]);

  if (!informer) return null;

  return isLoading ? (
    <Loader isLoading={isLoading} />
  ) : (
    <div style={{ display: 'inline-block' }}>
      <Status status={status} noBackground />
      &nbsp;
      <span>({t(`basic.isUserViews.${(views > 0).toString()}`)})</span>
    </div>
  );
};

InformerDetails.propTypes = {
  informer: oneOfType([
    string,
    shape({
      status: string,
      views: number,
    }),
  ]),
};

export default withTranslation()(InformerDetails);
