import { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

// Actions
import { getSitesStatuses as getSitesStatusesAction } from '../../redux/actions/moderation';

const InitRequests = ({ getSitesStatuses, user, history }) => {
  const handleRequests = useCallback(() => {
    const isAuth = !!Object.keys(user).length;

    if (!isAuth) return;

    getSitesStatuses();
  }, [user, getSitesStatuses]);

  useEffect(handleRequests, [user, history.location.pathname]);

  return null;
};

const mapStateToProps = ({ auth, router }) => ({
  user: auth.user,
  router: router.location,
});

const mapDispatchToProps = {
  getSitesStatuses: getSitesStatusesAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(InitRequests));
