import {
  colorGreen,
  colorCyan,
  colorGrey,
  colorBlue,
  colorRed,
  colorYellow,
} from '../styles/js/variables/colors';

const STATUS_ACTIVE = 'active';
const STATUS_ANALYTICS = 'analytics';
const STATUS_ANALYTICS_SPEEDUP = 'analytics_speedup';
const STATUS_DELETED = 'deleted';
const STATUS_DISABLED = 'disabled';
const STATUS_FINISHED = 'finished';
const STATUS_NOT_ACTIVE = 'not_active';
const STATUS_ON_COMPLETION = 'on_completion';
const STATUS_PENDING = 'pending';
const STATUS_SUSPENDED = 'suspended';
const STATUS_VERIFICATION = 'verification';
const STATUS_WAITING = 'waiting';

// Label: ALL POSSIBLE STATUSES
const S = {
  STATUS_ACTIVE,
  STATUS_ANALYTICS,
  STATUS_ANALYTICS_SPEEDUP,
  STATUS_DELETED,
  STATUS_DISABLED,
  STATUS_NOT_ACTIVE,
  STATUS_ON_COMPLETION,
  STATUS_PENDING,
  STATUS_SUSPENDED,
  STATUS_VERIFICATION,
  STATUS_WAITING,
};
export const allStatuses = S;

export const statusesSites = {
  STATUS_VERIFICATION,
  STATUS_ANALYTICS,
  STATUS_ANALYTICS_SPEEDUP,
  STATUS_SUSPENDED,
  STATUS_PENDING,
  STATUS_DELETED,
  STATUS_ACTIVE,
};

export const statusesAbTests = {
  STATUS_ACTIVE,
  STATUS_NOT_ACTIVE,
  STATUS_DELETED,
  STATUS_FINISHED,
};

export const statusesArticles = {
  STATUS_DISABLED,
  STATUS_WAITING,
  STATUS_ON_COMPLETION,
  STATUS_ACTIVE,
};

// Label: STYLES

export const statusesStyles = {
  [STATUS_ACTIVE]: {
    color: colorGreen,
    icon: 'safety-certificate',
  },
  [STATUS_ANALYTICS]: {
    color: colorCyan,
    theme: 'outlined',
    icon: 'bar-chart',
  },
  [STATUS_ANALYTICS_SPEEDUP]: {
    color: colorCyan,
    theme: 'outlined',
    icon: 'bar-chart',
  },
  [STATUS_FINISHED]: {
    color: colorGrey,
    theme: 'outlined',
    icon: 'check-circle',
  },
  [STATUS_DELETED]: {
    color: colorGrey,
    icon: 'delete',
  },
  [STATUS_DISABLED]: {
    color: colorGrey,
    icon: 'stop',
  },
  [STATUS_NOT_ACTIVE]: {
    color: colorRed,
    icon: 'stop',
    theme: 'outlined',
  },
  [STATUS_ON_COMPLETION]: {
    color: colorGrey,
    icon: 'rollback',
  },
  [STATUS_PENDING]: {
    color: colorBlue,
    icon: 'security-scan',
  },
  [STATUS_SUSPENDED]: {
    color: colorRed,
    theme: 'outlined',
    icon: 'stop',
  },
  [STATUS_VERIFICATION]: {
    color: colorYellow,
    icon: 'file-search',
    theme: 'outlined',
  },
  [STATUS_WAITING]: {
    color: colorBlue,
    icon: 'clock-circle',
  },
};
