import {
  START_SIGN_IN_AUTH,
  SUCCESS_SIGN_IN_AUTH,
  FAILURE_SIGN_IN_AUTH,
  CLEAR_USER,
} from '../types';

import { signIn as signInAuth } from '../../api';
import { ERROR_AUTH_WRONG_DATA } from '../../constants/messages';
import { saveDataToLocalStorage } from '../../services/localStorage';

export const clearUser = () => dispatch => {
  dispatch({
    type: CLEAR_USER,
  });
};

export const signInAction = signInData => dispatch => {
  dispatch({
    type: START_SIGN_IN_AUTH,
  });

  return signInAuth(signInData)
    .then(response => {
      const { data, status } = response;
      if (status !== 200) throw new Error();

      saveDataToLocalStorage(data);

      return dispatch({
        type: SUCCESS_SIGN_IN_AUTH,
        payload: data,
      });
    })
    .catch(fail => {
      return dispatch({
        status: 401,
        type: FAILURE_SIGN_IN_AUTH,
        fail,
        notification: {
          type: 'error',
          text: ERROR_AUTH_WRONG_DATA,
        },
      });
    });
};
