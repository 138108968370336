import {
  FAILURE_FETCH_MODERATION_LIST,
  START_FETCH_MODERATION_LIST,
  SUCCESS_FETCH_MODERATION_LIST,
} from '../types/articles';
import {
  START_SITE_MODERATION,
  SUCCESS_SITE_MODERATION,
  FAILURE_SITE_MODERATION,
  START_FETCH_SITES_STATUSES,
  SUCCESS_FETCH_SITES_STATUSES,
  FAILURE_FETCH_SITES_STATUSES,
} from '../types';
import {
  getModerationSitesApi,
  updateSiteModerationStatusApi,
  getSitesStatusesApi,
} from '../../api';
import { ERROR_NOT_RESPONDED } from '../../constants/messages';

export const fetchModerationList = params => dispatch => {
  dispatch({
    type: START_FETCH_MODERATION_LIST,
  });

  return getModerationSitesApi(params)
    .then(payload => {
      dispatch({
        type: SUCCESS_FETCH_MODERATION_LIST,
        payload: payload.data,
      });
    })
    .catch(error => {
      dispatch({
        type: FAILURE_FETCH_MODERATION_LIST,
        payload: error,
        notification: {
          type: 'error',
          text: ERROR_NOT_RESPONDED,
        },
      });
    });
};

export const moderateSite = params => dispatch => {
  dispatch({
    type: START_SITE_MODERATION,
  });

  return updateSiteModerationStatusApi(params)
    .then(payload =>
      dispatch({
        type: SUCCESS_SITE_MODERATION,
        payload: {
          id: params.siteId,
          status: params.status,
          data: payload.data,
        },
      }),
    )
    .catch(error =>
      dispatch({
        type: FAILURE_SITE_MODERATION,
        payload: error,
        notification: {
          type: 'error',
          text: ERROR_NOT_RESPONDED,
        },
      }),
    );
};

export const getSitesStatuses = params => dispatch => {
  dispatch({
    type: START_FETCH_SITES_STATUSES,
  });

  return getSitesStatusesApi(params)
    .then(payload =>
      dispatch({
        type: SUCCESS_FETCH_SITES_STATUSES,
        payload: payload.data,
      }),
    )
    .catch(error =>
      dispatch({
        type: FAILURE_FETCH_SITES_STATUSES,
        payload: error,
      }),
    );
};
