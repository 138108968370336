export default {
  views: {
    name: 'chart.views',
    hint: 'chart.tooltips.views',
  },
  clickOn: {
    name: 'chart.click_on',
    hint: 'chart.tooltips.click_on',
  },
  clickFrom: {
    name: 'chart.click_from',
    hint: 'chart.tooltips.click_from',
  },
  efficiencies: {
    name: 'chart.efficiencies',
  },
  coefficient: {
    name: 'chart.coefficient',
    hint: 'chart.tooltips.coefficient',
    isPositionRight: true,
  },
  viewsInformer: {
    name: 'chart.views_informer',
  },
  viewsRotator: {
    name: 'chart.views_rotator',
  },
  viewsPreview: {
    name: 'chart.views_preview',
  },
  clicksInformer: {
    name: 'chart.clicks_informer',
  },
  clicksRotator: {
    name: 'chart.clicks_rotator',
  },
  clicksPreview: {
    name: 'chart.clicks_preview',
  },
  ctrInformer: {
    name: 'chart.ctr_informer',
    isPositionRight: true,
  },
  ctrRotator: {
    name: 'chart.ctr_rotator',
    isPositionRight: true,
  },
  ctrPreview: {
    name: 'chart.ctr_preview',
    isPositionRight: true,
  },
  gaCoefficient: {
    name: 'chart.ga_coefficient',
  },
  gaFromPublisher: {
    name: 'chart.ga_from_publisher',
  },
  gaFromPublisherFault: {
    name: 'chart.ga_from_publisher_fault',
  },
  gaToPublisher: {
    name: 'chart.ga_to_publisher',
  },
  gaToPublisherFault: {
    name: 'chart.ga_to_publisher_fault',
  },
  gaReproductionCoefficient: {
    name: 'chart.ga_reproduction_coefficient',
  },
  gaReproductionCoefficientFault: {
    name: 'chart.ga_reproduction_coefficient_fault',
  },
};
