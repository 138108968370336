import React, { useCallback, useEffect } from 'react';
import { Checkbox, Col, Divider, Row } from 'antd';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

// Redux
import { fetchNewsCategoriesList } from '../../../redux/actions/dataLists';

// Components
import WithToggleButton from '../../Button/ButtonTogglerConditional';
import Loader from '../../Loader';

const CategoriesCustomBlock = ({
  isNewsCategoriesListLoading,
  categoriesDisabled = [],
  getCategories,
  categories = [],
  onChange,
  t,
}) => {
  const saveToInformer = cat =>
    onChange({
      item: 'categories_disabled',
      response: cat,
      isSetting: true,
    });

  const handleChange = ({ target: { value: id } }) => {
    const newState = [...categoriesDisabled];
    const index = categoriesDisabled.indexOf(id);

    if (index >= 0) {
      newState.splice(index, 1);
    } else {
      newState.push(id);
    }

    saveToInformer(newState);
  };

  const selectAll = () =>
    saveToInformer(
      categoriesDisabled.length === 0 ? categories.map(({ id }) => id) : [],
    );

  const handleFetchCategories = useCallback(() => {
    if (!categories.length) getCategories();
  }, [categories, getCategories]);

  useEffect(handleFetchCategories, [categories]);

  return (
    <>
      <Loader isLoading={isNewsCategoriesListLoading} />
      <WithToggleButton
        buttonTitle={t('basic.categories')}
        buttonProps={{
          style: { margin: '-1em 0 0 -15px' },
        }}
      >
        <div className="box ant-transfer-list--automatic">
          <Checkbox
            checked={categoriesDisabled.length === 0}
            onChange={selectAll}
          >
            {t('basic.selectAll')}
          </Checkbox>
          <Divider style={{ margin: '10px 0 10px' }} />
          <Row>
            {categories.map(({ id, title }) => (
              <Col xs={24} sm={24} md={12} key={id}>
                <Checkbox
                  value={id}
                  checked={categoriesDisabled.indexOf(id) < 0}
                  onChange={handleChange}
                >
                  {title}
                </Checkbox>
              </Col>
            ))}
          </Row>
        </div>
      </WithToggleButton>
    </>
  );
};

CategoriesCustomBlock.propTypes = {};

const mapStateToProps = ({ network, informerSettings, dataLists }) => ({
  categoriesDisabled: informerSettings.categories_disabled,
  categories: dataLists.newsCategoriesList,
  isNewsCategoriesListLoading: network.START_FETCH_NEWS_CATEGORIES_LIST,
});

const mapDispatchToProps = {
  getCategories: fetchNewsCategoriesList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(CategoriesCustomBlock));
