import {
  SUCCESS_CREATE_ARTICLE,
  SUCCESS_FETCH_ARTICLES_LIST,
  SUCCESS_EDIT_ARTICLE,
} from 'redux/types/articles';

const initialState = {
  list: [],
};

export default function articles(state = initialState, action = {}) {
  switch (action.type) {
    case SUCCESS_CREATE_ARTICLE:
      return state;

    case SUCCESS_FETCH_ARTICLES_LIST:
      return {
        ...state,
        list: action.payload.data,
        total: action.payload.total,
      };

    case SUCCESS_EDIT_ARTICLE:
      return {
        ...state,
        list: state.list.map(news =>
          news.id === action.payload.newsId
            ? {
                ...news,
                status: action.payload.status,
              }
            : news,
        ),
      };

    default:
      return state;
  }
}
