import React from 'react';
import { Icon, Tag, Tooltip } from 'antd';

// TODO: refactor this after approving new articles statuses
const icons = {
  active: {
    icon: 'eye',
    background: 'green',
  },
  disabled: {
    icon: 'eye-invisible',
    background: 'green',
  },
  on_completion: {
    icon: 'rollback',
    background: 'grey',
  },
  waiting: {
    icon: 'clock-circle',
    background: 'blue',
  },
};

const Status = ({ tooltip, className, status, onSwitchStatus }) => (
  <Tooltip title={tooltip}>
    <Tag
      color={icons[status]?.background || 'grey'}
      className={className}
      onClick={onSwitchStatus}
    >
      <Icon type={icons[status]?.icon || 'question-circle'} />
    </Tag>
  </Tooltip>
);

export default Status;
