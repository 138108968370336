import { secureAxios } from 'services/axios/enhanceAxios';
import { adminApi } from './config';

// Publishers
// ________________________________________________________
export const getNewsCategoriesListApi = () =>
  secureAxios().get(`${adminApi}/news/categories/all`, {});

export const getSitesListApi = () =>
  secureAxios().get(`${adminApi}/sites/all`, {});

export const getSitesListWithInformersApi = () =>
  secureAxios().get(`${adminApi}/sites/informers`, {});

export const getLanguagesListApi = () =>
  secureAxios().get(`${adminApi}/news/languages/all`, {});
