import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'antd';

// Components
import LoginForm from '../../components/Form/forms/LoginForm';

const Login = ({ isMobile }) => {
  return (
    <Row
      type="flex"
      align="top"
      justify="center"
      style={{
        height: `calc(100vh - ${isMobile ? 0 : '- 20vh'} 100px)`,
        marginTop: isMobile ? 0 : '20vh',
      }}
    >
      <Col xs={24} sm={16} md={10} lg={8} xl={7} xxl={5}>
        <LoginForm />
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ userSettings }) => ({
  isMobile: userSettings.isMobile,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
