import React from 'react';
import { bool, func } from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Popover, Icon } from 'antd';

// Components
import Logo from './components/Logo';
import MenuList from './components/MenuList';

const Header = props => {
  const { isAuth, logout, user, isMobile, t } = props;

  return (
    <div className="header">
      <Logo />

      <Popover
        trigger="click"
        placement="topRight"
        content={<MenuList logout={logout} isAuth={isAuth} />}
      >
        <div className="user-panel">
          <div className="user-panel__image">
            <Icon type={isAuth ? 'user' : 'setting'} />
          </div>

          {!isMobile && (
            <div className="user-panel__details">
              <div className="user-panel__text">
                {isAuth ? user.name : t('basic.settings')}
              </div>
              {isAuth && (
                <div className="user-panel__role">
                  {t(`header.${user.role}`)}
                </div>
              )}
            </div>
          )}
        </div>
      </Popover>
    </div>
  );
};

Header.propTypes = {
  isAuth: bool.isRequired,
  logout: func.isRequired,
};

const mapStateToProps = ({ auth, userSettings }) => ({
  user: auth.user,
  isMobile: userSettings.isMobile,
});

export default connect(mapStateToProps)(withTranslation('common')(Header));
