import { Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { statusesAbTests } from '../../../constants/statuses';
import * as C from '../../../styles/js/variables/colors';

const LogicOptionStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  
  >div {
    max-width: 75%;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  span {
    margin: 0 0.5em;
  }
  
  b {
    font-weight: bold;
    font-size: 11px;
    color: ${({ mark }) => (mark === 'back' ? C.colorGreen : C.colorBlue)}
`;

const FormSelectLogicOptionContent = ({ item }) => {
  const { t } = useTranslation();

  return (
    <LogicOptionStyled mark={item.implementedOn}>
      <div>
        {`${item.id}.`}
        <span>{item.description}</span>
      </div>
      <b>({`${t(`basic.${item.implementedOn}end`)}`})</b>
    </LogicOptionStyled>
  );
};

export const FormSelectLogic = ({
  currentForm,
  bindedInputFunctions,
  name,
  list = [],
}) => {
  const { t } = useTranslation();

  return (
    <Select
      showSearch
      name={name}
      placeholder="..."
      defaultValue={currentForm[name]}
      optionFilterProp="title"
      filterOption={(input, option) =>
        option.props.title.toLowerCase().includes(input.toLowerCase())
      }
      {...bindedInputFunctions}
    >
      {list.map(el =>
        el.status !== statusesAbTests.STATUS_ACTIVE ? null : (
          <Select.Option
            key={el.id}
            name={name}
            value={el.id}
            title={`${el.id}. ${el.description} (${t(
              `basic.${el.implementedOn}end`,
            )}`}
          >
            <FormSelectLogicOptionContent item={el} />
          </Select.Option>
        ),
      )}
    </Select>
  );
};
