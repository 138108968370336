import {
  colorBlue,
  colorGreen,
  colorRed,
} from '../../../styles/js/variables/colors';

export const chartColors = [
  colorBlue,
  colorGreen,
  colorRed,
  // colorGreyLight,
  '#FF9655',
  '#6AF9C4',
  '#058DC7',
  '#50B432',
  '#ED561B',
  '#DDDF00',
  '#24CBE5',
  '#64E572',
];
