import React from 'react';
import Grid from '../../Grid';
import Label from '../../Label';
import ValidateInput from '../../ValidateInput';

const InputsBlock = ({ params, labels, onChange, rules, inputProps }) => (
  <Grid columns={labels.length}>
    {Object.keys(params).map((key, i) => (
      <div key={key}>
        <Label labelText={labels[i]} asterisk />
        <ValidateInput
          {...inputProps}
          inputProps={inputProps}
          name={key}
          validateName={`settings-${key}`}
          defaultValue={params[key]}
          value={[params[key]]}
          controlled
          placeholder="0.1"
          onChange={value => onChange(value, key)}
          rules={rules}
          // onValidate={input => this.props.actions.validate(input)}
        />
      </div>
    ))}
  </Grid>
);

export default InputsBlock;
