import React, { useState, useCallback } from 'react';
import ImageViewer from 'react-simple-image-viewer';

// Styles
import { ImageViewWrapper, ImageViewImgWrapper } from './styles';

const ImageView = ({ images = [] }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback(index => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <ImageViewWrapper>
      {images.map((src, index) => (
        <ImageViewImgWrapper key={index} onClick={() => openImageViewer(index)}>
          <img src={src} alt="" />
        </ImageViewImgWrapper>
      ))}

      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
        />
      )}
    </ImageViewWrapper>
  );
};

export default ImageView;
