import React from 'react';
import classNames from 'classnames';
import { moment } from 'utils/date';
import { Checkbox, DatePicker, Form, Select, Input } from 'antd';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

const formClasses = ({ isSmall, isBox, isFilterFixed, isMobile }) =>
  classNames({
    'articles-list__filter--vertical': isSmall || isMobile,
    box: isBox,
    'box--shown': isFilterFixed && isBox,
  });

const FilterForm = ({
  onChange,
  sitesList,
  newsCategoriesList,
  pagination,
  t,
  ...otherProps
}) => (
  <Form className={`articles-list__filter ${formClasses(otherProps)}`}>
    <Form.Item style={{ margin: 0 }}>
      <Input
        onChange={event => onChange('search', event.target.value)}
        placeholder={t('basic.newsLink')}
      />
    </Form.Item>
    <Form.Item style={{ margin: 0 }}>
      <DatePicker
        showTime
        format="DD/MM/YYYY (HH:mm)"
        placeholder={t('basic.dateCreationStart')}
        value={moment(Number(pagination.start))}
        onChange={e => onChange('start', e && e.format('x'))}
        style={{ marginRight: otherProps.isMobile ? 'inherit' : '10px' }}
      />
    </Form.Item>
    <Form.Item style={{ margin: 0 }}>
      <DatePicker
        showTime
        format="DD/MM/YYYY (HH:mm)"
        placeholder={t('basic.dateCreationEnd')}
        value={moment(Number(pagination.end))}
        onChange={e => onChange('end', e && e.format('x'))}
      />
    </Form.Item>
    <Form.Item style={{ margin: 0 }}>
      <Select
        placeholder={`-- ${t('basic.category')} --`}
        value={pagination.categoryId}
        onChange={e => onChange('categoryId', e)}
        style={{ width: '150px' }}
      >
        <Select.Option value={null}>{`-- ${t(
          'basic.category',
        )} --`}</Select.Option>
        {newsCategoriesList.map(({ id, title }) => (
          <Select.Option key={id} value={id}>
            {title}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
    <Form.Item style={{ margin: 0 }}>
      <Select
        placeholder={`-- ${t('basic.site')} --`}
        onChange={e => onChange('sites', e)}
        mode="multiple"
        value={pagination.sites}
        style={{ width: '150px' }}
      >
        {sitesList.map(({ id, name }) => (
          <Select.Option key={id} value={id}>
            {name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
    <Form.Item style={{ margin: 0 }}>
      <Checkbox
        onChange={e => onChange('isArtificial', e.target.checked)}
        checked={pagination.isArtificial}
      >
        {t('basic.customArticles')}
      </Checkbox>
    </Form.Item>
  </Form>
);

const mapStateToProps = ({ userSettings }) => ({
  isMobile: userSettings.isMobile,
});

export default connect(mapStateToProps)(withTranslation()(FilterForm));
