import React, { Fragment, PureComponent } from 'react';
import { Empty, Table as TableAntD } from 'antd';
import classNames from 'classnames';
import { isEqual } from 'lodash';

// Utils
import { withTranslation } from 'react-i18next';
import columnsAuto from './config/columnsAuto';

// Components
import actionsConfig from './components/Actions/actionsConfig';
import renderActions from './components/Actions/renderActions';
import WithTableFooter from './components/WithTableFooter';
import sortDirections from '../../constants/sortDirections';

class Table extends PureComponent {
  state = {
    currentPage: 1,
    sortBy: '',
    sortDirection: sortDirections.ASC,
    tableHeaders: [],
    filters: {},
    rowActions: {},
  };

  componentDidMount() {
    this.convertActions();
  }

  // prettier-ignore
  componentDidUpdate(prevProps, prevState) {
    const { data } = this.props;
    const { sortBy, sortDirection, filters } = this.state;
    const isNewData = prevProps?.data && prevProps?.data?.length !== data && data?.length;
    const isEmptyHeaders = prevState.tableHeaders && prevState.tableHeaders.length === 0;
    const isSortingChanged = sortBy !== prevState.sortBy || sortDirection.table !== prevState.sortDirection.table;
    const isFiltersChanged = !isEqual(filters, prevState.filters);

    if ((isNewData && isEmptyHeaders) || isSortingChanged || isFiltersChanged) {
      this.setHeaders();
    }
  }

  changePagination = () => {
    const { fetch, isClientPagination } = this.props;
    const { sortBy, sortDirection, currentPage, filters } = this.state;

    if (!isClientPagination) {
      fetch(currentPage, sortBy, sortDirection.api, filters);
    }
  };

  getAutoHeader = () => {
    const {
      customHeaders = {},
      data,
      actions,
      isAutoHeadersSort,
      t,
    } = this.props;
    const { sortDirection, sortBy, rowActions } = this.state;
    const result = [];
    const params = {
      sorter: isAutoHeadersSort,
      sortOrder: sortDirection.table,
      sortBy,
    };
    const columnsKeys = Object.keys(data[0]);
    const newActions = actions ? [renderActions(rowActions)] : [];

    const getTitle = key => {
      const translation = t(`table.auto.${key}`);
      const translationKey = `table.auto.${key}`;

      return translation === translationKey ? key : translation;
    };

    columnsKeys.forEach(key => {
      if (key in customHeaders && customHeaders[key] === null) return;

      result.push({
        name: key,
        align: 'center',
        title: getTitle(key),
        dataIndex: key,
        ...columnsAuto[key],
        ...customHeaders[key],
        ...params,
        sortOrder: params.sortBy === key ? params.sortOrder : null,
      });
    });

    return [...result, ...newActions];
  };

  getHeader = () => {
    const { headers, actions } = this.props;
    const { sortDirection, sortBy, rowActions, filters } = this.state;
    const params = { sorter: true, sortOrder: sortDirection.table, sortBy };
    const newActions = actions ? [renderActions(rowActions)] : [];
    const newHeaders =
      typeof headers === 'function' ? headers(params) : headers;

    const withFilters = newHeaders.map(header => ({
      ...header,
      filteredValue: filters[header.dataIndex] || null,
    }));

    return [...withFilters, ...newActions];
  };

  convertActions = () => {
    const { actions = {}, t } = this.props;

    // TODO: actions translations is not ready on load. Fix this bug after Antd update
    const translationFallback = (trans, fallback) =>
      t(trans) === trans ? fallback : t(trans);

    const result = Object.entries(actions).reduce(
      (acc, [key, action]) => ({
        ...acc,
        [key]: {
          ...actionsConfig[key],
          ...(actionsConfig[key]?.confirm && {
            confirm: {
              title: translationFallback('confirm.areYouSure', 'Are you sure?'),
              okText: translationFallback('form.buttonProceed', 'Proceed'),
              cancelText: translationFallback('form.buttonCancel', 'Cancel'),
              okButtonProps: {
                type: 'warning',
              },
              ...actionsConfig[key]?.confirm,
            },
          }),
          tooltip: t(`table.rowActions.${key}`),
          clickKey: key,
          action,
        },
      }),
      {},
    );

    this.setState({ rowActions: result });
  };

  setHeaders = () => {
    const { isAutoHeaders } = this.props;
    const tableHeaders = isAutoHeaders ? this.getAutoHeader : this.getHeader;

    this.setState({ tableHeaders: tableHeaders() });
  };

  // prettier-ignore
  handleChange = (pagination, filters, sorter) => {
    const { isClientPagination } = this.props;
    const { sortBy, sortDirection, currentPage } = this.state;
    const { columnKey, order } = sorter;
    const { ASC, DESC } = sortDirections;

    // Order config
    const isSorterEnabled = (!!columnKey || !!order) && columnKey !== order;
    // const isSameColumn = columnKey === sortBy;
    const isSameOrder = order === sortDirection.table;
    const isOrderSwitchedActive = isSorterEnabled && !isSameOrder;
    const isOrderSwitchedFirstTime = isSorterEnabled && !sortBy && isSameOrder;
    const isOrderSwitched = isOrderSwitchedFirstTime || isOrderSwitchedActive;
    const reversedOrder = sortDirection.table === ASC.table ? DESC : ASC;
    const direction = isOrderSwitched ? reversedOrder : sortDirection;

    // Pagination config
    const isPaginationChanged = currentPage !== pagination.current;
    const isPaginationFetchOff = isClientPagination && isPaginationChanged && !isOrderSwitched;

    this.setState(
      {
        currentPage: isOrderSwitched ? currentPage : pagination.current,
        sortBy: columnKey,
        sortDirection: direction,
        filters,
      },
      isPaginationFetchOff ? () => {} : this.changePagination,
    );
  };

  render() {
    const { props } = this;
    const {
      actions,
      className: classNameProp,
      data = [],
      fetch,
      headers,
      isAutoHeaders,
      isAutoWidth,
      isClientPagination,
      isLoading,
      limit,
      summaryData = {},
      summaryKeys,
      t,
      total,
      rowKey = record => record.id,
      ...antTableProps
    } = props;
    const { tableHeaders } = this.state;
    const isMarkRowByStatus = tableHeaders.find(
      ({ dataIndex }) => dataIndex === 'status',
    );
    const isStatusColumn = tableHeaders.find(
      ({ dataIndex }) => dataIndex === 'status',
    );

    const getKey = () => {
      if (data[0].id) return 'id';
      if (data[0].created) return 'created';
      return rowKey;
    };

    // ClassNames
    const rowClassNamesString = ({ status, display, views }) =>
      classNames({
        [`ant-table-row--${status}`]: isMarkRowByStatus && status,
        'ant-table-row--warning':
          status === 'active' && display < 1 && views === 0 && isStatusColumn,
      });
    const tableClassNamesString = classNames({
      [`${classNameProp}`]: true,
      'ant-table--empty': data?.length === 0,
      'ant-table--auto-size': isAutoWidth,
    });

    return (
      <Fragment>
        <TableAntD
          loading={isLoading}
          className={tableClassNamesString}
          columns={tableHeaders}
          dataSource={data}
          rowKey={data?.length && getKey()}
          rowClassName={rowClassNamesString}
          bordered
          locale={{
            filterConfirm: t('table.confirm'),
            filterReset: t('table.reset'),
            emptyText: (
              <Empty
                description={t('basic.emptyData')}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            ),
          }}
          size="default"
          pagination={{
            total,
            defaultPageSize: limit,
            size: 'large',
            position: 'bottom',
            hideOnSinglePage: true,
            showQuickJumper: false,
            locale: {
              jump_to: 'Перейти',
            },
          }}
          onChange={this.handleChange}
          components={{
            table: tableProps => (
              <WithTableFooter
                {...tableProps}
                summaryKeys={summaryKeys}
                summaryData={summaryData}
              />
            ),
          }}
          {...antTableProps}
        />
      </Fragment>
    );
  }
}

export default withTranslation('common')(Table);
