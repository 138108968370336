import React from 'react';
import Status from '../../../components/Status';
import TableBoolean from '../../../components/Table/components/TableBoolean';
import { cutStringWithDots } from '../../../utils/string';

export const formatValue = (key, value) => {
  switch (true) {
    case key === 'styles':
      return cutStringWithDots(value, 80);
    case key === 'status' || value === 'true' || value === 'false':
      return <Status status={value} noBackground style={{ padding: 0 }} />;
    case typeof value === 'boolean':
      return <TableBoolean boolResult={!!value} />;
    default:
      return value;
  }
};
