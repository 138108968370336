import React from 'react';
import { moment } from 'utils/date';
import { Button, Icon, Popconfirm, Tooltip } from 'antd';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

// Utils
import TooltipConditional from '../../../components/Tooltip/TooltipConditional';
import { statusesArticles } from '../../../constants/statuses';
import { editBasicArticle } from '../../../redux/actions/articles';

// Components
import Status from './Status';

const Actions = ({
  article,
  onDeleteArticle,
  runBeforeClose,
  onEditArticle,
  editNewsStatus,
  t,
}) => {
  const { status, id: newsId } = article;
  const isMobile = true;
  const onDelete = articleId =>
    onDeleteArticle(articleId).then(result => {
      // eslint-disable-next-line no-prototype-builtins
      const isError = result && 'isAxiosError' in result;

      if (!isError) runBeforeClose();
    });
  const onEdit = e => onEditArticle(e, article);
  const isDisabled = !article.is_artificial;

  const onSwitchStatus = () =>
    editNewsStatus({
      newsId,
      status:
        status === statusesArticles.STATUS_ACTIVE
          ? statusesArticles.STATUS_DISABLED
          : statusesArticles.STATUS_ACTIVE,
    });

  return (
    <div className="articles-list__actions">
      <div className="articles-list__date">
        <Tooltip title={t('basic.dateCreation')}>
          {moment(article.created).format('DD/MM')}
        </Tooltip>
      </div>

      <Status
        className="articles-list__status"
        status={article.status}
        tooltip={t(`status.${article.status}`)}
        onSwitchStatus={onSwitchStatus}
      />

      <Button.Group>
        <Button
          className="button--inverted button--blue"
          href={article.url}
          target="_blank"
        >
          <Icon type="link" />
          {!isMobile && t('basic.proceed')}
        </Button>

        <TooltipConditional
          withTooltip={isDisabled}
          defaultVisible={false}
          placement="topRight"
          title={t('tooltip.onlyArtificialArticle')}
        >
          <Button
            type="default"
            disabled={isDisabled}
            className="button--inverted"
            target="_blank"
            onClick={onEdit}
            style={{
              background: isDisabled ? '#f5f5f5' : '#fff',
              borderColor: isDisabled ? '#d9d9d9' : 'rgba(170, 170, 169, 0.5)',
            }}
          >
            <Icon type="edit" />
            {!isMobile && t('basic.edit')}
          </Button>
        </TooltipConditional>

        <Popconfirm
          disabled={isDisabled}
          placement="topRight"
          title={t('basic.confirmBasic')}
          onConfirm={() => onDelete(article.id)}
          okText={t('basic.delete')}
          cancelText={t('basic.cancel')}
        >
          <TooltipConditional
            withTooltip={isDisabled}
            defaultVisible={false}
            placement="topRight"
            title={t('tooltip.onlyArtificialArticle')}
          >
            <Button
              disabled={isDisabled}
              className="button--inverted button--red"
            >
              <Icon type="delete" />
              {!isMobile && ` ${t('basic.delete')}`}
            </Button>
          </TooltipConditional>
        </Popconfirm>
      </Button.Group>
    </div>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  editNewsStatus: editBasicArticle,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Actions));
