import { getSuccessStatus } from '../helpers/statuses';
import * as T from '../types';

import { monthEarlierDate, todayDate } from '../../utils/date';
import { convertAllKeysToChartsData } from '../../services/formatter';

const initialState = {
  info: {},
  news: [],
  chart: {},
  table: {
    total: 0,
    limit: 20,
    offset: 0,
    start: +monthEarlierDate,
    end: +todayDate,
    data: [],
    isLoading: false,
  },
  history: {
    data: [],
    total: 0,
  },
  statisticsChart: {},
  statisticsTable: {
    isLoading: false,
    total: 0,
    limit: 20,
    offset: 0,
    start: +monthEarlierDate,
    end: +todayDate,
    data: [],
  },
  statisticsBasic: {},
};

export default function site(state = initialState, action = {}) {
  switch (action.type) {
    // label SITE
    case T.SUCCESS_FETCH_SITE:
      return {
        ...state,
        info: action.payload.data,
      };

    case T.SUCCESS_FETCH_SITE_NEWS:
      return {
        ...state,
        news: action.payload.news,
      };

    // label CHART
    case T.SUCCESS_FETCH_CHART_INFORMERS:
      return {
        ...state,
        chart: convertAllKeysToChartsData(action.payload.data),
      };

    // label TABLE
    case T.SUCCESS_FETCH_TABLE_INFORMERS:
      return {
        ...state,
        table: {
          ...state.table,
          ...action.payload,
          isLoading: false,
        },
      };

    // label SITE STATISTICS CHART
    case T.SUCCESS_FETCH_CHART_SITE_STATISTICS:
      return {
        ...state,
        statisticsChart: convertAllKeysToChartsData(action.payload.data, true),
      };

    // label SITE STATISTICS TABLE
    case T.START_FETCH_TABLE_SITE_STATISTICS:
      return {
        ...state,
        statisticsTable: { ...state.statisticsTable, isLoading: true },
      };

    case T.SUCCESS_FETCH_TABLE_SITE_STATISTICS:
      return {
        ...state,
        statisticsTable: {
          ...state.statisticsTable,
          ...action.payload,
          isLoading: false,
        },
      };

    case getSuccessStatus(T.EDIT_SITE_FORM):
      return {
        ...state,
        info: action.payload.data,
      };

    case getSuccessStatus(T.FETCH_SITE_STATUSES_HISTORY):
      return {
        ...state,
        history: action.payload,
      };

    case getSuccessStatus(T.FETCH_PARSING_ERRORS):
      return {
        ...state,
        parsingErrors: action.payload,
      };

    case getSuccessStatus(T.FETCH_SCRIPT_CHECKS_LOG):
      return {
        ...state,
        scriptsChecks: action.payload,
      };

    case getSuccessStatus(T.FETCH_SITES_LIST):
      return {
        ...state,
        statisticsBasic: {
          ...state.statisticsBasic,
          ...action.payload,
        },
      };

    case T.FAILURE_FETCH_TABLE_SITE_STATISTICS:
      return {
        ...state,
        statisticsTable: { ...state.statisticsTable, isLoading: false },
      };

    default:
      return state;
  }
}
