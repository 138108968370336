// ARTICLES
// -------------------------------------------------------------

export const START_CREATE_ARTICLE = 'START_CREATE_ARTICLE';
export const SUCCESS_CREATE_ARTICLE = 'SUCCESS_CREATE_ARTICLE';
export const FAILURE_CREATE_ARTICLE = 'FAILURE_CREATE_ARTICLE';

export const START_FETCH_ARTICLES_LIST = 'START_FETCH_ARTICLES_LIST';
export const SUCCESS_FETCH_ARTICLES_LIST = 'SUCCESS_FETCH_ARTICLES_LIST';
export const FAILURE_FETCH_ARTICLES_LIST = 'FAILURE_FETCH_ARTICLES_LIST';

export const START_FETCH_ARTICLE_POSITION = 'START_FETCH_ARTICLE_POSITION';
export const SUCCESS_FETCH_ARTICLE_POSITION = 'SUCCESS_FETCH_ARTICLE_POSITION';
export const FAILURE_FETCH_ARTICLE_POSITION = 'FAILURE_FETCH_ARTICLE_POSITION';

export const START_FETCH_SEARCH_ARTICLES = 'START_FETCH_SEARCH_ARTICLES';
export const SUCCESS_FETCH_SEARCH_ARTICLES = 'SUCCESS_FETCH_SEARCH_ARTICLES';
export const FAILURE_FETCH_SEARCH_ARTICLES = 'FAILURE_FETCH_SEARCH_ARTICLES';

export const START_FETCH_MODERATION_LIST = 'START_FETCH_MODERATION_LIST';
export const SUCCESS_FETCH_MODERATION_LIST = 'SUCCESS_FETCH_MODERATION_LIST';
export const FAILURE_FETCH_MODERATION_LIST = 'FAILURE_FETCH_MODERATION_LIST';

export const START_FETCH_TABLE_ARTICLES_STATISTICS =
  'START_FETCH_TABLE_ARTICLES_STATISTICS';
export const SUCCESS_FETCH_TABLE_ARTICLES_STATISTICS =
  'SUCCESS_FETCH_TABLE_ARTICLES_STATISTICS';
export const FAILURE_FETCH_TABLE_ARTICLES_STATISTICS =
  'FAILURE_FETCH_TABLE_ARTICLES_STATISTICS';

// PROMOTION ARTICLES
// -------------------------------------------------------------

export const START_PROMOTE_ARTICLE = 'START_PROMOTE_ARTICLE';
export const SUCCESS_PROMOTE_ARTICLE = 'SUCCESS_PROMOTE_ARTICLE';
export const FAILURE_PROMOTE_ARTICLE = 'FAILURE_PROMOTE_ARTICLE';

export const START_PROMOTE_ARTICLE_FOR_ROTATOR =
  'START_PROMOTE_ARTICLE_FOR_ROTATOR';
export const SUCCESS_PROMOTE_ARTICLE_FOR_ROTATOR =
  'SUCCESS_PROMOTE_ARTICLE_FOR_ROTATOR';
export const FAILURE_PROMOTE_ARTICLE_FOR_ROTATOR =
  'FAILURE_PROMOTE_ARTICLE_FOR_ROTATOR';

// CUSTOM ARTICLES
// -------------------------------------------------------------

export const START_FETCH_CUSTOM_ARTICLES = 'START_FETCH_CUSTOM_ARTICLES';
export const SUCCESS_FETCH_CUSTOM_ARTICLES = 'SUCCESS_FETCH_CUSTOM_ARTICLES';
export const FAILURE_FETCH_CUSTOM_ARTICLES = 'FAILURE_FETCH_CUSTOM_ARTICLES';

export const START_FETCH_CUSTOM_ARTICLES_ROTATOR =
  'START_FETCH_CUSTOM_ARTICLES_ROTATOR';
export const SUCCESS_FETCH_CUSTOM_ARTICLES_ROTATOR =
  'SUCCESS_FETCH_CUSTOM_ARTICLES_ROTATOR';
export const FAILURE_FETCH_CUSTOM_ARTICLES_ROTATOR =
  'FAILURE_FETCH_CUSTOM_ARTICLES_ROTATOR';

export const START_FETCH_INFORMER_CUSTOM_ARTICLES =
  'START_FETCH_INFORMER_CUSTOM_ARTICLES';
export const SUCCESS_FETCH_INFORMER_CUSTOM_ARTICLES =
  'SUCCESS_FETCH_INFORMER_CUSTOM_ARTICLES';
export const FAILURE_FETCH_INFORMER_CUSTOM_ARTICLES =
  'FAILURE_FETCH_INFORMER_CUSTOM_ARTICLES';

export const START_FETCH_ADD_CUSTOM_ARTICLE = 'START_FETCH_ADD_CUSTOM_ARTICLE';
export const SUCCESS_FETCH_ADD_CUSTOM_ARTICLE =
  'SUCCESS_FETCH_ADD_CUSTOM_ARTICLE';
export const FAILURE_FETCH_ADD_CUSTOM_ARTICLE =
  'FAILURE_FETCH_ADD_CUSTOM_ARTICLE';

export const START_EDIT_ARTICLE = 'START_EDIT_ARTICLE';
export const SUCCESS_EDIT_ARTICLE = 'SUCCESS_EDIT_ARTICLE';
export const FAILURE_EDIT_ARTICLE = 'FAILURE_EDIT_ARTICLE';
