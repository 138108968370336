import styled from 'styled-components';
import { Button, Input } from 'antd';
import { Box } from '../../styles/js/base';
import { colorBlue } from '../../styles/js/variables/colors';

export const CodeBlockWrapper = styled(Box)`
  height: ${({ isVisible }) => (isVisible ? 'auto' : '0')};
  padding: ${({ isVisible }) => (isVisible ? '1em' : '0')};
  overflow: hidden;
  opacity: ${({ isVisible }) => Number(isVisible)};
  transition: height 0.25s, opacity 0.25s;
`;

export const CodeBlockDescription = styled.div``;

export const CodeBlockInput = styled(Input.TextArea)`
  z-index: 2;
  position: relative;
  background-color: #f5f5f5;
  padding-right: 50px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

export const CodeBlockTest = styled.div`
  z-index: -1;
  position: absolute;
  visibility: hidden;
  height: auto;
  width: auto;
  padding-right: 50px;
  padding-left: 4px;
`;

export const CodeBlockButton = styled(Button)`
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  color: ${colorBlue};
  background: rgba(44, 158, 180, 0.1);
  border-color: rgba(44, 158, 180, 0.4);
  height: 100%;
  padding: 2px 2px 2px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    border-color: rgba(44, 158, 180, 0.4);
  }

  span {
    width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
  }
`;

export const CodeBlockInputWrapper = styled.div`
  position: relative;
  padding-right: 58px;
  margin-top: 10px;
`;
