import React, { Fragment } from 'react';
import { func, number } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Utils
import CodeBlock from '../../CodeBlock';

// Components

const CodeBlockModal = props => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <h3>{t('blocks.scriptAdd.title')}</h3>
      <p>{t('blocks.scriptAdd.description')}</p>
      <CodeBlock {...props} />
    </Fragment>
  );
};

CodeBlockModal.propTypes = {
  informerId: number,
  t: func,
};

CodeBlockModal.defaultProps = {};

export default CodeBlockModal;
