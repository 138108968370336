import React, { useCallback, useEffect, useState } from 'react';
import locale from 'antd/es/date-picker/locale/ru_RU';
import { DatePicker, InputNumber, Select } from 'antd';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { debounce } from 'lodash';

import { moment } from 'utils/date';
import { devicesList } from '../../../constants/devices';

const { RangePicker } = DatePicker;
const { Option } = Select;

const StatisticsFilter = props => {
  const {
    sitesListWithInformers = [],
    fetchData,
    isRotator,
    onChange,
    siteId,
    abTest,
    from,
    to,
    t,
  } = props;

  const [informers, setInformers] = useState([]);

  const getInformers = useCallback(
    id => {
      const filtered = sitesListWithInformers.filter(site => site.id === id);
      return filtered.length ? filtered[0].informers : [];
    },
    [sitesListWithInformers],
  );

  useEffect(() => setInformers(getInformers(siteId)), [getInformers, siteId]);

  return (
    <div className="statistics-block__filter">
      <RangePicker
        allowClear={false}
        style={{ marginBottom: 20, minWidth: 400 }}
        showTime={{ format: 'HH:mm' }}
        format="DD.MM.YYYY, HH:mm"
        onChange={([start, end]) => onChange({ from: start, to: end })}
        onOk={fetchData}
        locale={locale}
        value={[from, to]}
        ranges={{
          [t('dates.today')]: [moment().startOf('day'), moment()],
          [t('dates.thisWeek')]: [moment().startOf('week'), moment()],
          [t('dates.thisMonth')]: [moment().startOf('month'), moment()],
        }}
      />
      <div>
        {/* label: SITES */}
        &nbsp;
        <Select
          defaultValue={null}
          placeholder={`-- ${t('basic.site')} --`}
          onChange={value => onChange({ siteId: value })}
          style={{ minWidth: 120 }}
        >
          <Option value={null}>
            {`-- ${t('basic.all')} ${t('basic.sites').toLowerCase()} --`}
          </Option>
          {sitesListWithInformers.map(({ id, name }) => (
            <Option key={id} value={id}>
              {name}
            </Option>
          ))}
        </Select>
        {/* label: INFORMERS */}
        &nbsp;
        <Select
          defaultValue={null}
          disabled={!siteId || (siteId && !informers.length)}
          onChange={value => onChange({ informerId: value })}
          style={{ minWidth: 120 }}
        >
          <Option value={null}>
            {`-- ${t('basic.all')} ${t('basic.informers').toLowerCase()} --`}
          </Option>
          {informers &&
            informers.map(({ id, name }) => (
              <Option key={id} value={id}>
                {name}
              </Option>
            ))}
        </Select>
        {/* label: DEVICE */}
        &nbsp;
        <Select
          defaultValue={null}
          onChange={value => onChange({ deviceType: value })}
          style={{ minWidth: 120 }}
        >
          <Option value={null}>
            {`-- ${t('basic.all')} ${t('basic.devices').toLowerCase()} --`}
          </Option>
          {devicesList.map((deviceType, i) => (
            <Option key={i} value={deviceType}>
              {t(`device.${deviceType}`)}
            </Option>
          ))}
        </Select>
        {/* label: A/B tests */}
        &nbsp;
        {isRotator && (
          <InputNumber
            defaultValue={abTest}
            placeholder="А/В-test.."
            onChange={debounce(value => onChange({ abTest: value }), 300)}
            style={{ minWidth: 120 }}
          />
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    sitesList: state.dataLists.sitesList,
    sitesListWithInformers: state.dataLists.sitesListWithInformers,
  };
}

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(StatisticsFilter));
