import { Icon, Badge } from 'antd';
import React from 'react';

export const SubMenuTitle = ({ icon, qtyMark, title }) => (
  <div>
    <Icon type={icon} />
    <Badge dot={qtyMark > 0}>
      <span>{title}</span>
    </Badge>
  </div>
);
