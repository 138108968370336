import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddSiteForm from '../../../components/Form/forms/AddSiteForm';
import Loader from '../../../components/Loader';
import { editSite } from '../../../redux/actions/site';
import { getStartStatus } from '../../../redux/helpers/statuses';
import { EDIT_SITE_FORM } from '../../../redux/types';

export const SiteSettings = () => {
  const dispatch = useDispatch();
  const siteData = useSelector(s => s.site.info);
  const network = useSelector(s => s.network);
  const isLoading = network[getStartStatus(EDIT_SITE_FORM)];

  const onSubmit = data => editSite(data)(dispatch);

  return (
    <>
      <Loader isLoading={isLoading} />
      {siteData.id && (
        <AddSiteForm formData={siteData} onSubmit={onSubmit} isSaving={false} />
      )}
    </>
  );
};
