import React, { useState } from 'react';
import { string, oneOfType, element, shape, func } from 'prop-types';
import { Button, Popover } from 'antd';
import { colorBlue } from '../../../styles/js/variables/colors';
import { getShownPopups, setShownPopups } from '../../../services/localStorage';

const TableNotice = ({
  iconComponent: Icon,
  color = colorBlue,
  title = 'info',
  buttonProps,
  buttonText,
  storageKey,
  iconProps,
  text,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const shownPopups = getShownPopups();

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
    setShownPopups({ ...shownPopups, [storageKey]: true });
  };

  const handleButtonClick = () => {
    toggleVisibility();
    buttonProps.onClick();
  };

  return (
    <Popover
      title={title && <b style={{ color }}>{title}</b>}
      content={
        <div style={{ maxWidth: '300px' }}>
          {text}&nbsp;
          {buttonText && (
            <Button {...buttonProps} onClick={handleButtonClick}>
              {buttonText}
            </Button>
          )}
        </div>
      }
      trigger="click"
      onVisibleChange={toggleVisibility}
      visible={isVisible}
    >
      <Icon
        {...iconProps}
        className="look-at-me"
        style={{
          color,
          cursor: 'pointer',
          fontSize: iconProps ? 'inherit' : '1.125em',
        }}
      />
    </Popover>
  );
};

TableNotice.propTypes = {
  storageKey: string.isRequired,
  text: oneOfType([string, element]).isRequired,
  title: string,
  color: string,
  iconComponent: func,
  iconProps: shape({}),
  buttonProps: shape({}),
  buttonText: string,
};

export default TableNotice;
