import React from 'react';
import { func } from 'prop-types';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import { Button, Icon, List, Select } from 'antd';

// Utils
import { languagesList } from '../../../config/i18n';

const MenuList = ({ logout, isAuth, t }) => {
  const handleLanguageSwitch = language => i18next.changeLanguage(language);

  return (
    <List
      dataSource={[
        {
          title: t('header.language'),
          action: (
            <Select
              placeholder="---"
              onChange={handleLanguageSwitch}
              defaultValue={i18next.language}
              style={{ minWidth: '100px' }}
            >
              {Object.entries(languagesList).map(([el, { key, title }]) => (
                <Select.Option key={el} value={key}>
                  {t(`languages.${key}`)}
                </Select.Option>
              ))}
            </Select>
          ),
        },
        {
          title: t('header.leaveSite'),
          action: (
            <Button type="primary" onClick={logout}>
              <Icon type="logout" /> {t('header.logout')}
            </Button>
          ),
          isHidden: !isAuth,
        },
      ]}
      renderItem={({ title, action, isHidden }) =>
        isHidden ? (
          <></>
        ) : (
          <List.Item
            actions={[action]}
            style={{ justifyContent: 'space-between' }}
          >
            <span>{title}</span>
          </List.Item>
        )
      }
    />
  );
};

MenuList.propTypes = {
  t: func,
  logout: func,
};

export default withTranslation()(MenuList);
