import React from 'react';
import { FormSwitchStyled, FormSwitchWrapper } from './styles';

const FormSwitch = ({
  children,
  className,
  name,
  label,
  checked,
  ...otherProps
}) => {
  const { onChange } = otherProps;

  const handleChange = (isChecked, event) => {
    onChange({
      ...event,
      target: { ...event.target, checked: isChecked, name, value: isChecked },
    });
  };

  return (
    <FormSwitchWrapper checked={checked}>
      <FormSwitchStyled
        {...otherProps}
        onClick={() => {}}
        checked={checked}
        onChange={handleChange}
      />
      {label}
    </FormSwitchWrapper>
  );
};

export default FormSwitch;
