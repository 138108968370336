import { useCallback, useEffect, useState } from 'react';
import { string } from 'prop-types';
import { types } from '../../../config/routePaths';

// prettier-ignore
const submenus = {
  [types.AB_TESTS]:                   `sub-${types.AB_TESTS}`,
  [types.ARTICLES]:                   `sub-${types.ARTICLES}`,
  [types.ARTICLES_STATISTICS]:        `sub-${types.ARTICLES}`,
  [types.ARTICLE_PROMOTION]:          `sub-${types.ARTICLE_PROMOTION}`,
  [types.AB_TESTS_STATISTICS]:        `sub-${types.GLOBAL_STATISTICS}`,
  [types.GLOBAL_STATISTICS]:          `sub-${types.GLOBAL_STATISTICS}`,
  [types.STATISTICS_BY_TYPE]:         `sub-${types.GLOBAL_STATISTICS}`,
  [types.SITES_LIST]:                 `sub-${types.SITES}`,
  [types.SITES_MODERATION]:           `sub-${types.SITES}`,
};

const allSubmenusUnique = Object.values(submenus).reduce((acc, key) => {
  if (!acc[key]) acc.push(key);
  return acc;
}, []);

const useOpenedMenu = ({ currentPage }) => {
  const [openedSubmenus, setOpenedSubmenu] = useState(
    submenus[currentPage] || [],
  );

  const handleOpenedSubmenus = useCallback(
    () => submenus[currentPage] && setOpenedSubmenu(submenus[currentPage]),
    [currentPage],
  );

  useEffect(handleOpenedSubmenus, [currentPage]);

  // Now we should show all submenus
  return allSubmenusUnique || openedSubmenus;
};

useOpenedMenu.propTypes = {
  currentPage: string,
};

export default useOpenedMenu;
