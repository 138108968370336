import React from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Form, Input, Row } from 'antd';

import { mustBeEmail, mustBeFilled } from '../../../config/validationRules';
import useValidation from '../utils/useValidation';
import FormActions from '../components/FormActions';

const initialForm = {
  name: { value: '', rules: [mustBeFilled] },
  email: { value: '', rules: [mustBeFilled, mustBeEmail] },
  login: { value: '', rules: [mustBeFilled] },
  password: { value: '', rules: [] },
};

const AddPublisherForm = ({
  formData,
  onSubmit,
  onCancel,
  isSaving,
  isLoading,
  t,
}) => {
  const {
    errors,
    currentForm,
    bindedSubmit,
    bindedInputFunctions,
  } = useValidation(onSubmit, initialForm, formData);

  return (
    <Form>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12}>
          <Form.Item {...errors.name} label={t('form.nameLabel')}>
            <Input
              name="name"
              placeholder={t('form.namePlaceholder')}
              defaultValue={currentForm.name}
              {...bindedInputFunctions}
            />
          </Form.Item>
          <Form.Item {...errors.email} label={t('form.emailLabel')}>
            <Input
              name="email"
              placeholder={t('form.emailPlaceholder')}
              defaultValue={currentForm.email}
              {...bindedInputFunctions}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item {...errors.login} label={t('form.loginLabel')}>
            <Input
              name="login"
              placeholder={t('form.loginPlaceholder')}
              defaultValue={currentForm.login}
              {...bindedInputFunctions}
            />
          </Form.Item>
          <Form.Item {...errors.password} label={t('form.passwordLabel')}>
            <Input.Password
              name="password"
              placeholder={t('form.passwordPlaceholder')}
              defaultValue={currentForm.password}
              {...bindedInputFunctions}
            />
          </Form.Item>
        </Col>
      </Row>

      <br />
      <FormActions
        isLoading={isSaving || isLoading}
        style={{ textAlign: 'right' }}
        titleSubmit="Сохранить"
        onSubmit={bindedSubmit}
        onCancel={onCancel}
      />
    </Form>
  );
};

export default withTranslation('common')(AddPublisherForm);
