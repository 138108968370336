import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Button, Icon } from 'antd';

// Redux
import {
  choiceInformer,
  resetInformerStylesLocal,
} from '../../redux/actions/informerSettings';
import { fetchSite, fetchChart, fetchTable } from '../../redux/actions/site';
import { showModal } from '../../redux/actions/modals';
import { deleteInformer } from '../../redux/actions/informer';

// Utils
import {
  getDaysAgoRange,
  getDaysRange,
  datesArrayToUnix,
} from '../../utils/date';
import { colorBlue, colorYellow } from '../../styles/js/variables/colors';

// Components
import Table from '../../components/Table';
import LinearChart from '../../components/LinearChart';
import { siteHeader } from '../../components/Table/config/tables';
import PageTitle from '../../components/PageTitle';
import LinkWithTag from '../../components/LinkWithTag';
import TooltipConditional from '../../components/Tooltip/TooltipConditional';
import routePaths, { getFilledRoutePath } from '../../config/routePaths';

class InformersList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      calendarDates: getDaysAgoRange(),
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { siteId },
      },
    } = this.props;

    this.props.fetchSite(siteId);
    this.handleFetchSiteRequest(datesArrayToUnix(getDaysAgoRange()));
  }

  handleFetchSiteRequest = dates => {
    const { props } = this;
    const { table } = props;
    const {
      match: {
        params: { siteId },
      },
    } = props;
    const { limit, offset } = table;

    this.props.fetchChart({
      siteId,
      start: +dates[0],
      end: +dates[1],
    });

    this.props.fetchTable({
      siteId,
      limit,
      offset,
      start: +dates[0],
      end: +dates[1],
    });

    this.setState({
      calendarDates: getDaysRange(dates),
    });
  };

  handleFetch = (page, sortBy, sortDirection) => {
    const { props } = this;
    const { table } = props;
    const {
      match: {
        params: { siteId },
      },
    } = props;
    const { limit, start, end } = table;
    const offset = (page - 1) * limit;

    props.fetchTable({
      siteId,
      limit,
      offset,
      start,
      end,
      sortBy,
      sortDirection,
    });
  };

  editInformer = ({ id }) => `${this.props.match.url}/${id}/edit`;

  handleAddInformer = () => {
    const { history, match } = this.props;

    this.props.resetInformerStylesLocal();

    const linkToInformerAdd = getFilledRoutePath({
      path: routePaths.INFORMER_ADD,
      params: match.params,
    });

    history.push(linkToInformerAdd);
  };

  handleDeleteInformer = ({ id }) => {
    const { start, end } = this.props.table;

    this.props
      .deleteInformer(id)
      .then(() => this.handleFetchSiteRequest([start, end]));
  };

  handleLinkInformer = row => `${this.props.match.url}/${row.id}`;

  handleShowTooltip = ({ id, ...otherProps }) => {
    this.props.showModal({
      type: 'MODAL_CODE_BLOCK',
      params: {
        informerId: id,
        siteId: this.props.info.id,
        ...otherProps,
      },
    });
  };

  render() {
    const { props, state } = this;
    const { table, info, isChartLoading, isTableLoading, isMobile, t } = props;
    const { name } = info;
    const isEmptyTable = table.data.length === 0 && table.total === 0;
    const pathToSiteStatistics = `${this.props.match.url}/statistics`;

    return (
      <div className="page-body main-page">
        {name && (
          <PageTitle
            title={`${t('content.title.siteInformers')}: "${info.name ||
              '...'}"`}
            hint={t('content.titleHint.siteInformers')}
            button={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <LinkWithTag
                  isButton
                  href={pathToSiteStatistics}
                  linkProps={{
                    type: 'default',
                  }}
                >
                  <Icon type="line-chart" style={{ color: colorBlue }} />
                  {!isMobile && t('form.buttonSiteStatistics')}
                </LinkWithTag>
                &nbsp;
                <TooltipConditional
                  withTimeout
                  isHiddenByDefault={false}
                  withTooltip={!isTableLoading && isEmptyTable}
                  defaultVisible={isEmptyTable}
                  placement="bottomRight"
                  title={t('tooltip.emptyListAddItem', {
                    entity: t('basic.informers_5').toLowerCase(),
                  })}
                  color={colorYellow}
                >
                  <Button type="success" onClick={this.handleAddInformer}>
                    <Icon type="plus" />
                    {`${t('basic.add')} ${t('basic.informer').toLowerCase()}`}
                  </Button>
                </TooltipConditional>
              </div>
            }
          />
        )}

        <LinearChart
          isShowChart={!!table.data.length}
          isLoading={isChartLoading}
          categoriesForX={state.calendarDates}
          onChange={this.handleFetchSiteRequest}
          data={props.chart}
          showKeys={['views', 'clickOn', 'clickFrom', 'coefficient']}
        />
        <Table
          isLoading={isTableLoading}
          headers={siteHeader}
          data={table.data}
          total={table.total}
          limit={table.limit}
          fetch={this.handleFetch}
          actions={{
            code: this.handleShowTooltip,
            editInformer: this.editInformer,
            page: this.handleLinkInformer,
            delete: this.handleDeleteInformer,
          }}
          type="siteActions"
        />
      </div>
    );
  }
}

InformersList.defaultProps = {
  fetchSite: () => {},
  fetchChart: () => {},
  fetchTable: () => {},
  deleteInformer: () => {},
};

InformersList.propTypes = {
  fetchSite: PropTypes.func,
  fetchChart: PropTypes.func,
  fetchTable: PropTypes.func,
  deleteInformer: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({}).isRequired,
    url: PropTypes.string,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    info: state.site.info,
    chart: state.site.chart,
    table: state.site.table,
    isChartLoading: state.site.chart.isLoading,
    isTableLoading: state.site.table.isLoading,
    isMobile: state.userSettings.isMobile,
  };
}

const mapDispatchToProps = {
  showModal,
  fetchSite,
  fetchChart,
  fetchTable,
  deleteInformer,
  choiceInformer,
  resetInformerStylesLocal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(InformersList));
