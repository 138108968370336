import React from 'react';
import { connect } from 'react-redux';
import { List } from 'antd';
import {
  fetchNewsCategoriesList,
  fetchSitesList,
} from '../../../redux/actions/dataLists';
import {
  START_FETCH_NEWS_CATEGORIES_LIST,
  START_FETCH_SITES_LIST,
} from '../../../redux/types/dataLists';
import { START_CREATE_ARTICLE } from '../../../redux/types/articles';
import { createArticle } from '../../../redux/actions/articles';
import PureArticlesListItem from './PureArticlesListItem';
import EmptyData from '../../../components/EmptyData';

const PureArticlesList = props => {
  const {
    articlesList,
    isLoading,
    pagination,
    runBeforeClose,
    onEditArticle,
  } = props;

  return (
    <List
      className="articles-list"
      itemLayout="vertical"
      dataSource={articlesList}
      size="small"
      loading={isLoading}
      renderItem={article => (
        <PureArticlesListItem
          key={article.id}
          article={article}
          runBeforeClose={runBeforeClose}
          onEditArticle={onEditArticle}
        />
      )}
      pagination={pagination}
      locale={{ emptyText: <EmptyData /> }}
    />
  );
};

const mapStateToProps = state => ({
  dataLists: state.dataLists,
  isNewsCategoriesListLoading: state.network[START_FETCH_NEWS_CATEGORIES_LIST],
  isSitesListLoading: state.network[START_FETCH_SITES_LIST],
  isCreateArticleLoading: state.network[START_CREATE_ARTICLE],
});

const mapDispatchToProps = {
  fetchNewsCategoriesList,
  fetchSitesList,
  createArticle,
};

export default connect(mapStateToProps, mapDispatchToProps)(PureArticlesList);
