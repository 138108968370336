import {
  transform,
  isArray,
  camelCase,
  snakeCase,
  isObject,
  map,
} from 'lodash';

export const replaceObjectByKeys = (prev, next) => {
  const result = prev;

  Object.entries(next).forEach(([key, value]) => {
    result[key] = isObject(value)
      ? replaceObjectByKeys(result[key], value)
      : value;
  });

  return result;
};

const camelize = obj =>
  transform(obj, (acc, value, key, target) => {
    const camelKey = isArray(target) ? key : camelCase(key);

    acc[camelKey] = isObject(value) ? camelize(value) : value;
  });

const snakelize = obj =>
  transform(obj, (acc, value, key, target) => {
    const camelKey = isArray(target) ? key : snakeCase(key);

    acc[camelKey] = isObject(value) ? snakelize(value) : value;
  });

const convertDataTo = (data, handler) => {
  if (!data) return data;

  let result;

  if (Array.isArray(data)) {
    result = map(data, handler);
  } else if (isObject(data)) {
    result = handler(data);
  } else {
    result = data;
  }

  return result;
};

export const convertDataToCamelCase = data => convertDataTo(data, camelize);
export const convertDataToSnakeCase = data => convertDataTo(data, snakelize);
