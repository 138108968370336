import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Modal } from 'antd';

const StyledModal = styled(Modal)`
  .ant-modal-footer {
    display: block;
  }
`;

export const UserLeaveConfirmationModal = ({
  content,
  onSubmit,
  isVisible,
  ...restProps
}) => {
  const { t } = useTranslation();
  return (
    <StyledModal
      centered
      onOk={onSubmit}
      visible={isVisible}
      okText={t('form.buttonProceed')}
      cancelText={t('form.buttonCancel')}
      {...restProps}
    >
      {content}
    </StyledModal>
  );
};
