import React, { Component } from 'react';
import { connect } from 'react-redux';

import { fetchTable } from 'redux/actions/informerByDate';

import { getDayUnix } from 'utils/date';
import PageTitle from '../../components/PageTitle';
import Table from '../../components/Table';
import { informerByDateHeader } from '../../components/Table/config/tables';
import { withTranslation } from 'react-i18next';

class InformerByDate extends Component {
  componentDidMount() {
    const { props } = this;
    const {
      match: {
        params: { date },
      },
    } = props;

    this.handleUpdateAll(getDayUnix(date));
  }

  handleUpdateAll = dates => {
    const { props } = this;
    const {
      match: {
        params: { informerId, publisherId },
      },
    } = props;
    const { limit, offset } = props.table;

    props.fetchTable({
      limit,
      offset,
      start: +dates[0],
      end: +dates[1],
      informerId,
      publisherId,
    });
  };

  handleFetch = (page, sortBy, sortDirection) => {
    const { props } = this;
    const { table } = props;
    const {
      match: {
        params: { informerId, publisherId },
      },
    } = props;
    const { limit, start, end } = table;
    const offset = (page - 1) * limit;

    // todo ID
    props.fetchTable({
      informerId,
      publisherId,
      limit,
      offset,
      start,
      end,
      sortBy,
      sortDirection,
    });
  };

  handleLinkUrl = row => `news/${row.id}`;

  render() {
    const { props } = this;
    const {
      match: {
        params: { date },
      },
      table,
      t,
    } = props;
    const { isLoading } = table;

    return (
      <div className="page-body main-page">
        <PageTitle
          title={`${t('content.title.informerNewsStats')}: ${date}`}
          hint={t('content.titleHint.informerNewsStats')}
        />

        <Table
          isLoading={isLoading}
          headers={informerByDateHeader}
          data={props.table.data}
          total={props.table.total}
          limit={props.table.limit}
          fetch={this.handleFetch}
          actions={{
            page: this.handleLinkUrl,
          }}
          type="informerByDateActions"
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    table: state.informerByDate.table,
  };
}

const mapDispatchToProps = {
  fetchTable,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(InformerByDate));
