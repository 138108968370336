import { useCallback, useEffect, useState, useMemo } from 'react';
import { string } from 'prop-types';
import routesPath from '../../../config/routePaths';

const useCurrentPage = ({ pathname }) => {
  const page = useMemo(
    () =>
      Object.keys(routesPath).filter(path => {
        const pattern = `^(${routesPath[path]})$`;
        const regexp = new RegExp(pattern);
        return regexp.test(pathname) ? path : null;
      })[0],
    [pathname],
  );

  const [currentPage, setCurrentPage] = useState(page);

  const handleSetCurrentPage = useCallback(() => setCurrentPage(page), [page]);

  useEffect(handleSetCurrentPage, [pathname]);

  return currentPage;
};

useCurrentPage.propTypes = {
  pathname: string,
};

export default useCurrentPage;
