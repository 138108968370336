import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { bool, number, string, oneOfType } from 'prop-types';
import { CodeInput } from './components/CodeInput';
import { CodeToggler } from './components/CodeToggler';
import { useCodesGenerator } from './helpers/useCodesGenerator';

// Styles
import { CodeBlockWrapper } from './styles';

// Utils
import { colorYellow } from '../../styles/js/variables/colors';

const CodeBlock = props => {
  const {
    informerId = '',
    siteId = '',
    isCollapsible = null,
    domain,
    isAmp,
  } = props;
  const { t } = useTranslation();
  const [isVisible, toggleVisibility] = useReducer(
    state => !state,
    isCollapsible !== true,
  );
  const codes = useCodesGenerator({ siteId, informerId, siteDomain: domain });

  // Todo: tied part
  const basicHtml = t('blocks.scriptAdd.tagDescription', {
    place: informerId ? 'HTML' : '<body></body>',
    color: colorYellow,
  });
  const basicCode = informerId ? codes.informer : codes.scriptUnique;

  return (
    <>
      <CodeToggler
        isVisible={isVisible}
        toggleVisibility={toggleVisibility}
        isCollapsible={isCollapsible}
      />

      <CodeBlockWrapper isVisible={isVisible}>
        <CodeInput html={basicHtml} code={basicCode} />
      </CodeBlockWrapper>

      {/* LABEL: AMP */}
      {(isAmp || !!informerId) && isVisible && (
        <>
          <br />
          <h5>{t('blocks.scriptAdd.ampTitle')}:</h5>
          <CodeBlockWrapper isVisible={isVisible}>
            <CodeInput
              html={t('blocks.scriptAdd.ampHeadScripts', {
                place: '<head></head>',
                color: colorYellow,
              })}
              code={codes.ampScript}
            />
            <br />
            <CodeInput html={basicHtml} code={codes.ampInformer} />
          </CodeBlockWrapper>
        </>
      )}
    </>
  );
};

CodeBlock.propTypes = {
  informerId: oneOfType([string, number]),
  siteId: oneOfType([string, number]),
  isCollapsible: bool,
};

export default CodeBlock;
