import React from 'react';
import { connect } from 'react-redux';
import { Avatar, List } from 'antd';
import { deleteArticle } from '../../../redux/actions/articles';
import Actions from './Actions';
import { colorGrey, colorGreyLight } from '../../../styles/js/variables/colors';

const PureArticlesListItem = props => {
  const { article, runBeforeClose, onEditArticle } = props;

  return (
    <List.Item
      className="articles-list__item"
      key={article.id}
      extra={[
        <Actions
          key={article.id}
          article={article}
          onDeleteArticle={props.deleteArticle}
          onEditArticle={onEditArticle}
          runBeforeClose={runBeforeClose}
        />,
      ]}
    >
      <div className="articles-list__text">
        <List.Item.Meta
          avatar={
            <Avatar
              src={article.img}
              icon="file-text"
              style={{ backgroundColor: colorGreyLight, color: colorGrey }}
            />
          }
          title={article.title}
          description={
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: article.description.replace(/<\/?script>/gi, ''),
              }}
            />
          }
        />
      </div>
    </List.Item>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  deleteArticle,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PureArticlesListItem);
