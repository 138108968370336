import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import AddPublisherForm from '../../Form/forms/AddPublisherForm';

const AddPublisherModal = ({
  fields,
  onClose,
  actions,
  success,
  isSaving,
  t,
}) => {
  const handleSubmit = form =>
    actions
      .onSubmit(form)
      .then(onClose)
      .then(success.onSubmit);

  return (
    <>
      <h3>
        {t(fields ? 'form.title.editPublisher' : 'form.title.addPublisher')}
      </h3>
      <AddPublisherForm
        isSaving={isSaving}
        formData={fields}
        onSubmit={handleSubmit}
        onCancel={onClose}
      />
    </>
  );
};

AddPublisherModal.propTypes = {
  onClose: PropTypes.func,
  success: PropTypes.shape({
    onSubmit: PropTypes.func,
  }),
};

AddPublisherModal.defaultProps = {
  onClose: () => {},
  success: {
    onSubmit: () => {},
  },
};

const mapStateToProps = ({ network }) => ({
  isSaving: network.START_EDIT_PUBLISHER,
});

export default connect(mapStateToProps)(withTranslation('')(AddPublisherModal));
