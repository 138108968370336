import React, { useState } from 'react';
import { connect } from 'react-redux';
import { func } from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import PageTitle from '../../components/PageTitle';

// Constants
import { statusesStyles, statusesSites as S } from '../../constants/statuses';
import { iStatusesList } from '../../prop-types';
import { START_FETCH_MODERATION_LIST } from '../../redux/types/articles';

// Components
import ModerationTable from './components/ModerationTable';
import ModerationTab from './components/ModerationTab';

const { TabPane } = Tabs;

const Moderation = ({ t, statusesList, ...otherProps }) => {
  const [currentTab, setCurrentTab] = useState(S.STATUS_ANALYTICS_SPEEDUP);
  const confirmationStatuses = [
    S.STATUS_PENDING,
    S.STATUS_ANALYTICS_SPEEDUP,
    S.STATUS_VERIFICATION,
    S.STATUS_SUSPENDED,
  ];

  const handleTabChange = activeKey => setCurrentTab(activeKey);

  return (
    <div className="page-body main-page">
      <PageTitle
        title={t('content.title.sitesModeration')}
        hint={t('content.titleHint.sitesModeration')}
      />
      <Tabs
        onChange={handleTabChange}
        defaultActiveKey={currentTab}
        type="card"
      >
        {confirmationStatuses.map(status => (
          <TabPane
            tab={
              <ModerationTab
                style={statusesStyles[status]}
                status={status}
                statusesList={statusesList}
                isMarked={
                  status !== S.STATUS_VERIFICATION &&
                  status !== S.STATUS_SUSPENDED
                }
              />
            }
            key={status}
          >
            <ModerationTable
              status={status}
              currentTab={currentTab}
              {...otherProps}
            />
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

Moderation.propTypes = {
  t: func.isRequired,
  statusesList: iStatusesList,
};

const mapStateToProps = state => ({
  statusesList: state.moderation.statuses,
  isLoading: state.network[START_FETCH_MODERATION_LIST],
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('common')(Moderation));
