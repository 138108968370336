import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ValidateInput from 'components/ValidateInput';
import ColorPicker from 'components/ColorPicker';

import './style.scss';

// TODO: fix bug when we pick color picker after error
class ValidateInputWithColorPicker extends Component {
  constructor(props) {
    super(props);

    const { defaultValue } = props;
    this.state = {
      value: defaultValue,
      isLastChangeSafe: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.controlled) {
      return {
        ...state,
        value: props.value,
      };
    }
    return null;
  }

  setValue = (value, isLastChangeSafe) =>
    this.setState({ value, isLastChangeSafe });

  onChange = value => {
    const { onChange } = this.props.inputValidation;

    this.setValue(value, false);
    onChange(value);
  };

  onDrag = value => {
    const { onDrag } = this.props.colorPicker;
    this.setValue(value, true);
    onDrag(value);
  };

  onComplete = value => {
    const { colorPicker } = this.props;
    const { onComplete } = colorPicker;

    this.setValue(value, true);
    onComplete(value);
  };

  render() {
    const { inputValidation, validateName } = this.props;
    const { placeholder, type, rules, onValidate } = inputValidation;
    const { value, isLastChangeSafe } = this.state;

    return (
      <div className="input-validate-colorpicker">
        <ValidateInput
          placeholder={placeholder}
          type={type}
          onChange={this.onChange}
          onValidate={onValidate}
          validateName={validateName}
          rules={rules}
          defaultValue={value}
          value={value}
          controlled
          force={isLastChangeSafe}
        />
        <ColorPicker
          onDrag={this.onDrag}
          onComplete={this.onComplete}
          defaultValue={value}
          value={value}
          controlled
        />
      </div>
    );
  }
}

ValidateInputWithColorPicker.propTypes = {
  validateName: PropTypes.string.isRequired,
  colorPicker: PropTypes.shape({
    onDrag: PropTypes.func,
    onComplete: PropTypes.func,
  }),
  inputValidation: PropTypes.shape({
    defaultValue: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onValidate: PropTypes.func,
    rules: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.shape({})),
    ]),
  }),
};

ValidateInputWithColorPicker.defaultProps = {
  defaultValue: '#000',
  colorPicker: {
    onDrag: () => {},
    onComplete: () => {},
  },
  inputValidation: {
    type: 'text',
    placeholder: '',
    onChange: () => {},
    onValidate: () => {},
    rules: [],
  },
};

export default ValidateInputWithColorPicker;
