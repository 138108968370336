export const prepareFilters = filters => {
  const { status, name, publisher_name: publisherId } = filters;
  return {
    ...(name && { sites: name.join(',') }),
    ...(status && { statuses: status.join(',') }),
    ...(publisherId && {
      publisher_ids: publisherId.join(','),
    }),
  };
};
