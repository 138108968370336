import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { moment } from 'utils/date';
import {
  Form,
  Input,
  Card,
  Select,
  Row,
  Col,
  DatePicker,
  Button,
  Radio,
} from 'antd';

// Utils
import { withTranslation } from 'react-i18next';
import {
  mustBeFilled,
  mustBeImgLink,
  mustBeUrl,
} from '../../../config/validationRules';
import { statusesArticles } from '../../../constants/statuses';

// Types
import {
  START_FETCH_NEWS_CATEGORIES_LIST,
  START_FETCH_SITES_LIST,
} from '../../../redux/types/dataLists';

// Redux
import { START_CREATE_ARTICLE } from '../../../redux/types/articles';
import {
  fetchLanguagesList,
  fetchNewsCategoriesList,
  fetchSitesList,
} from '../../../redux/actions/dataLists';

// Components
import FormActions from '../components/FormActions';
import ArticlePreviewModal from '../../Modals/components/ArticlePreviewModal';
import useValidation from '../utils/useValidation';

const initialForm = {
  title: {
    value: '',
    rules: [mustBeFilled],
  },
  description: {
    value: '',
    rules: [mustBeFilled],
  },
  content: {
    value: '',
    rules: [mustBeFilled],
  },
  img: {
    value: '',
    rules: [mustBeFilled, mustBeImgLink],
  },
  url: {
    value: '',
    rules: [mustBeFilled, mustBeUrl],
  },
  language_id: {
    value: '',
    rules: [mustBeFilled],
  },
  site_id: {
    value: 1,
    rules: [mustBeFilled],
  },
  category_id: {
    value: 1,
    rules: [mustBeFilled],
  },
  created: {
    value: moment().valueOf(),
    rules: [mustBeFilled],
  },
};

const AddArticleForm = props => {
  const {
    dataLists,
    isNewsCategoriesListLoading,
    isSitesListLoading,
    isCreateArticleLoading,
    formData,
    fetchNewsCategories,
    fetchSites,
    fetchLanguages,
    onSubmit,
    onCancel,
    t,
  } = props;
  const isCreate = !formData;
  const { sitesList, newsCategoriesList, languagesList } = dataLists;

  const [isOpenedView, setIsOpenedView] = useState(false);
  const {
    errors,
    currentForm,
    bindedSubmit,
    bindedInputFunctions,
  } = useValidation(onSubmit, initialForm, formData);

  const handleFetchSites = useCallback(() => {
    if (!sitesList.length) fetchSites();
  }, [fetchSites, sitesList]);

  const handleFetchLanguages = useCallback(() => {
    if (!languagesList) fetchLanguages();
  }, [fetchLanguages, languagesList]);

  const handleFetchCategories = useCallback(() => {
    if (!newsCategoriesList.length) fetchNewsCategories();
  }, [fetchNewsCategories, newsCategoriesList]);

  const toggleArticleView = () => setIsOpenedView(!isOpenedView);

  useEffect(handleFetchSites, [sitesList]);
  useEffect(handleFetchLanguages, [languagesList]);
  useEffect(handleFetchCategories, [newsCategoriesList]);

  return (
    <Form>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={16}>
          <Form.Item {...errors.title} label={t('basic.title')}>
            <Input
              name="title"
              placeholder={`${t('basic.articleTitle')}..`}
              size="large"
              defaultValue={currentForm.title}
              {...bindedInputFunctions}
            />
          </Form.Item>

          <Form.Item {...errors.description} label={t('basic.description')}>
            <Input.TextArea
              name="description"
              placeholder={`${t('basic.articleDescription')}..`}
              rows={6}
              defaultValue={currentForm.description}
              {...bindedInputFunctions}
            />
          </Form.Item>

          <Form.Item {...errors.content} label={t('basic.content')}>
            <Input.TextArea
              name="content"
              placeholder={`${t('basic.articleContent')}..`}
              rows={6}
              defaultValue={currentForm.content}
              {...bindedInputFunctions}
            />
          </Form.Item>

          <Row gutter={16}>
            <Col xs={24} sm={24} md={12}>
              <Form.Item {...errors.img} label={t('basic.imageLink')}>
                <Input
                  name="img"
                  placeholder={`${t('basic.url')}..`}
                  defaultValue={currentForm.img}
                  {...bindedInputFunctions}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12}>
              <Form.Item {...errors.url} label={t('basic.newsLink')}>
                <Input
                  name="url"
                  placeholder={`${t('basic.url')}..`}
                  defaultValue={currentForm.url}
                  {...bindedInputFunctions}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Col xs={24} sm={24} md={8}>
          <Card title={t('basic.settings')} style={{ marginTop: '20px' }}>
            <Form.Item {...errors.language_id} label={t('basic.language')}>
              <Radio.Group
                value={currentForm.language_id}
                name="language_id"
                buttonStyle="solid"
                style={{ width: '100%' }}
                {...bindedInputFunctions}
              >
                {languagesList &&
                  Object.entries(languagesList).map(
                    // eslint-disable-next-line no-unused-vars
                    ([index, { id, code }], i, arr) => (
                      <Radio.Button
                        key={id}
                        value={id}
                        style={{ width: `${100 / arr.length}%` }}
                      >
                        {t(`languages.${code}`)}
                      </Radio.Button>
                    ),
                  )}
              </Radio.Group>
            </Form.Item>

            <Form.Item {...errors.status} label={t('basic.status')}>
              <Select
                disabled={isCreate}
                placeholder={`-- ${t('basic.status')} --`}
                // onSelect={handleSelectChange}
                defaultValue={currentForm.status}
                style={{ width: '100%' }}
              >
                {Object.keys(statusesArticles).map(statusText => (
                  <Select.Option
                    key={statusText}
                    defaultValue={statusText}
                    name="status"
                    disabled={statusText === statusesArticles.STATUS_DISABLED}
                  >
                    {t(`status.${statusText}`)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item {...errors.site_id} label={t('basic.site')}>
              <Select
                placeholder={`-- ${t('basic.site')} --`}
                loading={isSitesListLoading}
                style={{ minWidth: '100%' }}
                disabled={!sitesList}
                defaultValue={currentForm.site_id || initialForm.site_id.value}
                {...bindedInputFunctions}
              >
                {sitesList.map(site => (
                  <Select.Option key={site.id} value={site.id} name="site_id">
                    {site.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item {...errors.category_id} label={t('basic.category')}>
              <Select
                placeholder={`-- ${t('basic.category')} --`}
                loading={isNewsCategoriesListLoading}
                style={{ minWidth: '100%' }}
                disabled={!newsCategoriesList}
                defaultValue={
                  currentForm.category_id || initialForm.category_id.value
                }
                {...bindedInputFunctions}
              >
                {newsCategoriesList.map(category => (
                  <Select.Option
                    key={category.id}
                    value={category.id}
                    name="category_id"
                  >
                    {category.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item {...errors.created} label={t('basic.datePublication')}>
              <DatePicker
                allowClear={false}
                defaultValue={moment(
                  currentForm.created || initialForm.created.value,
                )}
                format="DD/MM/YYYY"
                placeholder="DD/MM/YYYY"
                name="created"
                style={{ width: '100%' }}
                {...bindedInputFunctions}
                onChange={momentDate =>
                  bindedInputFunctions.onChange(momentDate, 'created', true)
                }
              />
            </Form.Item>
          </Card>
        </Col>
        <Col xs={24} sm={24}>
          <ArticlePreviewModal
            description={currentForm.description}
            onClose={toggleArticleView}
            isOpen={isOpenedView}
            title={currentForm.title}
            image={currentForm.img}
            url={currentForm.url}
          />

          <FormActions
            titleSubmit={t('form.buttonSave')}
            onSubmit={bindedSubmit}
            onCancel={onCancel}
            disabled={isCreateArticleLoading}
            isLoading={isCreateArticleLoading}
            appendLeft={
              <Button type="info" onClick={toggleArticleView}>
                {t('basic.articleView')}
              </Button>
            }
          />
        </Col>
      </Row>
      <br />
    </Form>
  );
};

const mapStateToProps = state => ({
  dataLists: state.dataLists,
  isNewsCategoriesListLoading: state.network[START_FETCH_NEWS_CATEGORIES_LIST],
  isSitesListLoading: state.network[START_FETCH_SITES_LIST],
  isCreateArticleLoading: state.network[START_CREATE_ARTICLE],
});

const mapDispatchToProps = {
  fetchNewsCategories: fetchNewsCategoriesList,
  fetchLanguages: fetchLanguagesList,
  fetchSites: fetchSitesList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(AddArticleForm));
