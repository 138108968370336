import React, { useCallback, useState, memo } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Select } from 'antd';

const positions = [...Array(25).keys()].slice(1);

const PositionSelector = props => {
  const {
    disabledPositions,
    placeholder,
    onChange,
    isError,
    tooltip,
    value,
  } = props;
  const fontStyle = { fontSize: 12 };
  const style = { minWidth: '55px', ...fontStyle };
  const [isOpen, setIsOpen] = useState(false);

  const visibleChangeHandler = useCallback(isVisible => setIsOpen(isVisible), [
    setIsOpen,
  ]);

  return (
    <Tooltip title={isOpen ? tooltip : placeholder} placement="right">
      <Select
        onDropdownVisibleChange={visibleChangeHandler}
        placeholder={placeholder}
        className={isError ? 'error' : ''}
        onChange={onChange}
        style={style}
        size="small"
        value={value}
      >
        {positions.map(position => {
          return (
            <Select.Option
              style={fontStyle}
              key={position}
              className={
                disabledPositions.includes(position) ? 'disabled-option' : ''
              }
            >
              {position}
            </Select.Option>
          );
        })}
      </Select>
    </Tooltip>
  );
};

PositionSelector.defaultProps = {
  disabledPositions: [],
  placeholder: 'Позиция',
  isError: false,
  tooltip:
    'Занятые позиции подсвечены красным. При выборе недоступной позиции для этого информера произойдет замена новости, находящейся на выбранной позиции.',
};

PositionSelector.propTypes = {
  disabledPositions: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isError: PropTypes.bool,
  tooltip: PropTypes.string,
};

export default memo(PositionSelector);
