import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { oneOfType, shape, func } from 'prop-types';

const processMiddleware = (middlewares, props) => {
  let location = { ...props.location };

  const replace = data => {
    location = { ...data };
  };

  if (middlewares) {
    const isArr = Array.isArray(middlewares);
    const middlewaresArray = isArr ? middlewares : [middlewares];

    middlewaresArray.forEach(middleware => middleware(props, replace));
  }

  return location;
};

const LayoutWrapper = ({
  component: Component,
  urlParams,
  setUrlParams,
  ...props
}) => (
  <div
    className={`layout-wrapper ${
      props.fullscreen === false ? '' : 'fullscreen'
    }`}
  >
    <Route
      path={props.path}
      render={renderProps => {
        // saving current page match params for breadcrumbs
        if (!urlParams || urlParams.path !== renderProps.location.pathname) {
          setUrlParams({
            params: renderProps.match.params,
            path: renderProps.location.pathname,
          });
        }

        const to = processMiddleware(props.middleware, renderProps);
        const isRedirect =
          to.pathname !== (renderProps.location.pathname || '');

        return isRedirect ? (
          <Redirect to={to} />
        ) : (
          <Component {...renderProps} />
        );
      }}
    />
  </div>
);

LayoutWrapper.propTypes = {
  component: oneOfType([shape({}), func]).isRequired,
};

export default LayoutWrapper;
