import { useCallback, useEffect, useState } from 'react';
import { string } from 'prop-types';

// Utils
import {
  getSidebarTheme,
  setSidebarTheme,
} from '../../../services/localStorage';

const useSidebarTheme = () => {
  const [theme, setTheme] = useState(getSidebarTheme() || 'dark');

  const switchTheme = () => setTheme(theme === 'light' ? 'dark' : 'light');

  const saveToStorage = useCallback(() => {
    setSidebarTheme(theme);
  }, [theme]);

  useEffect(saveToStorage, [theme]);

  return [theme, switchTheme];
};

useSidebarTheme.propTypes = {
  currentPage: string,
};

export default useSidebarTheme;
