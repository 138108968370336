import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'antd';

// Redux
import { hideModal } from '../../redux/actions/modals';

// Styles
import './styles/style.scss';

// Components
import { AddAbTestLogicModal } from './components/AddAbTestLogicModal';
import { AddAbTestModal } from './components/AddAbTestModal';
import AddSiteModal from './components/AddSiteModal';
import ConfirmModal from './components/ConfirmModal';
import SiteIntegrationModal from './components/SiteIntegrationModal';
import CodeBlockModal from './components/CodeBlockModal';
import CodeBlockSiteModal from './components/CodeBlockSiteModal';
import AddArticleModal from './components/AddArticleModal';
import AddPublisherModal from './components/AddPublisherModal';
import SiteModeration from './components/SiteModeration';

const ModalContainer = ({ modals, hideModalAction }) => {
  const { type, params, modalWidth } = modals;
  const modalComponents = {
    MODAL_CONFIRM: ConfirmModal,
    MODAL_SITE_INTEGRATION: SiteIntegrationModal,
    MODAL_ADD_SITE: AddSiteModal,
    MODAL_ADD_ARTICLE: AddArticleModal,
    MODAL_ADD_PUBLISHER: AddPublisherModal,
    MODAL_SITE_MODERATION: SiteModeration,
    MODAL_CODE_BLOCK: CodeBlockModal,
    MODAL_CODE_BLOCK_SITE: CodeBlockSiteModal,
    MODAL_ADD_AB_TESTS_LOGIC: AddAbTestLogicModal,
    MODAL_ADD_AB_TEST: AddAbTestModal,
  };
  const ModalComponent = modalComponents[type];

  return (
    <Modal
      visible={!!type}
      onCancel={hideModalAction}
      width={modalWidth || 700}
      {...params.modalProps}
    >
      {ModalComponent ? (
        <ModalComponent onClose={hideModalAction} {...params} />
      ) : null}
    </Modal>
  );
};

const mapStateToProps = state => ({
  modals: state.modals,
});

const mapDispatchToProps = dispatch => ({
  hideModalAction: bindActionCreators(hideModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);
