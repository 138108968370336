import {
  getGroupHeaders,
  getSingleHeader,
} from 'components/Table/config/moldingTable';

// todo CALL ALL FUNCTION
const tableHeaders = {
  // label PUBLISHERS
  publishersHeader: params => [
    getSingleHeader('id', params),
    getSingleHeader('publisher', params),
    getSingleHeader('login', params),
    ...getGroupHeaders(
      [
        'views_our',
        'views_another',
        'views',
        'views_our_real',
        'views_another_real',
        'views_real',
        'display',
        'ctr',
        'click_from',
        'click_on',
        'ko',
      ],
      params,
    ),
  ],

  // label SITES (Publisher)
  sitesHeader: params => [
    getSingleHeader('sites', params),
    getSingleHeader('status', params),
    ...getGroupHeaders(
      [
        'views_our',
        'views_another',
        'views',
        'views_our_real',
        'views_another_real',
        'views_real',
        'display',
        'ctr',
        'click_from',
        'click_on',
        'ko',
      ],
      params,
    ),
  ],

  // label SITE STATISTICS
  siteStatisticsHeader: params => [
    getSingleHeader('date', params),
    ...getGroupHeaders(
      [
        'views_our',
        'views_another',
        'views',
        'views_our_real',
        'views_another_real',
        'views_real',
        'display',
        'ctr',
        'click_from',
        'ko',
        'ctr_informer',
        'ctr_rotator',
        'ctr_preview',
        'clicks_informer',
        'clicks_rotator',
        'clicks_preview',
        'views_informer',
        'views_rotator',
        'views_preview',
      ],
      params,
    ),
  ],

  // label SITE (informers list)
  siteHeader: params => [
    getSingleHeader('informer', params),
    getSingleHeader('status', params),
    ...getGroupHeaders(
      [
        'views_our',
        'views_another',
        'views',
        'views_our_real',
        'views_another_real',
        'views_real',
        'display',
        'ctr',
        'click_from',
      ],
      params,
    ),
  ],

  // label INFORMER STATISTICS
  informerStatisticsHeader: params => [
    getSingleHeader('date', params),
    ...getGroupHeaders(
      [
        'views_our',
        'views_another',
        'views',
        'views_our_real',
        'views_another_real',
        'views_real',
        'display',
        'ctr',
        'click_from',
      ],
      params,
    ),
  ],

  // label SITE
  informerByDateHeader: params => [
    getSingleHeader('url', params),
    ...getGroupHeaders(
      [
        'views_our',
        'views_another',
        'views',
        'views_our_real',
        'views_another_real',
        'views_real',
        'display',
        'ctr',
        'click_from',
      ],
      params,
    ),
  ],

  // label INFORMER NEWS
  informerNewsHeader: params => [
    getSingleHeader('date', params),
    ...getGroupHeaders(
      [
        'views_our',
        'views_another',
        'views',
        'views_our_real',
        'views_another_real',
        'views_real',
        'display',
        'ctr',
        'click_from',
        'ctr_informer',
        'ctr_rotator',
        'ctr_preview',
        'clicks_informer',
        'clicks_rotator',
        'clicks_preview',
        'views_informer',
        'views_rotator',
        'views_preview',
      ],
      params,
    ),
  ],

  // label DATE
  dateStatisticsHeader: params => [
    getSingleHeader('date', params),
    ...getGroupHeaders(
      [
        'views_our',
        'views_another',
        'views',
        'views_our_real',
        'views_another_real',
        'views_real',
        'display',
        'ctr',
        'click_from',
      ],
      params,
    ),
  ],

  // label A/B TESTING
  abTestingHeader: () => [
    getSingleHeader('ab_title', {}),
    ...getGroupHeaders(['click_on', 'click_from', 'ko'], {}),
  ],
};

const tableFooter = {
  // label PUBLISHERS
  publishersFooter: [
    'id',
    'name',
    'login',
    'views_our',
    'views_another',
    'views',
    'views_our_real',
    'views_another_real',
    'views_real',
    'display',
    'ctr',
    'click_from',
    'click_on',
    'ko',
    'actions',
  ],
};

export const {
  sitesHeader,
  siteHeader,
  siteStatisticsHeader,
  informerStatisticsHeader,
  informerNewsHeader,
  dateStatisticsHeader,
  publishersHeader,
  informerByDateHeader,
  abTestingHeader,
} = tableHeaders;

export const { publishersFooter } = tableFooter;
