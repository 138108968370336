import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs } from 'antd';

import { fetchSite } from 'redux/actions/site';
import { START_FETCH_SITE } from '../../redux/types';

import Loader from '../../components/Loader';
import Status from '../../components/Status';
import TabsWithQuery from '../../components/TabsWithQuery';
import PageTitle from '../../components/PageTitle';
import { SiteInformers } from './components/SiteInformers';
import { SiteNotice } from './components/SiteNotice';
import { SiteSettings } from './components/SiteSettings';
import { SiteStatistics } from './components/SiteStatistics';

export const Site = ({ match }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { siteId } = match.params;
  const siteData = useSelector(s => s.site.info);
  const network = useSelector(s => s.network);
  const isLoading = network[START_FETCH_SITE];

  const getSiteInfo = () => {
    fetchSite(siteId)(dispatch);
  };

  useEffect(getSiteInfo, []);

  return (
    <>
      <Loader isLoading={isLoading} />
      <PageTitle
        title={`${t('content.title.site')}: ${siteData.name || '...'}`}
        hint={
          <>
            {t('basic.status')}:{' '}
            {siteData.status && <Status status={siteData.status} />}
          </>
        }
      />
      <SiteNotice siteData={siteData} />
      <TabsWithQuery type="card">
        <Tabs.TabPane key="stats" tab={t('basic.statistics')}>
          <SiteStatistics siteId={siteData.id} />
        </Tabs.TabPane>
        <Tabs.TabPane key="edit" tab={t('basic.settings')}>
          <div style={{ maxWidth: 640 }}>
            <SiteSettings siteData={siteData} />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane key="informers" tab={t('basic.informers')}>
          <SiteInformers siteId={siteData.id} />
        </Tabs.TabPane>
        {/* <Tabs.TabPane key="script" tab={t('basic.script')}>
          <SiteScript siteId={siteData.id} />
        </Tabs.TabPane> */}
        {/* <Tabs.TabPane key="history" tab={t('basic.history')}>
          <SiteHistory siteId={siteData.id} />
        </Tabs.TabPane> */}
        {/* <Tabs.TabPane key="tickets" tab={t('basic.tickets')}></Tabs.TabPane> */}
      </TabsWithQuery>
    </>
  );
};
