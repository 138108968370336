import {
  START_FETCH_TABLE_INFORMER_NEWS,
  SUCCESS_FETCH_TABLE_INFORMER_NEWS,
  FAILURE_FETCH_TABLE_INFORMER_NEWS,
  START_FETCH_CHART_INFORMER_NEWS,
  SUCCESS_FETCH_CHART_INFORMER_NEWS,
  FAILURE_FETCH_CHART_INFORMER_NEWS,
  START_FETCH_NEWS,
  SUCCESS_FETCH_NEWS,
  FAILURE_FETCH_NEWS,
} from 'redux/types';
import { convertAllKeysToChartsData } from 'services/formatter';
import { monthEarlierDate, todayDate } from '../../utils/date';

const initialState = {
  info: {
    url: '',
    title: '',
  },
  table: {
    total: 0,
    limit: 20,
    offset: 0,
    start: +monthEarlierDate,
    end: +todayDate,
    data: [],
    isLoading: false,
  },
  chart: {},
};

export default function informer(state = initialState, action = {}) {
  switch (action.type) {
    case START_FETCH_TABLE_INFORMER_NEWS:
      return { ...state, table: { ...state.table, isLoading: true } };

    case SUCCESS_FETCH_TABLE_INFORMER_NEWS:
      return {
        ...state,
        table: { ...state.table, ...action.payload, isLoading: false },
      };

    case FAILURE_FETCH_TABLE_INFORMER_NEWS:
      return { ...state, table: { ...state.table, isLoading: false } };

    case START_FETCH_CHART_INFORMER_NEWS:
      return state;

    case SUCCESS_FETCH_CHART_INFORMER_NEWS:
      return {
        ...state,
        chart: convertAllKeysToChartsData(action.payload.data, true),
      };

    case FAILURE_FETCH_CHART_INFORMER_NEWS:
      return state;

    case START_FETCH_NEWS:
      return state;

    case SUCCESS_FETCH_NEWS:
      return {
        ...state,
        info: {
          url: action.payload.data.url,
          title: action.payload.data.title,
        },
      };

    case FAILURE_FETCH_NEWS:
      return state;

    default:
      return state;
  }
}
