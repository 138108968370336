import { Button, Icon } from 'antd';
import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { debounce, isEqual } from 'lodash';
import { withTranslation } from 'react-i18next';

// Redux
import { START_FETCH_ARTICLES_LIST } from '../../redux/types/articles';
import { showModal } from '../../redux/actions/modals';
import {
  fetchArticlesList,
  createArticle,
  editArticle,
} from '../../redux/actions/articles';
import {
  fetchNewsCategoriesList,
  fetchSitesList,
} from '../../redux/actions/dataLists';
import { setFilterFixed, getFilterFixed } from '../../services/localStorage';

// Utils
import { monthEarlierDate, todayDate } from '../../utils/date';

// Components
import PageTitle from '../../components/PageTitle';
import FilterForm from './components/FilterForm';
import PureArticlesList from './components/PureArticlesList';
import PopupFilter from './components/PopupFilter';

const basicPaginationState = {
  isArtificial: false,
  search: '',
  start: +monthEarlierDate,
  end: +todayDate,
  categoryId: null,
  sites: [],
};

class Articles extends PureComponent {
  state = {
    isBasicPagination: true,
    isFilterFixed: getFilterFixed(),
    pagination: {
      ...basicPaginationState,
      total: 0,
      offset: 0,
      pageSize: 10,
      pageSizeOptions: ['5', '10', '25', '50'],
      showSizeChanger: false,
      showQuickJumper: false,
    },
  };

  componentDidMount() {
    const { sitesList, newsCategoriesList } = this.props.dataLists;
    const isListsLoaded = sitesList.length && newsCategoriesList.length;

    if (!isListsLoaded) {
      this.props.fetchNewsCategoriesList();
      this.props.fetchSitesList();
    }

    this.handleFetchArticlesList();
  }

  onPaginationSizeChange = (current, size) => {
    this.setState(
      state => ({
        ...state,
        pagination: {
          ...state.pagination,
          pageSize: size,
        },
      }),
      () => this.props.fetchArticlesList(this.state.pagination),
    );
  };

  onPaginationChange = page => {
    const { pagination } = this.state;
    this.props.fetchArticlesList({
      ...pagination,
      offset: page * (pagination.pageSize - 1),
    });
  };

  onFilterChange = debounce((key, value) => {
    this.setState(
      state => ({
        ...state,
        pagination: {
          ...state.pagination,
          [key]: value || basicPaginationState[key],
        },
      }),
      () => {
        this.setState(state => ({
          isBasicPagination: this.checkIfBasicPagination(state),
        }));
        this.handleFetchArticlesList(this.state);
      },
    );
  }, 300);

  handleFetchArticlesList = (state = this.state) => {
    this.props
      .fetchArticlesList(state.pagination)
      .then(this.addArticlesConfigToState);
  };

  addArticlesConfigToState = () => {
    this.setState(state => ({
      ...state,
      pagination: {
        ...state.pagination,
        total: this.props.articlesTotal,
        showSizeChanger: this.props.articlesTotal >= state.pagination.pageSize,
        showQuickJumper: this.props.articlesTotal >= state.pagination.pageSize,
      },
    }));
  };

  toggleFilterFix = () => {
    this.setState(
      state => ({
        ...state,
        isFilterFixed: !state.isFilterFixed,
      }),
      () => setFilterFixed(this.state.isFilterFixed),
    );
  };

  checkIfBasicPagination = ({ pagination }) => {
    return isEqual(basicPaginationState, {
      isArtificial: pagination.isArtificial,
      start: pagination.start,
      end: pagination.end,
      categoryId: pagination.categoryId,
      sites: pagination.sites,
    });
  };

  handleModalAddArticle = (event, article) => {
    const { props } = this;
    const { publisherId } = props.match.params;

    props.showModal({
      modalWidth: 900,
      type: 'MODAL_ADD_ARTICLE',
      params: {
        fields: article,
        actions: {
          onSubmit: article ? props.editArticle : props.createArticle,
        },
        success: {
          onSubmit: () => this.handleFetchArticlesList(this.state),
        },
        publisherId,
      },
    });
  };

  render() {
    const { isArticlesListLoading, articlesList, dataLists, t } = this.props;
    const { sitesList, newsCategoriesList } = dataLists;
    const { pagination, isBasicPagination, isFilterFixed } = this.state;

    return (
      <Fragment>
        <PageTitle
          title={t('content.title.articles')}
          bordered
          button={
            <div style={{ textAlign: 'right' }}>
              <PopupFilter
                isBasicPagination={isBasicPagination}
                dataLists={dataLists}
                pagination={pagination}
                sitesList={sitesList}
                newsCategoriesList={newsCategoriesList}
                onFilterChange={this.onFilterChange}
                toggleFilter={this.toggleFilter}
                fixFilter={this.toggleFilterFix}
                isFilterFixed={isFilterFixed}
              />
              &nbsp;
              <Button
                type="success"
                className="add-article"
                onClick={this.handleModalAddArticle}
              >
                <Icon type="plus" />
                {t('form.buttonAdd')}
              </Button>
            </div>
          }
        />

        <FilterForm
          pagination={pagination}
          sitesList={sitesList}
          newsCategoriesList={newsCategoriesList}
          onChange={this.onFilterChange}
          isFilterFixed={isFilterFixed}
          isBox
        />

        <div className="content">
          <PureArticlesList
            articlesList={articlesList}
            isLoading={isArticlesListLoading}
            runBeforeClose={this.handleFetchArticlesList}
            onEditArticle={this.handleModalAddArticle}
            pagination={{
              ...pagination,
              onChange: this.onPaginationChange,
              onShowSizeChange: this.onPaginationSizeChange,
            }}
          />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  dataLists: state.dataLists,
  articlesList: state.articles.list,
  articlesTotal: state.articles.total,
  isArticlesListLoading: state.network[START_FETCH_ARTICLES_LIST],
});

const mapDispatchToProps = {
  fetchArticlesList,
  fetchNewsCategoriesList,
  fetchSitesList,
  createArticle,
  editArticle,
  showModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('')(Articles));
