import React, { Component } from 'react';
import { connect } from 'react-redux';

import { fetchInformer, fetchTable, fetchChart } from 'redux/actions/informer';
import { getDaysRange, getDaysAgoRange, datesArrayToUnix } from 'utils/date';
import { convertDate } from 'services/formatter';
import Table from 'components/Table';
import LinearChart from 'components/LinearChart';
import { informerStatisticsHeader } from '../../components/Table/config/tables';
import PageTitle from '../../components/PageTitle';
import { withTranslation } from 'react-i18next';

class Informer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      calendarDates: getDaysAgoRange(),
    };
  }

  componentDidMount() {
    const { props } = this;
    const {
      match: {
        params: { informerId },
      },
    } = props;

    this.handleUpdateAll(datesArrayToUnix(getDaysAgoRange()));

    props.fetchInformer(informerId);
  }

  handleUpdateAll = dates => {
    const { props } = this;
    const {
      match: {
        params: { informerId },
      },
    } = props;
    const { limit, offset } = props.table;

    props.fetchChart({
      start: +dates[0],
      end: +dates[1],
      id: informerId,
    });

    props.fetchTable({
      limit,
      offset,
      start: +dates[0],
      end: +dates[1],
      id: informerId,
    });

    this.setState({
      calendarDates: getDaysRange(dates),
    });
  };

  handleFetch = (page, sortBy, sortDirection) => {
    const { props } = this;
    const { table } = props;
    const {
      match: {
        params: { informerId },
      },
    } = props;
    const { limit, start, end } = table;
    const offset = (page - 1) * limit;

    props.fetchTable({
      limit,
      offset,
      start,
      end,
      id: informerId,
      sortBy,
      sortDirection,
    });
  };

  handleLinkInformer = row =>
    `${this.props.match.url}/${convertDate(row.date)}`;

  render() {
    const { props, state } = this;
    const { info, table, t } = props;
    const { name } = info;
    const { isLoading } = table;
    const informerTitle = name ? `: "${name}"` : '';

    return (
      <div className="page-body main-page">
        <PageTitle
          title={`${t('content.title.informer')}${informerTitle}`}
          hint={t('content.titleHint.informer')}
        />

        <LinearChart
          isLoading={isLoading}
          categoriesForX={state.calendarDates}
          onChange={this.handleUpdateAll}
          data={props.chart}
        />

        <Table
          isLoading={isLoading}
          headers={informerStatisticsHeader}
          data={props.table.data}
          total={props.table.total}
          limit={props.table.limit}
          fetch={this.handleFetch}
          rowKey="date"
          actions={{
            page: this.handleLinkInformer,
          }}
          type="informerActions"
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    info: state.informer.info,
    chart: state.informer.chart,
    table: state.informer.table,
  };
}

const mapDispatchToProps = {
  fetchInformer,
  fetchTable,
  fetchChart,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Informer));
