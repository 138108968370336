import React from 'react';
import { Form, Input, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';

// Utils
import {
  mustBeFilled,
  atLeastOneFilled,
} from '../../../config/validationRules';
import { statusesAbTests } from '../../../constants/statuses';

// Components
import FormActions from '../components/FormActions';
import { FormSelectLogic } from '../components/FormSelectLogic';
import useValidation from '../utils/useValidation';

const implementationTypes = {
  frontend: 'front',
  backend: 'back',
};

export const AbTestForm = props => {
  const { formData, onSubmit, onCancel, logicList, isLoading } = props;
  const { status } = formData;
  const isEditMode = !!Object.keys(formData).length;
  const isResultEnabled =
    status !== statusesAbTests.STATUS_NOT_ACTIVE && isEditMode;
  const { t } = useTranslation();
  const logics = logicList.reduce((acc, el) => {
    acc[el.implementedOn] = [...(acc[el.implementedOn] || []), el];
    return acc;
  }, {});

  const initialForm = {
    // status: { value: '', rules: [] },
    title: { value: '', rules: [mustBeFilled] },
    description: { value: '', rules: [mustBeFilled] },
    viewsWeight: { value: 1, rules: [mustBeFilled] },
    ...(isResultEnabled && {
      result: { value: '', rules: [mustBeFilled] },
    }),
    ...(!isEditMode && {
      status: { value: 'not_active' },
      logicIdFrontend: {
        value: '',
        rules: [atLeastOneFilled(['logicIdFrontend', 'logicIdBackend'])],
      },
      logicIdBackend: {
        value: '',
        rules: [atLeastOneFilled(['logicIdFrontend', 'logicIdBackend'])],
      },
    }),
  };

  const {
    errors,
    currentForm,
    bindedSubmit,
    bindedInputFunctions,
  } = useValidation(onSubmit, initialForm, formData);

  return (
    <Form>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24}>
          <Form.Item {...errors.title} label={t('basic.name')}>
            <Input
              name="title"
              placeholder="..."
              rows={4}
              defaultValue={currentForm.title}
              style={{ width: '100%' }}
              {...bindedInputFunctions}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24}>
          <Form.Item {...errors.description} label={t('basic.description')}>
            <Input.TextArea
              name="description"
              placeholder="..."
              rows={4}
              defaultValue={currentForm.description}
              style={{ width: '100%' }}
              {...bindedInputFunctions}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24}>
          <Form.Item {...errors.viewsWeight} label={t('form.viewsWeight')}>
            <Input
              name="viewsWeight"
              placeholder="..."
              rows={4}
              defaultValue={currentForm.viewsWeight}
              style={{ width: '100%' }}
              {...bindedInputFunctions}
            />
          </Form.Item>
        </Col>
        {/*
          <Col xs={24} sm={24} md={24}>
            <Form.Item {...errors.status} label={t('basic.status')}>
              <Select
                name="status"
                defaultValue={currentForm.status}
                placeholder={`-- ${t('basic.status')} --`}
                style={{ minWidth: '100%' }}
                {...bindedInputFunctions}
              >
                {Object.values(statusesAbTests).map(status => (
                  <Select.Option key={status} name="status" value={status}>
                    <Status status={status} noBackground />
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        */}

        {!isEditMode && (
          <Col xs={24} sm={24} md={24}>
            {/* Label: FRONTEND */}
            <Form.Item
              {...errors.logicIdFrontend}
              label={`${t('table.includesLogic')}: ${t('basic.frontend')}`}
            >
              <FormSelectLogic
                list={logics[implementationTypes.frontend]}
                bindedInputFunctions={bindedInputFunctions}
                name="logicIdFrontend"
                currentForm={currentForm}
              />
            </Form.Item>

            {/* Label: BACKEND */}
            <Form.Item
              {...errors.logicIdBackend}
              label={`${t('table.includesLogic')}: ${t('basic.backend')}`}
            >
              <FormSelectLogic
                list={logics[implementationTypes.backend]}
                bindedInputFunctions={bindedInputFunctions}
                name="logicIdBackend"
                currentForm={currentForm}
              />
            </Form.Item>
          </Col>
        )}

        {isResultEnabled && (
          <Col xs={24} sm={24} md={24}>
            <Form.Item {...errors.result} label={t('basic.result')}>
              <Input.TextArea
                name="result"
                placeholder="..."
                rows={4}
                defaultValue={currentForm.result}
                style={{ width: '100%' }}
                {...bindedInputFunctions}
              />
            </Form.Item>
          </Col>
        )}
        <Col xs={24} sm={24}>
          <FormActions
            isLoading={isLoading}
            wrapperStyles={{ textAlign: 'right', width: '100%' }}
            titleSubmit={t('form.buttonSave')}
            onSubmit={bindedSubmit}
            onCancel={onCancel}
          />
        </Col>
      </Row>
    </Form>
  );
};
