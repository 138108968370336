/* eslint-disable camelcase */
import { secureAxios, notSecureAxios } from 'services/axios/enhanceAxios';
import qs from 'querystringify';
import { correctParams, paramsToQueries } from './helper';
import { adminApi, api } from './config';

// label AUTH ________________________________________________________

export const signIn = params =>
  notSecureAxios.post(`${adminApi}/login`, correctParams(params));

// label PUBLISHERS ________________________________________________________

export const getPublishersInfo = ({
  limit,
  offset,
  start,
  end,
  sortBy,
  sortDirection,
}) =>
  secureAxios().get(`${adminApi}/users`, {
    params: {
      limit,
      offset,
      start,
      end,
      ...(sortBy && { order_by: sortBy, sort: sortDirection }),
    },
  });

export const getPublishersStatistics = ({ start, end }) =>
  secureAxios().get(`${adminApi}/statistics`, { params: { start, end } });

export const getPublisherById = id =>
  secureAxios().get(`${adminApi}/users/${id}`, {});

export const addNewPublisher = params =>
  secureAxios().post(`${adminApi}/users`, correctParams(params));

export const editPublisherById = params => {
  return secureAxios().put(
    `${adminApi}/users/${params.id}`,
    correctParams(params),
  );
};

export const removePublisherById = id =>
  secureAxios().delete(`${adminApi}/users/${id}`, {});

// label SITES ________________________________________________________

export const getSitesInfo = ({ sortBy, sortDirection, ...restParams }) =>
  secureAxios().get(`${adminApi}/sites`, {
    params: {
      ...correctParams(restParams),
      ...(sortBy && { order_by: sortBy, sort: sortDirection }),
    },
  });

export const getSitesStatistics = params =>
  secureAxios().get(`${adminApi}/statistics`, {
    params: correctParams(params),
  });

export const getSiteById = id =>
  secureAxios().get(`${adminApi}/sites/${id}`, {});

export const addNewSite = ({
  views_another_real,
  views_our_real,
  views_another,
  publisherId,
  click_from,
  views_our,
  views_real,
  views,
  click_on,
  display,
  status,
  ctr,
  ko,
  id,
  ...otherParams
}) =>
  secureAxios().post(`${adminApi}/sites`, {
    publisher_id: publisherId,
    ...otherParams,
  });

export const editSiteById = ({
  views_another_real,
  views_our_real,
  publisher_name,
  views_another,
  publisher_id,
  click_from,
  tech_status,
  views_our,
  views_real,
  views,
  click_on,
  display,
  ctr,
  id,
  ko,
  notice,
  ...otherParams
}) => secureAxios().put(`${adminApi}/sites/${id}`, { ...otherParams });

export const removeSiteById = id =>
  secureAxios().delete(`${adminApi}/sites/${id}`, {});

export const analyticsCheckApi = ({ siteId, ...params }) =>
  secureAxios().post(
    `${adminApi}/sites/${siteId}/analytics`,
    correctParams(params, true),
  );

// Site statistics
export const getSiteStatisticsTable = params => {
  return secureAxios().get(`${adminApi}/statistics/site`, {
    params: correctParams(params),
  });
};

export const getSitesStatusesApi = () =>
  secureAxios().get(`${adminApi}/sites/statuses`, {});

export const getSiteStatusesHistory = params =>
  secureAxios().get(
    `${adminApi}/sites/status-history${qs.stringify(
      correctParams(params),
      true,
    )}`,
    {},
  );

export const getSiteParsingLog = ({ siteId, ...params }) =>
  secureAxios().get(
    `${adminApi}/sites/parser-errors/${siteId}${qs.stringify(
      correctParams(params),
      true,
    )}`,
    {},
  );

// label INFORMER ________________________________________________________

export const getInformersBySite = params =>
  secureAxios().get(`${adminApi}/informers`, {
    params: correctParams(params),
  });

export const getInformersStatisticBySite = params =>
  secureAxios().get(`${adminApi}/statistics`, {
    params: correctParams(params),
  });

export const getInformerById = id =>
  secureAxios().get(`${adminApi}/informers/${id}`, {});

export const removeInformerById = id =>
  secureAxios().delete(`${adminApi}/informers/${id}`, {});

export const getInformerInfo = ({
  id,
  limit,
  offset,
  start,
  end,
  sortBy,
  sortDirection,
}) =>
  secureAxios().get(`${adminApi}/statistics/informer`, {
    params: {
      informer_id: id,
      limit,
      offset,
      start,
      end,
      ...(sortBy && { order_by: sortBy, sort: sortDirection }),
    },
  });

export const getInformerStatistics = ({ start, end, id }) =>
  secureAxios().get(`${adminApi}/statistics`, {
    params: { start, end, informer_id: id },
  });

export const addInformerStyles = ({
  name,
  siteId,
  data: {
    desktop,
    mobile,
    isVisible,
    settings,
    categories_disabled,
    languages,
  }, // eslint-disable-line camelcase
}) =>
  secureAxios().post(`${adminApi}/informers`, {
    name,
    languages,
    site_id: siteId,
    categories_disabled,
    styles: { desktop, mobile, isVisible },
    settings,
  });

export const editInformerStyles = ({
  id,
  name,
  data: {
    desktop,
    mobile,
    isVisible,
    settings,
    categories_disabled,
    languages,
  }, // eslint-disable-line camelcase
}) =>
  secureAxios().put(`${adminApi}/informers/${id}`, {
    name,
    styles: { desktop, mobile, isVisible },
    categories_disabled,
    languages,
    settings,
  });

export const resetInformerStylesApi = ({ id }) =>
  secureAxios().put(`${adminApi}/informers/${id}/reset`);

export const getInformerByDateInfo = params =>
  secureAxios().get(`${adminApi}/news`, {
    params: correctParams(params),
  });

export const getSiteNewsApi = params =>
  secureAxios().get(`${adminApi}/informers/news`, {
    params: correctParams(params),
  });

// label NEWS ________________________________________________________

export const getInformerNewsInfo = params =>
  secureAxios().get(`${adminApi}/statistics/news`, {
    params: correctParams(params),
  });

export const getNews = id => secureAxios().get(`${api}/admin/news/${id}`, {});

// Statistics
// ________________________________________________________

export const getGlobalStatisticsApi = () =>
  secureAxios().get(`${adminApi}/information`, {});

export const getStatisticsByType = ({
  from,
  to,
  block,
  siteId,
  informerId,
  abTest,
  isInformer,
  deviceType,
}) => {
  const type = isInformer ? 'informer' : 'rotator';
  const receivedParams = correctParams({
    start: from,
    end: to,
    ...(!isInformer && { block }),
    ...(siteId != null && { siteId }),
    ...(informerId != null && { informerId }),
    ...(!isInformer && abTest !== null && { abTest }),
    ...(deviceType !== null && { device_type: deviceType }),
  });
  const queryParams = qs.stringify(receivedParams, true);

  return secureAxios().get(`${adminApi}/analytics/${type + queryParams}`, {});
};

export const getAbTestingStatisticsApi = params =>
  secureAxios().get(
    `${adminApi}/statistics/ab_testing${qs.stringify(params, true)}`,
    {},
  );

export const getStatisticsGa = params =>
  secureAxios().get(
    `${adminApi}/statistics/ga${qs.stringify(params, true)}`,
    {},
  );

// Settings
// ________________________________________________________
export const getGlobalSettingsApi = () =>
  secureAxios().get(`${adminApi}/settings`, {});

export const setGlobalSettingApi = params =>
  secureAxios().post(`${adminApi}/settings`, params);

// A/B TESTS
// ________________________________________________________

export const getAbTestsLogic = () =>
  secureAxios().get(`${adminApi}/ab_tests_logic`, {});

export const addAbTestLogicApi = ({ ...restParams }) =>
  secureAxios().post(`${adminApi}/ab_tests_logic`, correctParams(restParams));

export const editAbTestLogicApi = ({ id, isEditMode, ...restParams }) =>
  secureAxios().put(
    `${adminApi}/ab_tests_logic/${id}`,
    correctParams(restParams),
  );

export const getAbTests = () => secureAxios().get(`${adminApi}/ab_tests`, {});

export const addAbTestApi = ({
  logicIdBackend,
  logicIdFrontend,
  ...restParams
}) =>
  secureAxios().post(
    `${adminApi}/ab_tests`,
    correctParams({
      ...restParams,
      logicIds: [
        ...(logicIdBackend ? [logicIdBackend] : []),
        ...(logicIdFrontend ? [logicIdFrontend] : []),
      ],
    }),
  );

export const editAbTestApi = ({
  id,
  isEditMode,
  logicIdBackend,
  logicIdFrontend,
  published,
  unpublished,
  resultKo,
  data,
  ...restParams
}) =>
  secureAxios().post(`${adminApi}/ab_tests/${id}`, correctParams(restParams));

export const getAbTestResults = ({ id }) =>
  secureAxios().get(`${adminApi}/ab_tests/history/${id}`, correctParams({}));

// Scripts check
// ________________________________________________________

export const getScriptChecks = () =>
  secureAxios().get(`${adminApi}/check-scripts`, {});

export const addScriptCheckApi = params =>
  secureAxios().post(`${adminApi}/check-scripts/create`, correctParams(params));

export const editScriptCheckApi = ({
  created,
  id,
  message,
  status,
  ...restParams
}) =>
  secureAxios().put(
    `${adminApi}/check-scripts/${id}`,
    correctParams(restParams),
  );

export const restartScriptCheck = params =>
  secureAxios().post(`${adminApi}/check-scripts/start`, correctParams(params));

export const getSiteScriptVersions = ({ siteId, params }) =>
  secureAxios().get(`${adminApi}/scripts-history/${siteId}`, { ...params });

export const getScriptsChecksLog = ({ siteId, ...params }) =>
  secureAxios().get(
    `${adminApi}/scripts-history/log/${siteId}${qs.stringify(params, true)}`,
    {},
  );

// User UserNotifications
// ________________________________________________________

export const getUserNotifications = (params = {}) =>
  secureAxios().get(
    `${adminApi}/notifications${
      Object.keys(params).length ? '?' : ''
    }${qs.stringify(correctParams(params, true))}`,
    {},
  );

export const markAsReadUserNotification = params =>
  secureAxios().put(`${adminApi}/notifications`, {
    notification_id: params.id,
    is_read: params.isRead,
  });

// UserNotifications
// ________________________________________________________

export const getUserNotificationsConfig = params =>
  secureAxios().get(
    // TODO: fix it when there will be separated route for notifications config
    `${adminApi}/notifications${
      Object.keys(params).length ? '?' : ''
    }${qs.stringify(correctParams(params))}`,
    {},
  );

export const addUserNotificationConfig = params =>
  secureAxios().post(`${adminApi}/notifications/create`, params);

export const editUserNotificationConfig = ({ id, created, ...restParams }) =>
  secureAxios().put(
    `${adminApi}/notifications/${id}`,
    correctParams(restParams),
  );

export const deleteUserNotificationConfig = ({ id }) =>
  secureAxios().delete(`${adminApi}/notifications/${id}`, correctParams({}));

// Settings
// ________________________________________________________
// fetch list of sites with informers
export const getSitesWithInformers = () =>
  secureAxios().get(`${adminApi}/informers/custom/all`, {});

// fetch list of sites with informers for rotator
export const getSitesWithInformersForRotator = () =>
  secureAxios().get(`${adminApi}/informers/rotator/all`, {});

export const getCustomArticlesByInformerId = ({ informerId }) =>
  secureAxios().get(`${adminApi}/informers/${informerId}/custom/news`, {});

// fetch list of informers where this news was published
export const getInformersDataByNewsId = newsId =>
  secureAxios().get(`${adminApi}/informers/custom?news_id=${newsId}`, {});

// fetch list of informers related to rotator where this news was published
export const getInformersDataForRotatorByNewsId = newsId =>
  secureAxios().get(`${adminApi}/informers/rotator?news_id=${newsId}`, {});

// promote article on informers
export const postPromoteArticle = params =>
  secureAxios().post(`${adminApi}/informers/custom`, params);

// promote articles on informers related to rotator
export const postPromoteArticleForRotator = params =>
  secureAxios().post(`${adminApi}/informers/rotator`, params);

export const getCustomArticlesStatisticsApi = () =>
  secureAxios().get(`${adminApi}/artificial/news/statistics`, {});

export const setCustomArticlePosition = ({ custom_id, ...params }) =>
  secureAxios().put(`${adminApi}/informers/custom/${custom_id}`, params);

export const postCustomArticle = params =>
  secureAxios().post(`${adminApi}/informers/custom`, params);

export const searchArticlesApi = text =>
  secureAxios().get(`${adminApi}/search?q=${text}`, {});

export const deleteCustomArticleApi = custom_id =>
  secureAxios().delete(`${adminApi}/informers/custom/${custom_id}`, {});

// Fetch list of published articles on informers
export const fetchCustomArticlesApi = () =>
  secureAxios().get(`${adminApi}/informers/custom/news`, {});

// Fetch list of published articles on rotator
export const fetchCustomArticlesRotatorApi = () =>
  secureAxios().get(`${adminApi}/informers/rotator/news`, {});

// ________________________________________________
// END Settings

// ARTICLES
// ________________________________________________________

export const createArticleApi = ({ created, ...params }) =>
  secureAxios().post(`${adminApi}/artificial/news`, {
    ...params,
    created_original: created,
  });

export const editArticleApi = ({ id, created, is_artificial, ...params }) =>
  secureAxios().put(`${adminApi}/artificial/news/${id}`, {
    ...params,
    created_original: created,
  });

export const getArticlesListApi = ({
  isArtificial,
  categoryId,
  pageSize,
  search,
  sites,
  offset,
  start,
  end,
}) =>
  secureAxios().get(`${adminApi}/artificial/news`, {
    params: {
      is_artificial: isArtificial,
      category_id: categoryId,
      sites: (sites && sites.join()) || null,
      search,
      limit: pageSize,
      offset,
      start,
      end,
    },
  });

export const deleteArticleApi = articleId =>
  secureAxios().delete(`${adminApi}/artificial/news/${articleId}`, {});

export const editBasicArticleApi = ({ newsId, ...params }) =>
  secureAxios().put(`${adminApi}/news/${newsId}`, correctParams(params));

export const getSitesListApi = params => {
  return secureAxios().get(
    `${adminApi}/sites${paramsToQueries({
      params: correctParams(params),
      isClearCommas: true,
    })}`,
    {},
  );
};

// ________________________________________________
// END ARTICLES

export const getModerationSitesApi = params =>
  secureAxios().get(`${adminApi}/sites/all${qs.stringify(params, true)}`, {});

export const updateSiteModerationStatusApi = ({ siteId, ...otherParams }) =>
  secureAxios().post(`${adminApi}/sites/${siteId}/status`, { ...otherParams });

export const getModerationSpeedupInfoApi = ({ siteId }) =>
  secureAxios().get(`${adminApi}/sites/speedup-analytics/${siteId}`, {});
