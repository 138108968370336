import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { node } from 'prop-types';
import { Tabs } from 'antd';
import { HASH_KEY_TABS } from '../../constants/location';
import { setHash, getHash } from '../../services/location';

const TabsWithQuery = ({ children, ...otherProps }) => {
  const [activeKey, setActiveKey] = useState();
  const [hashState, setHashState] = useState(getHash());
  const [childrenKeys, setChildrenKeys] = useState([]);

  const getChildrenKeys = () =>
    Object.values(children).reduce((acc, cur) => [...acc, cur.key], []);

  const checkIfTabsIsPresent = useMemo(
    () => Object.keys(hashState).length && hashState[HASH_KEY_TABS],
    [hashState],
  );

  const getIndex = useCallback(
    isSearchInCurrent => {
      const currentTabs = hashState[HASH_KEY_TABS];
      const stackArr = isSearchInCurrent ? currentTabs : childrenKeys;
      const pinArr = isSearchInCurrent ? childrenKeys : currentTabs;

      return stackArr.findIndex(key => pinArr.find(tab => tab === key));
    },
    [hashState, childrenKeys],
  );

  const handleSetHash = useCallback(
    tabKey => {
      const currentTabs = hashState[HASH_KEY_TABS] || [tabKey];

      if (checkIfTabsIsPresent) {
        const currentIndexInHash = getIndex(true);

        if (currentIndexInHash === -1) {
          currentTabs.push(tabKey);
        } else {
          currentTabs[currentIndexInHash] = tabKey;
        }
      }

      setHash(HASH_KEY_TABS, currentTabs);
      setHashState(getHash());
    },
    [checkIfTabsIsPresent, getIndex, hashState],
  );

  useEffect(() => {
    let index = 0;
    setHashState(getHash());

    if (checkIfTabsIsPresent) {
      const currentIndex = getIndex(false);

      index = currentIndex > -1 ? currentIndex : index;
    }

    setActiveKey(childrenKeys[index]);
  }, [childrenKeys]); // eslint-disable-line

  useEffect(() => {
    setChildrenKeys(getChildrenKeys(children));
  }, [children]); // eslint-disable-line

  return activeKey ? (
    <Tabs
      {...otherProps}
      onTabClick={handleSetHash}
      defaultActiveKey={activeKey}
    >
      {children}
    </Tabs>
  ) : (
    <></>
  );
};

TabsWithQuery.propTypes = {
  children: node,
};

export default TabsWithQuery;
