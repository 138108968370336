import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

const options = [
  {
    key: 'Rubric',
    value: 'rubric',
  },
  {
    key: 'Main',
    value: 'main',
  },
  {
    key: 'Last',
    value: 'last',
  },
];

const RotatorBlockSelector = props => {
  const { placeholder, onChange, isError, value } = props;
  const fontStyle = { fontSize: 12 };
  const style = {
    marginRight: 3,
    minWidth: '100px',
    maxWidth: '100px',
    ...fontStyle,
  };

  return (
    <Select
      placeholder={placeholder}
      className={isError ? 'error' : ''}
      onChange={onChange}
      style={style}
      size="small"
      value={value}
    >
      {options.map(option => (
        <Select.Option
          title={option.key}
          style={fontStyle}
          key={option.value}
          value={option.value}
        >
          {option.key}
        </Select.Option>
      ))}
    </Select>
  );
};

RotatorBlockSelector.defaultProps = {
  placeholder: 'Блок',
  isError: false,
};

RotatorBlockSelector.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isError: PropTypes.bool,
};

export default memo(RotatorBlockSelector);
