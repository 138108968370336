import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import history from 'redux/history';

import auth from 'redux/reducers/auth';
import abTests from 'redux/reducers/abTests';
import articles from 'redux/reducers/articles';
import dataLists from 'redux/reducers/dataLists';
import globalSettings from 'redux/reducers/globalSettings';
import informer from 'redux/reducers/informer';
import informerByDate from 'redux/reducers/informerByDate';
import informerSettings from 'redux/reducers/informerSettings';
import informerNews from 'redux/reducers/informerNews';
import modals from 'redux/reducers/modals';
import moderation from 'redux/reducers/moderation';
import network from 'redux/reducers/network';
import notifications from 'redux/reducers/notifications';
import publishers from 'redux/reducers/publishers';
import publisherSettings from 'redux/reducers/publisherSettings';
import sites from 'redux/reducers/sites';
import site from 'redux/reducers/site';
import statistics from 'redux/reducers/statistics';
import userSettings from 'redux/reducers/userSettings';
import articlesStatistics from './articlesStatistics';

export default combineReducers({
  router: connectRouter(history),
  abTests,
  articles,
  articlesStatistics,
  auth,
  dataLists,
  globalSettings,
  informer,
  informerByDate,
  informerNews,
  informerSettings,
  notifications,
  network,
  modals,
  moderation,
  publishers,
  publisherSettings,
  statistics,
  site,
  sites,
  userSettings,
});
