import React, { useEffect, useState, useCallback } from 'react';
import { withRouter, Switch } from 'react-router-dom';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { clearUser as clearUserAction } from '../../redux/actions/auth';

// Redux
import { setIsMobileAction } from '../../redux/actions/userSettings';

// Services
import LayoutWrapper from '../../services/layout';
import * as LS from '../../services/localStorage';

// Utils
import { isMobileDevice } from '../../utils/layout';
import routes from '../../routes';

// Components
import Loader from '../../components/Loader';
import Header from '../../components/Header';
import Breadcrumbs from '../../components/Breadcrumbs';
import Footer from '../../components/Footer';
import Sidebar from '../../components/Sidebar';

const LayoutDefault = props => {
  const {
    isLoadingSidebar,
    history,
    location,
    isMobile,
    setIsMobile,
    clearUser,
  } = props;
  const isAuth = pathname => pathname !== '/login';
  const isLoggedIn = isAuth(location.pathname);
  const [urlParams, setUrlParams] = useState(null);

  const logout = () => {
    LS.removeAccessToken();
    LS.removeRefreshToken();
    LS.removeLoginRedirect();
    LS.logOutUser();
    clearUser();
    history.push('/login');
  };

  const handleSetIsMobile = useCallback(
    debounce(() => setIsMobile(isMobileDevice()), 300),
    [setIsMobile],
  );

  useEffect(() => {
    if (isMobile === null) handleSetIsMobile();

    window.addEventListener('resize', handleSetIsMobile);
    return () => window.removeEventListener('resize', handleSetIsMobile);
  }, [handleSetIsMobile, isMobile]);

  return (
    <div id="page-wrapper" className="page-wrapper">
      <Layout className="page-layout">
        <Loader isLoading={isLoadingSidebar} />
        {isLoggedIn && (
          <Sidebar
            className="page-layout__sidebar"
            history={history}
            location={location}
          />
        )}
        {(!isLoggedIn || !isLoadingSidebar) && (
          <Layout className="page-layout__content">
            <Layout.Header className="fullscreen">
              <Header
                logout={logout}
                history={history}
                isAuth={isAuth(location.pathname)}
              />
              {isLoggedIn && (
                <Breadcrumbs history={history} urlParams={urlParams} />
              )}
            </Layout.Header>

            <Layout.Content>
              <Switch>
                {routes.map(item => (
                  <LayoutWrapper
                    key={item.path}
                    {...item}
                    urlParams={urlParams}
                    setUrlParams={setUrlParams}
                  />
                ))}
              </Switch>
            </Layout.Content>

            <Layout.Footer>
              <Footer />
            </Layout.Footer>
          </Layout>
        )}
      </Layout>
    </div>
  );
};

const mapStateToProps = ({ network, userSettings }) => ({
  isLoadingSidebar: network.START_GET_SIDEBAR_VISIBILITY,
  isMobile: userSettings.isMobile,
  userSettings,
});

const mapDispatchToProps = {
  setIsMobile: setIsMobileAction,
  clearUser: clearUserAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(LayoutDefault));
