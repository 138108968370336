import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// Utils
import { statusesStyles, statusesSites } from '../../../constants/statuses';

// Components
import SiteModerationForm from '../../Form/forms/SiteModerationForm';
import SpeedupInfo from '../../SpeedupInfo';

const SiteModeration = ({
  nextStatus,
  actions,
  onClose,
  domain,
  status,
  fields,
  id,
  t,
}) => {
  const handleSubmit = form =>
    actions.onSubmit({ ...form, siteId: id, status: nextStatus }).then(onClose);

  const preparedDomain = domain.includes('://') ? domain : `https://${domain}`;

  return (
    <Fragment>
      <h3>
        {t('form.title.moderation')}&nbsp;
        <a href={preparedDomain} target="_blank" rel="noopener noreferrer">
          <b>
            <u>{domain}</u>
          </b>
        </a>
      </h3>

      <p>
        {t('form.approveWithStatus')}&nbsp;
        <b style={{ color: statusesStyles[nextStatus].color }}>
          "{t(`status.${nextStatus}`)}"
        </b>
      </p>

      {status === statusesSites.STATUS_ANALYTICS_SPEEDUP && (
        <SpeedupInfo status={status} siteId={id} />
      )}

      <SiteModerationForm
        formData={fields}
        onSubmit={handleSubmit}
        onCancel={onClose}
        nextStatus={nextStatus}
      />
    </Fragment>
  );
};

SiteModeration.propTypes = {
  onClose: PropTypes.func.isRequired,
  actions: PropTypes.shape({
    onSubmit: PropTypes.func.isRequired,
  }).isRequired,
};

SiteModeration.defaultProps = {};

export default withTranslation('common')(SiteModeration);
