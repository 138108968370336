export const getPeriodValues = t => [
  {
    key: 'one_day',
    text: t('basic.daysCount_1', { count: 1 }),
    value: 'one_day',
  },
  {
    key: 'seven_days',
    text: t('basic.daysCount_7', { count: 7 }),
    value: 'seven_days',
  },
  {
    key: 'fourteen_days',
    text: t('basic.daysCount_7', { count: 14 }),
    value: 'fourteen_days',
  },
];

export const getSelfOptionValues = t => [
  {
    key: 'disabled',
    text: t('basic.disabled'),
    value: 'disabled',
  },
  {
    key: 'allowed',
    text: t('basic.allowedOnlyInExtremeCases'),
    value: 'allowed',
  },
  {
    key: 'required',
    text: t('basic.required'),
    value: 'required',
  },
];

export const blockTypesKeys = ['Last', 'Main', 'Rubric'];
export const newsGroupsKeys = ['Top', 'Rubric', 'Mix'];
export const ratioTypesKeys = ['Top ratio'];
