import { Tooltip, Icon } from 'antd';
import React from 'react';

// Utils
import {
  colorGreenSemantic,
  colorRed,
} from '../../../styles/js/variables/colors';

const TableBoolean = ({ boolResult, title, translation }) => (
  <Tooltip title={translation || title || ''}>
    <Icon
      type={boolResult ? 'check' : 'close'}
      twoToneColor={boolResult ? colorGreenSemantic : colorRed}
      theme="outlined"
      style={{ color: boolResult ? colorGreenSemantic : colorRed }}
    />
  </Tooltip>
);

export default TableBoolean;
