import React from 'react';
import { Badge } from 'antd';
import styled from 'styled-components';
import Table from '../../components/Table';
import { colorRed, colorGreyDark } from '../../styles/js/variables/colors';

export const ModerationTableWrapper = styled(Table)``;

export const ModerationTabPane = styled.span`
  padding-right: 25px;
`;

export const ModerationBadge = styled(({ isMarked, ...rest }) => (
  <Badge {...rest} />
))`
  .ant-badge-count {
    color: #999;
    background: #fff;

    ${({ isMarked }) =>
      isMarked &&
      `
      background: ${colorRed};
      border-color: transparent;
      color: #fff;
    `};
  }
`;

export const ModerationTabTitle = styled.span`
  text-transform: capitalize;
  color: ${colorGreyDark};
`;
