import React from 'react';
import { Statistic, Row, Col, Icon, Divider } from 'antd';
import EmptyData from '../../../components/EmptyData';

const ChangedFieldsBlock = ({ changedFields }) => (
  <div className="changed-fields">
    {!Object.entries(changedFields).length && <EmptyData />}

    {Object.entries(changedFields).map(([key, field]) => (
      <div className="changed-fields__block" key={key}>
        <Divider className="changed-fields__block-label">{key}</Divider>
        <div className="nth-list--reversed">
          {Object.entries(field).map(
            ([fieldName, { after, before, label }]) => (
              <Row
                key={fieldName}
                type="flex"
                justify="space-between"
                align="middle"
                className="changed-fields__row"
              >
                <Col>{label}</Col>
                <Col>
                  <Statistic
                    className="changed-fields__result"
                    value={before}
                    formatter={value => (
                      <>
                        {value} <Icon type="arrow-right" />
                      </>
                    )}
                    suffix={after}
                  />
                </Col>
              </Row>
            ),
          )}
        </div>
      </div>
    ))}
  </div>
);

export default ChangedFieldsBlock;
