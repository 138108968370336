import { snakeCase } from 'lodash';
import qs from 'querystringify';

const keysTransformations = {
  sortBy: 'order_by',
  sortDirection: 'sort',
  abTest: 'a_b_c',
};

export const correctParams = (params = {}) => {
  const result = {};

  Object.entries(params).forEach(([key, value]) => {
    if (!value) return;

    result[snakeCase(keysTransformations[key] || key)] = value;
  });

  return result;
};

export const paramsToQueries = ({ params, isClearCommas }) => {
  let result = qs.stringify(params, true);
  const isCorrectString =
    typeof result === 'string' && typeof result.replaceAll === 'function';

  if (isClearCommas && isCorrectString) {
    result = result.replaceAll('%2C', ',');
  }

  return result;
};

export const isSuccessResponse = response => {
  const { type = '' } = response;
  return type.toLowerCase().includes('success');
};
