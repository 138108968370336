import { FaMobile, FaDesktop, FaQuestionCircle } from 'react-icons/fa';
import {
  colorBlue,
  colorGreen,
  colorGrey,
} from '../styles/js/variables/colors';

export const TYPE_MOBILE = 'mobile';
export const TYPE_DESKTOP = 'desktop';

export const devicesList = [TYPE_MOBILE, TYPE_DESKTOP];

export const deviceIcons = {
  [TYPE_MOBILE]: {
    icon: FaMobile,
    color: colorBlue,
  },
  [TYPE_DESKTOP]: {
    icon: FaDesktop,
    color: colorGreen,
  },
  default: {
    icon: FaQuestionCircle,
    color: colorGrey,
  },
};
