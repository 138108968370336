import { Icon } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from '../../../components/Button';

// Utils
import { unixToDate } from '../../../utils/date';
import { cutStringWithDots } from '../../../utils/string';

// Redux
import { getStartStatus } from '../../../redux/helpers/statuses';
import { FETCH_AB_TESTS } from '../../../redux/types';

// Components
import Status from '../../../components/Status';
import Table from '../../../components/Table';
import { TestsActions } from './TestsActions';
import { TestsLogicCell } from './TestsLogicCell';
import { TestsWeightCell } from './TestsWeightCell';

export const TestsTable = ({
  onEdit,
  onActivate,
  onDeactivate,
  onEditAbTestsLogic,
}) => {
  const { t } = useTranslation('common');
  const networkState = useSelector(({ network }) => network);
  const { testsList } = useSelector(({ abTests }) => abTests);
  const isLoading = networkState[getStartStatus(FETCH_AB_TESTS)];

  return (
    <>
      <div style={{ marginBottom: '2em' }}>
        <Button type="success" onClick={() => onEdit({})}>
          <Icon type="plus" /> {t('form.title.addAbTest')}
        </Button>
      </div>
      <Table
        isClientPagination
        className="ant-table--auto-size"
        isLoading={isLoading}
        headers={() => [
          {
            dataIndex: 'id',
            title: 'ID',
            fixed: 'left',
          },
          {
            dataIndex: 'title',
            title: t('basic.name'),
            render: (text, row) => (
              <Button type="link" onClick={() => onEdit(row)}>
                {text ? cutStringWithDots(text, 30) : ''}
              </Button>
            ),
          },
          {
            title: t('table.views'),
            dataIndex: 'views_weight',
            render: (text, row) => <TestsWeightCell current={text} row={row} />,
          },
          {
            dataIndex: 'description',
            title: t('basic.description'),
            align: 'left',
            render: text => (
              <div style={{ width: '30em' }}>
                {text ? cutStringWithDots(text, 120) : ''}
              </div>
            ),
          },
          {
            dataIndex: 'logics',
            title: t('basic.logic'),
            align: 'left',
            render: text => (
              <TestsLogicCell
                current={text}
                onClickAction={onEditAbTestsLogic}
              />
            ),
          },
          {
            title: t('table.status'),
            dataIndex: 'status',
            render: (text, record) => (text ? <Status {...record} /> : ''),
          },
          {
            title: t('table.published'),
            key: 'published',
            children: [
              {
                title: t('basic.start'),
                dataIndex: 'published',
                render: text => (text ? unixToDate(text, 'DD.MM.YY') : ''),
              },
              {
                title: t('basic.end'),
                dataIndex: 'unpublished',
                render: text => (text ? unixToDate(text, 'DD.MM.YY') : ''),
              },
            ],
          },
          {
            dataIndex: 'result',
            title: t('basic.result'),
            align: 'left',
            render: text => (
              <div style={{ width: '15em' }}>
                {text ? cutStringWithDots(text, 60) : ''}
              </div>
            ),
          },
          {
            dataIndex: 'result_ko',
            title: t('table.ko'),
            tooltip: t('table.tooltips.abTestsKo'),
            render: text => (String(text) ? text : ''),
          },
          {
            dataIndex: 'actions',
            title: t('table.actions'),
            fixed: 'right',
            render: (text, rowData) => (
              <TestsActions
                {...rowData}
                onActivate={() => onActivate(rowData)}
                onDeactivate={() => onDeactivate(rowData)}
                onEdit={() => onEdit(rowData)}
              />
            ),
          },
        ]}
        data={testsList}
        // total={table.total}
        limit={15}
        // fetch={this.handleFetch}
      />
    </>
  );
};
