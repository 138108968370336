import {
  START_ADD_INFORMER,
  SUCCESS_ADD_INFORMER,
  FAILURE_ADD_INFORMER,
  START_EDIT_INFORMER,
  SUCCESS_EDIT_INFORMER,
  FAILURE_EDIT_INFORMER,
  EDIT_INFORMER_LOCAL,
  CHOICE_INFORMER,
  RESET_INFORMER_STYLE,
  START_RESET_INFORMER_STYLES,
  FAILURE_RESET_INFORMER_STYLES,
  SUCCESS_RESET_INFORMER_STYLES,
} from 'redux/types';

import {
  addInformerStyles,
  editInformerStyles,
  resetInformerStylesApi,
} from 'api';
import { showErrorSavingMessage } from '../../components/Notifications/helper';

export const editInformerLocal = payload => ({
  type: EDIT_INFORMER_LOCAL,
  payload,
});

export const choiceInformer = payload => ({
  type: CHOICE_INFORMER,
  payload,
});

export const editInformer = styleInformer => dispatch => {
  dispatch({
    type: START_EDIT_INFORMER,
  });
  return editInformerStyles(styleInformer)
    .then(response => {
      const { data } = response;

      dispatch({
        type: SUCCESS_EDIT_INFORMER,
        payload: data,
        notification: {
          type: 'success',
          text: 'Настройки информера сохранены',
        },
      });
    })
    .catch(fail => {
      dispatch({
        status: 401,
        type: FAILURE_EDIT_INFORMER,
        fail,
        notification: {
          type: 'error',
          text: showErrorSavingMessage(fail),
        },
      });
    });
};

export const addInformer = styleInformer => dispatch => {
  dispatch({
    type: START_ADD_INFORMER,
  });

  return addInformerStyles(styleInformer)
    .then(response => {
      const { data } = response;

      dispatch({
        type: SUCCESS_ADD_INFORMER,
        payload: data,
      });
    })
    .catch(fail => {
      dispatch({
        status: 401,
        type: FAILURE_ADD_INFORMER,
        fail,
        notification: {
          type: 'error',
          text: showErrorSavingMessage(fail),
        },
      });
    });
};

export const resetInformerStylesLocal = () => ({
  type: RESET_INFORMER_STYLE,
});

export const resetInformerStyles = ({ id }) => dispatch => {
  dispatch({
    type: START_RESET_INFORMER_STYLES,
  });

  return resetInformerStylesApi({ id })
    .then(response => {
      const { data } = response;

      dispatch({
        type: SUCCESS_RESET_INFORMER_STYLES,
        payload: data,
        notification: {
          type: 'success',
          text: 'Сброс информера произведен. Настройки сохранены',
        },
      });
    })
    .catch(fail => {
      dispatch({
        type: FAILURE_RESET_INFORMER_STYLES,
        status: 401,
        fail,
        notification: {
          type: 'error',
          text: 'Сброс настроек не произведен',
        },
      });
    });
};
