import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Router } from 'react-router-dom';
import * as routesPath from 'config/routePaths';

import { connect } from 'react-redux';

import Breadcrumbs from 'react-router-dynamic-breadcrumbs';

import { fetchInformer } from 'redux/actions/informer';
import { fetchSite } from 'redux/actions/site';
import { fetchPublisher } from 'redux/actions/publishers';
import { fetchNews } from 'redux/actions/informerNews';
import { withTranslation } from 'react-i18next';
import LinkItem from './components/LinkItem';

const Breadcrumb = props => {
  const { infos, history, urlParams, t } = props;
  const { informer, site, publisher, news } = infos;

  const printTitle = useCallback(
    (title, label) =>
      JSON.stringify({
        title: title || '',
        label: label || '',
      }),
    [],
  );

  const getInfoBreadcrumbs = useCallback(() => {
    const { params } = urlParams;

    Object.entries(params).forEach(async ([key, id]) => {
      const pureKey = key.substr(-2) === 'Id' ? key.slice(0, -2) : key;
      const isSameId = (infos[pureKey] && infos[pureKey].id) === String(id);

      if ((!infos[pureKey] || !isSameId) && props[pureKey]) {
        await props[pureKey](id);
      }
    });
  }, [urlParams, infos, props]);

  // prettier-ignore
  const routes = useMemo(() => ({
    [routesPath.AB_TESTS_STATISTICS]: printTitle(t('menu.abTestsStatistics')),
    [routesPath.AB_TESTS]: printTitle(t('menu.abTestsConfig')),
    [routesPath.ARTICLE_PROMOTION]: printTitle(t('menu.articlesPromotion')),
    [routesPath.ARTICLES]: printTitle(t('menu.articles')),
    [routesPath.ARTICLES_STATISTICS]: printTitle(t('menu.articlesStatistics')),
    [routesPath.GLOBAL_SETTINGS]: printTitle(t('menu.globalSettings')),
    [routesPath.GLOBAL_STATISTICS]: printTitle(t('menu.globalStatistics')),
    [routesPath.HOME]: printTitle(t('menu.homepage')),
    [routesPath.INFORMERS_LIST]: printTitle(t('menu.informersList')),
    [routesPath.INFORMER]: printTitle(informer.name, t('menu.informer')),
    [routesPath.INFORMER_ADD]: printTitle(t('menu.createInformer')),
    [routesPath.INFORMER_BY_DATE]: (url, match) => match.date === 'news' ? null : printTitle(t('menu.statisticsByDate')),
    [routesPath.INFORMER_EDIT]: printTitle(informer.name, t('menu.editInformer')),
    [routesPath.INFORMER_NEWS]: printTitle(news.title, t('menu.news')),
    [routesPath.LOGIN]: printTitle(t('menu.login')),
    [routesPath.PUBLISHER]: printTitle(publisher.name, t('menu.publisher')),
    [routesPath.SITE]: printTitle(site.name, t('menu.site')),
    [routesPath.SITE_STATISTICS]: printTitle(t('menu.siteStatisticsBreadcrumbs')),
    [routesPath.SITES_LIST]: printTitle(t('menu.sitesList')),
    [routesPath.SITES_MODERATION]: printTitle(t('menu.moderation')),
    [routesPath.STATISTICS_BY_TYPE]: printTitle(t('menu.analytics')),
  }), [informer, news, publisher, site, printTitle, t]);

  useEffect(() => {
    if (urlParams) {
      getInfoBreadcrumbs();
    }
  }, [urlParams]); // eslint-disable-line

  return (
    <Router history={history}>
      <Breadcrumbs
        divider="/"
        mappedRoutes={routes}
        WrapperComponent={prop => (
          <ul className="breadcrumbs">{prop.children}</ul>
        )}
        ActiveLinkComponent={LinkItem}
        LinkComponent={LinkItem}
      />
    </Router>
  );
};

function mapStateToProps(state) {
  return {
    infos: {
      informer: state.informer.info,
      site: state.site.info,
      publisher: state.publishers.info,
      news: state.informerNews.info,
    },
  };
}

const mapDispatchToProps = {
  informer: fetchInformer,
  site: fetchSite,
  publisher: fetchPublisher,
  news: fetchNews,
};

Breadcrumbs.propTypes = {
  history: PropTypes.shape({}),
  infos: PropTypes.shape({
    informer: PropTypes.shape({}),
    site: PropTypes.shape({}),
    publisher: PropTypes.shape({}),
    news: PropTypes.shape({}),
  }),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('common')(Breadcrumb));
