import { Icon } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Types
import { FETCH_AB_TESTS_LOGIC } from '../../../redux/types';

// Actions
import { getStartStatus } from '../../../redux/helpers/statuses';

// Components
import Button from '../../../components/Button';
import Status from '../../../components/Status';
import Table from '../../../components/Table';
import { colorBlue, colorGreen } from '../../../styles/js/variables/colors';
import { cutStringWithDots } from '../../../utils/string';

export const LogicTable = ({ onEdit, onDelete }) => {
  const { t } = useTranslation('common');
  const net = useSelector(({ network }) => network);
  const list = useSelector(({ abTests }) => abTests.logicList);
  const isLoading = net[getStartStatus(FETCH_AB_TESTS_LOGIC)];

  return (
    <>
      <div style={{ marginBottom: '2em' }}>
        <Button type="warning" onClick={() => onEdit({})}>
          <Icon type="plus" /> {t('form.title.addAbTestLogic')}
        </Button>
      </div>
      <Table
        isClientPagination
        className="ant-table--auto-size"
        isLoading={isLoading}
        headers={() => [
          {
            dataIndex: 'id',
            title: 'ID',
          },
          {
            dataIndex: 'title',
            title: t('basic.name'),
            render: (text, row) => (
              <Button type="link" onClick={() => onEdit(row)}>
                {text ? cutStringWithDots(text, 30) : ''}
              </Button>
            ),
          },
          {
            dataIndex: 'description',
            title: t('basic.description'),
            align: 'left',
            render: text => (
              <div style={{ width: '30em' }}>
                {text ? cutStringWithDots(text, 120) : ''}
              </div>
            ),
          },
          {
            dataIndex: 'status',
            title: t('table.status'),
            render: (text, record) => (text ? <Status {...record} /> : ''),
          },
          {
            dataIndex: 'implemented_on',
            title: t('basic.implementation'),
            render: text => (
              <b style={{ color: text === 'front' ? colorBlue : colorGreen }}>
                {t(`basic.${text}end`)}
              </b>
            ),
          },
        ]}
        data={list}
        type="informerActions"
        actions={{
          edit: onEdit,
          delete: onDelete,
        }}
        // total={table.total}
        limit={15}
        // fetch={this.handleFetch}
      />
    </>
  );
};
