import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';

import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { fetchSite, fetchStatisticsTable } from '../../redux/actions/site';

import {
  getDaysAgoRange,
  getDaysRange,
  datesArrayToUnix,
} from '../../utils/date';

// Types
import routePaths, { getFilledRoutePath } from '../../config/routePaths';

// Components
import LinearChart from '../../components/LinearChart';
import Table from '../../components/Table';
import { siteStatisticsHeader } from '../../components/Table/config/tables';
import PageTitle from '../../components/PageTitle';
import LinkWithTag from '../../components/LinkWithTag';

class SiteStatistics extends Component {
  constructor(props) {
    super(props);

    this.state = {
      calendarDates: getDaysAgoRange(),
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { siteId },
      },
    } = this.props;

    this.props.fetchSite(siteId);
    this.handleFetchSiteRequest(datesArrayToUnix(getDaysAgoRange()));
  }

  handleFetchSiteRequest = dates => {
    const { props } = this;
    const { table } = props;
    const {
      match: {
        params: { siteId },
      },
    } = props;
    const { offset } = table;

    this.props.fetchTable({
      siteId,
      offset,
      start: +dates[0],
      end: +dates[1],
      isWithChart: true,
    });

    this.setState({
      calendarDates: getDaysRange(dates),
    });
  };

  handlePagination = (page, sortBy, sortDirection) => {
    const { props } = this;
    const { table } = props;
    const {
      match: {
        params: { siteId },
      },
    } = props;
    const { limit, start, end } = table;
    const offset = (page - 1) * limit;

    props.fetchTable({
      siteId,
      offset,
      start,
      end,
      sortBy,
      sortDirection,
    });
  };

  render() {
    const { props, state } = this;
    const { table, info, match, t } = props;
    const { limit, total, data, isLoading } = table;
    const { name } = info;
    const linkToInformersList = getFilledRoutePath({
      path: routePaths.INFORMERS_LIST,
      params: match.params,
    });

    return (
      <div className="page-body main-page">
        {name && (
          <PageTitle
            title={t('content.title.siteStatistics')}
            hint={t('content.titleHint.siteStatistics')}
            button={
              <LinkWithTag
                isButton
                href={linkToInformersList}
                pageType={routePaths.SITE_STATISTICS}
                linkProps={{
                  type: 'default',
                }}
              >
                <Icon type="unordered-list" />
                {t('form.buttonInformersList')}
              </LinkWithTag>
            }
          />
        )}

        <LinearChart
          isLoading={isLoading}
          categoriesForX={state.calendarDates}
          onChange={this.handleFetchSiteRequest}
          data={props.chart}
          rightTitle="CTR"
          showKeys={[
            'views',
            'clickOn',
            'clickFrom',
            'viewsInformer',
            'viewsRotator',
            'viewsPreview',
            'clicksInformer',
            'clicksRotator',
            'clicksPreview',
            'ctrInformer',
            'ctrRotator',
            'ctrPreview',
          ]}
        />

        <Table
          isLoading={isLoading}
          isClientPagination
          headers={siteStatisticsHeader}
          data={data}
          total={total}
          limit={limit}
          rowKey="date"
          fetch={this.handlePagination}
        />
      </div>
    );
  }
}

SiteStatistics.defaultProps = {
  fetchTable: () => {},
};

SiteStatistics.propTypes = {
  fetchSite: PropTypes.func,
  fetchTable: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({}).isRequired,
    url: PropTypes.string,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    info: state.site.info,
    chart: state.site.statisticsChart,
    table: state.site.statisticsTable,
  };
}

const mapDispatchToProps = {
  fetchSite,
  fetchTable: fetchStatisticsTable,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(SiteStatistics));
