import React from 'react';
import { Form, Input, Row, Col, Radio, Select } from 'antd';

// Utils
import { useTranslation } from 'react-i18next';
import { mustBeFilled } from '../../../config/validationRules';
import { statusesAbTests } from '../../../constants/statuses';
import { colorBlue, colorGreen } from '../../../styles/js/variables/colors';

// Components
import FormActions from '../components/FormActions';
import useValidation from '../utils/useValidation';
import Status from '../../Status';

export const AbTestLogicForm = props => {
  const { formData, onSubmit, onCancel, isEditMode, isLoading } = props;
  const { t } = useTranslation();

  const initialForm = {
    title: { value: '', rules: [mustBeFilled] },
    description: { value: '', rules: [mustBeFilled] },
    implementedOn: { value: '', rules: [mustBeFilled] },
    status: {
      value: isEditMode ? '' : statusesAbTests.STATUS_ACTIVE,
      rules: [],
    },
  };

  const {
    errors,
    currentForm,
    bindedSubmit,
    bindedInputFunctions,
  } = useValidation(onSubmit, initialForm, formData);

  return (
    <Form>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24}>
          <Form.Item {...errors.title} label={t('basic.name')}>
            <Input
              name="title"
              placeholder="..."
              rows={4}
              defaultValue={currentForm.title}
              style={{ width: '100%' }}
              {...bindedInputFunctions}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24}>
          <Form.Item {...errors.description} label={t('basic.description')}>
            <Input.TextArea
              name="description"
              placeholder="..."
              rows={4}
              defaultValue={currentForm.description}
              style={{ width: '100%' }}
              {...bindedInputFunctions}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24}>
          {isEditMode && (
            <Form.Item {...errors.status} label={t('basic.status')}>
              <Select
                name="status"
                defaultValue={currentForm.status}
                placeholder={`-- ${t('basic.status')} --`}
                style={{ minWidth: '100%' }}
                {...bindedInputFunctions}
              >
                {Object.values(statusesAbTests).map(
                  status =>
                    status !== statusesAbTests.STATUS_FINISHED &&
                    status !== statusesAbTests.STATUS_DELETED && (
                      <Select.Option key={status} name="status" value={status}>
                        <Status status={status} noBackground />
                      </Select.Option>
                    ),
                )}
              </Select>
            </Form.Item>
          )}
        </Col>
        <Col xs={24} sm={24} md={24}>
          <Form.Item
            {...errors.implementedOn}
            label={t('basic.implementation')}
          >
            <Radio.Group
              name="implementedOn"
              defaultValue={currentForm.implementedOn}
              style={{ display: 'flex', flexDirection: 'column' }}
              {...bindedInputFunctions}
            >
              <Radio value="front">
                <b style={{ color: colorBlue }}>{t('basic.frontend')}</b>
              </Radio>
              <Radio value="back">
                <b style={{ color: colorGreen }}>{t('basic.backend')}</b>
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24}>
          <FormActions
            isLoading={isLoading}
            wrapperStyles={{ textAlign: 'right', width: '100%' }}
            titleSubmit={t('form.buttonSave')}
            onSubmit={bindedSubmit}
            onCancel={onCancel}
          />
        </Col>
      </Row>
    </Form>
  );
};
