import cloneDeep from 'lodash/cloneDeep';
import {
  CHOICE_INFORMER,
  EDIT_INFORMER_LOCAL,
  RESET_INFORMER_STYLE,
  SUCCESS_RESET_INFORMER_STYLES,
} from '../types';

const crossPlatformStyles = {
  general: {
    triggerMobile: '768',
    marginBottom: '10',
    paddingHorizontal: '5',
  },
  block: {
    position: 'relative',
    paddingTop: '0',
    paddingBottom: '0',
    paddingRight: '0',
    paddingLeft: '0',
    marginTop: '10',
    marginBottom: '0',
    marginRight: '0',
    marginLeft: '0',
    borderType: 'solid',
    radius: '0',
    widthBorder: '0',
    backgroundColor: '#fff',
    justifyContent: 'flex-start',
    flexBasis: '100%',
  },
  font: {
    paddingTop: '10',
    paddingBottom: '0',
    paddingRight: '0',
    paddingLeft: '0',
    marginTop: '10',
    marginBottom: '10',
    marginRight: '10',
    marginLeft: '10',
    name: 'Default',
    textAlign: 'left',
    fontWeight: 'normal',
    size: '16',
    height: '18',
    color: 'black',
    position: 'relative',
    bottom: '0',
    left: '0',
  },
  image: {
    widthBorder: '0',
    zoomOnHover: true,
  },
  title: {
    tag: 'h2',
    fontName: 'Default',
    color: '#000000',
    textAlign: 'left',
    size: '18',
    height: '18',
    paddingTop: '0',
    paddingBottom: '0',
    paddingRight: '0',
    paddingLeft: '0',
  },
};

const informerStyleInitial = {
  desktop: {
    general: {
      ...crossPlatformStyles.general,
      countCalls: '3',
      countRows: '2',
    },

    block: {
      ...crossPlatformStyles.block,
      color: '#fff',
      viewType: 'vertical',
    },

    image: {
      ...crossPlatformStyles.image,
      borderType: 'dashed',
      color: '#000',
      radius: '0',
      height: '150',
    },

    font: {
      ...crossPlatformStyles.font,
    },

    title: {
      ...crossPlatformStyles.title,
      name: '',
    },
  },

  mobile: {
    general: {
      // name: 'МОБИЛЬНЫЙ ЗАГОЛОВОК',
      ...crossPlatformStyles.general,
      countCalls: '1',
      countRows: '6',
      transition: 'all .2s ease-out',
    },

    block: {
      ...crossPlatformStyles.block,
      maxWidth: '100%',
      color: '#000',
      viewType: 'horizontal',
    },

    image: {
      ...crossPlatformStyles.image,
      radius: '5',
      borderType: 'solid',
      width: '100',
      height: '100',
      color: 'red',
    },

    font: {
      ...crossPlatformStyles.font,
      paddingLeft: '10',
    },

    title: {
      ...crossPlatformStyles.title,
      name: '',
    },
  },
  isVisible: true,
  name: '',
  settings: {
    rubric_ratio: 0.1,
    rubric_period: 'one_day',
    mix_period: 'one_day',
    rubric_self: 'disabled',
    mix_self: 'disabled',
    rotator_last_self: 'disabled',
    rotator_main_self: 'disabled',
    rotator_rubric_self: 'disabled',
    rotator_last_period: 'one_day',
    rotator_main_period: 'one_day',
    rotator_rubric_period: 'one_day',
  },
  categories_disabled: [],
  languages: [],
};

export default function informer(
  state = cloneDeep(informerStyleInitial),
  action = {},
) {
  switch (action.type) {
    case CHOICE_INFORMER:
      return { ...state, ...action.payload };

    case SUCCESS_RESET_INFORMER_STYLES:
      return { ...state, ...action.payload };

    case EDIT_INFORMER_LOCAL: {
      const { item, response, typeDevice, isSetting } = action.payload;
      const container = state[typeDevice];

      const block = isSetting
        ? { [item]: response }
        : {
            [typeDevice]: {
              ...container,
              [item]: {
                ...container[item],
                [response.name]: response.value,
              },
            },
          };

      return {
        ...state,
        ...block,
      };
    }

    case RESET_INFORMER_STYLE: {
      return cloneDeep(informerStyleInitial);
    }

    default:
      return state;
  }
}
