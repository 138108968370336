import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Modal, List, Avatar } from 'antd';
import Button from 'components/Button';
import '../styles/articlesListModal.scss';

const ArticlesListModal = props => {
  const { title, onClose, articleModal, list, onArticleClick } = props;

  const articleClickHandler = useCallback(
    article => {
      onArticleClick(article, articleModal);
      onClose();
    },
    [onClose, onArticleClick, articleModal],
  );

  return (
    <Modal
      onCancel={onClose}
      visible={!!articleModal}
      title={title}
      onOk={onClose}
      bodyStyle={{ maxHeight: '60vh', overflow: 'auto' }}
      footer={
        <Button type="primary" onClick={onClose}>
          Закрыть
        </Button>
      }
    >
      <List
        dataSource={list}
        className="articles-list"
        locale={{
          emptyText: <div />,
        }}
        renderItem={item => (
          <List.Item
            onClick={() => articleClickHandler(item)}
            className="articles-list__item"
          >
            <List.Item.Meta
              avatar={<Avatar src={item.img} />}
              title={item.title}
            />
          </List.Item>
        )}
      />
    </Modal>
  );
};

ArticlesListModal.defaultProps = {
  articleModal: null,
  title: 'Список размещенных статей',
  onArticleClick: () => {},
  list: [],
};

ArticlesListModal.propTypes = {
  onArticleClick: PropTypes.func,
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  articleModal: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ArticlesListModal;
