import {
  createArticleApi,
  getArticlesListApi,
  deleteArticleApi,
  editArticleApi,
} from 'api';
import { editBasicArticleApi } from '../../api';
import {
  START_CREATE_ARTICLE,
  SUCCESS_CREATE_ARTICLE,
  FAILURE_CREATE_ARTICLE,
  FAILURE_FETCH_ARTICLES_LIST,
  START_FETCH_ARTICLES_LIST,
  SUCCESS_FETCH_ARTICLES_LIST,
  START_EDIT_ARTICLE,
  SUCCESS_EDIT_ARTICLE,
  FAILURE_EDIT_ARTICLE,
} from '../types/articles';
import {
  ARTICLE_CREATED,
  ARTICLE_DELETED,
  ARTICLE_NOT_DELETED,
  ARTICLE_SAVED,
  ERROR_NOT_RESPONDED,
  SUCCESS_SAVED,
  ERROR_NOT_SAVED,
} from '../../constants/messages';
import { showErrorSavingMessage } from '../../components/Notifications/helper';

export const fetchArticlesList = params => dispatch => {
  dispatch({
    type: START_FETCH_ARTICLES_LIST,
  });

  return getArticlesListApi(params)
    .then(payload => {
      dispatch({
        type: SUCCESS_FETCH_ARTICLES_LIST,
        payload: payload.data,
      });
    })
    .catch(error => {
      dispatch({
        type: FAILURE_FETCH_ARTICLES_LIST,
        payload: error,
        notification: {
          type: 'error',
          text: ERROR_NOT_RESPONDED,
        },
      });
    });
};

export const createArticle = params => dispatch => {
  dispatch({
    type: START_CREATE_ARTICLE,
  });

  return createArticleApi(params)
    .then(payload => {
      dispatch({
        type: SUCCESS_CREATE_ARTICLE,
        payload: payload.data,
        notification: {
          type: 'success',
          text: ARTICLE_CREATED,
        },
      });
      return payload;
    })
    .catch(error => {
      dispatch({
        type: FAILURE_CREATE_ARTICLE,
        payload: error,
        notification: {
          type: 'error',
          text: showErrorSavingMessage(error),
        },
      });
      return error;
    });
};

export const editBasicArticle = params => dispatch => {
  dispatch({
    type: START_EDIT_ARTICLE,
  });

  return editBasicArticleApi(params)
    .then(() =>
      dispatch({
        type: SUCCESS_EDIT_ARTICLE,
        payload: params,
        notification: {
          type: 'success',
          text: SUCCESS_SAVED,
        },
      }),
    )
    .catch(error =>
      dispatch({
        type: FAILURE_EDIT_ARTICLE,
        payload: error,
        notification: {
          type: 'error',
          text: ERROR_NOT_SAVED,
        },
      }),
    );
};

export const editArticle = params => dispatch => {
  dispatch({
    type: START_CREATE_ARTICLE,
  });

  return editArticleApi(params)
    .then(payload => {
      dispatch({
        type: SUCCESS_CREATE_ARTICLE,
        payload: payload.data,
        notification: {
          type: 'success',
          text: ARTICLE_SAVED,
        },
      });
      return payload;
    })
    .catch(error => {
      dispatch({
        type: FAILURE_CREATE_ARTICLE,
        payload: error,
        notification: {
          type: 'error',
          text: showErrorSavingMessage(error),
        },
      });
      return error;
    });
};

export const deleteArticle = articleId => dispatch => {
  dispatch({
    type: START_FETCH_ARTICLES_LIST,
  });

  return deleteArticleApi(articleId)
    .then(payload => {
      dispatch({
        type: SUCCESS_FETCH_ARTICLES_LIST,
        payload: payload.data,
        notification: {
          type: 'success',
          text: ARTICLE_DELETED,
        },
      });
      return payload;
    })
    .catch(error => {
      dispatch({
        type: FAILURE_FETCH_ARTICLES_LIST,
        payload: error,
        notification: {
          type: 'error',
          text: ARTICLE_NOT_DELETED,
        },
      });
      return error;
    });
};
