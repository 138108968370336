import { Tabs } from 'antd';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

// Redux
import { connect } from 'react-redux';
import { fetchSitesListWithInformers } from '../../redux/actions/dataLists';
import { fetchStatisticsByType } from '../../redux/actions/statistics';

// Styles
import './styles.scss';

// Components
import TabsWithQuery from '../../components/TabsWithQuery';
import PageTitle from '../../components/PageTitle';
import RotatorStatistics from './components/RotatorStatistics';
import InformerStatistics from './components/InformerStatistics';

class StatisticsByType extends Component {
  componentDidMount() {
    if (!this.props.sitesListWithInformers.length) {
      this.props.fetchSitesListWithInformers();
    }
  }

  render() {
    const { t } = this.props;

    return (
      <div className="page-body page-main-settings statistics-block">
        <PageTitle
          title={t('content.title.statsByType')}
          hint={t('content.titleHint.statsByType')}
        />

        <TabsWithQuery type="card">
          <Tabs.TabPane tab={t('basic.rotator')} key="rotator">
            <RotatorStatistics />
          </Tabs.TabPane>

          <Tabs.TabPane tab={t('basic.informer')} key="informer">
            <InformerStatistics />
          </Tabs.TabPane>
        </TabsWithQuery>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    sitesListWithInformers: state.dataLists.sitesListWithInformers,
  };
}

const mapDispatchToProps = {
  fetchSitesListWithInformers,
  fetchStatisticsByType,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('')(StatisticsByType));
