import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Icon } from 'antd';
import { withTranslation } from 'react-i18next';

// Redux
import {
  fetchTable,
  addSite,
  deleteSite,
  editSite,
  fetchChart,
} from 'redux/actions/sites';
import { showModal } from 'redux/actions/modals';

// Utils
import { getDaysRange, getDaysAgoRange, datesArrayToUnix } from 'utils/date';

// Components
import { sitesHeader } from '../../components/Table/config/tables';
import LinearChart from '../../components/LinearChart';
import Table from '../../components/Table';
import PageTitle from '../../components/PageTitle';
import TooltipConditional from '../../components/Tooltip/TooltipConditional';
import { colorYellow } from '../../styles/js/variables/colors';
import { prepareFilters } from '../SitesList/helpers/prepareFilters';

class Sites extends Component {
  constructor(props) {
    super(props);

    this.state = {
      calendarDates: getDaysAgoRange(),
    };
  }

  componentDidMount() {
    this.handleUpdateAll(datesArrayToUnix(getDaysAgoRange()));
  }

  handleUpdateAll = dates => {
    const { props } = this;
    const { limit, offset } = props.table;
    const { publisherId } = props.match.params;

    this.setState({
      calendarDates: getDaysRange(dates),
    });

    props.fetchChart({
      start: +dates[0],
      end: +dates[1],
      publisherIds: publisherId,
    });

    props.fetchTable({
      limit,
      offset,
      start: +dates[0],
      end: +dates[1],
      publisherIds: publisherId,
    });
  };

  handleFetch = (page, sortBy, sortDirection, filters) => {
    const { props } = this;
    const { table, match } = props;
    const { publisherId } = match.params;
    const { limit, start, end } = table;
    const offset = (page - 1) * limit;

    props.fetchTable({
      limit,
      offset,
      start,
      end,
      sortBy,
      sortDirection,
      ...prepareFilters(filters),
      publisherIds: publisherId,
    });
  };

  handleModalAddSite = () => {
    const { props } = this;
    const { publisherId } = props.match.params;

    props.showModal({
      type: 'MODAL_ADD_SITE',
      params: {
        actions: {
          onSubmit: props.addSite,
        },
        success: {
          onSubmit: () =>
            props.fetchTable({
              limit: props.table.limit,
              offset: props.table.offset,
              start: props.table.start,
              end: props.table.end,
              publisherIds: publisherId,
            }),
        },
        publisherId,
      },
    });
  };

  handleModalEditSite = item => {
    const { props } = this;
    const { start, end } = this.props.table;

    props.showModal({
      type: 'MODAL_ADD_SITE',
      params: {
        fields: item,
        actions: {
          onSubmit: props.editSite,
        },
        success: {
          onSubmit: () => this.handleUpdateAll([start, end]),
        },
      },
    });
  };

  handleDeleteSite = ({ id }) => {
    const { start, end } = this.props.table;

    this.props.deleteSite(id).then(() => this.handleUpdateAll([start, end]));
  };

  // handleLinkSite = ({ domain }) => {
  //   const protocol = !domain.match(/^[a-zA-Z]+:\/\//) ? 'https://' : '';
  //
  //   return protocol + domain;
  // };

  handleSiteStatistics = ({ id }) => `${this.props.match.url}/${id}/statistics`;

  handleSite = ({ id }) => `${this.props.match.url}/${id}`;

  handleSiteInformers = ({ id }) =>
    `${this.props.match.url}/${id}/informers-list`;

  handleShowTooltip = ({ id, ...otherProps }) => {
    this.props.showModal({
      type: 'MODAL_CODE_BLOCK_SITE',
      params: {
        history: this.props.history,
        siteId: id,
        ...otherProps,
      },
    });
  };

  render() {
    const { calendarDates } = this.state;
    const {
      isChartLoading,
      isTableLoading,
      table,
      info,
      chart,
      t,
    } = this.props;
    const isEmptyTable = table.data.length === 0 && table.total === 0;

    return (
      <div className="page-body main-page">
        <PageTitle
          title={`${t('content.title.publisher')}: "${info.name || '...'}"`}
          hint={t('content.titleHint.publisher')}
          button={
            <TooltipConditional
              withTimeout
              isHiddenByDefault={false}
              withTooltip={!isTableLoading && isEmptyTable}
              defaultVisible={isEmptyTable}
              placement="bottomRight"
              title={t('tooltip.emptyListAddItem', {
                entity: t('basic.sites_5').toLowerCase(),
              })}
              color={colorYellow}
            >
              <Button type="success" onClick={this.handleModalAddSite}>
                <Icon type="plus" />
                {`${t('basic.add')} ${t('basic.site').toLowerCase()}`}
              </Button>
            </TooltipConditional>
          }
        />

        <LinearChart
          isShowChart={!!table.data.length}
          isLoading={isChartLoading}
          categoriesForX={calendarDates}
          onChange={this.handleUpdateAll}
          data={chart}
          isAutoKeys
          excludeKeys={['efficiencies']}
        />

        <Table
          isLoading={isTableLoading}
          headers={sitesHeader}
          data={table.data}
          total={table.total}
          limit={table.limit}
          fetch={this.handleFetch}
          actions={{
            page: this.handleSiteStatistics,
            informersList: this.handleSiteInformers,
            code: this.handleShowTooltip,
            editSite: this.handleSite,
            delete: this.handleDeleteSite,
          }}
          type="sitesActions"
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isChartLoading: state.sites.chart.isLoading,
    isTableLoading: state.sites.table.isLoading,
    chart: state.sites.chart,
    table: state.sites.table,
    info: state.publishers.info,
  };
}

const mapDispatchToProps = {
  showModal,
  fetchTable,
  fetchChart,
  addSite,
  editSite,
  deleteSite,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Sites));
