import React from 'react';
import { Button as ButtonAnt, Tooltip } from 'antd';

export default function Button(props) {
  const { tooltip, ...restProps } = props;

  return tooltip ? (
    <Tooltip title={tooltip}>
      <ButtonAnt {...restProps} />
    </Tooltip>
  ) : (
    <ButtonAnt {...restProps} />
  );
}
