import { Tabs, Card } from 'antd';
import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import TabsWithQuery from '../../TabsWithQuery';
import RotatorSettingsBlock from './RotatorSettingsBlock';
import InformerSettingsBlock from './InformerSettingsBlock';

const SettingsBlock = ({ handleChange, t }) => (
  <Fragment>
    <Card size="small">
      <h3>{t('basic.advancedSettings')}</h3>
      <TabsWithQuery type="card" tabBarStyle={{ textAlign: 'center' }}>
        <Tabs.TabPane key="informer" tab={t('basic.informer')}>
          <InformerSettingsBlock handleChange={handleChange} />
        </Tabs.TabPane>
        <Tabs.TabPane key="rotator" tab={t('basic.rotator')}>
          <RotatorSettingsBlock handleChange={handleChange} />
        </Tabs.TabPane>
      </TabsWithQuery>
    </Card>
  </Fragment>
);

export default withTranslation()(SettingsBlock);
