import React from 'react';

// Types
import {
  COLOR_GREY_TITLE,
  COLOR_GREY_LIGHT_TITLE,
} from '../../../constants/colors';

// Utils
import { Translation } from '../../Translation';
import { formatCell } from '../helpers/formatCell';

// Components
import columns from './columns';
import TableColumnTitle from '../components/TableColumnTitle';

export const getGroupHeaders = (keys, params = {}) => {
  const keysToColors = {
    analytics_wrapper: COLOR_GREY_TITLE,
    analytics_ctr_wrapper: COLOR_GREY_TITLE,
    analytics_views_wrapper: COLOR_GREY_TITLE,
    analytics_clicks_wrapper: COLOR_GREY_TITLE,
    ctr_informer: COLOR_GREY_TITLE,
    ctr_rotator: COLOR_GREY_TITLE,
    ctr_preview: COLOR_GREY_TITLE,
    clicks_informer: COLOR_GREY_TITLE,
    clicks_rotator: COLOR_GREY_TITLE,
    clicks_preview: COLOR_GREY_TITLE,
    views_informer: COLOR_GREY_TITLE,
    views_rotator: COLOR_GREY_TITLE,
    views_preview: COLOR_GREY_TITLE,

    clicks_wrapper: COLOR_GREY_LIGHT_TITLE,
    clicks: COLOR_GREY_LIGHT_TITLE,
    click_from: COLOR_GREY_LIGHT_TITLE,
    click_on: COLOR_GREY_LIGHT_TITLE,
    ctr: COLOR_GREY_LIGHT_TITLE,
    ko: COLOR_GREY_LIGHT_TITLE,
  };

  const views = {
    title: <Translation type="table.views_wrapper" />,
    dataIndex: 'views_wrapper',
    className: `tooltip-wrapper ant-table-column--${keysToColors.views_wrapper}`,
  };

  const clicks = {
    title: <Translation type="table.clicks_wrapper" />,
    dataIndex: 'clicks_wrapper',
    className: `tooltip-wrapper ant-table-column--${keysToColors.clicks_wrapper}`,
  };

  // Analytics
  const analyticsCtr = {
    title: <Translation type="table.analytics.ctr_wrapper" />,
    dataIndex: 'analytics_ctr_wrapper',
    className: `tooltip-wrapper ant-table-column--${keysToColors.analytics_ctr_wrapper}`,
  };
  const analyticsViews = {
    title: <Translation type="table.analytics.views_wrapper" />,
    dataIndex: 'analytics_views_wrapper',
    className: `tooltip-wrapper ant-table-column--${keysToColors.analytics_views_wrapper}`,
  };
  const analyticsClicks = {
    title: <Translation type="table.analytics.clicks_wrapper" />,
    dataIndex: 'analytics_clicks_wrapper',
    className: `tooltip-wrapper ant-table-column--${keysToColors.analytics_clicks_wrapper}`,
  };

  // Sort by blocks
  const keysToEl = {
    clicks,
    click_from: clicks,
    click_on: clicks,
    ctr: clicks,
    ko: clicks,

    views,
    display: views,
    views_another: views,
    views_another_real: views,
    views_our: views,
    views_our_real: views,
    views_real: views,

    ctr_informer: analyticsCtr,
    ctr_rotator: analyticsCtr,
    ctr_preview: analyticsCtr,
    clicks_informer: analyticsClicks,
    clicks_rotator: analyticsClicks,
    clicks_preview: analyticsClicks,
    views_informer: analyticsViews,
    views_rotator: analyticsViews,
    views_preview: analyticsViews,
  };

  keys.forEach(key => {
    const column = columns[key];
    const isSorted = params.sortBy === column.key;
    const { tooltip, name, key: columnKey } = column;

    keysToEl[key].children = keysToEl[key].children || [];

    keysToEl[key].children.push({
      title: <TableColumnTitle title={name} tooltip={tooltip} />,
      dataIndex: columnKey,
      className: `tooltip-wrapper ant-table-column--${keysToColors[key]}`,
      render: column?.render || formatCell,
      ...params,
      ...{ sortOrder: isSorted ? params.sortOrder : null },
    });
  });

  const analytics = {
    title: (
      <TableColumnTitle
        title={<Translation type="table.analytics.wrapper" />}
      />
    ),
    dataIndex: 'analytics_wrapper',
    className: `tooltip-wrapper ant-table-column--${keysToColors.analytics_wrapper}`,
    children: [
      ...(analyticsClicks.children ? [analyticsClicks] : []),
      ...(analyticsViews.children ? [analyticsViews] : []),
      ...(analyticsCtr.children ? [analyticsCtr] : []),
    ],
  };

  return [views, clicks, analytics].filter(
    item => item.children && item.children.length,
  );
};

export const getSingleHeader = (key, params = {}) => {
  const column = columns[key];
  const isSorted = params.sortBy === column.key;

  return {
    title: <TableColumnTitle title={column.name} />,
    // fixed: isMobile ? null : 'left',
    dataIndex: column.key,
    ...column,
    ...params,
    ...{ sortOrder: isSorted ? params.sortOrder : null },
  };
};
