import React, { useState } from 'react';
import { func, node, string } from 'prop-types';
import { Button } from 'antd';
import { withTranslation } from 'react-i18next';

const ButtonTogglerConditional = ({
  children,
  buttonTitle,
  buttonProps,
  t,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => setIsVisible(!isVisible);

  return (
    <div>
      <Button
        type="link"
        icon={`${isVisible ? 'minus' : 'plus'}-square`}
        {...buttonProps}
        onClick={toggleVisibility}
      >
        {buttonTitle ||
          `${t(`basic.${isVisible ? 'hide' : 'show'}`)} ${t(
            'basic.code',
          ).toLowerCase()}`}
      </Button>
      {isVisible && children}
    </div>
  );
};

ButtonTogglerConditional.propTypes = {
  children: node,
  buttonTitle: string,
  t: func,
};

export default withTranslation()(ButtonTogglerConditional);
