import {
  START_FETCH_TABLE_INFORMER_NEWS,
  SUCCESS_FETCH_TABLE_INFORMER_NEWS,
  FAILURE_FETCH_TABLE_INFORMER_NEWS,
  SUCCESS_FETCH_CHART_INFORMER_NEWS,
  START_FETCH_NEWS,
  SUCCESS_FETCH_NEWS,
  FAILURE_FETCH_NEWS,
} from 'redux/types';

import { getInformerNewsInfo, getNews } from 'api';

export const fetchTable = ({ isWithChart, ...params }) => dispatch => {
  dispatch({
    type: START_FETCH_TABLE_INFORMER_NEWS,
  });

  return getInformerNewsInfo(params)
    .then(({ data }) => {
      dispatch({
        type: SUCCESS_FETCH_TABLE_INFORMER_NEWS,
        payload: data,
      });

      if (isWithChart) {
        dispatch({
          type: SUCCESS_FETCH_CHART_INFORMER_NEWS,
          payload: data,
        });
      }
    })
    .catch(err => {
      dispatch({
        type: FAILURE_FETCH_TABLE_INFORMER_NEWS,
        payload: err,
        notification: {
          type: 'error',
          text: 'failure fetch data for informers news table',
        },
      });
    });
};

export const fetchNews = id => dispatch => {
  dispatch({
    type: START_FETCH_NEWS,
  });

  return getNews(id)
    .then(({ data }) => {
      dispatch({
        type: SUCCESS_FETCH_NEWS,
        payload: data,
      });
    })
    .catch(err => {
      dispatch({
        type: FAILURE_FETCH_NEWS,
        payload: err,
        notification: {
          type: 'error',
          text: 'failure fetch news',
        },
      });
    });
};
