import React, { useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Divider } from 'antd';

import {
  blockTypesKeys,
  newsGroupsKeys,
  getSelfOptionValues,
  getPeriodValues,
} from '../utils/lists';
import SelectsBlock from '../utils/SelectsBlocks';

const RotatorSettingsBlock = ({ informerSettings, handleChange, t }) => {
  const {
    rotator_last_self,
    rotator_main_self,
    rotator_rubric_self,

    rotator_last_period,
    rotator_main_period,
    rotator_rubric_period,
  } = informerSettings.settings;

  const selfOptions = useMemo(() => getSelfOptionValues(t), [t]);
  const periodValues = useMemo(() => getPeriodValues(t), [t]);

  return (
    <>
      <Divider style={{ marginBottom: 0 }}>Self</Divider>
      <SelectsBlock
        params={{
          rotator_last_self,
          rotator_main_self,
          rotator_rubric_self,
        }}
        list={selfOptions}
        labels={blockTypesKeys}
        onChange={handleChange}
      />
      <br />

      <Divider style={{ marginBottom: 0 }}>Period</Divider>
      <SelectsBlock
        params={{
          rotator_last_period,
          rotator_main_period,
          rotator_rubric_period,
        }}
        list={periodValues}
        labels={newsGroupsKeys}
        onChange={handleChange}
      />
    </>
  );
};

function mapStateToProps(state) {
  return {
    informerSettings: state.informerSettings,
  };
}

export default connect(mapStateToProps)(
  withTranslation()(RotatorSettingsBlock),
);
