import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { AutoComplete, Tooltip, Input, Form } from 'antd';
import Loader from 'components/Loader';
import ArticlePreviewModal from 'components/Modals/components/ArticlePreviewModal';
import { searchArticles } from 'redux/actions/publisherSettings';
import TooltipConditional from '../../../components/Tooltip/TooltipConditional';
import { ViewArticleButton } from '../styles';
import { SearchArticlesButton } from './SearchArticlesButton';

class SearchArticles extends PureComponent {
  state = {
    isModalOpen: false,
    isLoading: false,
    article: null,
    inputValue: '',
  };

  componentDidUpdate(prevProps) {
    const { selectedArticle, publisherSettings } = this.props;
    const { article } = this.state;
    if (
      selectedArticle &&
      publisherSettings.search !== prevProps.publisherSettings.search &&
      (!article || article.news_id !== selectedArticle.id)
    ) {
      const foundArticle = publisherSettings.search.results.find(
        item => item.news_id === selectedArticle.id,
      );
      if (foundArticle) {
        this.setState({
          article: {
            ...foundArticle,
            id: foundArticle.news_id,
          },
          inputValue: selectedArticle.title,
        });
      }
    }
  }

  getSearchResults = () => {
    const { results } = this.props.publisherSettings.search;
    const { Option } = AutoComplete;

    return (
      results &&
      results.map(({ news_id: articleId, ...restProps }) => (
        <Option key={articleId} title={restProps.title} data={{ ...restProps }}>
          <Tooltip placement="topLeft" title={restProps.title}>
            {restProps.title}
          </Tooltip>
        </Option>
      ))
    );
  };

  openModalHandler = () => this.setState({ isModalOpen: true });

  closeModalHandler = () => this.setState({ isModalOpen: false });

  selectHandler = (articleId, option) =>
    this.setState({
      article: articleId
        ? {
            id: articleId,

            ...option.props.data,
          }
        : null,
      inputValue: articleId ? option.props.title : '',
    });

  submitHandler = () => this.props.onSubmit(this.state.article);

  searchHandler = value => {
    this.setState({ isLoading: true });

    this.props
      .searchArticles(value)
      .then(() => this.setState({ isLoading: false }));
  };

  inputChangeHandler = value => {
    this.setState({ inputValue: value });
    this.debouncedSearchHandler(value);
  };

  // eslint-disable-next-line react/sort-comp
  debouncedSearchHandler = debounce(this.searchHandler, 300);

  autocompleteChangeHandler = value => {
    const { onSubmit } = this.props;
    if (!value && this.state.article) {
      this.selectHandler(null);
      onSubmit(null);
    }
  };

  render() {
    const { publisherSettings, isMobile, selectedArticle, t } = this.props;
    const { isLoading, article, isModalOpen, inputValue } = this.state;

    return (
      <div style={{ paddingRight: isMobile ? 0 : '10%' }}>
        <Form.Item label={t('form.articlePromotionSelection')}>
          <div
            style={{
              display: 'flex',
              position: 'relative',
              alignItems: 'flex-start',
            }}
          >
            <AutoComplete
              autoFocus
              optionLabelProp="title"
              placeholder={`${t('basic.searchArticle')}..`}
              dataSource={publisherSettings.search && this.getSearchResults()}
              allowClear
              onSearch={this.inputChangeHandler}
              onSelect={this.selectHandler}
              onChange={this.autocompleteChangeHandler}
              style={{ minWidth: isMobile ? '100%' : 'calc(100% - 90px)' }}
              value={inputValue}
            >
              <Input suffix={<Loader isLoading={isLoading} />} />
            </AutoComplete>

            <SearchArticlesButton
              article={article}
              selectedArticle={selectedArticle}
              submitHandler={this.submitHandler}
            />

            {article && (
              <TooltipConditional
                withTooltip
                placement="topRight"
                title={t('basic.viewArticle')}
              >
                <ViewArticleButton onClick={this.openModalHandler} />
              </TooltipConditional>
            )}
          </div>
        </Form.Item>
        {article && (
          <ArticlePreviewModal
            description={article.description}
            onClose={this.closeModalHandler}
            isOpen={isModalOpen}
            title={article.title}
            image={article.img}
            url={article.url}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ publisherSettings, userSettings }) => ({
  publisherSettings,
  isMobile: userSettings.isMobile,
});

const mapDispatchToProps = {
  searchArticles,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(SearchArticles));
