import styled from 'styled-components';
import { FaUser, FaImages } from 'react-icons/all';
import { colorGrey } from '../../styles/js/variables/colors';

export const SpeedupInfoWrapper = styled.div`
  margin: 15px 0;
  padding: 10px 20px 15px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  > * {
    width: 50%;

    &:nth-child(2) {
      padding-left: 20px;
      border-left: 1px solid #e5e5e5;
      margin-left: 20px;
      width: calc(50% - 20px);
    }

    @media (max-width: 768px) {
      border-color: transparent;
      padding-left: 0;
      width: 100%;
      margin: 10px 0;

      &:nth-child(2) {
        padding-left: 0;
        border-left: 0;
      }
    }
  }
`;

export const SpeedupInfoTitle = styled.div`
  text-align: center;
  font-size: 18px;
  margin-bottom: 5px;
  opacity: 0.75;
`;

export const SpeedupInfoDescription = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

const iconStyles = `
  width: auto;
  height: 40px;
  color: ${colorGrey};
  margin-right: 10px;
  opacity: 0.7;
`;

export const SpeedupInfoDescriptionIcon = styled(FaUser)`
  ${iconStyles}
`;
export const SpeedupInfoImagesIcon = styled(FaImages)`
  ${iconStyles}
`;

export const SpeedupInfoLabel = styled.div`
  opacity: 0.75;
  font-size: 12px;
`;

export const SpeedupInfoComment = styled.div`
  font-size: 16px;
  line-height: 1.6;
  width: 300px;
  font-family: Georgia;
  font-style: italic;
  font-weight: normal;

  div {
    font-weight: normal;
    margin-bottom: 4px;
  }
`;
