import React, { useState } from 'react';
import { bool, func } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { Button, Form, Icon, Input } from 'antd';

// Actions
import { signInAction } from '../../../redux/actions/auth';

// Types
import { START_SIGN_IN_AUTH } from '../../../redux/types';

// Utils
import {
  lengthMustBeMoreAndLess,
  mustBeAllowedSymbols,
  mustBeFilled,
} from '../../../config/validationRules';
import {
  getLoginRedirect,
  removeLoginRedirect,
} from '../../../services/localStorage';
import useValidation from '../utils/useValidation';

// Components
import FormCaptcha from '../components/FormCaptcha';
import Loader from '../../Loader';
import PageTitle from '../../PageTitle';

const initialForm = {
  login: {
    value: '',
    rules: [
      mustBeFilled,
      lengthMustBeMoreAndLess(1, 256),
      mustBeAllowedSymbols,
    ],
  },
  password: {
    value: '',
    rules: [mustBeFilled, lengthMustBeMoreAndLess(1, 256)],
  },
  gRecaptchaResponse: {
    value: null,
    rules: [mustBeFilled],
  },
};

const LoginFrom = props => {
  const { isLoading, history, signIn, t } = props;
  const loginRedirect = getLoginRedirect(); // check if user were redirected
  const redirectPath = loginRedirect ? loginRedirect.pathname : '/';
  const [isServerError, setIsServerError] = useState(null);

  const onSubmit = currentForm =>
    signIn(currentForm)
      .then(response => {
        setIsServerError(false);
        return !response.fail || Promise.reject();
      })
      .then(isSuccess => (isSuccess && removeLoginRedirect()) || isSuccess)
      .then(isSuccess => isSuccess && history.push(redirectPath))
      .catch(() => setIsServerError(true));

  const { bindedInputFunctions, bindedSubmit, errors } = useValidation(
    onSubmit,
    initialForm,
  );

  return (
    <>
      <Loader isLoading={isLoading} />

      <PageTitle
        title={t('content.title.login')}
        hint={t('content.titleHint.login')}
      />

      <Form className="login-form">
        <Form.Item {...errors.login}>
          <Input
            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            name="login"
            placeholder={t('basic.username')}
            autoComplete="off"
            size="large"
            defaultValue={initialForm.login.value}
            {...bindedInputFunctions}
          />
        </Form.Item>

        <Form.Item {...errors.password}>
          <Input.Password
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            name="password"
            placeholder={t('basic.password')}
            autoComplete="off"
            size="large"
            defaultValue={initialForm.password.value}
            {...bindedInputFunctions}
          />
        </Form.Item>

        <Form.Item {...errors.gRecaptchaResponse} style={{ minHeight: '78px' }}>
          <FormCaptcha
            name="gRecaptchaResponse"
            isServerError={isServerError}
            {...bindedInputFunctions}
          />
        </Form.Item>

        <Form.Item>
          <Button
            size="large"
            type="primary"
            onClick={bindedSubmit}
            style={{ width: '100%' }}
          >
            {t('form.buttonLogin')}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

LoginFrom.propTypes = {
  isLoading: bool,
  signInAction: func,
  t: func,
};

const mapStateToProps = ({ auth, network }) => ({
  status: auth.user.status,
  isLoading: network[START_SIGN_IN_AUTH],
});

const mapDispatchToProps = dispatch => ({
  signIn: bindActionCreators(signInAction, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withTranslation('')(LoginFrom)));
