import React from 'react';
import { Divider } from 'antd';
import { connect } from 'react-redux';
import Table from '../../../components/Table';
import { fetchStatisticsByType } from '../../../redux/actions/statistics';

const StatisticsBlock = ({
  blockTitle,
  statistics,
  isRotator,
  isLoading,
  handleFetch,
}) => {
  const blockType = isRotator ? 'rotator' : 'informer';
  const table = isRotator
    ? statistics[blockType][blockTitle]
    : statistics[blockType];

  return (
    <div className="statistics-block">
      {blockTitle && <Divider>{blockTitle.toUpperCase()}</Divider>}
      <Table
        isAutoHeaders
        isAutoHeadersSort={false}
        className="statistics-block__table"
        isLoading={isLoading}
        fetch={handleFetch}
        rowKey="position"
        {...table}
      />
      <br />
      <br />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    sitesList: state.dataLists.sitesList,
    statistics: state.statistics.blocks,
  };
}

const mapDispatchToProps = {
  fetchStatisticsByType,
};

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsBlock);
