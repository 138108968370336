import {
  FAILURE_GET_SIDEBAR_VISIBILITY,
  SET_CURRENT_PAGE,
  START_GET_SIDEBAR_VISIBILITY,
  SUCCESS_GET_SIDEBAR_VISIBILITY,
  SET_IS_MOBILE,
} from '../types';

import {
  getSidebarVisibility,
  setSidebarVisibility,
} from '../../services/localStorage';

export const getSidebarVisibilityLoc = () => dispatch => {
  dispatch({
    type: START_GET_SIDEBAR_VISIBILITY,
  });

  return getSidebarVisibility()
    .then(isVisible =>
      dispatch({
        type: SUCCESS_GET_SIDEBAR_VISIBILITY,
        payload: isVisible,
      }),
    )
    .catch(error => {
      dispatch({
        type: FAILURE_GET_SIDEBAR_VISIBILITY,
        payload: error,
      });
    });
};

export const setSidebarVisibilityLoc = value => dispatch => {
  setSidebarVisibility(value);

  dispatch({
    type: SUCCESS_GET_SIDEBAR_VISIBILITY,
    payload: value,
  });
};

export const setCurrentPage = value => dispatch => {
  dispatch({
    type: SET_CURRENT_PAGE,
    payload: value,
  });
};

export const setIsMobileAction = isMobile => dispatch => {
  dispatch({
    type: SET_IS_MOBILE,
    payload: { data: isMobile },
  });
};
