import * as api from '../../api';
import { basicAction } from '../helpers/actions';
import * as T from '../types';

export const fetchSite = id => dispatch => {
  dispatch({
    type: T.START_FETCH_SITE,
  });

  return api
    .getSiteById(id)
    .then(payload => {
      dispatch({
        type: T.SUCCESS_FETCH_SITE,
        payload: payload.data,
      });
    })
    .catch(error => {
      dispatch({
        type: T.FAILURE_FETCH_SITE,
        payload: error,
        notification: {
          type: 'error',
          text: `Fetch site has failure. Status ${error.status}`,
        },
      });
    });
};

export const fetchChart = params => dispatch => {
  dispatch({
    type: T.START_FETCH_CHART_INFORMERS,
  });

  return api
    .getInformersStatisticBySite(params)
    .then(payload => {
      dispatch({
        type: T.SUCCESS_FETCH_CHART_INFORMERS,
        payload: payload.data,
      });
    })
    .catch(error => {
      dispatch({
        type: T.FAILURE_FETCH_CHART_INFORMERS,
        payload: error,
        notification: {
          type: 'error',
          text: `Fetch chart informers of sites has failure. Status ${error.status}`,
        },
      });
    });
};

export const fetchTable = params => dispatch => {
  dispatch({
    type: T.START_FETCH_TABLE_INFORMERS,
  });

  const { site_id: id, ...tableSettings } = params;

  return api
    .getInformersBySite(params)
    .then(payload =>
      dispatch({
        type: T.SUCCESS_FETCH_TABLE_INFORMERS,
        payload: {
          ...payload.data,
          ...tableSettings,
        },
      }),
    )
    .catch(error =>
      dispatch({
        type: T.FAILURE_FETCH_TABLE_INFORMERS,
        payload: error,
        notification: {
          type: 'error',
          text: `Fetch table informers of sites has failure. Status ${error.status}`,
        },
      }),
    );
};

export const fetchStatisticsTable = ({
  isWithChart,
  ...params
}) => dispatch => {
  dispatch({
    type: T.START_FETCH_TABLE_SITE_STATISTICS,
  });

  const { siteId, ...tableSettings } = params;

  return api
    .getSiteStatisticsTable(params)
    .then(payload => {
      dispatch({
        type: T.SUCCESS_FETCH_TABLE_SITE_STATISTICS,
        payload: {
          ...payload.data,
          ...tableSettings,
        },
      });

      if (isWithChart) {
        dispatch({
          type: T.SUCCESS_FETCH_CHART_SITE_STATISTICS,
          payload: payload.data,
        });
      }
    })
    .catch(error => {
      dispatch({
        type: T.FAILURE_FETCH_TABLE_SITE_STATISTICS,
        payload: error,
        notification: {
          type: 'error',
          text: `Fetch site statistics has failure. Status ${error.status}`,
        },
      });
    });
};

export const getSiteNews = params => dispatch => {
  dispatch({
    type: T.START_FETCH_SITE_NEWS,
  });

  return api
    .getSiteNewsApi(params)
    .then(response => {
      const { data } = response;

      dispatch({
        type: T.SUCCESS_FETCH_SITE_NEWS,
        payload: data,
      });
    })
    .catch(fail => {
      dispatch({
        type: T.FAILURE_FETCH_SITE_NEWS,
        fail,
      });
    });
};

export const getSiteStatusesHistory = params => dispatch =>
  basicAction({
    apiCall: () => api.getSiteStatusesHistory(params),
    type: T.FETCH_SITE_STATUSES_HISTORY,
  })(dispatch);

export const editSite = params => dispatch =>
  basicAction({
    apiCall: () => api.editSiteById(params),
    type: T.EDIT_SITE_FORM,
    payloadData: params,
  })(dispatch);

export const getSites = params => dispatch =>
  basicAction({
    apiCall: () => api.getSitesListApi(params),
    type: T.FETCH_SITES_LIST,
  })(dispatch);

export const getScriptsChecksLog = params => dispatch =>
  basicAction({
    apiCall: () => api.getScriptsChecksLog(params),
    type: T.FETCH_SCRIPT_CHECKS_LOG,
  })(dispatch);

export const getSiteParsingLog = params => dispatch =>
  basicAction({
    apiCall: () => api.getSiteParsingLog(params),
    type: T.FETCH_PARSING_ERRORS,
  })(dispatch);
