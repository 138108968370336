import React, { Component } from 'react';
import { FaInfoCircle } from 'react-icons/all';
import { connect } from 'react-redux';
import { func, bool, shape } from 'prop-types';
import { DatePicker, Select, InputNumber, Form } from 'antd';
import { moment } from 'utils/date';
import { withTranslation } from 'react-i18next';
import locale from 'antd/es/date-picker/locale/ru_RU';
import { debounce } from 'lodash';

// Redux
import { fetchAbTestingStatistics } from 'redux/actions/statistics';

// Utils
import { devicesList } from '../../constants/devices';

// Components
import Table from '../../components/Table';
import PageTitle from '../../components/PageTitle';
import { abTestingHeader } from '../../components/Table/config/tables';
import TooltipConditional from '../../components/Tooltip/TooltipConditional';
import { colorGreen } from '../../styles/js/variables/colors';

const { RangePicker } = DatePicker;

class AbTestsStatistics extends Component {
  state = {
    from: moment().set({ hour: 0, minute: 0 }),
    to: moment(),
    deviceType: null,
    abTest: null,
    clicksLimit: 100,
  };

  componentDidMount() {
    this.fetchAbTestingStatistics();
  }

  fetchAbTestingStatistics = (page, sortBy, sortDirection, filters = {}) => {
    const { from, to, deviceType, abTest, clicksLimit } = this.state;
    this.props.fetchAbTestingStatistics({
      start: from.unix() * 1000,
      end: to.unix() * 1000,
      ...(deviceType !== null && { device_type: deviceType }),
      ...(clicksLimit && clicksLimit >= 0 && { clicks_limit: clicksLimit }),
      ...(abTest && abTest >= 0 && { a_b_c: abTest }),
    });
  };

  onDateChange = ([from, to]) => this.setState({ from, to });

  onClicksLimitChange = value => {
    this.setState({ clicksLimit: value }, this.fetchAbTestingStatistics);
  };

  onSelectChange = value => {
    this.setState({ deviceType: value }, this.fetchAbTestingStatistics);
  };

  onAbTestChange = value => {
    this.setState({ abTest: value }, this.fetchAbTestingStatistics);
  };

  render() {
    const { from, to, deviceType, clicksLimit } = this.state;
    const { abTestsStatistics, isLoading, t } = this.props;

    return (
      <div className="page-body page-main-settings">
        <PageTitle
          title={t('content.title.abTestsStatistics')}
          hint={t('content.titleHint.abTestsStatistics')}
        />

        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <Form.Item label={t('basic.dates')}>
            <RangePicker
              allowClear={false}
              style={{ minWidth: 400 }}
              showTime={{ format: 'HH:mm' }}
              format="DD.MM.YYYY, HH:mm"
              onChange={this.onDateChange}
              onOk={this.fetchAbTestingStatistics}
              locale={locale}
              value={[from, to]}
              ranges={{
                [t('dates.today')]: [moment().startOf('day'), moment()],
                [t('dates.thisWeek')]: [moment().startOf('week'), moment()],
                [t('dates.thisMonth')]: [moment().startOf('month'), moment()],
              }}
            />
          </Form.Item>
          &nbsp;
          <Form.Item
            label={
              <>
                <TooltipConditional
                  withTooltip
                  defaultVisible={false}
                  placement="topRight"
                  title={t('tooltip.clicksPeriodToPublisherFromPublisher')}
                >
                  <FaInfoCircle color={colorGreen} />
                </TooltipConditional>
                &nbsp;К-во кликов
              </>
            }
          >
            <InputNumber
              style={{ minWidth: 120 }}
              defaultValue={clicksLimit}
              onChange={debounce(e => this.onClicksLimitChange(e), 300)}
            />
          </Form.Item>
          &nbsp;
          <Form.Item label={t('basic.devices')}>
            <Select
              defaultValue={deviceType}
              onChange={this.onSelectChange}
              style={{ minWidth: 120 }}
            >
              <Select.Option value={null}>
                {`-- ${t('basic.all')} ${t('basic.devices').toLowerCase()} --`}
              </Select.Option>
              {devicesList.map(type => (
                <Select.Option key={type} value={type}>
                  {t(`device.${type}`)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          &nbsp;
          <Form.Item label="A/B-test..">
            <InputNumber
              placeholder="52.."
              style={{ maxWidth: 'em' }}
              onChange={debounce(e => this.onAbTestChange(e), 300)}
            />
          </Form.Item>
        </div>
        <Table
          isLoading={isLoading}
          headers={abTestingHeader}
          rowKey="ab_title"
          data={abTestsStatistics.data}
          total={abTestsStatistics.total}
          limit={abTestsStatistics.limit}
          fetch={this.fetchAbTestingStatistics}
        />
      </div>
    );
  }
}

AbTestsStatistics.defaultProps = {
  abTestsStatistics: {},
  isLoading: false,
};

AbTestsStatistics.propTypes = {
  abTestsStatistics: shape({}),
  isLoading: bool,
  fetchAbTestingStatistics: func,
};

const mapStateToProps = ({ statistics, network }) => ({
  abTestsStatistics: statistics.abTests,
  isLoadingStats: network.START_FETCH_GLOBAL_STATISTICS,
  isLoading: network.START_FETCH_AB_TESTING_STATISTICS,
});

const mapDispatchToProps = {
  fetchAbTestingStatistics,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('')(AbTestsStatistics));
