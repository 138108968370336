import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { statusesAbTests } from '../../../constants/statuses';

export const TestsWeightCell = ({ current, row }) => {
  const { t } = useTranslation();
  const isActive = row?.status === statusesAbTests.STATUS_ACTIVE;
  const { totalWeight } = useSelector(({ abTests }) => abTests);
  const [percent, setPercent] = React.useState(0);

  const countPercent = () =>
    setPercent(Math.round((current / totalWeight) * 100));

  useEffect(countPercent, [totalWeight]);

  return (
    <>
      {`${current}/${totalWeight}`}
      <br />
      <small style={{ opacity: 0.5, whiteSpace: 'nowrap' }}>
        ({isActive ? `~${percent}%` : t('basic.notIncluded')})
      </small>
    </>
  );
};
