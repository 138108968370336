import { SUCCESS_FETCH_MODERATION_LIST } from '../types/articles';
import {
  SUCCESS_SITE_MODERATION,
  SUCCESS_FETCH_SITES_STATUSES,
} from '../types';

const initialState = {
  list: [],
  statuses: {},
};

export default function moderation(state = initialState, action = {}) {
  switch (action.type) {
    case SUCCESS_FETCH_MODERATION_LIST:
      return {
        ...state,
        list: action.payload.data,
      };

    case SUCCESS_FETCH_SITES_STATUSES:
      return {
        ...state,
        statuses: action.payload.data,
      };

    case SUCCESS_SITE_MODERATION:
      return {
        ...state,
        list: state.list.filter(item => item.id !== action.payload.id),
      };

    default:
      return state;
  }
}
