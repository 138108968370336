import { basicAction } from '../helpers/actions';
import * as T from '../types';
import * as api from '../../api';

// Label: LOGIC

export const getAbTestsLogic = () => dispatch =>
  basicAction({
    apiCall: api.getAbTestsLogic,
    type: T.FETCH_AB_TESTS_LOGIC,
  })(dispatch);

export const addAbTestLogic = (params = {}) => dispatch =>
  basicAction({
    apiCall: () => api.addAbTestLogicApi(params),
    type: T.ADD_AB_TEST_LOGIC,
    addToPayload: params,
  })(dispatch);

export const editAbTestLogic = (params = {}) => dispatch =>
  basicAction({
    apiCall: () => api.editAbTestLogicApi(params),
    type: T.EDIT_AB_TEST_LOGIC,
    addToPayload: params,
  })(dispatch);

// Label: TESTS

export const getAbTests = () => dispatch =>
  basicAction({
    apiCall: api.getAbTests,
    type: T.FETCH_AB_TESTS,
  })(dispatch);

export const addAbTest = (params = {}) => dispatch =>
  basicAction({
    apiCall: () => api.addAbTestApi(params),
    type: T.ADD_AB_TEST,
    addToPayload: params,
  })(dispatch);

export const editAbTest = (params = {}) => dispatch =>
  basicAction({
    apiCall: () => api.editAbTestApi(params),
    type: T.EDIT_AB_TEST,
    addToPayload: params,
  })(dispatch);
