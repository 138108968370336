import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import AddArticleForm from '../../Form/forms/AddArticleForm';

const AddArticleModal = ({ fields, onClose, actions, success, t }) => {
  const handleSubmit = form =>
    actions
      .onSubmit(form)
      .then(response => response && response.status === 200 && onClose())
      .then(success.onSubmit);

  return (
    <>
      <h3>{t(fields ? 'form.title.editArticle' : 'form.title.addArticle')}</h3>
      <AddArticleForm
        formData={fields}
        onSubmit={handleSubmit}
        onCancel={onClose}
      />
    </>
  );
};

AddArticleModal.propTypes = {
  onClose: PropTypes.func,
  success: PropTypes.shape({
    onSubmit: PropTypes.func,
  }),
};

AddArticleModal.defaultProps = {
  onClose: () => {},
  success: {
    onSubmit: () => {},
  },
};

export default withTranslation('')(AddArticleModal);
