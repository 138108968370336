import React, { useEffect } from 'react';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { FaVial, FaFileAlt } from 'react-icons/fa';
import { useDispatch } from 'react-redux';

// Components
import PageTitle from '../../components/PageTitle';
import { statusesAbTests } from '../../constants/statuses';
import {
  editAbTestLogic,
  addAbTestLogic,
  editAbTest,
  addAbTest,
  getAbTests,
  getAbTestsLogic,
} from '../../redux/actions/abTests';
import { showModal } from '../../redux/actions/modals';
import { colorGreen, colorYellow } from '../../styles/js/variables/colors';
import { LogicTable } from './components/LogicTable';
import { TestsTable } from './components/TestsTable';

export const AbTests = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  // Label: LOGIC ACTIONS
  const onEditAbTestsLogic = item => {
    const isEditMode = Object.keys(item).length > 0;
    const action = isEditMode ? editAbTestLogic : addAbTestLogic;

    dispatch(
      showModal({
        type: 'MODAL_ADD_AB_TESTS_LOGIC',
        modalWidth: 400,
        params: {
          fields: item,
          isEditMode,
          actions: {
            onSubmit: params => dispatch(action(params)),
          },
        },
      }),
    );
  };

  const onAbLogicDelete = item => {
    dispatch(
      editAbTestLogic({ ...item, status: statusesAbTests.STATUS_DELETED }),
    );
  };

  // Label TESTS ACTIONS
  const editAbTestStatus = ({ item, newStatus }) =>
    dispatch(
      editAbTest({
        ...item,
        status: newStatus,
      }),
    );

  const onEditAbTest = item => {
    const action = Object.keys(item).length ? editAbTest : addAbTest;

    return dispatch(
      showModal({
        type: 'MODAL_ADD_AB_TEST',
        modalWidth: 400,
        params: {
          fields: item,
          actions: {
            onSubmit: params => dispatch(action(params)),
          },
        },
      }),
    );
  };

  useEffect(() => {
    dispatch(getAbTests());
    dispatch(getAbTestsLogic());
  }, [dispatch]);

  return (
    <div>
      <PageTitle
        title={t('content.title.abTests')}
        hint={t('content.titleHint.abTests')}
      />
      <Tabs type="card" defaultActiveKey="tests">
        <Tabs.TabPane
          tab={
            <>
              <FaFileAlt color={colorYellow} /> {t('basic.logic')}
            </>
          }
          key="logic"
        >
          <LogicTable onEdit={onEditAbTestsLogic} onDelete={onAbLogicDelete} />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <>
              <FaVial color={colorGreen} /> {t('basic.tests')}
            </>
          }
          key="tests"
        >
          <TestsTable
            onEditAbTestsLogic={onEditAbTestsLogic}
            onEdit={onEditAbTest}
            onActivate={item =>
              editAbTestStatus({
                item,
                newStatus: statusesAbTests.STATUS_ACTIVE,
              })
            }
            onDeactivate={item =>
              editAbTestStatus({
                item,
                newStatus: statusesAbTests.STATUS_FINISHED,
              })
            }
          />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};
