import React from 'react';
import { Progress } from 'antd';

const XpathsProgress = ({ title, row }) => {
  const xpathsKeys = [
    'description_xpath',
    'content_xpath',
    'image_xpath',
    'title_xpath',
    'date_xpath',
  ];
  const stylesByPercent = {
    0: {
      status: 'exception',
      trailColor: 'transparent',
    },
    100: {
      status: 'success',
      trailColor: 'transparent',
      strokeColor: 'transparent',
    },
  };

  const filled = Object.entries(row).reduce(
    (acc, [key, value]) =>
      acc + Number(xpathsKeys.includes(key) && value && value.length > 0),
    0,
  );

  const percent = filled ? Math.ceil(xpathsKeys.length / filled) * 100 : 0;

  return (
    <Progress
      width={25}
      type="circle"
      percent={percent}
      style={{ margin: '-5px' }}
      status="normal"
      {...stylesByPercent[percent]}
    />
  );
};

export default XpathsProgress;
