import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter, Prompt } from 'react-router';
import { Row, Col, notification, Icon, Tabs } from 'antd';
import { withTranslation } from 'react-i18next';

// Types
import {
  START_FETCH_INFORMERS_DATA_BY_NEWS_ID,
  START_FETCH_INFORMERS_DATA_FOR_ROTATOR_BY_NEWS_ID,
} from '../../redux/types';

// Redux
import {
  fetchSitesWithInformers,
  fetchInformerCustomArticles,
  fetchInformersDataByNewsId,
  promoteArticle,
  searchArticles,
  fetchCustomArticles,
  fetchCustomArticlesRotator,
  fetchInformersDataForRotatorByNewsId,
  fetchSitesWithInformersForRotator,
  promoteArticleForRotator,
} from '../../redux/actions/publisherSettings';
import { PromotionStats } from './components/PromotionStats';

// Components
import SearchArticles from './components/SearchArticles';
import TransferTree from './components/TransferTree';
import ArticleDetails from './components/ArticleDetails';
import PageTitle from '../../components/PageTitle';
import Button from '../../components/Button';
import ArticlesListModal from '../../components/Modals/components/ArticlesListModal';

const { TabPane } = Tabs;
const modeTypes = [
  { key: 'informer', title: 'Информер' },
  { key: 'rotator', title: 'Ротатор' },
];

class ArticlePromotion extends Component {
  state = {
    selectedArticle: null,
    articleModal: null,
    informer: {
      targetKeys: [],
      selectedInformers: [],
      commonPosition: undefined,
      invalidInformersIds: [],
    },
    rotator: {
      targetKeys: [],
      selectedInformers: [],
      commonPosition: undefined,
      commonBlock: undefined,
      invalidInformersIds: [],
      invalidInformersBlocksIds: [],
    },
    mode: 'informer',
  };

  componentDidMount() {
    this.props.fetchSitesWithInformers();
    this.props.fetchSitesWithInformersForRotator();
    this.props.fetchCustomArticles();
    this.props.fetchCustomArticlesRotator();

    this.unlisten = this.props.history.listen(location => {
      // console.log('route changes', location);
    });
  }

  componentDidUpdate(prevProps) {
    const { mode } = this.state;
    const informersData = this.props.informersDataSource[mode];

    if (
      informersData !== prevProps.informersDataSource[mode] &&
      informersData.length
    ) {
      this.transferChangeHandler(
        informersData.map(informer => String(informer.informer_id)),
      );
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  transferChangeHandler = targetKeys => {
    const { mode } = this.state;
    const selectedInformers = this.props.informersSource[mode]
      .filter(informer => targetKeys.includes(informer.key))
      .map(
        informer =>
          this.state[mode].selectedInformers.find(
            item => informer.key === item.key,
          ) || informer,
      );
    this.setState(state => ({
      [state.mode]: { ...state[state.mode], targetKeys, selectedInformers },
    }));
  };

  articleSubmitHandler = article => {
    this.setState(state => ({
      selectedArticle: article,
      informer: {
        ...state.informer,
        commonPosition: undefined,
        targetKeys: [],
        selectedInformers: [],
      },
      rotator: {
        ...state.rotator,
        commonPosition: undefined,
        targetKeys: [],
        selectedInformers: [],
      },
    }));
    if (article) {
      this.props.fetchInformersDataByNewsId(article.id);
      this.props.fetchInformersDataForRotatorByNewsId(article.id);
    }
  };

  sortHandler = field => (a, b) => {
    if (a[field] > b[field]) return -1;
    if (a[field] < b[field]) return 1;
    return 0;
  };

  positionChangeHandler = (position, informerId) => {
    if (informerId) {
      this.setState(state => ({
        [state.mode]: {
          ...state[state.mode],
          invalidInformersIds: state[state.mode].invalidInformersIds.filter(
            id => id !== informerId,
          ),
          selectedInformers: state[state.mode].selectedInformers.map(informer =>
            informer.key === informerId ? { ...informer, position } : informer,
          ),
        },
      }));
    } else {
      this.setState(state => ({
        [state.mode]: {
          ...state[state.mode],
          invalidInformersIds: [],
          commonPosition: position,
          selectedInformers: state[state.mode].selectedInformers.map(
            informer => ({
              ...informer,
              position,
            }),
          ),
        },
      }));
    }
  };

  blockChangeHandler = (block, informerId) => {
    if (informerId) {
      this.setState(state => ({
        [state.mode]: {
          ...state[state.mode],
          invalidInformersIds: state[state.mode].invalidInformersIds.filter(
            id => id !== informerId,
          ),
          selectedInformers: state[state.mode].selectedInformers.map(informer =>
            informer.key === informerId ? { ...informer, block } : informer,
          ),
        },
      }));
    } else {
      this.setState(state => ({
        [state.mode]: {
          ...state[state.mode],
          invalidInformersIds: [],
          commonBlock: block,
          selectedInformers: state[state.mode].selectedInformers.map(
            informer => ({
              ...informer,
              block,
            }),
          ),
        },
      }));
    }
  };

  submitHandler = () => {
    const { selectedArticle, mode } = this.state;
    const { selectedInformers } = this.state[mode];
    const submitAction = {
      informer: this.props.promoteArticle,
      rotator: this.props.promoteArticleForRotator,
    };
    const isRotatorMode = mode === 'rotator';

    const invalidInformersIds = [];
    const invalidInformersBlocksIds = [];
    const data = selectedInformers.map(({ key, position, block }) => {
      if (!position) {
        invalidInformersIds.push(key);
      }

      if (isRotatorMode && !block) {
        invalidInformersBlocksIds.push(key);
      }

      return {
        informer_id: key,
        news_id: selectedArticle.id,
        position,
        ...(isRotatorMode && { block }),
      };
    });

    this.setState(state => ({
      [state.mode]: {
        ...state[state.mode],
        invalidInformersIds,
        invalidInformersBlocksIds,
      },
    }));

    let isValid = true;

    if (invalidInformersIds.length) {
      notification.error({
        message: 'Выберите позиции для всех информеров!',
      });
      isValid = false;
    }
    if (isRotatorMode && invalidInformersBlocksIds.length) {
      notification.error({
        message: 'Выберите блоки для всех информеров!',
      });
      isValid = false;
    }

    if (!isValid) return;

    submitAction[mode]({ data, news_id: selectedArticle.id });
  };

  openModalHandler = mode => this.setState({ articleModal: mode });

  closeModalHandler = () => this.setState({ articleModal: null });

  articleClickHandler = (article, mode) => {
    const articleId = String(article.id);
    if (articleId !== this.state.selectedArticle) {
      this.setState(
        state => ({
          selectedArticle: article,
          mode,
          informer: {
            ...state.informer,
            invalidInformersIds: [],
          },
          rotator: {
            ...state.rotator,
            invalidInformersIds: [],
            invalidInformersBlocksIds: [],
          },
        }),
        () => {
          this.articleSubmitHandler(article);
          this.props.searchArticles(article.title);
        },
      );
    }
  };

  modeChangeHandler = mode => this.setState({ mode });

  render() {
    const {
      customArticlesListSource,
      informersDataSource,
      isLoadingSource,
      informersSource,
      sitesSource,
      isMobile,
      t,
    } = this.props;
    const { selectedArticle, articleModal, mode } = this.state;
    const {
      invalidInformersBlocksIds,
      invalidInformersIds,
      selectedInformers,
      commonPosition,
      commonBlock,
      targetKeys,
    } = this.state[mode];

    return (
      <>
        <Prompt
          when={!!selectedArticle}
          message={JSON.stringify({
            title: t('basic.warning'),
            content: t('confirm.unsavedChanges'),
          })}
        />
        <Row>
          <Col md={12} xs={24}>
            <PageTitle
              title={t('content.title.articlesPromotion')}
              hint={t('content.titleHint.articlesPromotion')}
            />
            <SearchArticles
              selectedArticle={selectedArticle}
              onSubmit={this.articleSubmitHandler}
            />
          </Col>
          <Col md={3} xs={24} />
          <Col md={9} xs={24}>
            <PromotionStats
              isMobile={isMobile}
              customArticlesListSource={customArticlesListSource}
              openModalHandler={this.openModalHandler}
            />
          </Col>
        </Row>
        <Tabs type="card" activeKey={mode} onTabClick={this.modeChangeHandler}>
          {modeTypes.map(modeType => (
            <TabPane
              key={modeType.key}
              tab={
                <>
                  <Icon type="pic-center" />
                  {modeType.title}
                </>
              }
            >
              <Row>
                <Col md={15} xs={24}>
                  <TransferTree
                    invalidIds={invalidInformersIds}
                    invalidBlocksIds={invalidInformersBlocksIds}
                    onPositionChange={this.positionChangeHandler}
                    onBlockChange={this.blockChangeHandler}
                    commonPosition={commonPosition}
                    commonBlock={commonBlock}
                    targetObjects={selectedInformers}
                    showSelectAll
                    transferData={informersSource[mode]}
                    dataSource={sitesSource[mode]}
                    targetKeys={targetKeys}
                    onChange={this.transferChangeHandler}
                    disabled={!selectedArticle}
                    isLoading={isLoadingSource[mode]}
                    mode={mode}
                  />
                  <br />
                  <div className="text-right">
                    <Button
                      disabled={!selectedArticle}
                      tooltip={!selectedArticle && 'Сначала выберите статью'}
                      onClick={this.submitHandler}
                      type="primary"
                    >
                      {t('form.buttonSave')}
                    </Button>
                  </div>
                </Col>
                <Col md={9} xs={24}>
                  <ArticleDetails
                    mode={mode}
                    selectedArticle={selectedArticle}
                    tableSortHandler={this.sortHandler}
                    isLoading={isLoadingSource[mode]}
                    tableProps={{
                      dataSource: informersDataSource[mode],
                    }}
                  />
                </Col>
              </Row>
            </TabPane>
          ))}
        </Tabs>
        <ArticlesListModal
          onArticleClick={this.articleClickHandler}
          onClose={this.closeModalHandler}
          articleModal={articleModal}
          list={customArticlesListSource[articleModal]}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    isMobile: state.userSettings.isMobile,

    // Data depends on mode (active tab)
    informersDataSource: {
      informer: state.publisherSettings.informersData,
      rotator: state.publisherSettings.informersDataForRotator,
    },
    isLoadingSource: {
      informer: state.network[START_FETCH_INFORMERS_DATA_BY_NEWS_ID],
      rotator: state.network[START_FETCH_INFORMERS_DATA_FOR_ROTATOR_BY_NEWS_ID],
    },
    customArticlesListSource: {
      informer: state.publisherSettings.customArticles,
      rotator: state.publisherSettings.customArticlesRotator,
    },
    informersSource: {
      informer: state.publisherSettings.informers,
      rotator: state.publisherSettings.informersForRotator,
    },
    sitesSource: {
      informer: state.publisherSettings.sites,
      rotator: state.publisherSettings.sitesForRotator,
    },
  };
}

const mapDispatchToProps = {
  fetchInformersDataForRotatorByNewsId,
  fetchSitesWithInformersForRotator,
  fetchCustomArticlesRotator,
  promoteArticleForRotator,
  fetchInformerCustomArticles,
  fetchInformersDataByNewsId,
  fetchSitesWithInformers,
  fetchCustomArticles,
  searchArticles,
  promoteArticle,
};

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(ArticlePromotion);
