/* eslint-disable indent */
import {
  SUCCESS_FETCH_GLOBAL_STATISTICS,
  SUCCESS_FETCH_AB_TESTING_STATISTICS,
  SUCCESS_FETCH_STATISTICS_BY_TYPE,
} from '../types';

const initialTable = {
  data: [{ position: '-', views: '-', clicks: '-', ctr: '-' }],
  total: 0,
};

const initialState = {
  global: {},
  table: {
    data: [],
    limit: 25,
  },
  blocks: {
    rotator: {
      base: initialTable,
      last: initialTable,
      rubric: initialTable,
      main: initialTable,
    },
    informer: initialTable,
  },
};

export default function statistics(state = initialState, action = {}) {
  switch (action.type) {
    case SUCCESS_FETCH_GLOBAL_STATISTICS:
      return {
        ...state,
        global: action.payload.data || {},
      };

    case SUCCESS_FETCH_AB_TESTING_STATISTICS:
      return {
        ...state,
        table: {
          ...state.table,
          ...action.payload,
        },
      };

    case SUCCESS_FETCH_STATISTICS_BY_TYPE: {
      const { isInformer, data, blockTitle } = action.payload;
      const blockType = isInformer ? 'informer' : 'rotator';

      return {
        ...state,
        blocks: {
          ...state.blocks,
          [blockType]: isInformer
            ? { ...data }
            : {
                ...state.blocks[blockType],
                [blockTitle]: {
                  ...state.blocks[blockTitle],
                  ...data,
                },
              },
        },
      };
    }

    default:
      return state;
  }
}
