import { useEffect, useState, useCallback } from 'react';

// Utils
import { statusesSites } from '../../../constants/statuses';
import { iStatusesList } from '../../../prop-types';
import useCurrentPage from './useCurrentPage';

const useModerationQty = ({ statusesList }) => {
  const [sitesOnWait, setSitesOnWait] = useState(0);
  const countedStatuses = [
    statusesSites.STATUS_PENDING,
    statusesSites.STATUS_ANALYTICS_SPEEDUP,
  ];

  const countSitesOnWait = useCallback(() => {
    setSitesOnWait(
      countedStatuses.reduce(
        (acc, status) => acc + (statusesList[status] || 0),
        0,
      ),
    );
  }, [countedStatuses, statusesList]);

  useEffect(countSitesOnWait, [statusesList]);

  return sitesOnWait;
};

useCurrentPage.propTypes = {
  statuses: iStatusesList,
};

export default useModerationQty;
