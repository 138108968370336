import chartTranslations from './chartTranslations';

const getKeys = ({ data, showKeys, isAutoKeys, excludeKeys }) =>
  (isAutoKeys ? Object.keys(data) : showKeys).filter(
    key => !excludeKeys.includes(key),
  );

export const propsDataToChart = props => {
  const { data, t } = props;
  const keys = getKeys(props);
  const result = [];

  keys.forEach(key => {
    const { name, hint, ...restLabelProps } = chartTranslations[key] || {
      name: key,
      hint: '',
    };

    result.push({
      ...(name && { name: t(name) }),
      ...(hint && { hint: t(hint) }),
      ...restLabelProps,
      data: data[key],
    });
  });

  return result;
};
