import React from 'react';
import { FaExclamationCircle } from 'react-icons/all';
import { connect } from 'react-redux';
import { number, string } from 'prop-types';
import { withTranslation } from 'react-i18next';
import { FaEyeSlash, FaEnvelope, FaInfoCircle } from 'react-icons/fa';

// Redux
import { showModal } from '../../../redux/actions/modals';

import {
  colorBlue,
  colorYellow,
  colorRed,
} from '../../../styles/js/variables/colors';

// Components
import TableTag from '../../Status';
import TableNotice from './TableNotice';

const flexStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const TableStatus = ({
  tech_status: techStatus,
  status,
  notice,
  description,
  id: siteId,
  display,
  views,
  styles = null,
  t,
}) => {
  const isBadInformerView = status === 'active' && display < 1 && views === 0;

  return (
    <div style={flexStyle} className="ant-table-status">
      <TableTag status={status} style={{ width: '100%' }} />
      <div style={{ ...flexStyle, fontSize: '1.125em' }}>
        {isBadInformerView && (
          <TableNotice
            storageKey={`notice${siteId + t('basic.attention')}`}
            iconComponent={FaEyeSlash}
            color={colorYellow}
            title={t('basic.attention')}
            text={t(
              `tooltip.${
                styles === null ? 'siteInformerBadPlace' : 'informerBadPlace'
              }`,
            )}
          />
        )}
        {notice && (
          <TableNotice
            storageKey={`notice${siteId + t('basic.attention')}`}
            iconComponent={FaEnvelope}
            color={colorYellow}
            title={t('message.notice')}
            text={notice}
          />
        )}
        {description && (
          <TableNotice
            storageKey={`description${siteId + t('basic.attention')}`}
            iconComponent={FaInfoCircle}
            color={colorBlue}
            title={t('message.description')}
            text={description}
          />
        )}
        {techStatus && techStatus !== 'active' && (
          <TableNotice
            storageKey={`notice${siteId + t('basic.attention')}`}
            iconComponent={FaExclamationCircle}
            color={colorRed}
            title=""
            text={
              <>
                <b>{t('basic.error')}:</b>
                &nbsp;{t(`errors.${techStatus}`) || t('basic.errorUnknown')}
                &nbsp;({techStatus})
              </>
            }
          />
        )}
      </div>
    </div>
  );
};

TableStatus.defaultProps = {
  notice: '',
  description: '',
};

TableStatus.propTypes = {
  status: string.isRequired,
  notice: string,
  description: string,
  id: number,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  showIntegrationModal: showModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(TableStatus));
