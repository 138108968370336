import { Select } from 'antd';
import React from 'react';
import Grid from '../../Grid';
import Label from '../../Label';
import { mustBeFilled } from '../../../config/validationRules';

const SelectsBlock = ({
  params,
  list,
  labels,
  onChange,
  rules,
  columnsQty,
}) => (
  <Grid columns={columnsQty || labels.length}>
    {Object.keys(params).map((key, i) => (
      <div key={key}>
        <Label
          labelText={labels[i]}
          asterisk={rules && !!rules.includes(mustBeFilled)}
        />

        <Select
          name={key}
          onChange={value => onChange(value, key)}
          value={params[key]}
          style={{ width: '100%' }}
        >
          {list.map(option => (
            <Select.Option {...option} title={option.text}>
              {option.text}
            </Select.Option>
          ))}
        </Select>
      </div>
    ))}
  </Grid>
);

export default SelectsBlock;
