import {
  START_FETCH_TABLE_ARTICLES_STATISTICS,
  SUCCESS_FETCH_TABLE_ARTICLES_STATISTICS,
  FAILURE_FETCH_TABLE_ARTICLES_STATISTICS,
} from 'redux/types/articles';

import { getCustomArticlesStatisticsApi } from 'api';
import { ERROR_NOT_RESPONDED } from '../../constants/messages';

export const fetchArticlesStatistics = () => dispatch => {
  dispatch({
    type: START_FETCH_TABLE_ARTICLES_STATISTICS,
  });

  return getCustomArticlesStatisticsApi()
    .then(payload => {
      dispatch({
        type: SUCCESS_FETCH_TABLE_ARTICLES_STATISTICS,
        payload: payload.data,
      });
    })
    .catch(error => {
      dispatch({
        type: FAILURE_FETCH_TABLE_ARTICLES_STATISTICS,
        payload: error,
        notification: {
          type: 'error',
          text: ERROR_NOT_RESPONDED,
        },
      });
    });
};
