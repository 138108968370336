import { convertToPublishersChartsData } from 'services/formatter';
import { monthEarlierDate, todayDate } from 'utils/date';
import { getSuccessStatus } from '../helpers/statuses';
import {
  START_FETCH_SITES,
  SUCCESS_FETCH_SITES,
  FAILURE_FETCH_SITES,
  SUCCESS_EDIT_SITE,
  SUCCESS_FETCH_CHART,
  SUCCESS_FETCH_SPEEDUP_INFO,
  FETCH_SITE_SCRIPT_VERSIONS,
} from '../types';

const initialState = {
  chart: {},
  table: {
    total: 0,
    limit: 20,
    offset: 0,
    start: +monthEarlierDate,
    end: +todayDate,
    data: [],
    isLoading: false,
  },
  speedupInfo: {
    description: '',
    screens: [],
  },
  siteScriptVersions: [],
};

export default function sites(state = initialState, action = {}) {
  switch (action.type) {
    // Fetch table sites
    // ________________________________________________________
    case START_FETCH_SITES:
      return { ...state, table: { ...state.table, isLoading: true } };

    case SUCCESS_FETCH_SITES:
      return {
        ...state,
        table: {
          ...state.table,
          ...action.payload,
          isLoading: false,
        },
      };

    case FAILURE_FETCH_SITES:
      return {
        ...state,
        table: state.table,
        isLoading: false,
      };

    // Edit  informersList in table
    // ________________________________________________________

    case SUCCESS_EDIT_SITE:
      return {
        ...state,
        table: {
          ...state.table,
          data: action.payload,
        },
      };

    // Fetch chart data
    // ________________________________________________________
    case SUCCESS_FETCH_CHART:
      return {
        ...state,
        chart: convertToPublishersChartsData(action.payload.data),
      };

    case SUCCESS_FETCH_SPEEDUP_INFO:
      return {
        ...state,
        speedupInfo: action.payload.data,
      };

    case getSuccessStatus(FETCH_SITE_SCRIPT_VERSIONS):
      return {
        ...state,
        siteScriptVersions: action.payload.data,
      };

    default:
      return state;
  }
}
