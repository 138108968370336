import * as routesPath from 'config/routePaths';
import AddInformer from 'pages/AddInformer';
import Publishers from 'pages/Publishers';
import { Site } from 'pages/Site';
import Sites from 'pages/Sites';
import SiteStatistics from 'pages/SiteStatistics';
import Informer from 'pages/Informer';
import InformerByDate from 'pages/InformerByDate';
import Login from 'pages/Login';
import InformerNews from 'pages/InformerNews';
import GlobalSettings from 'pages/GlobalSettings';
import GlobalStatistics from 'pages/GlobalStatistics';
import StatisticsByType from 'pages/StatisticsByType';
import Articles from 'pages/Articles';
import ArticlePromotion from 'pages/ArticlePromotion';
import ArticlesStatistics from 'pages/ArticlesStatistics';
import { isNotAuth, isAuth, areInvalidRoutes } from 'services/routeMiddlewares';
import { AbTests } from './pages/AbTests';
import AbTestsStatistics from './pages/AbTestsStatistics';
import InformersList from './pages/InformersList';
import Moderation from './pages/Moderation';
import SitesList from './pages/SitesList';

const publicRoutes = [
  {
    path: routesPath.LOGIN,
    component: Login,
    exact: true,
    middleware: isAuth,
  },
];

const privateRoutes = [
  {
    path: routesPath.HOME,
    component: Publishers,
    exact: true,
    middleware: isNotAuth,
  },
  {
    path: routesPath.ARTICLE_PROMOTION,
    component: ArticlePromotion,
    exact: true,
    middleware: isNotAuth,
  },
  {
    path: routesPath.SITES_LIST,
    component: SitesList,
    exact: true,
    middleware: isNotAuth,
  },
  {
    path: routesPath.AB_TESTS,
    component: AbTests,
    exact: true,
    middleware: isNotAuth,
  },
  {
    path: routesPath.AB_TESTS_STATISTICS,
    component: AbTestsStatistics,
    exact: true,
    middleware: isNotAuth,
  },
  {
    path: routesPath.GLOBAL_SETTINGS,
    component: GlobalSettings,
    exact: true,
    middleware: isNotAuth,
    fullscreen: false,
  },
  {
    path: routesPath.GLOBAL_STATISTICS,
    component: GlobalStatistics,
    exact: true,
    middleware: isNotAuth,
  },
  {
    path: routesPath.STATISTICS_BY_TYPE,
    component: StatisticsByType,
    exact: true,
    middleware: isNotAuth,
  },
  {
    path: routesPath.ARTICLES,
    component: Articles,
    exact: true,
    middleware: isNotAuth,
  },
  {
    path: routesPath.ARTICLES_STATISTICS,
    component: ArticlesStatistics,
    exact: true,
    middleware: isNotAuth,
  },
  {
    path: routesPath.SITES_MODERATION,
    component: Moderation,
    exact: true,
    middleware: isNotAuth,
  },
  {
    path: routesPath.PUBLISHER,
    component: Sites,
    exact: true,
    middleware: isNotAuth,
  },
  {
    path: routesPath.SITE,
    component: Site,
    exact: true,
    middleware: isNotAuth,
  },
  {
    path: routesPath.SITE_STATISTICS,
    component: SiteStatistics,
    exact: true,
    middleware: isNotAuth,
  },
  {
    path: routesPath.INFORMER_ADD,
    component: AddInformer,
    exact: true,
    middleware: isNotAuth,
  },
  {
    path: routesPath.INFORMERS_LIST,
    component: InformersList,
    exact: true,
    middleware: isNotAuth,
  },
  {
    path: routesPath.INFORMER,
    component: Informer,
    exact: true,
    middleware: isNotAuth,
  },
  {
    path: routesPath.INFORMER_EDIT,
    component: AddInformer,
    exact: true,
    middleware: isNotAuth,
  },
  {
    path: routesPath.INFORMER_BY_DATE,
    component: InformerByDate,
    exact: true,
    middleware: [isNotAuth, areInvalidRoutes],
  },
  {
    path: routesPath.INFORMER_NEWS,
    component: InformerNews,
    exact: true,
    middleware: isNotAuth,
  },
];

const routes = [...publicRoutes, ...privateRoutes];

export default routes;
