import { node } from 'prop-types';
import * as ReactDOM from 'react-dom';

const headRoot = document.head;

const Head = ({ children }) => ReactDOM.createPortal(children, headRoot);

Head.propTypes = {
  children: node,
};

export default Head;
