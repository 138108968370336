import React, { Component } from 'react';

// Redux
import { connect } from 'react-redux';
import {
  fetchTable,
  addPublisher,
  deletePublisher,
  editPublisher,
  fetchChart,
} from 'redux/actions/publishers';
import { showModal } from 'redux/actions/modals';

// Utils
import { withTranslation } from 'react-i18next';
import { getDaysRange, getDaysAgoRange, datesArrayToUnix } from 'utils/date';

// Components
import { Button, Icon } from 'antd';
import Table from 'components/Table';
import LinearChart from 'components/LinearChart';
import {
  publishersHeader,
  publishersFooter,
} from 'components/Table/config/tables';
import PageTitle from '../../components/PageTitle';

class Publishers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      calendarDates: getDaysAgoRange(),
    };
  }

  componentDidMount() {
    this.handleUpdateAll(datesArrayToUnix(getDaysAgoRange()));
  }

  handleUpdateAll = dates => {
    const { props } = this;
    const { limit, offset } = props.table;

    props.fetchChart({
      start: +dates[0],
      end: +dates[1],
    });

    props.fetchTable({
      limit,
      offset,
      start: +dates[0],
      end: +dates[1],
    });

    this.setState({
      calendarDates: getDaysRange(dates),
    });
  };

  handleFetch = (page, sortBy, sortDirection) => {
    const { props } = this;
    const { table } = props;
    const { limit, start, end } = table;
    const offset = (page - 1) * limit;

    props.fetchTable({ limit, offset, start, end, sortBy, sortDirection });
  };

  handleModalAddPublisher = () => {
    const { props } = this;

    props.showModal({
      type: 'MODAL_ADD_PUBLISHER',
      params: {
        actions: {
          onSubmit: props.addPublisher,
        },
        success: {
          onSubmit: () =>
            props.fetchTable({
              limit: props.table.limit,
              offset: props.table.offset,
              start: props.table.start,
              end: props.table.end,
            }),
        },
      },
    });
  };

  handleModalEditPublisher = item => {
    const { props } = this;
    const { start, end } = props.table;

    props.showModal({
      type: 'MODAL_ADD_PUBLISHER',
      params: {
        fields: item,
        actions: {
          onSubmit: updateItem =>
            props
              .editPublisher(updateItem)
              .then(() => this.handleUpdateAll([start, end])),
        },
      },
    });
  };

  handleDeletePublisher = ({ id }) => {
    const { props } = this;
    const { start, end } = props.table;

    props.deletePublisher(id).then(() => this.handleUpdateAll([start, end]));
  };

  handlePublisherPage = ({ id }) => `${this.props.match.url}${id}`;

  handlePublisherSettings = ({ id }) => {
    const { history } = this.props;

    history.push(`${id}/settings`);
  };

  render() {
    const { table, chart, t, isLoading } = this.props;
    const { calendarDates } = this.state;

    return (
      <div className="page-body main-page">
        <PageTitle
          title={t('content.title.publishers')}
          hint={t('content.titleHint.publishers')}
          button={
            <Button type="success" onClick={this.handleModalAddPublisher}>
              <Icon type="plus" />
              {t('form.title.addPublisher')}
            </Button>
          }
        />

        <LinearChart
          isLoading={isLoading}
          categoriesForX={calendarDates}
          onChange={this.handleUpdateAll}
          data={chart}
          isAutoKeys
          excludeKeys={['efficiencies', 'date']}
        />

        <Table
          isLoading={isLoading}
          headers={publishersHeader}
          data={table.data}
          total={table.total}
          limit={table.limit}
          fetch={this.handleFetch}
          summaryKeys={publishersFooter}
          summaryData={table.summary}
          actions={{
            edit: this.handleModalEditPublisher,
            delete: this.handleDeletePublisher,
            page: this.handlePublisherPage,
            // settings: this.handlePublisherSettings,
          }}
          type="publishersActions"
        />
      </div>
    );
  }
}

const mapStateToProps = ({ publishers, network }) => ({
  chart: publishers.chart,
  table: publishers.table,
  isSaving: network.START_EDIT_PUBLISHER,
  isLoading: network.START_FETCH_PUBLISHERS_TABLE,
});

const mapDispatchToProps = {
  showModal,
  fetchTable,
  fetchChart,
  addPublisher,
  editPublisher,
  deletePublisher,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Publishers));
