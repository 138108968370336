import React from 'react';
import { LogicButton } from './LogicButton';

export const TestsLogicCell = ({ current, onClickAction }) =>
  !current ? null : (
    <div style={{ maxWidth: 300, overflow: 'hidden' }}>
      <div className="dots-overflow">
        {current.split(',').map(logicId => (
          <LogicButton
            key={logicId}
            logicId={logicId}
            onClickAction={onClickAction}
          >
            {logicId}
          </LogicButton>
        ))}
      </div>
    </div>
  );
