import React, { useCallback, memo } from 'react';
import { Transfer, Tree, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import EmptyData from 'components/EmptyData';
import Loader from 'components/Loader';
import { connect } from 'react-redux';
import PositionSelector from './PositionSelector';
import RotatorBlockSelector from './RotatorBlockSelector';

const { TreeNode } = Tree;

const TransferTree = ({
  onPositionChange,
  invalidBlocksIds,
  commonPosition,
  onBlockChange,
  targetObjects,
  transferData,
  commonBlock,
  invalidIds,
  dataSource,
  targetKeys,
  isLoading,
  isMobile,
  mode,
  ...restProps
}) => {
  const isRotatorMode = mode === 'rotator';
  const itemStyle = {
    width: `calc(100% - ${isRotatorMode ? 185 : 80}px)`,
    verticalAlign: 'middle',
  };
  const listStyle = {
    ...(restProps.disabled && { pointerEvents: 'none' }),
    flex: '1 0 calc(50% - 35px)',
    height: 450,
  };

  const isChecked = useCallback(
    (selectedKeys, eventKey) => selectedKeys.indexOf(eventKey) !== -1,
    [],
  );

  const renderSiteTitle = useCallback(
    item =>
      item.domain
        ? `${item.publisher ? `${item.publisher} - ` : ''}${item.title} (${
            item.domain
          })`
        : item.title,
    [],
  );

  const checkHandler = useCallback(
    (
      _,
      {
        node: {
          props: { eventKey, children, checked },
        },
      },
      { onItemSelect, onItemSelectAll, checkedKeys },
    ) => {
      if (children && children.length) {
        onItemSelectAll(
          children.map(item => item.key),
          !checked,
        );
      } else {
        onItemSelect(eventKey, !isChecked(checkedKeys, eventKey));
      }
    },
    [isChecked],
  );

  // Render list of TreeNode components for Tree component

  const generateTree = useCallback(
    (treeNodes = [], checkedKeys = []) => {
      return treeNodes.map(({ children, ...props }) => (
        <TreeNode
          {...props}
          disabled={
            checkedKeys.includes(props.key) ||
            (children &&
              children.length &&
              children.every(item => checkedKeys.includes(item.key))) ||
            (children && !children.length)
          }
          key={props.key}
          title={
            children && !children.length ? (
              <Tooltip title="У этого сайта нет добавленных информеров">
                {renderSiteTitle(props)}
              </Tooltip>
            ) : (
              renderSiteTitle(props)
            )
          }
        >
          {generateTree(children, checkedKeys)}
        </TreeNode>
      ));
    },
    [renderSiteTitle],
  );

  const renderTree = useCallback(
    ({ direction, onItemSelect, selectedKeys, onItemSelectAll }) => {
      if (direction === 'left') {
        const checkedKeys = [...selectedKeys, ...targetKeys];
        const args = { onItemSelect, onItemSelectAll, checkedKeys };
        return (
          <Tree
            blockNode
            checkable
            checkedKeys={checkedKeys}
            onCheck={(...props) => checkHandler(...props, args)}
            onSelect={(...props) => checkHandler(...props, args)}
          >
            {generateTree(dataSource, targetKeys)}
          </Tree>
        );
      }
    },
    [generateTree, checkHandler, dataSource, targetKeys],
  );

  // Render item in transfer list

  const renderTransferItem = useCallback(
    item => {
      const currentItem =
        targetKeys.includes(item.key) &&
        targetObjects.find(object => String(object.key) === item.key);
      const blockValue = isRotatorMode && currentItem && currentItem.block;
      const positionValue = currentItem && currentItem.position;
      const publishedNews = item.news || [];
      const filteredNews = isRotatorMode
        ? publishedNews.filter(news => news.block && news.block === blockValue)
        : publishedNews;
      const disabledPositions = filteredNews.map(news => news.position);

      return item.site ? (
        <span>
          <Tooltip title={`${item.title} - ${item.site} (${item.siteDomain})`}>
            <span
              className="text-elipsis"
              style={itemStyle}
            >{`${item.title} - ${item.site} (${item.siteDomain})`}</span>
          </Tooltip>

          {/* eslint-disable */}
          {isRotatorMode && (
            <span onClick={e => e.stopPropagation()}>
              <RotatorBlockSelector
                isError={invalidBlocksIds.includes(item.key)}
                value={blockValue}
                onChange={value => onBlockChange(value, item.key)}
              />
            </span>
          )}

          <span onClick={e => e.stopPropagation()}>
            <PositionSelector
              isError={invalidIds.includes(item.key)}
              value={positionValue}
              disabledPositions={disabledPositions}
              onChange={value => onPositionChange(value, item.key)}
            />
          </span>
          {/* eslint-enable */}
        </span>
      ) : (
        item.title
      );
    },
    [
      isRotatorMode,
      onBlockChange,
      onPositionChange,
      invalidBlocksIds,
      invalidIds,
      itemStyle,
      targetKeys,
      targetObjects,
    ],
  );

  return (
    <Transfer
      {...restProps}
      targetKeys={targetKeys}
      dataSource={transferData}
      className="tree-transfer"
      titles={[
        null,

        targetKeys && targetKeys.length ? (
          <>
            {isRotatorMode && (
              <RotatorBlockSelector
                value={commonBlock}
                onChange={value => onBlockChange(value)}
              />
            )}
            <PositionSelector
              tooltip="Выбранная позиция будет применена ко всем информерам. Если позиция занята, произойдет замена новости, находящейся на выбранной позиции."
              onChange={value => onPositionChange(value)}
              value={commonPosition}
            />
          </>
        ) : null,
      ]}
      render={renderTransferItem}
      showSelectAll
      listStyle={listStyle}
      locale={{
        itemUnit: 'Выбрать все информеры',
        itemsUnit: 'Выбрать все информеры',
        notFoundContent: isLoading ? (
          <Loader isLoading />
        ) : (
          <EmptyData
            style={{ padding: '0 10px' }}
            title="Выберите информеры из списка слева, на которых следует расместить статью"
          />
        ),
      }}
      style={{ flexDirection: isMobile ? 'column' : 'row' }}
    >
      {renderTree}
    </Transfer>
  );
};

TransferTree.propTypes = {
  transferData: PropTypes.arrayOf(PropTypes.object).isRequired,
  targetKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  dataSource: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = ({ userSettings }) => ({
  isMobile: userSettings.isMobile,
});

export default connect(mapStateToProps)(memo(TransferTree));
