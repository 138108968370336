import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import Button from 'components/Button';

const ArticlePreviewModal = props => {
  const { title, description, onClose, image, url, isOpen } = props;

  return (
    <Modal
      onCancel={onClose}
      visible={isOpen}
      title={title}
      onOk={onClose}
      footer={
        <Button type="primary" onClick={onClose}>
          Закрыть
        </Button>
      }
    >
      {image && (
        <div>
          <img style={{ width: 300 }} src={image} alt={title} />
        </div>
      )}
      <br />
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: description.replace(/<\/?script>/gi, ''),
        }}
      />
      <br />
      {url && (
        <a href={url} target="_blank" rel="noopener noreferrer">
          Открыть статью в новой вкладке
        </a>
      )}
    </Modal>
  );
};

ArticlePreviewModal.defaultProps = {
  isOpen: false,
  image: null,
  url: null,
};

ArticlePreviewModal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  image: PropTypes.string,
  url: PropTypes.string,
};

export default ArticlePreviewModal;
