import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { withTranslation } from 'react-i18next';
import {
  fetchGlobalSettings,
  editGlobalSetting,
  setInitGlobalSettings,
} from 'redux/actions/globalSettings';
import { Button, Form } from 'antd';

import TabParser from './components/TabParser';
import PageTitle from '../../components/PageTitle';
import Loader from '../../components/Loader';
import { showModal } from '../../redux/actions/modals';
import ChangedFieldsBlock from './components/ChangedFieldsBlock';
import { saveGlobalSetting } from '../../redux/actions/globalSettings';

const GlobalSettings = props => {
  const {
    globalSettings,
    editSetting,
    isLoading,
    isSaving,
    fetchSettings,
    setInitSettings,
    saveSetting,
    openModal,
    t,
  } = props;
  const [changedFields, setChangedFields] = useState({});

  const addToChangedFields = useCallback(
    elProps => {
      const { tabIndex, blockIndex, elementIndex, element } = elProps;
      const { tabs } = globalSettings;
      const beforeValue =
        globalSettings.initTabs[tabIndex].blocks[blockIndex][elementIndex]
          .value;

      const fields = {
        ...changedFields,
        [tabs[tabIndex].title]: {
          ...changedFields[tabs[tabIndex].title],
          [element.id]: {
            before:
              globalSettings.initTabs[tabIndex].blocks[blockIndex][elementIndex]
                .value,
            after: element.value,
            label: element.label,
            element,
          },
        },
      };

      // delete if same value
      if (beforeValue === element.value) {
        delete fields[tabs[tabIndex].title][element.id];

        // delete if parent are empty too
        if (!Object.keys(fields[tabs[tabIndex].title]).length) {
          delete fields[tabs[tabIndex].title];
        }
      }

      setChangedFields(fields);
    },
    [globalSettings, changedFields, setChangedFields],
  );

  const handleEditSetting = debounce(
    useCallback(
      elProps => {
        addToChangedFields(elProps);
        editSetting(elProps);
      },
      [addToChangedFields, editSetting],
    ),
    300,
  );

  const handleSubmit = useCallback(() => {
    const promises = Object.entries(changedFields).map(([tabKey, tab]) =>
      Object.entries(tab).map(
        ([blockKey, { element }]) =>
          new Promise(resolve => saveSetting(element).then(resolve)),
      ),
    );

    Promise.all(promises).then(() => setChangedFields({}));
  }, [changedFields, saveSetting]);

  const confirmSubmit = useCallback(
    () =>
      openModal({
        type: 'MODAL_CONFIRM',
        params: {
          titleText: t('form.title.changesMade'),
          contentText: <ChangedFieldsBlock changedFields={changedFields} />,
          submitType: 'warning',
          actions: { onConfirm: handleSubmit },
        },
      }),
    [changedFields, handleSubmit, openModal, t],
  );

  useEffect(() => {
    fetchSettings().then(setInitSettings);
  }, [fetchSettings, setInitSettings]);

  return (
    <div className="page-body page-main-settings">
      <PageTitle
        centered
        title={t('content.title.globalSettings')}
        hint={t('content.titleHint.globalSettings')}
      />
      <Loader isLoading={isSaving || isLoading} />
      <Form>
        <TabParser
          tabs={globalSettings.tabs}
          onChange={handleEditSetting}
          isLoading={isLoading}
        />

        <br />

        <Button
          className="button--blue"
          style={{ float: 'right' }}
          onClick={confirmSubmit}
          loading={isSaving}
          disabled={!Object.keys(changedFields).length}
        >
          {t('form.buttonSave')}
        </Button>
      </Form>
    </div>
  );
};

const mapStateToProps = ({ network, globalSettings }) => ({
  isLoading: network.START_FETCH_GLOBAL_SETTINGS,
  isSaving: network.START_SAVE_GLOBAL_SETTINGS,
  initTabs: globalSettings.initTabs,
  globalSettings,
});

const mapDispatchToProps = {
  fetchSettings: fetchGlobalSettings,
  setInitSettings: setInitGlobalSettings,
  saveSetting: saveGlobalSetting,
  editSetting: editGlobalSetting,
  openModal: showModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('')(GlobalSettings));
