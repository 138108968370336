import { unix } from 'moment';
import { truthyDecorator } from 'utils';
import { defaultDate } from 'config/datesFormat';
import { camelCase } from 'lodash';

// Convert string to number
export const getNumber = maybeDate =>
  truthyDecorator(maybeDate)(date => +date.replace(',', '.').replace(/ /g, ''));

// Convert timestamp to default date
export const convertDate = maybeDate =>
  truthyDecorator(maybeDate)(date => unix(date).format(defaultDate));

// Convert data to chart's format
export const convertToChartsData = data =>
  data.reduce(
    (acc, chunk) => {
      const views = [...acc.views, chunk.views];
      const efficiencies = [...acc.efficiencies, chunk.efficiency];
      const clickOn = [...acc.clickOn, chunk.click_on];
      const clickFrom = [...acc.clickFrom, chunk.click_from];

      return { views, efficiencies, clickOn, clickFrom };
    },
    { data, views: [], efficiencies: [], clickOn: [], clickFrom: [] },
  );

export const convertAllKeysToChartsData = (data, shouldReverse = false) => {
  const result = {};
  // We need reverse to prepare data for chart. Remove it if backend change this sorting
  const correctedData = shouldReverse ? data.reverse() : data;

  correctedData.forEach(
    el =>
      Object.entries(el).forEach(([key, value]) => {
        const keyCamelCase = camelCase(key);

        result[keyCamelCase] = result[keyCamelCase]
          ? [...result[keyCamelCase], value]
          : [value];
      }),
    {},
  );
  return result;
};

// Convert data to chart's format for publishers page (with additional fields)
export const convertToPublishersChartsData = data =>
  data.reduce(
    (acc, chunk) => {
      const views = [...acc.views, chunk.views];
      const efficiencies = [...acc.efficiencies, chunk.efficiency];
      const coefficient = [...acc.coefficient, chunk.coefficient];
      const clickOn = [...acc.clickOn, chunk.click_on];
      const clickFrom = [...acc.clickFrom, chunk.click_from];

      return { views, efficiencies, clickOn, clickFrom, coefficient };
    },
    {
      data,
      views: [],
      efficiencies: [],
      clickOn: [],
      clickFrom: [],
      coefficient: [],
    },
  );

export const convertNumberToFormattedString = num => {
  const isPercent = num % 1 !== 0;
  const regexpEveryThirdSymbol = /\B(?=(\d{3})+(?!\d))/g;
  const suffix = isPercent ? '%' : '';
  const result = isPercent ? num.toFixed(2) : String(num);

  return result.replace(regexpEveryThirdSymbol, ' ') + suffix;
};
