import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Checkbox, Divider, Select } from 'antd';

// Utils
import ValidateInput from 'components/ValidateInput';
import { mustBeFilled } from 'config/validationRules';
import { ValidatorContext } from 'context/ValidatorContext';

// Components
import Label from 'components/Label';
import CategoriesBlock from './CategoriesBlock';
import SettingsBlock from './SettingsBlock';

const GeneralBlock = props => {
  const { mainField, onChange, informerSettings, t } = props;
  const { actions } = React.useContext(ValidatorContext);
  const { settings } = informerSettings;
  const dividerStyle = { margin: '12px 0', background: '#eee' };

  const handleHideInformer = event => {
    const { name, checked } = event.target;
    const isVisible = !checked;

    props.onChange({
      item: name,
      response: isVisible,
      isSetting: true,
    });
  };

  const handleChange = (value, fieldName) => {
    onChange({
      item: 'settings',
      response: { ...settings, [fieldName]: value },
      isSetting: true,
    });
  };

  const handleLanguagesChange = value => {
    onChange({
      item: 'languages',
      response: value.split(','),
      isSetting: true,
    });
  };

  return (
    <Fragment>
      {/* <Header as="h3">{t('basic.basic')}</Header> */}
      <Label labelText={t('basic.informerName')} asterisk />
      <ValidateInput
        name="name"
        validateName="block-name"
        defaultValue={mainField}
        value={mainField}
        placeholder="..."
        controlled
        force
        onChange={value =>
          onChange({
            item: 'name',
            response: value,
            isSetting: true,
          })
        }
        onValidate={input => actions.validate(input)}
        rules={[mustBeFilled]}
      />
      <br />
      {t('basic.hideInformer')}
      &nbsp;&nbsp;
      <Checkbox
        name="isVisible"
        checked={!informerSettings.isVisible}
        onChange={handleHideInformer}
      />
      <Divider style={dividerStyle} />
      <div>
        {t('basic.contentLanguage')}
        &nbsp;&nbsp;
        <Select
          name="languages"
          onChange={handleLanguagesChange}
          value={
            informerSettings.languages && informerSettings.languages.toString()
          }
          style={{ minWidth: '180px' }}
        >
          <Select.Option value="1,2">{t('basic.all')}</Select.Option>
          <Select.Option value="1">{t('languages.ru')}</Select.Option>
          <Select.Option value="2">{t('languages.uk')}</Select.Option>
        </Select>
      </div>
      <Divider style={dividerStyle} />
      <CategoriesBlock onChange={onChange} />
      <Divider style={dividerStyle} />
      <SettingsBlock handleChange={handleChange} settings={informerSettings} />
    </Fragment>
  );
};

GeneralBlock.propTypes = {
  element: PropTypes.shape({}),
  onChange: PropTypes.func,
};

GeneralBlock.defaultProps = {
  element: {},
  onChange: () => {},
};

function mapStateToProps(state) {
  return {
    informerSettings: state.informerSettings,
    informer: state.informer,
  };
}

export default connect(mapStateToProps)(withTranslation()(GeneralBlock));
