import React, { Component } from 'react';
import { moment } from 'utils/date';
import { connect } from 'react-redux';
import StatisticsFilter from './StatisticsFilter';
import StatisticsBlock from './StatisticsBlock';
import { fetchStatisticsByType } from '../../../redux/actions/statistics';

class InformerStatistics extends Component {
  state = {
    from: moment().set({ hour: 0, minute: 0 }),
    to: moment(),
    siteId: null,
    informerId: null,
    deviceType: null,
  };

  componentDidMount() {
    this.handleFetch();
  }

  handleFetch = () =>
    this.props.fetchStatisticsByType({
      ...this.state,
      from: this.state.from.format('x'),
      to: this.state.to.format('x'),
      isInformer: true,
    });

  onChangeFilter = changes => {
    this.setState(state => ({ ...state, ...changes }), this.handleFetch);
  };

  render() {
    return (
      <>
        <StatisticsFilter {...this.state} onChange={this.onChangeFilter} />
        <StatisticsBlock
          isLoading={this.props.isLoading}
          handleFetch={this.handleFetch}
        />
      </>
    );
  }
}

const mapStateToProps = ({ network }) => ({
  isLoading: network.START_FETCH_STATISTICS_BY_TYPE,
});

const mapDispatchToProps = {
  fetchStatisticsByType,
};

export default connect(mapStateToProps, mapDispatchToProps)(InformerStatistics);
