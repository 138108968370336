import styled from 'styled-components';
import { FaEye } from 'react-icons/fa';

import { colorBlue } from '../../styles/js/variables/colors';

export const ViewArticleButton = styled(FaEye)`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  border-radius: 50%;
  color: ${colorBlue};
  width: 24px;
  height: 24px;
  font-size: 14px;
  padding: 4px;
  z-index: 5;
  box-shadow: 0 0 3px 0 rgb(0 0 0 / 25%);
  transform: translateX(-50%) translateY(-50%);
  cursor: pointer;
`;
