import {
  START_FETCH_NEWS_CATEGORIES_LIST,
  SUCCESS_FETCH_NEWS_CATEGORIES_LIST,
  FAILURE_FETCH_NEWS_CATEGORIES_LIST,
  START_FETCH_SITES_LIST,
  SUCCESS_FETCH_SITES_LIST,
  FAILURE_FETCH_SITES_LIST,
} from 'redux/types/dataLists';
import { getNewsCategoriesListApi, getSitesListApi } from 'api/basic';
import {
  FAILURE_FETCH_LANGUAGES_LIST,
  FAILURE_FETCH_SITES_LIST_WITH_INFORMERS,
  START_FETCH_LANGUAGES_LIST,
  START_FETCH_SITES_LIST_WITH_INFORMERS,
  SUCCESS_FETCH_LANGUAGES_LIST,
  SUCCESS_FETCH_SITES_LIST_WITH_INFORMERS,
} from '../types/dataLists';
import {
  getLanguagesListApi,
  getSitesListWithInformersApi,
} from '../../api/basic';

export const fetchNewsCategoriesList = () => dispatch => {
  dispatch({
    type: START_FETCH_NEWS_CATEGORIES_LIST,
  });

  return getNewsCategoriesListApi()
    .then(payload => {
      dispatch({
        type: SUCCESS_FETCH_NEWS_CATEGORIES_LIST,
        payload: payload.data,
      });
    })
    .catch(error => {
      dispatch({
        type: FAILURE_FETCH_NEWS_CATEGORIES_LIST,
        payload: error,
      });
    });
};

export const fetchSitesList = () => dispatch => {
  dispatch({
    type: START_FETCH_SITES_LIST,
  });

  return getSitesListApi()
    .then(payload => {
      dispatch({
        type: SUCCESS_FETCH_SITES_LIST,
        payload: payload.data,
      });
    })
    .catch(error => {
      dispatch({
        type: FAILURE_FETCH_SITES_LIST,
        payload: error,
      });
    });
};

export const fetchLanguagesList = () => dispatch => {
  dispatch({
    type: START_FETCH_LANGUAGES_LIST,
  });

  return getLanguagesListApi()
    .then(payload => {
      dispatch({
        type: SUCCESS_FETCH_LANGUAGES_LIST,
        payload: payload.data,
      });
    })
    .catch(error => {
      dispatch({
        type: FAILURE_FETCH_LANGUAGES_LIST,
        payload: error,
      });
    });
};

export const fetchSitesListWithInformers = () => dispatch => {
  dispatch({
    type: START_FETCH_SITES_LIST_WITH_INFORMERS,
  });

  return getSitesListWithInformersApi()
    .then(payload => {
      dispatch({
        type: SUCCESS_FETCH_SITES_LIST_WITH_INFORMERS,
        payload: payload.data,
      });
    })
    .catch(error => {
      dispatch({
        type: FAILURE_FETCH_SITES_LIST_WITH_INFORMERS,
        payload: error,
      });
    });
};
